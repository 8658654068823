import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { any } from 'prop-types'
import classnames from 'classnames'
import { Avatar } from '../avatar'

export const UserAvatarStatus = ({ item }) => {
  const css = useStyles(styles)
  return (
    <div className={css('agent-name')}>
      <div className={css('agent-avatar')}>
        <Avatar url={item.profile?.avatarUrl} cls={css('avatar')} />
        <div className={css(classnames('user-status', item.status))}>
          {item.doNotDisturb && <div className={css('not-disturb')} />}
        </div>
      </div>
      <div className={css('name')}>{item.profile?.name || item.id}</div>
    </div>
  )
}

UserAvatarStatus.propTypes = {
  item: any
}
