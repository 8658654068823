import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { func, object } from 'prop-types'
import { ValueSelector } from '../shared/value-selector'
import { Input } from '@flomni/components/dist/components/input'
import styles from './index.module.scss'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import { useDispatch, useSelector } from 'react-redux'
import { Meteor } from '../../../../../../../../meteorAdapter';
import { processError } from '../../../../../../../services/helpers'

export const MetaDataFilterItem = ({ item, onChangeItemProperty }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const currentUserGroupId = useSelector(({ main }) => main.currentUserGroupId)

  const [fields, setFields] = useState([])
  const [selectedField, setSelectedField] = useState(item.field)
  const comparisons = ['equals', 'doesNotEqual', 'contains', 'doesNotContains', 'isSet', 'isNotSet']
  const [selectedComparison, setSelectedComparison] = useState(item.comparison)
  const [selectedMetaDataValue, setSelectedMetaDataValue] = useState(item.metaDataValue)

  useEffect(() => {
    Meteor.invoke('groups.getGroupMetaDataFields', {
      groupId: currentUserGroupId
    })
      .then((fields) => {
        setFields(
          fields.map((field) => {
            return {
              label: field.name,
              value: field._id
            }
          })
        )
      })
      .catch((err) => {
        processError(err, main)
      })
  }, [])

  const onSelectField = (field) => {
    setSelectedField(field)
    onChangeItemProperty('field', field)
  }

  const onSelectComparison = (comparison) => {
    setSelectedComparison(comparison)
    onChangeItemProperty('duration', comparison)
  }

  const onSelectMetaDataValue = (metaDataValue) => {
    setSelectedMetaDataValue(metaDataValue)
    onChangeItemProperty('metaDataValue', metaDataValue)
  }

  return (
    <div className={css('container')}>
      <ValueSelector
        items={fields}
        item={selectedField}
        onChange={onSelectField}
        placeholder={t('dlg:selectField')}
        translate={false}
      />
      <ValueSelector items={comparisons} item={selectedComparison} onChange={onSelectComparison} />
      <Input
        shaded
        autoFocus
        classes={{ root: css('value') }}
        variation='secondary'
        placeholder={t('dlg:value')}
        value={selectedMetaDataValue || ''}
        onChange={(e) => onSelectMetaDataValue(e.target.value)}
      />
    </div>
  )
}

MetaDataFilterItem.propTypes = {
  onChangeItemProperty: func.isRequired,
  item: object.isRequired
}
