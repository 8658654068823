import React, { useCallback, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { func, object } from 'prop-types'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { SvgIconDelete } from '@flomni/components/dist/components/svg/feathers/SvgIconDelete'
import { SvgIconFilter } from '@flomni/components/dist/components/svg/feathers/SvgIconFilter'
import { SvgIconCalendar } from '@flomni/components/dist/components/svg/feathers/SvgIconCalendar'
import { SvgIconUser } from '@flomni/components/dist/components/svg/feathers/SvgIconUser'
import { SvgIconTag } from '@flomni/components/dist/components/svg/feathers/SvgIconTag'
import { SvgIconSheet } from '@flomni/components/dist/components/svg/feathers/SvgIconSheet'
import { SvgIconQuestion } from '@flomni/components/dist/components/svg/feathers/SvgIconQuestion'
import { SvgIconClockFilled } from '@flomni/components/dist/components/svg/feathers/SvgIconClockFilled'
import { SvgIconStarFilled } from '@flomni/components/dist/components/svg/feathers/SvgIconStarFilled'
import { SvgIconLightiningFilled } from '@flomni/components/dist/components/svg/feathers/SvgIconLightiningFilled'
import { SvgIconChatIdFilled } from '@flomni/components/dist/components/svg/feathers/SvgIconChatIdFilled'
import { PlateButtonText } from '@flomni/components/dist/components/plate-button/text'
import { Dropdown } from '@flomni/components/dist/components/dropdown'
import { DateFilterItem } from './date'
import { ChatDurationFilterItem } from './chat-duration'
import { AgentFilterItem } from './agent'
import { TagFilterItem } from './tag'
import { TopicFilterItem } from './topic'
import { ChatStatusFilterItem } from './chat-status'
import { ChatRateFilterItem } from './chat-rate'
import { MetaDataFilterItem } from './metadata'
import { ChatIdFilterItem } from './chat-id'
import { SecondaryDropdownItem } from '@flomni/components/dist/components/secondary-dropdown-item/item'
import { SecondaryDropdownItemText } from '@flomni/components/dist/components/secondary-dropdown-item/text'
import { SecondaryDropdownItemIcon } from '@flomni/components/dist/components/secondary-dropdown-item/icon'
import { DATE_SELECTOR, FILTER_PROPERTY, PERIOD } from '../constants'
import classnames from 'classnames'
import { ChatMessagesFilterItem } from './chat-messages'
import { ChannelFilterItem } from './channel'

export const FilterItem = ({ item, onDelete, onChange }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const [showPropertiesList, setShowPropertiesList] = useState(false)
  const [selectedProperty, setSelectedProperty] = useState(item.property)

  const DEFAULTS = {
    [FILTER_PROPERTY.DATE]: {
      selector: DATE_SELECTOR.IS_TODAY,
      tz: 'UTC',
      period: PERIOD.DAYS
    },
    [FILTER_PROPERTY.AGENT]: {
      agentType: null,
      agentId: null
    },
    [FILTER_PROPERTY.TAG]: {
      tagId: null
    },
    [FILTER_PROPERTY.CHANNEL]: {
      channelId: null
    },
    [FILTER_PROPERTY.METADATA_FIELD]: {
      field: null,
      comparison: 'equals',
      metaDataValue: null
    },
    [FILTER_PROPERTY.TOPIC]: {
      topicId: null
    },
    [FILTER_PROPERTY.CHAT_DURATION]: {
      duration: 'moreThan',
      durationValue: null
    },
    [FILTER_PROPERTY.CHAT_RATE]: {
      rate: null
    },
    [FILTER_PROPERTY.CHAT_STATUS]: {
      status: null
    },
    [FILTER_PROPERTY.CHAT_ID]: {
      chatIdEqual: true,
      chatId: null
    },
    [FILTER_PROPERTY.CHAT_MESSAGES]: {
      message: ''
    }
  }

  const PROPERTY_ICON = {
    [FILTER_PROPERTY.DATE]: <SvgIconCalendar />,
    [FILTER_PROPERTY.AGENT]: <SvgIconUser />,
    [FILTER_PROPERTY.TAG]: <SvgIconTag />,
    [FILTER_PROPERTY.METADATA_FIELD]: <SvgIconSheet />,
    [FILTER_PROPERTY.TOPIC]: <SvgIconQuestion />,
    [FILTER_PROPERTY.CHAT_DURATION]: <SvgIconClockFilled />,
    [FILTER_PROPERTY.CHAT_RATE]: <SvgIconStarFilled />,
    [FILTER_PROPERTY.CHAT_STATUS]: <SvgIconLightiningFilled />,
    [FILTER_PROPERTY.CHAT_ID]: <SvgIconChatIdFilled />,
    [FILTER_PROPERTY.CHAT_MESSAGES]: <SvgIconChatIdFilled />,
    [FILTER_PROPERTY.CHANNEL]: <SvgIconChatIdFilled />
  }

  const PROPERTY_FILTER_ITEM = {
    [FILTER_PROPERTY.DATE]: DateFilterItem,
    [FILTER_PROPERTY.AGENT]: AgentFilterItem,
    [FILTER_PROPERTY.TAG]: TagFilterItem,
    [FILTER_PROPERTY.METADATA_FIELD]: MetaDataFilterItem,
    [FILTER_PROPERTY.TOPIC]: TopicFilterItem,
    [FILTER_PROPERTY.CHAT_DURATION]: ChatDurationFilterItem,
    [FILTER_PROPERTY.CHAT_RATE]: ChatRateFilterItem,
    [FILTER_PROPERTY.CHAT_STATUS]: ChatStatusFilterItem,
    [FILTER_PROPERTY.CHAT_ID]: ChatIdFilterItem,
    [FILTER_PROPERTY.CHAT_MESSAGES]: ChatMessagesFilterItem,
    [FILTER_PROPERTY.CHANNEL]: ChannelFilterItem
  }

  const DISABLED_PROPERTIES = [FILTER_PROPERTY.CHAT_DURATION, FILTER_PROPERTY.METADATA_FIELD]

  const onOverlayHandler = useCallback(() => {
    setShowPropertiesList(false)
  }, [])

  const onSelectProperty = (property) => {
    setShowPropertiesList(false)
    setSelectedProperty(property)
    onChange({
      ...item,
      property,
      ...DEFAULTS[property]
    })
  }

  const onChangeItemProperty = (property, value) => {
    onChange({
      ...item,
      [property]: value
    })
  }

  const isMiddlePartFlex = item.property === FILTER_PROPERTY.CHAT_ID && !item.chatIdEqual

  const PropertyFilterItem = selectedProperty ? PROPERTY_FILTER_ITEM[selectedProperty] : null

  return (
    <div className={css(classnames('container', isMiddlePartFlex ? '--middle-flex' : null))}>
      <div>
        <PlateButton view={selectedProperty ? 'usual' : 'bright'} onClick={() => setShowPropertiesList(true)}>
          <PlateButtonIcon>
            {selectedProperty ? PROPERTY_ICON[selectedProperty] : <SvgIconFilter />}
          </PlateButtonIcon>
          <PlateButtonText>{t(`dlg:${selectedProperty || 'selectProperty'}`)}</PlateButtonText>
        </PlateButton>
        <Dropdown
          active={showPropertiesList}
          variation='secondary'
          classes={{ root: css('selector') }}
          offsetTarget='iconButton'
          onOverlayClick={onOverlayHandler}
        >
          {Object.values(FILTER_PROPERTY).map((property) => (
            <SecondaryDropdownItem
              key={property}
              view='usual'
              disabled={DISABLED_PROPERTIES.includes(property)}
              active={selectedProperty === property}
              onClick={() => onSelectProperty(property)}
            >
              <SecondaryDropdownItemIcon>{PROPERTY_ICON[property]}</SecondaryDropdownItemIcon>
              <SecondaryDropdownItemText>{t(`dlg:${property}`)}</SecondaryDropdownItemText>
            </SecondaryDropdownItem>
          ))}
        </Dropdown>
      </div>
      {PropertyFilterItem && <PropertyFilterItem item={item} onChangeItemProperty={onChangeItemProperty} />}
      <PlateButton noBg hoverType='warning' onClick={onDelete}>
        <PlateButtonIcon>
          <SvgIconDelete />
        </PlateButtonIcon>
      </PlateButton>
    </div>
  )
}

FilterItem.propTypes = {
  item: object.isRequired,
  onChange: func.isRequired,
  onDelete: func.isRequired
}
