import React, { useContext } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { array, bool } from 'prop-types'
import { Spinner } from '@flomni/components/dist/components/spinner'
import { store } from '../../../../../../state/dialogs'
import { ChatItem } from '../../../conversations/Conversations/left-panel/shared/chat-item'
import { ChatItemInfo } from '../../../conversations/Conversations/left-panel/shared/chat-item-info'

export const CompletedList = ({ chats, loading }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { selectedDialogId, dispatch } = useContext(store)

  return (
    <div className={css('completed-body')}>
      <div className={css('chat-items')}>
        {chats.map((chat) => (
          <ChatItem
            key={chat._id}
            chat={chat}
            isSelected={selectedDialogId === chat._id}
            isInboundCountVisible={false}
            onSelect={() => dispatch({ type: 'setSelectedDialog', selectedDialog: chat })}
          />
        ))}
        {!loading && chats.length === 0 && (
          <ChatItemInfo
            icon='hand'
            title={t('dlg:noCompletedChatsYet')}
            description={t('dlg:completedChatsShowHere')}
          />
        )}
        {loading && (
          <div className={css('loader')}>
            <div className={css('spinner')}>
              <Spinner strokeWidth={5} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

CompletedList.props = {
  chats: array,
  loading: bool
}
