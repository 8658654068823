import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { Input } from '@flomni/components/dist/components/input'
import { ControlContainer } from '@flomni/components/dist/components/control-container'
import { useForm } from 'react-hook-form'
import { Meteor, Match } from '../../../meteorAdapter';
import { ButtonWithLoader } from '../shared/button-with-loader'
import { Tooltip } from '@flomni/components/dist/components/tooltip'
import classnames from 'classnames'
import { isMobileMode } from '../../services/helpers'

export const Login = () => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [isHide, setIsHide] = useState(false)
  const [invalidLoginError, setInvalidLoginError] = useState(null)
  const { register, formState, errors, getValues } = useForm({
    mode: 'onChange'
  })

  const handlerClickLogin = async (event) => {
    event.preventDefault()
    setInvalidLoginError(null)
    setIsLoading(true)

    const form = getValues()
    Meteor.loginWithPassword(form.email, form.password, (err) => {
      if (err) {
        setInvalidLoginError(err)
      } else {
        setIsHide(true)
        Meteor.logoutOtherClients()
      }
      setIsLoading(false)
    })
  }

  const getPasswordErrorMessage = () => {
    if (errors.password) {
      return errors.password.message
    }
    if (invalidLoginError?.error === 'too-many-agents') {
      return t('dlg:tooManyAgents')
    }
    if (invalidLoginError?.error === 'ip-rejected') {
      return t('dlg:ipRejected')
    }
    if (invalidLoginError?.error === 'access-forbidden') {
      const { prefix = '' } = invalidLoginError.details
      return t('dlg:accessForbidden', { prefix })
    }
    if (invalidLoginError?.error === 'too-many-requests') {
      const timeToReset = Math.ceil(invalidLoginError.details.timeToReset / 1000)
      return t('dlg:tooManyRequest', { timeToReset })
    }
    if (invalidLoginError?.error === 401) {
      return t('dlg:userBlocked')
    }
    if (invalidLoginError?.error === 403) {
      return t('dlg:incorrectEmailOrPassword')
    }
    if (invalidLoginError?.error === 404) {
      return t('dlg:accountIsRemoved')
    }
    if (Match.isNonEmptyString(invalidLoginError?.reason)) {
      return invalidLoginError.reason
    }
    return null
  }

  const TooltipContent = () => {
    return (
      <>
        <div>{t('dlg:forgotYourPassword')}</div>
        <div className={css('tooltip-body')}>{t('dlg:forgotYourPasswordDescription')}</div>
      </>
    )
  }

  return (
    <div className={css(classnames('main', isMobileMode ? '--mobile' : null))}>
      <div className={css('header')} />
      <div className={css('container')}>
        <div />
        <div className={css('center')}>
          <div className={css('center-content')}>
            <div className={css(classnames('hide', isHide ? '--active' : null))}>
              <div className={css('logo')} />
              <div className={css('form')}>
                <form>
                  <div className={css('title')}>{t('dlg:signInToAccount')}</div>
                  <div className={css('title-block')}>{t('dlg:email')}</div>
                  <ControlContainer
                    variation='secondary'
                    warning={errors.email ? errors.email.message : null}
                  >
                    <Input
                      variation='secondary'
                      autoFocus
                      tall
                      placeholder={t('dlg:email')}
                      warning={!!errors.email}
                      name='email'
                      ref={register({
                        required: t('dlg:emailRequired'),
                        validate: (value) => {
                          return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.trim())
                            ? true
                            : t('dlg:invalidEmailAddress')
                        }
                      })}
                    />
                  </ControlContainer>
                  <div className={css('title-block')}>
                    <span>{t('dlg:password')}</span>
                    <div>
                      <Tooltip variation='secondary' content={<TooltipContent />}>
                        <span className={css('link')}>{t('dlg:forgotYourPassword')}</span>
                      </Tooltip>
                    </div>
                  </div>
                  <ControlContainer variation='secondary' warning={getPasswordErrorMessage()}>
                    <Input
                      variation='secondary'
                      tall
                      placeholder={t('dlg:password')}
                      warning={!!errors.password}
                      name='password'
                      type='password'
                      ref={register({ required: t('dlg:passwordRequired') })}
                    />
                  </ControlContainer>
                  <ButtonWithLoader
                    size='full'
                    text={t('dlg:signIn')}
                    cls={css('login-btn')}
                    onClick={handlerClickLogin}
                    disabled={!formState.isValid || !formState.isDirty}
                    isLoading={isLoading}
                    spinnerSize={23}
                    spinnerStrokeWidth={7}
                    hideContentOnLoading
                  />
                </form>
              </div>
            </div>
            <div className={css('footer')}>
              <a className={css('footer-link')} href='https://flomni.com/' target='_blank' rel="noreferrer">
                @ Flomni
              </a>
              <div className={css('point')} />
              <a className={css('footer-link')} href='https://docs.flomni.com/' target='_blank' rel="noreferrer">
                {t('dlg:helpCenter')}
              </a>
              <div className={css('point')} />
              <a className={css('footer-link')} href='https://flomni.com/privacy/' target='_blank' rel="noreferrer">
                {t('dlg:privacyAndTerms')}
              </a>
            </div>
          </div>
        </div>
        <div />
      </div>
    </div>
  )
}
