import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { string } from 'prop-types'

export const ChatItemInfo = ({ icon, title, description }) => {
  const css = useStyles(styles)

  return (
    <div className={css('item')}>
      <div className={css(['icon', icon])} />
      <div className={css('message')}>
        <div className={css('title')}>{title}</div>
        <div className={css('description')}>{description}</div>
      </div>
    </div>
  )
}

ChatItemInfo.props = {
  icon: string.isRequired,
  title: string.isRequired,
  description: string.isRequired
}
