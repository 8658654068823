import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { ROUTE, routes } from '../../../../../configs/routes'
import { AccountInterface } from './interface'
import { AccountTemplateSuggestions } from './template-suggestions'
import { Meteor, useTracker } from '../../../../../../meteorAdapter';
import { useSelector } from 'react-redux'

export const Account = () => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const user = useTracker(() => Meteor.user(), [])
  const currentUserTheme = useSelector(({ main }) => main.currentUserTheme)
  const currentUserMegabrainSettings = useSelector(({ main }) => main.currentUserMegabrainSettings)

  return (
    <div className={css('main')}>
      <div className={css('container')}>
        <div className={css('nav-bar')}>
          <NavLink className={css('link')} to={routes[ROUTE.SETTINGS]}>
            {t('dlg:dialogsSettings')}
          </NavLink>
          <div>></div>
          <div className={css('active')}>{t('dlg:accountConfiguration')}</div>
        </div>
        <div>
          {currentUserTheme && <AccountInterface currentUserTheme={currentUserTheme} />}
          {user && currentUserMegabrainSettings.allowSuggestions && (
            <AccountTemplateSuggestions user={user} />
          )}
        </div>
      </div>
    </div>
  )
}
