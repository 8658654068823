import React, { useContext, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { Popup } from '@flomni/components/dist/components/popup/popup'
import { PopupButtons } from '@flomni/components/dist/components/popup/buttons'
import { PopupContent } from '@flomni/components/dist/components/popup/content'
import { PopupTitle } from '@flomni/components/dist/components/popup/title'

import { func, string } from 'prop-types'
import { useForm } from 'react-hook-form'
import { ButtonWithLoader } from '../../../../../../shared/button-with-loader'
import { Meteor } from '../../../../../../../../meteorAdapter';
import { useDispatch } from 'react-redux'
import { store } from '../../../../../../../state/dialogs'
import { processError } from '../../../../../../../services/helpers'
import { ControlContainer } from '@flomni/components/dist/components/control-container'
import { Input } from '@flomni/components/dist/components/input'

const EditNameDialog = ({ chatName, onEdited, onCancel }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const { selectedDialogId } = useContext(store)
  const [showLoader, setShowLoader] = useState(false)

  const { register, formState, getValues, errors } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: chatName
    }
  })

  const handlerClickSave = async (event) => {
    event.preventDefault()
    setShowLoader(true)
    await new Promise((resolve) => setTimeout(resolve, 800))

    try {
      const values = getValues()
      await Meteor.invoke('renameDialogClient', selectedDialogId, values.name)
      setShowLoader(false)
      onEdited()
    } catch (err) {
      setShowLoader(false)
      processError(err, main)
    }
  }

  const handlerClickOnCancel = (event) => {
    event.preventDefault()
    onCancel()
  }

  return (
    <Popup open variation='secondary'>
      <PopupTitle title={t('dlg:editClientsName')} />
      <form>
        <PopupContent classes={{ root: css('content') }}>
          <div className={css('line')}>
            <div className={css('title')}>{t('dlg:fullName')}</div>
            <ControlContainer variation='secondary' warning={errors.name ? errors.name.message : null}>
              <Input
                autoFocus
                variation='secondary'
                classes={{ root: css('field') }}
                placeholder={t('dlg:fullName')}
                warning={!!errors.name}
                name='name'
                ref={register({ required: t('dlg:fullNameRequired') })}
              />
            </ControlContainer>
          </div>
        </PopupContent>
        <PopupButtons>
          <SecondaryButton view='light' onClick={handlerClickOnCancel}>
            <SecondaryButtonText>{t('dlg:close')}</SecondaryButtonText>
          </SecondaryButton>
          <ButtonWithLoader
            view='usual'
            text={t('dlg:update')}
            disabled={!formState.isValid || !formState.isDirty}
            onClick={handlerClickSave}
            spinnerSize={10}
            spinnerStrokeWidth={14}
            isLoading={showLoader}
          />
        </PopupButtons>
      </form>
    </Popup>
  )
}

EditNameDialog.propTypes = {
  chatName: string,
  onEdited: func.isRequired,
  onCancel: func.isRequired
}

export default EditNameDialog
