import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { func, object } from 'prop-types'
import { SvgIconLightiningFilled } from '@flomni/components/dist/components/svg/feathers/SvgIconLightiningFilled'
import { useTranslation } from 'react-i18next'

export const AttachmentAction = ({ action, onChange }) => {
  const { t } = useTranslation()
  const css = useStyles(styles)

  return (
    <>
      <div className={css('container')} onClick={onChange}>
        <div className={css('header')}>
          <SvgIconLightiningFilled />
          <div className={css('name')}>{action.name}</div>
        </div>
        <div className={css('text')}>{t('dlg:chatbotAction')}</div>
        <div className={css('change')}>{t('dlg:clickToChangeAction')}</div>
      </div>
    </>
  )
}

AttachmentAction.propTypes = {
  action: object.isRequired,
  onChange: func.isRequired
}
