// originally file was located at /lib/helpers/dialogHelpers.js in meteor
import { Meteor } from '../../meteorAdapter'
import moment from 'moment'

const getCurrentUserId = () => Meteor.userId()

const DIALOG_CHAT_TEMPLATE_SUBSTITUTES = [
  {
    key: '@{name}',
    cb (find, text = '', { clientName } = {}) {
      const replace = typeof clientName === 'string'
        ? clientName
        : 'N/A'

      return text.replace(new RegExp(find, 'g'), replace)
    }
  },
  {
    key: '@{date}',
    cb (find, text = '') {
      const replace = moment().format('YYYY-MM-DD')

      return text.replace(new RegExp(find, 'g'), replace)
    }
  },
  {
    key: '@{date,',
    cb (find, text = '') {
      const regex = /(@{date,[\\w ]*['\"]([A-Za-z- .\\\\s:]+)['\"][\\w ]*})/gim // eslint-disable-line no-useless-escape

      return text.replace(regex, (match, group1, group2) => moment().format(group2))
    }
  },
  {
    key: '@{agent}',
    cb (find, text = '', { agentId = getCurrentUserId() } = {}) {
      const currentUser = Meteor.users.findOne(agentId, {
        fields: {
          emails: 1
        }
      }) || {}

      const replace = currentUser.emails?.[0]?.address
      const replacement = typeof replace === 'string'
        ? replace
        : ''

      return text.replace(new RegExp(find, 'g'), replacement)
    }
  },
  {
    key: '@{agentName}',
    cb (find, text = '', { agentId = getCurrentUserId() } = {}) {
      const currentUser = Meteor.users.findOne(agentId, {
        fields: {
          'profile.name': 1
        }
      }) || {}

      const replace = currentUser.profile?.name
      const replacement = typeof replace === 'string'
        ? replace
        : ''

      return text.replace(new RegExp(find, 'g'), replacement)
    }
  }
]

export default class DialogHelpers {
  static populateTemplatePlaceholders (text = '', data = {}) {
    DIALOG_CHAT_TEMPLATE_SUBSTITUTES.forEach(item => {
      if (text.search(item.key) >= 0) {
        text = item.cb(item.key, text, data)
      }
    })
    return text
  }
}
