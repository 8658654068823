import React, { useRef, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { Popup } from '@flomni/components/dist/components/popup'
import { PopupButtons } from '@flomni/components/dist/components/popup/buttons'
import { PopupContent } from '@flomni/components/dist/components/popup/content'
import { PopupTitle } from '@flomni/components/dist/components/popup/title'
import { array, func, string } from 'prop-types'
import { useForm } from 'react-hook-form'
import { ControlContainer } from '@flomni/components/dist/components/control-container'
import { Input } from '@flomni/components/dist/components/input'
import { Select } from '@flomni/components/dist/components/select'
import { base64MimeType, processError, toBase64, urlToFile } from '../../../../../../services/helpers'
import { Avatar } from '../../../../shared/avatar'
import { Meteor } from '../../../../../../../meteorAdapter';
import { useDispatch } from 'react-redux'
import { ButtonWithLoader } from '../../../../../shared/button-with-loader'
import UploaderHelpers from '../../../../../../helpers/uploaderHelpers'

const AddMember = ({ onAdded, onCancel, departments, groupId }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const [showSaveLoader, setShowSaveLoader] = useState(false)

  const avatarInputFile = useRef(null)
  const { register, formState, errors, getValues, watch, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      name: '',
      departmentId: '',
      gender: '',
      avatarUrl: '',
      password: '',
      chatDistributionLimit: ''
    }
  })

  const avatarUrl = watch('avatarUrl')
  const departmentId = watch('departmentId')
  const gender = watch('gender')

  const handlerClickAdd = async (e) => {
    e.preventDefault()
    const values = getValues()
    const userData = {
      email: values.email,
      name: values.name,
      password: values.password,
      groupId,
      departmentId: values.departmentId,
      gender: values.gender,
      chatDistributionLimit: +values.chatDistributionLimit
    }
    try {
      setShowSaveLoader(true)
      await new Promise((resolve) => setTimeout(resolve, 800))
      const userId = await Meteor.invoke('users.addUser', userData)
      if (values.avatarUrl) {
        const fileAvatar = await urlToFile(values.avatarUrl, 'avatar', base64MimeType(values.avatarUrl))
        await UploaderHelpers.uploadAvatar(userId, fileAvatar)
      }
      setShowSaveLoader(false)
      onAdded()
    } catch (err) {
      setShowSaveLoader(false)
      processError(err, main)
    }
  }

  const handlerClickOnCancel = () => {
    onCancel()
  }

  const selectAvatar = (e) => {
    e.preventDefault()
    avatarInputFile.current.click()
  }

  const handleFileChange = (event) => {
    if (event.target.files.length) {
      toBase64(event.target.files[0]).then((url) => {
        setValue('avatarUrl', url, { shouldDirty: true })
      })
    }
  }

  const departmentOptions = departments.map((department) => {
    return {
      label: department.name,
      value: department._id
    }
  })
  const selectedDepartmentOption = departmentOptions.find((option) => option.value === departmentId)

  const genderOptions = [{
    label: t('dlg:male'),
    value: 'M'
  }, {
    label: t('dlg:female'),
    value: 'F'
  }]
  const selectedGenderOption = genderOptions.find((option) => option.value === gender)

  return (
    <Popup open variation='secondary'>
      <PopupTitle title={t('dlg:addAgentToTeam')} description={t('dlg:addAgentDescription')} />
      <form className={css('form')}>
        <PopupContent>
          <div className={css('group')}>
            <div className={css('title')}>{t('dlg:email')}</div>
            <ControlContainer variation='secondary' warning={errors.email ? errors.email.message : null}>
              <Input
                variation='secondary'
                classes={{ root: css('field') }}
                placeholder={t('dlg:email')}
                warning={!!errors.email}
                name='email'
                ref={register({
                  required: t('dlg:emailRequired'),
                  validate: (value) => {
                    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.trim())
                      ? true
                      : t('dlg:invalidEmailAddress')
                  }
                })}
              />
            </ControlContainer>
            <div className={css('description')}>{t('dlg:emailDescription')}</div>
          </div>
          <div className={css('group')}>
            <div className={css('title')}>{t('dlg:password')}</div>
            <ControlContainer
              variation='secondary'
              warning={errors.password ? errors.password.message : null}
            >
              <Input
                variation='secondary'
                type='password'
                classes={{ root: css('field') }}
                placeholder={t('dlg:password')}
                warning={!!errors.password}
                name='password'
                ref={register({
                  required: t('dlg:passwordRequired')
                })}
              />
            </ControlContainer>
          </div>
          <div className={css('group')}>
            <div className={css('title')}>{t('dlg:fullName')}</div>
            <ControlContainer variation='secondary' warning={errors.name ? errors.name.message : null}>
              <Input
                variation='secondary'
                classes={{ root: css('field') }}
                placeholder={t('dlg:fullName')}
                warning={!!errors.name}
                name='name'
                ref={register({
                  required: t('dlg:fullNameRequired')
                })}
              />
            </ControlContainer>
          </div>
          <div className={css('group')}>
            <div className={css('title')}>{t('dlg:gender')}</div>
            <input
              hidden
              name='gender'
              ref={register({})}
            />
            <ControlContainer
              variation='secondary'
              warning={errors.gender ? errors.gender.message : null}
            >
              <Select
                variation='secondary'
                className={css('field')}
                options={genderOptions}
                value={selectedGenderOption}
                menuPortalTarget={document.body}
                placeholder={t('dlg:isNotSelect')}
                onChange={(option) =>
                  setValue('gender', option.value, { shouldDirty: true, shouldValidate: true })
                }
              />
            </ControlContainer>
          </div>
          <div className={css('group')}>
            <div className={css('title')}>{t('dlg:group')}</div>
            <input
              hidden
              name='departmentId'
              ref={register({
                required: t('dlg:groupRequired')
              })}
            />
            <ControlContainer
              variation='secondary'
              warning={errors.departmentId ? errors.departmentId.message : null}
            >
              <Select
                variation='secondary'
                className={css('field')}
                options={departmentOptions}
                menuPortalTarget={document.body}
                placeholder={t('dlg:noGroup')}
                value={selectedDepartmentOption}
                onChange={(option) =>
                  setValue('departmentId', option.value, { shouldDirty: true, shouldValidate: true })
                }
              />
            </ControlContainer>
          </div>
          <div className={css('group')}>
            <div className={css('title')}>{t('dlg:personalChatDistributionLimit')}</div>
            <ControlContainer variation='secondary' warning={errors.chatDistributionLimit ? errors.chatDistributionLimit.message : null}>
              <Input
                type='number'
                min='0'
                max='20'
                variation='secondary'
                classes={{ root: css('field') }}
                placeholder={t('dlg:typeDistributionLimit')}
                warning={!!errors.chatDistributionLimit}
                name='chatDistributionLimit'
                ref={register()}
              />
            </ControlContainer>
          </div>
          <div className={css('group')}>
            <div className={css('title')}>{t('dlg:avatar')}</div>
            <div className={css(['field', 'avatar-block'])}>
              <Avatar url={avatarUrl} />
              <SecondaryButton onClick={selectAvatar}>
                <SecondaryButtonText>{t('dlg:upload')}</SecondaryButtonText>
              </SecondaryButton>
              <input hidden name='avatarUrl' ref={register()} />
              <input
                type='file'
                name='file'
                accept='image/*'
                ref={avatarInputFile}
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </div>
          </div>
        </PopupContent>
        <PopupButtons>
          <SecondaryButton view='light' onClick={handlerClickOnCancel}>
            <SecondaryButtonText>{t('dlg:cancel')}</SecondaryButtonText>
          </SecondaryButton>
          <ButtonWithLoader
            text={t('dlg:addAgent')}
            disabled={!formState.isValid || !formState.isDirty}
            onClick={handlerClickAdd}
            spinnerSize={10}
            spinnerStrokeWidth={14}
            isLoading={showSaveLoader}
          />
        </PopupButtons>
      </form>
    </Popup>
  )
}

AddMember.propTypes = {
  onAdded: func.isRequired,
  onCancel: func.isRequired,
  departments: array,
  groupId: string.isRequired
}

export default AddMember
