import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { array, bool, func, object } from 'prop-types'
import { RightPanel } from '../../Conversations/right-panel'
import { SvgIconClose } from '@flomni/components/dist/components/svg/feathers/SvgIconClose'
import { SvgIconMenuDots } from '@flomni/components/dist/components/svg/feathers/SvgIconMenuDots'
import { useTranslation } from 'react-i18next'
import { MenuPage } from './menu-page'

export const ClientDetails = ({
  banResult,
  updateBanResult,
  onClose,
  crmFields,
  relatedChats,
  recipient,
  metaDataLoading,
  setCrmFields,
  setRecipient
}) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const [showMenu, setShowMenu] = useState(false)

  return (
    <div className={css('main')}>
      <div className={css('header')}>
        <div className={css('side-container')}>
          <div className={css('icon')} onClick={onClose}>
            <SvgIconClose />
          </div>
        </div>
        <div className={css('text')}>{t('dlg:details')}</div>
        <div className={css('side-container')}>
          <div className={css('icon')} onClick={() => setShowMenu(true)}>
            <SvgIconMenuDots />
          </div>
        </div>
      </div>
      <div className={css('container')}>
        <RightPanel
          banResult={banResult}
          updateBanResult={updateBanResult}
          crmFields={crmFields}
          relatedChats={relatedChats}
          recipient={recipient}
          metaDataLoading={metaDataLoading}
          setCrmFields={setCrmFields}
          setRecipient={setRecipient}
        />
      </div>
      {showMenu && (
        <MenuPage
          onClose={() => setShowMenu(false)}
          banResult={banResult}
          updateBanResult={updateBanResult}
        />
      )}
    </div>
  )
}

ClientDetails.propTypes = {
  banResult: object,
  updateBanResult: func,
  onClose: func,
  crmFields: array,
  relatedChats: array,
  recipient: object,
  metaDataLoading: bool,
  setCrmFields: func.isRequired,
  setRecipient: func.isRequired
}
