import React from 'react'
import styles from '../../index.module.scss'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { mainSelectors } from '../../../../../../state/main/selectors'
import useStyles from '@flomni/modules/dist/helpers/useStyles'

const SettingsPolicies = () => {
  const css = useStyles(styles)

  const { t } = useTranslation()

  const isCurrentUserOperator = useSelector(mainSelectors.getIsUserOperator)

  const renderContent = () => {
    if (isCurrentUserOperator) {
      return null
    }

    return (
      <>
        <div className={css('item-header')}>{t('dlg:processingPolicies')}</div>
        <div className={css('links')}>
          <div className={css(['link', '--disabled'])}>{t('dlg:chatsDistribution')}</div>
          <div className={css(['link', '--disabled'])}>{t('dlg:chatsArchiving')}</div>
          <div className={css(['link', '--disabled'])}>{t('dlg:chatsVisibility')}</div>
          <div className={css(['link', '--disabled'])}>{t('dlg:channelPrioritization')}</div>
          <div className={css(['link', '--disabled'])}>{t('dlg:agentsGroups')}</div>
          <div className={css(['link', '--disabled'])}>{t('dlg:agentsStatuses')}</div>
        </div>
      </>
    )
  }

  return (
    <div className={css(['items', '--border-right'])}>
      {
        renderContent()
      }
    </div>
  )
}

export {
  SettingsPolicies
}
