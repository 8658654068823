export const ROUTE = {
  DASHBOARD: 'dashboard',
  SEARCH: 'search',
  CONVERSATIONS: 'conversations',
  CHATBOT: 'chatbot',
  EVENTS: 'events',
  SETTINGS: 'settings',
  PROFILE: 'profile',
  APPLICATION_INFO: 'application-info',
  NETWORK_CONNECTION: 'network-connection',
  CHAT_DISTRIBUTION: 'chat-distribution',
  ACCOUNT: 'account',
  NOTIFICATIONS: 'notifications',
  TEAM: 'team',
  GROUP: 'group',
  MESSAGE_TEMPLATES: 'message-templates',
  MESSAGE_BUTTONS: 'message-buttons',
  WORKFORCE_MANAGEMENT: 'workforce-management'
}

export const routes = {
  [ROUTE.DASHBOARD]: '/dashboard',
  [ROUTE.SEARCH]: '/search',
  [ROUTE.CONVERSATIONS]: '/conversations',
  [ROUTE.CHATBOT]: '/chatbot',
  [ROUTE.EVENTS]: '/events',
  [ROUTE.SETTINGS]: '/settings',
  [ROUTE.PROFILE]: '/settings/profile',
  [ROUTE.APPLICATION_INFO]: '/settings/application-info',
  [ROUTE.NETWORK_CONNECTION]: '/settings/network-connection',
  [ROUTE.CHAT_DISTRIBUTION]: '/settings/chat-distribution',
  [ROUTE.ACCOUNT]: '/settings/account',
  [ROUTE.NOTIFICATIONS]: '/settings/notifications',
  [ROUTE.TEAM]: '/settings/team',
  [ROUTE.GROUP]: '/settings/group',
  [ROUTE.MESSAGE_TEMPLATES]: '/settings/message-templates',
  [ROUTE.MESSAGE_BUTTONS]: '/settings/message-buttons',
  [ROUTE.WORKFORCE_MANAGEMENT]: '/settings/workforce-management'
}
