import React, { createContext, useReducer } from 'react'
import { Meteor, Match } from '../../meteorAdapter';

const initialState = {
  selectedDialogId: null,
  editingMessage: null,
  sortOrder: -1,
  sortFieldName: 'lastMessageAt',
  filterUsers: null,
  filterTags: null,
  filterStatuses: null,
  isEditMode: false,
  selectedDialogsTabIndex: 0
}
const store = createContext(initialState)
const { Provider } = store

const DialogsProvider = ({ children, isEditMode = false, isSearchMode = false, selectedDialogsTabIndex = 0 }) => {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'setSortOrder':
          return {
            ...state,
            sortOrder: action.sortOrder
          }
        case 'setSortFieldName':
          return {
            ...state,
            sortFieldName: action.sortFieldName
          }
        case 'setFilterUsers':
          return {
            ...state,
            filterUsers: action.filterUsers
          }
        case 'setFilterTags':
          return {
            ...state,
            filterTags: action.filterTags
          }
        case 'setFilterStatuses':
          return {
            ...state,
            filterStatuses: action.filterStatuses
          }
        case 'setEditingMessage':
          return {
            ...state,
            editingMessage: action.editingMessage
          }
        case 'setSelectedDialog':
          if (
            action.selectedDialog?.passiveMembers?.includes(Meteor.userId()) &&
            Match.isDocumentId(action.selectedDialog?._id)
          ) {
            Meteor.emitServerEvent('dialogs.dialog.detectedPassiveMemberActivity', {
              dialogId: action.selectedDialog._id
            })
          }

          return {
            ...state,
            selectedDialogId: action.selectedDialog ? action.selectedDialog._id : null
          }
        case 'setRelatedDialogsIds':
          return state = {
            ...state,
            relatedDialogIds: action.relatedDialogsIds
          }
        case 'setSelectedDialogsTabIndex':
          return {
            ...state,
            selectedDialogsTabIndex: action.selectedDialogsTabIndex
          }
        default:
          throw new Error()
      }
    },
    {
      ...initialState,
      isEditMode,
      isSearchMode,
      selectedDialogsTabIndex
    }
  )

  return <Provider value={{ ...state, dispatch }}>{children}</Provider>
}

export { store, DialogsProvider }
