import React, { useEffect, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { Popup } from '@flomni/components/dist/components/popup/popup'
import { PopupButtons } from '@flomni/components/dist/components/popup/buttons'
import { PopupContent } from '@flomni/components/dist/components/popup/content'
import { PopupTitle } from '@flomni/components/dist/components/popup/title'
import { func, object } from 'prop-types'
import { Tip } from '@flomni/components/dist/components/tip'
import { Select } from '@flomni/components/dist/components/select'
import { Meteor } from '../../../../../../../../../../../meteorAdapter';
import { processError } from '../../../../../../../../../../services/helpers'
import { useDispatch } from 'react-redux'
import { ButtonWithLoader } from '../../../../../../../../../shared/button-with-loader'

const MegabrainSendMessage = ({ message, onCancel }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const [topicId, setTopicId] = useState(null)
  const [topics, setTopics] = useState([])
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    Meteor.invoke('mbrain.TextLabeling.stories.topics.get')
      .then((res) => {
        if (res.status !== 'error') {
          setTopics(res.msg.topics || [])
        }
      })
      .catch((err) => {
        processError(err, main)
      })
  }, [])

  const handlerClickOnCancel = (event) => {
    event.preventDefault()
    onCancel()
  }

  const sendToMegabrain = () => {
    setShowLoader(true)
    Meteor.invoke('mbrain.TextLabeling.messages.post', {
      messages: [message.text],
      topics: [topicId]
    })
      .then(() => {
        onCancel()
      })
      .catch((err) => {
        processError(err, main)
      })
      .finally(() => {
        setShowLoader(false)
      })
  }

  const topicOptions = topics.map((topic) => {
    return {
      label: topic.name,
      value: topic.id
    }
  })
  const selectedTopicOption = topicOptions.find((option) => option.value === topicId) || null

  return (
    <Popup open variation='secondary'>
      <PopupTitle title={t('dlg:sendMessageToMegabrainInbox')} />
      <PopupContent classes={{ root: css('content') }}>
        <div className={css('tip-block')}>
          <Tip variation='secondary' view='info'>
            {t('dlg:sendMessageToMegabrainInboxTip')}
          </Tip>
        </div>
        <div className={css('line')}>
          <div className={css('title')}>{t('dlg:usersMessage')}</div>
          <div className={css('message')}>{message.text}</div>
        </div>
        <div className={css(['line', '--top-shadow'])}>
          <div className={css('title')}>{t('dlg:topic')}</div>
          <div className={css('field')}>
            <Select
              variation='secondary'
              placeholder={t('dlg:selectTopic')}
              options={topicOptions}
              value={selectedTopicOption}
              menuPortalTarget={document.body}
              onChange={(option) => {
                setTopicId(option.value)
              }}
            />
          </div>
          <div className={css('send-btn')}>
            <ButtonWithLoader
              view='usual'
              text={t('dlg:sendToMegabrain')}
              disabled={!topicId}
              onClick={sendToMegabrain}
              spinnerSize={10}
              spinnerStrokeWidth={14}
              isLoading={showLoader}
            />
          </div>
        </div>
      </PopupContent>
      <PopupButtons>
        <SecondaryButton view='light' onClick={handlerClickOnCancel}>
          <SecondaryButtonText>{t('dlg:close')}</SecondaryButtonText>
        </SecondaryButton>
      </PopupButtons>
    </Popup>
  )
}

MegabrainSendMessage.propTypes = {
  message: object.isRequired,
  onCancel: func.isRequired
}

export default MegabrainSendMessage
