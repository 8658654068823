import React, { useEffect, useMemo, useState, useCallback } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { ROUTE, routes } from '../../../../../configs/routes'
import { Input } from '@flomni/components/dist/components/input'
import { Tag } from '@flomni/components/dist/components/tag'
import { SvgIconSearch } from '@flomni/components/dist/components/svg/feathers/SvgIconSearch'
import { Meteor, useTracker } from '../../../../../../meteorAdapter';
import { Spinner } from '@flomni/components/dist/components/spinner'
import { processError } from '../../../../../services/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { DateUtils } from '@flomni/modules/dist/services/date'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { SvgIconMenuDots } from '@flomni/components/dist/components/svg/feathers/SvgIconMenuDots'
import { Dropdown } from '@flomni/components/dist/components/dropdown'
import { SecondaryDropdownItem } from '@flomni/components/dist/components/secondary-dropdown-item/item'
import { SecondaryDropdownItemText } from '@flomni/components/dist/components/secondary-dropdown-item/text'
import { Tags } from '../../../../../customStores'
import { CheckboxOption } from '@flomni/components/dist/components/checkbox-option'

const MoreInfo = ({ group }) => {
  const { t } = useTranslation()
  const [showMenu, setShowMenu] = useState(false)

  const onOverlayClickHandler = useCallback(() => {
    setShowMenu(false)
  }, [])

  return (
    <div>
      <PlateButton noBg onClick={() => setShowMenu(true)}>
        <PlateButtonIcon>
          <SvgIconMenuDots />
        </PlateButtonIcon>
      </PlateButton>
      {showMenu && (
        <Dropdown
          active
          variation='secondary'
          position='right'
          offsetTarget='iconButton'
          onOverlayClick={onOverlayClickHandler}
        >
          <SecondaryDropdownItem view='bright' disabled>
            <SecondaryDropdownItemText>
              <CheckboxOption checked={group.allowUntaggedDialogs} variation='secondary' onChange={() => {}} disabled>
                {t('dlg:allowToDistributeUntaggedChats')}
              </CheckboxOption>
            </SecondaryDropdownItemText>
          </SecondaryDropdownItem>
          <SecondaryDropdownItem view='bright' disabled>
            <SecondaryDropdownItemText>
              <CheckboxOption checked={group.hasLimitPersonalChats} variation='secondary' onChange={() => {}} disabled>
                {t('dlg:limitNumberOfActiveChats')}
              </CheckboxOption>
            </SecondaryDropdownItemText>
          </SecondaryDropdownItem>
        </Dropdown>
      )}
    </div>
  )
}

export const AgentGroups = () => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const [groups, setGroups] = useState([])
  const [search, setSearch] = useState('')
  const [showLoader, setShowLoader] = useState(true)
  const currentUserGroupId = useSelector(({ main }) => main.currentUserGroupId)
  const [tags, setTags] = useState([])

  useTracker(() => {
    setTags(Tags.find({}, {
      fields: {
        name: 1
      }
    }).map(({ _id, name }) => ({
      id: _id,
      name
    })))
  }, [])

  useEffect(() => {
    if (currentUserGroupId) {
      Meteor.invoke('groups.getGroupDepartments', { groupId: currentUserGroupId })
        .then(({ items }) => {
          setGroups(items || [])
        })
        .catch((err) => {
          processError(err, main)
        })
        .finally(() => {
          setShowLoader(false)
        })
    }
  }, [currentUserGroupId])

  const getTagName = useCallback((id) => {
    return tags.find((tag) => tag.id === id)?.name || ''
  }, [tags])

  const filterGroups = useMemo(() => {
    return groups.filter((group) => !search || group.name.toUpperCase().includes(search.toUpperCase()))
  }, [groups, search])

  return (
    <div className={css('main')}>
      <div className={css('container')}>
        <div className={css('nav-bar')}>
          <NavLink className={css('link')} to={routes[ROUTE.SETTINGS]}>
            {t('dlg:dialogsSettings')}
          </NavLink>
          <div>></div>
          <div className={css('active')}>{t('dlg:agentGroups')}</div>
        </div>
        <div className={css('header')}>{t('dlg:groups')}</div>
        <div className={css('info')}>
          <div className={css('toolbox')}>
            <div className={css('toolbox-left')}>
              <Input
                icons={[<SvgIconSearch />]}
                variation='secondary'
                shaded
                placeholder={t('dlg:searchByName')}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className={css('table-header')}>
            <div>{t('dlg:name')}</div>
            <div>{t('dlg:agents')}</div>
            <div>{t('dlg:allowedTags')}</div>
            <div>{t('dlg:disallowedTags')}</div>
            <div>{t('dlg:modified')}</div>
            <div>{t('dlg:created')}</div>
          </div>
          {showLoader && (
            <div className={css('spinner')}>
              <Spinner strokeWidth={5} />
            </div>
          )}
          {!showLoader && (
            <>
              <div>
                {filterGroups.map((item) => {
                  return (
                    <div key={item._id} className={css('item')}>
                      <div className={css('item-name')}>
                        <NavLink className={css('link')} to={`${routes[ROUTE.TEAM]}?group=${item._id}`}>
                          {item.name}
                        </NavLink>
                      </div>
                      <div className={css('item-count')}>
                        {item._nMembers}
                      </div>
                      <div className={css('item-tag')}>
                        {item.allowedTags?.length ? item.allowedTags.map((tag) => (
                          <Tag color='blue' variation='secondary' view='filled' key={tag} classes={{ root: css('tag') }}>
                            {getTagName(tag)}
                          </Tag>
                        )) : t('dlg:noTags')}
                      </div>
                      <div className={css('item-tag')}>
                        {item.disallowedTags?.length ? item.disallowedTags.map((tag) => (
                          <Tag color='red' variation='secondary' view='filled' key={tag} classes={{ root: css('tag') }}>
                            {getTagName(tag)}
                          </Tag>
                        )) : t('dlg:noTags')}
                      </div>
                      <div>
                        {item.updatedAt ? DateUtils.format(item.updatedAt, DateUtils.DATE_TIME_FORMAT) : null}
                      </div>
                      <div>
                        {DateUtils.format(item.createdAt, DateUtils.DATE_TIME_FORMAT)}
                      </div>
                      <div className={css('item-buttons')}>
                        <MoreInfo group={item} />
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className={css('table-footer')}>
                <div className={css('count-members')}>
                  {t('dlg:countGroup', { count: filterGroups.length })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
