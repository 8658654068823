import React, { useCallback, useContext, useEffect, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { bool, func, object } from 'prop-types'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { SvgIconMenuDots } from '@flomni/components/dist/components/svg/feathers/SvgIconMenuDots'
import { SvgIconPin } from '@flomni/components/dist/components/svg/feathers/SvgIconPin'
import { SvgIconSheet } from '@flomni/components/dist/components/svg/feathers/SvgIconSheet'
import { DateUtils } from '@flomni/modules/dist/services/date'
import { Dropdown } from '@flomni/components/dist/components/dropdown'
import classnames from 'classnames'
import { Meteor } from '../../../../../../../../../../meteorAdapter';
import { useDispatch } from 'react-redux'
import { EditComment } from './edit-comment'
import { Spinner } from '@flomni/components/dist/components/spinner'
import { SecondaryDropdownItem } from '@flomni/components/dist/components/secondary-dropdown-item/item'
import { SecondaryDropdownItemText } from '@flomni/components/dist/components/secondary-dropdown-item/text'
import { store } from '../../../../../../../../../state/dialogs'
import { processError } from '../../../../../../../../../services/helpers'
import { Staff } from '../../../../../../../../../customStores'

export const CommentItem = ({
  comment,
  pinnedComment,
  isLast,
  isHighlighted,
  onResetHighlighted,
  hidePinOptions
}) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const { isEditMode, selectedDialogId } = useContext(store)
  const [showMenu, setShowMenu] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showIconLoader, setShowIconLoader] = useState(false)

  const user = Staff.findOne(comment.createdUserId, {
    fields: {
      profile: 1
    }
  })

  useEffect(() => {
    if (isHighlighted) {
      window.setTimeout(onResetHighlighted, 500)
    }
  }, [isHighlighted])

  const handleRemoveComment = (e) => {
    e.preventDefault()
    setShowMenu(false)
    setShowIconLoader(true)
    setTimeout(() => {
      Meteor.invoke('dialogs.removeComment', {
        dialogId: selectedDialogId,
        commentId: comment.id
      })
        .catch((err) => {
          processError(err, main)
        })
        .finally(() => {
          setShowIconLoader(false)
        })
    }, 800)
  }

  const handleEditComment = (e) => {
    e.preventDefault()
    setShowMenu(false)
    setShowEdit(true)
  }

  const handlePinComment = (e) => {
    e.preventDefault()
    setShowMenu(false)
    changePinned(comment.id, true)
  }

  const handleUnPinComment = (e) => {
    e.preventDefault()
    setShowMenu(false)
    changePinned(comment.id, false)
  }

  const handleReplacePinComment = (e) => {
    e.preventDefault()
    setShowMenu(false)
    if (pinnedComment) {
      changePinned(comment.id, true)
      changePinned(pinnedComment.id, false)
    }
  }

  const changePinned = (commentId, isPinned) => {
    main.showGlobalLoader(true)
    Meteor.invoke('dialogs.updateComment', {
      dialogId: selectedDialogId,
      commentId,
      isPinned
    })
      .catch((err) => {
        processError(err, main)
      })
      .finally(() => {
        main.showGlobalLoader(false)
      })
  }

  const onOverlayClickHandler = useCallback(() => {
    setShowMenu(false)
  }, [])

  return (
    <div className={css(classnames('item', isHighlighted ? '--highlighted' : null))}>
      <div className={css('item-left')}>
        <div className={css('line')} />
        {showIconLoader && (
          <div className={css('icon-spinner')}>
            <Spinner strokeWidth={14} />
          </div>
        )}
        {!showIconLoader && comment.isPinned && (
          <div className={css(['icon', '--pin'])}>
            <SvgIconPin />
          </div>
        )}
        {!showIconLoader && !comment.isPinned && (
          <div className={css(['icon', '--sheet'])}>
            <SvgIconSheet />
          </div>
        )}
        {!isLast && <div className={css('line')} />}
      </div>
      <div className={css(classnames('item-right', isLast ? '--last' : null))}>
        {!showEdit && (
          <div className={css('item-content')}>
            <div>
              {comment.isPinned && <div className={css('item-header')}>{t('dlg:pinnedNote')}</div>}
              <div className={css('item-body')}>{comment.text}</div>
              <div className={css('item-footer')}>
                {t('dlg:noteDateByName', {
                  date: DateUtils.format(comment.createdAt, DateUtils.DATE_TIME_FORMAT),
                  name: user?.profile?.name,
                  email: user?.profile?.email ? ` (${user?.profile?.email})` : ''
                })}
              </div>
            </div>
            {isEditMode && (
              <div>
                <PlateButton noBg onClick={() => setShowMenu(true)}>
                  <PlateButtonIcon>
                    <SvgIconMenuDots />
                  </PlateButtonIcon>
                </PlateButton>
                {showMenu && (
                  <Dropdown
                    active
                    variation='secondary'
                    position='right'
                    offsetTarget='iconButton'
                    onOverlayClick={onOverlayClickHandler}
                  >
                    {!hidePinOptions && !comment.isPinned && !pinnedComment && (
                      <SecondaryDropdownItem view='bright' onClick={handlePinComment}>
                        <SecondaryDropdownItemText>{t('dlg:pinToTop')}</SecondaryDropdownItemText>
                      </SecondaryDropdownItem>
                    )}
                    {!hidePinOptions && comment.isPinned && (
                      <SecondaryDropdownItem view='bright' onClick={handleUnPinComment}>
                        <SecondaryDropdownItemText>{t('dlg:unpin')}</SecondaryDropdownItemText>
                      </SecondaryDropdownItem>
                    )}
                    {!hidePinOptions && !!pinnedComment && pinnedComment.id !== comment.id && (
                      <SecondaryDropdownItem view='bright' onClick={handleReplacePinComment}>
                        <SecondaryDropdownItemText>{t('dlg:replacePin')}</SecondaryDropdownItemText>
                      </SecondaryDropdownItem>
                    )}
                    <SecondaryDropdownItem view='bright' onClick={handleEditComment}>
                      <SecondaryDropdownItemText>{t('dlg:edit')}</SecondaryDropdownItemText>
                    </SecondaryDropdownItem>
                    <SecondaryDropdownItem view='warning' onClick={handleRemoveComment}>
                      <SecondaryDropdownItemText>{t('dlg:delete')}</SecondaryDropdownItemText>
                    </SecondaryDropdownItem>
                  </Dropdown>
                )}
              </div>
            )}
          </div>
        )}
        {showEdit && (
          <EditComment
            comment={comment}
            onEdit={() => setShowEdit(false)}
            onCancel={() => setShowEdit(false)}
          />
        )}
      </div>
    </div>
  )
}

CommentItem.propTypes = {
  pinnedComment: object,
  comment: object,
  isLast: bool,
  isHighlighted: bool,
  onResetHighlighted: func,
  hidePinOptions: bool
}
