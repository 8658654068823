import React, { useCallback, useState } from 'react'
import { Meteor } from '../../../../../../../meteorAdapter'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { func, object, array, number, string } from 'prop-types'
import { DATE_SELECTOR, FILTER_PROPERTY, AGENT_TYPE } from '../constants'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { PlateButtonText } from '@flomni/components/dist/components/plate-button/text'
import { SvgIconSearch } from '@flomni/components/dist/components/svg/feathers/SvgIconSearch'
import { SvgIconHeartStroke } from '@flomni/components/dist/components/svg/feathers/SvgIconHeartStroke'
import { SvgIconMenuDots } from '@flomni/components/dist/components/svg/feathers/SvgIconMenuDots'
import { Dropdown } from '@flomni/components/dist/components/dropdown'
import { SecondaryDropdownItem } from '@flomni/components/dist/components/secondary-dropdown-item/item'
import { SecondaryDropdownItemText } from '@flomni/components/dist/components/secondary-dropdown-item/text'
import moment from 'moment'
import { SecondaryDropdownItemIcon } from '@flomni/components/dist/components/secondary-dropdown-item/icon'
import { SvgIconDelete } from '@flomni/components/dist/components/svg/feathers/SvgIconDelete'
import { SvgIconExportArrow } from '@flomni/components/dist/components/svg/feathers/SvgIconExportArrow'
import { ROUTE, routes } from '../../../../../../configs/routes'
import { conversationChannelsMapping } from '../../../../../../configs/conversationChannelsMapping'

const DATE_FORMAT = 'DD.MM.YYYY'
const DATETIME_FORMAT = 'DD.MM.YYYY HH:mm'
const INCOMING_DATETIME_FORMAT = 'DD-MM-YYYY HH:mm'

export const Item = ({ search, setFilters, reference, onDelete, index, addFavoriteSearch, id }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const [showSettings, setShowSettings] = useState(false)
  const overlayClick = useCallback(() => {
    setShowSettings(false)
  }, [])
  const APP_URL = Meteor.absoluteUrl(routes[ROUTE.SEARCH])

  const handlerClickCopy = useCallback((search) => {
    try {
      navigator.clipboard.writeText(`${APP_URL}?filter=${encodeURIComponent(JSON.stringify(search))}`)
      main.showSuccessSystemNotification(t('dlg:copied'))
      setShowSettings(false)
    } catch (e) {
      main.showWarningSystemNotification(t('dlg:cantCopy'))
    }
  }, [])

  const renderItem = useCallback((item) => {
    let value = ''
    switch (item.property) {
      case FILTER_PROPERTY.DATE:
        if (item.selector === DATE_SELECTOR.IS_TODAY) {
          value = t(`dlg:${DATE_SELECTOR.IS_TODAY}`)
        }
        if (item.selector === DATE_SELECTOR.IS_IN_THE_LAST) {
          value = `${t(`dlg:${DATE_SELECTOR.IS_IN_THE_LAST}`)} ${item.periodValue} ${t(`dlg:${item.period}`)}`
        }
        if (item.selector === DATE_SELECTOR.IS_EQUAL_TO) {
          value = `${t(`dlg:${DATE_SELECTOR.IS_EQUAL_TO}`)} ${moment(item.from, INCOMING_DATETIME_FORMAT).format(DATE_FORMAT)}`
        }
        if (item.selector === DATE_SELECTOR.IS_BETWEEN) {
          value = `${t(`dlg:${DATE_SELECTOR.IS_BETWEEN}`)} ${moment(item.from, INCOMING_DATETIME_FORMAT).format(DATETIME_FORMAT)} ${t('dlg:and')} ${moment(item.to, INCOMING_DATETIME_FORMAT).format(DATETIME_FORMAT)}`
        }
        if (item.selector === DATE_SELECTOR.IS_AFTER) {
          value = `${t(`dlg:${DATE_SELECTOR.IS_AFTER}`)} ${moment(item.from, INCOMING_DATETIME_FORMAT).format(DATE_FORMAT)}`
        }
        if (item.selector === DATE_SELECTOR.IS_BEFORE) {
          value = `${t(`dlg:${DATE_SELECTOR.IS_BEFORE}`)} ${moment(item.from, INCOMING_DATETIME_FORMAT).format(DATE_FORMAT)}`
        }
        break
      case FILTER_PROPERTY.AGENT:
        if (item.agentType === AGENT_TYPE.HUMAN) {
          value = reference.members?.find((member) => member._id === item.agentId)?.profile?.name
        } else {
          value = t(`dlg:${AGENT_TYPE.CHATBOT}`)
        }
        break
      case FILTER_PROPERTY.TAG:
        value = reference.tags?.find((tag) => tag._id === item.tagId)?.name
        break
      case FILTER_PROPERTY.TOPIC:
        value = reference.topics?.find((topic) => topic._id === item.topicId)?.name
        break
      case FILTER_PROPERTY.CHAT_RATE:
        value = item.rate
        break
      case FILTER_PROPERTY.CHAT_STATUS:
        value = t(`dlg:${item.status}`)
        break
      case FILTER_PROPERTY.CHAT_ID:
        value = `${t(`dlg:${item.chatIdEqual ? 'is' : 'in'}`)} ${item.chatId}`
        break
      case FILTER_PROPERTY.CHAT_MESSAGES:
        value = item.message
        break
      case FILTER_PROPERTY.CHANNEL:
        value = conversationChannelsMapping[item.channelId]
        break
      case FILTER_PROPERTY.METADATA_FIELD:
        break
      case FILTER_PROPERTY.CHAT_DURATION:
        value = item.duration
        break
      default:
        return (<></>)
    }
    return (
      <div key={item.id} className={css`item`}>
        <div className={css`title`}>{t(`dlg:${item.property}`)}</div>
        <div className={css`value`}>{value}</div>
      </div>
    )
  }, [reference])

  return (
    <div className={css`container`}>
      <div className={css`list`}>
        {search.map((item) => renderItem(item))}
      </div>
      <div className={css`actions`}>
        <PlateButton view={'usual'} onClick={() => setFilters(search)}>
          <PlateButtonIcon>
            <SvgIconSearch />
          </PlateButtonIcon>
          <PlateButtonText>{t('dlg:browse')}</PlateButtonText>
        </PlateButton>
        {!id && <PlateButton
          view='usual'
          noBg
          onClick={() => {
            addFavoriteSearch ? addFavoriteSearch(search) : onDelete(id)
          }}
        >
          <PlateButtonIcon>
            <SvgIconHeartStroke />
          </PlateButtonIcon>
          <PlateButtonText>{t('dlg:favorite')}</PlateButtonText>
        </PlateButton>}
        <div>
          <PlateButton view={'usual'} noBg onClick={() => setShowSettings(true)}>
            <PlateButtonIcon>
              <SvgIconMenuDots />
            </PlateButtonIcon>
          </PlateButton>
          {showSettings && <Dropdown
            active
            variation='secondary'
            position='right'
            offsetTarget='iconButton'
            onOverlayClick={overlayClick}
          >
            <SecondaryDropdownItem
              view='bright'
              onClick={() => handlerClickCopy(search)}
            >
              <SecondaryDropdownItemIcon>
                <SvgIconExportArrow />
              </SecondaryDropdownItemIcon>
              <SecondaryDropdownItemText>{t('dlg:shareSearch')}</SecondaryDropdownItemText>
            </SecondaryDropdownItem>
            <SecondaryDropdownItem
              view='warning'
              onClick={() => {
                onDelete(id || index)
                setShowSettings(false)
              }}
            >
              <SecondaryDropdownItemIcon>
                <SvgIconDelete />
              </SecondaryDropdownItemIcon>
              <SecondaryDropdownItemText>{t('dlg:delete')}</SecondaryDropdownItemText>
            </SecondaryDropdownItem>
          </Dropdown>}
        </div>
      </div>
    </div>
  )
}

Item.propTypes = {
  search: array.isRequired,
  setFilters: func.isRequired,
  reference: object.isRequired,
  onDelete: func.isRequired,
  index: number.isRequired,
  addFavoriteSearch: func,
  id: string
}
