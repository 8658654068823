import { NavLink } from 'react-router-dom'
import { ROUTE, routes } from '../../../../../../configs/routes'
import React from 'react'
import styles from '../../index.module.scss'
import { useTranslation } from 'react-i18next'
import useStyles from '@flomni/modules/dist/helpers/useStyles'

const SettingsAccount = () => {
  const css = useStyles(styles)

  const { t } = useTranslation()

  return (
    <div className={css(['items', '--border-right'])}>
      <div className={css('item-header')}>{t('dlg:yourAccount')}</div>
      <div className={css('links')}>
        <div>
          <NavLink className={css('link')} to={routes[ROUTE.PROFILE]}>
            {t('dlg:profileData')}
          </NavLink>
        </div>
        <div>
          <NavLink className={css('link')} to={routes[ROUTE.NOTIFICATIONS]}>
            {t('dlg:notifications')}
          </NavLink>
        </div>
        <div>
          <NavLink className={css('link')} to={routes[ROUTE.ACCOUNT]}>
            {t('dlg:accountConfiguration')}
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export {
  SettingsAccount
}
