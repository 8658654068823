import React, { useEffect, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { func, object } from 'prop-types'
import { ValueSelector } from '../shared/value-selector'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { Meteor } from '../../../../../../../../meteorAdapter';
import { AGENT_TYPE } from '../../constants'
import { processError } from '../../../../../../../services/helpers'

export const AgentFilterItem = ({ item, onChangeItemProperty }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const currentUserGroupId = useSelector(({ main }) => main.currentUserGroupId)

  const agentTypes = [AGENT_TYPE.CHATBOT, AGENT_TYPE.HUMAN]
  const [selectedAgentType, setSelectedAgentType] = useState(item.agentType)
  const [selectedAgentId, setSelectedAgentId] = useState(item.agentId)
  const [agents, setAgents] = useState([])

  useEffect(() => {
    if (currentUserGroupId) {
      Meteor.invoke('groups.getGroupMembers', {
        groupId: currentUserGroupId,
        fields: ['emails', 'profile.name'],
        excludeBlocked: true
      })
        .then((users) => {
          const members = users || []
          members.sort((a, b) => (a.profile.name > b.profile.name ? 1 : -1))
          setAgents(
            members.map((user) => {
              return { label: `${user.profile.name} (${user.emails[0].address})`, value: user._id }
            })
          )
        })
        .catch((err) => {
          processError(err, main)
        })
    }
  }, [currentUserGroupId])

  const onSelectAgentType = (agentType) => {
    setSelectedAgentType(agentType)
    onChangeItemProperty('agentType', agentType)
  }

  const onSelectAgentId = (agentId) => {
    setSelectedAgentId(agentId)
    onChangeItemProperty('agentId', agentId)
  }

  return (
    <div className={css(classnames('container', selectedAgentType === AGENT_TYPE.HUMAN ? '--human' : null))}>
      <span className={css('text-separator')}>{t('dlg:is')}</span>
      <ValueSelector
        placeholder={t('dlg:select')}
        items={agentTypes}
        item={selectedAgentType}
        onChange={onSelectAgentType}
      />
      {selectedAgentType === AGENT_TYPE.HUMAN && (
        <>
          <span className={css('text-separator')}>{t('dlg:named')}</span>
          <ValueSelector
            items={agents}
            item={selectedAgentId}
            onChange={onSelectAgentId}
            placeholder={t('dlg:selectAgent')}
            translate={false}
            isSearchable
          />
        </>
      )}
    </div>
  )
}

AgentFilterItem.propTypes = {
  onChangeItemProperty: func.isRequired,
  item: object.isRequired
}
