import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { func, object } from 'prop-types'
import { SvgIconUser } from '@flomni/components/dist/components/svg/feathers/SvgIconUser'
import { useTranslation } from 'react-i18next'
import EditContact from './edit-contact'
import { isMobileMode } from '../../../../../../../../../../services/helpers'
import MobileContactDialog from '../../../mobile/reply/attachments-menu/contact-dialog'

export const AttachmentContact = ({ contact, onChange }) => {
  const { t } = useTranslation()
  const css = useStyles(styles)
  const [showEdit, setShowEdit] = useState(false)

  const handlerChangeContact = () => {
    setShowEdit(true)
  }

  const onContactChange = (name, phone) => {
    setShowEdit(false)
    onChange({
      name,
      phone
    })
  }

  return (
    <>
      <div className={css('container')} onClick={handlerChangeContact}>
        <div className={css('header')}>
          <SvgIconUser />
          <div className={css('name')}>{contact.name}</div>
        </div>
        <div className={css('phone')}>{contact.phone}</div>
        <div className={css('edit')}>{t('dlg:clickToEdit')}</div>
      </div>
      {showEdit && !isMobileMode && (
        <EditContact contact={contact} onCancel={() => setShowEdit(false)} onEdited={onContactChange} />
      )}
      {showEdit && isMobileMode && (
        <MobileContactDialog
          contact={contact}
          onCancel={() => setShowEdit(false)}
          onEdited={onContactChange}
        />
      )}
    </>
  )
}

AttachmentContact.propTypes = {
  contact: object.isRequired,
  onChange: func.isRequired
}
