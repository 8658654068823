import en from './locales/en-US/dlg.json'
import ru from './locales/ru-RU/dlg.json'

const resources = {
  'en-US': {
    dlg: en
  },
  'ru-RU': {
    dlg: ru
  }
}

export default resources
