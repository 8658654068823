import React, { useCallback, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { PERIODS } from '../shared/utils'
import { WorkforceDashboardChart } from './chart'
import { SvgIconSheet } from '@flomni/components/dist/components/svg/feathers/SvgIconSheet'
import { PeriodSelect } from '../../../shared/period-select'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonIcon } from '@flomni/components/dist/components/secondary-button/icon'
import { SvgIconFilter } from '@flomni/components/dist/components/svg/feathers/SvgIconFilter'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { Dropdown } from '@flomni/components/dist/components/dropdown'
import { SecondaryDropdownItem } from '@flomni/components/dist/components/secondary-dropdown-item/item'
import { SecondaryDropdownItemText } from '@flomni/components/dist/components/secondary-dropdown-item/text'

const FILTER_TYPE = {
  SAME_WEEKDAY: '4_weekdays',
  LAST_7_DAYS: '7_last_days'
}

export const WorkforceDashboard = () => {
  const css = useStyles(styles)
  const { t } = useTranslation()

  const [selectedPeriodTeam, setSelectedPeriodTeam] = useState(PERIODS.TODAY)
  const [avgAgents, setAvgAgents] = useState(0)
  const [maxAgents, setMaxAgents] = useState(0)
  const [showLoader, setShowLoader] = useState(false)
  const [hasChartData, setHasChartData] = useState(true)
  const [showFilter, setShowFilter] = useState(false)
  const [selectedType, setSelectedType] = useState(FILTER_TYPE.SAME_WEEKDAY)

  const onMetricsCalculated = (avg, max, hasData) => {
    setAvgAgents(avg)
    setMaxAgents(max)
    setHasChartData(hasData)
  }

  const onOverlayFilterClickHandler = useCallback(() => {
    setShowFilter(false)
  }, [])

  return (
    <div className={css('container')}>
      <div className={css('header')}>
        <div>{t('dlg:workforceManagement')}</div>
        <div className={css('filters')}>
          <div>{t('dlg:historyPeriodDasboardFilter')}</div>
          <div>
            <SecondaryButton view='light' onClick={() => setShowFilter(true)}>
              <SecondaryButtonIcon>
                <SvgIconFilter />
              </SecondaryButtonIcon>
              <SecondaryButtonText>{t(`dlg:historyStats.${selectedType}`)}</SecondaryButtonText>
            </SecondaryButton>
            <Dropdown
              active={showFilter}
              variation='secondary'
              position='right'
              onOverlayClick={onOverlayFilterClickHandler}
            >
              {Object.values(FILTER_TYPE).map((filter) => (
                <SecondaryDropdownItem
                  key={filter}
                  disabled={filter === FILTER_TYPE.LAST_7_DAYS}
                  active={selectedType === filter}
                  onClick={() => {
                    setSelectedType(filter)
                    setShowFilter(false)
                  }}
                >
                  <SecondaryDropdownItemText>{t(`dlg:historyStats.${filter}`)}</SecondaryDropdownItemText>
                </SecondaryDropdownItem>
              ))}
            </Dropdown>
          </div>
          <div>{t('dlg:forDashboardFilter')}</div>
          <PeriodSelect selectedPeriod={selectedPeriodTeam} setSelectedPeriod={setSelectedPeriodTeam} />
        </div>
      </div>
      <div className={css('content')} style={{ display: hasChartData || showLoader ? 'grid' : 'none' }}>
        <WorkforceDashboardChart
          showLoader={showLoader}
          setShowLoader={setShowLoader}
          period={selectedPeriodTeam}
          onMetricsCalculated={onMetricsCalculated}
        />
        <div>
          <div className={css('line')}>
            <div className={css(['item', '--border-right', '--link'])}>
              <div className={css('title')}>{t('dlg:requiredAgents')}</div>
              <div className={css('value')}>{avgAgents}</div>
              <div className={css('description')}>{t('dlg:averageAgentsNeededForSelectedPeriod')}</div>
            </div>
            <div className={css('item')}>
              <div className={css('title')}>{t('dlg:peakAgentsValue')}</div>
              <div className={css('value')}>{maxAgents}</div>
            </div>
          </div>
        </div>
      </div>
      {!hasChartData && !showLoader && (
        <div className={css('no-items')}>
          <div className={css('no-items-header')}>
            <div className={css('no-items-icon')}>
              <SvgIconSheet width={20} height={20} />
            </div>
            <div>
              <div className={css('no-items-title')}>{t('dlg:noForecastTitle')}</div>
              <div className={css('no-items-description')}>{t('dlg:noForecastDescription')}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

WorkforceDashboard.propTypes = {}
