import React, { useMemo, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { Tabs } from '@flomni/components/dist/components/tabs/tabs'
import { TabsHeader } from '@flomni/components/dist/components/tabs/tabs-header'
import { Tab } from '@flomni/components/dist/components/tabs/tab'
import { TabsBody } from '@flomni/components/dist/components/tabs/tabs-body'
import { Panel } from '@flomni/components/dist/components/tabs/panel'
import { RemindersTab } from './reminders-tab'
import { ArchivedChatsTab } from './archived-chats-tab'
import { PeriodSelect } from '../../shared/period-select'
import { UserSelect } from '../../shared/user-select'
import { PERIODS } from '../dashboard/shared/utils'
import moment from 'moment'
import { BannedClientsTab } from './banned-clients-tab'
import { USER_ROLES } from '../../../../../constants'
import { useSelector } from 'react-redux'

export const Events = () => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedPeriod, setSelectedPeriod] = useState(PERIODS.TODAY)
  const [currentUserRoles] = useSelector(({ main }) => [main.currentUserRoles])
  const isSupervisor = currentUserRoles.includes(USER_ROLES.SUPERVISOR)

  const dates = useMemo(() => {
    switch (selectedPeriod) {
      case PERIODS.YESTERDAY:
        return [
          moment().subtract(1, 'day').startOf('day').toISOString(),
          moment().subtract(1, 'day').endOf('day').toISOString()
        ]
      case PERIODS.LAST_7_DAYS:
        return [moment().subtract(7, 'day').startOf('day').toISOString(), moment().endOf('day').toISOString()]
      case '':
        return [undefined, undefined]
      default:
        return [moment().startOf('day').toISOString(), moment().endOf('day').toISOString()]
    }
  }, [selectedPeriod])

  return (
    <div className={css('main')}>
      <div className={css('container')}>
        <div className={css('header')}>
          <div>{t('dlg:events')}</div>
          <div className={css('filters')}>
            <UserSelect selectedUser={selectedUser} setSelectedUser={setSelectedUser} showAll={isSupervisor} />
            <div>{t('dlg:forDashboardFilter')}</div>
            <PeriodSelect selectedPeriod={selectedPeriod} setSelectedPeriod={setSelectedPeriod} showAll={isSupervisor} />
          </div>
        </div>
        <Tabs view='secondary' classes={{ root: css('tabs') }}>
          <TabsHeader>
            <Tab>{t('dlg:reminders')}</Tab>
            <Tab>{t('dlg:autoArchivedChats')}</Tab>
            <Tab>{t('dlg:bannedClients')}</Tab>
          </TabsHeader>
          <TabsBody>
            <Panel>
              <RemindersTab userId={selectedUser?.userId} startDate={dates[0]} endDate={dates[1]} />
            </Panel>
            <Panel>
              <ArchivedChatsTab userId={selectedUser?.userId} startDate={dates[0]} endDate={dates[1]} />
            </Panel>
            <Panel>
              <BannedClientsTab userId={selectedUser?.userId} startDate={dates[0]} endDate={dates[1]} />
            </Panel>
          </TabsBody>
        </Tabs>
      </div>
    </div>
  )
}
