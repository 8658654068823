import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { func, object, string } from 'prop-types'
import { SvgIconClose } from '@flomni/components/dist/components/svg/feathers/SvgIconClose'
import { HeaderMenu } from '../../menu'

export const StatusesPage = ({ profile, agentStatus, onClose }) => {
  const css = useStyles(styles)
  const mainClass = css('main')

  const onOverlayClick = (e) => {
    if (e.target.classList.contains(mainClass)) {
      onClose()
    }
  }

  return (
    <div className={mainClass} onClick={onOverlayClick}>
      <div className={css('header')}>
        <div className={css('side-container')} />
        <div className={css('name')}>{profile?.name}</div>
        <div className={css('side-container')}>
          <div className={css('icon')} onClick={onClose}>
            <SvgIconClose />
          </div>
        </div>
      </div>
      <div className={css('body')}>
        <HeaderMenu agentStatus={agentStatus} onStatusChange={onClose} />
      </div>
    </div>
  )
}

StatusesPage.propTypes = {
  agentStatus: string,
  onClose: func.isRequired,
  profile: object
}
