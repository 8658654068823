import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { Spinner } from '@flomni/components/dist/components/spinner'
import { bool, func, node, number, string } from 'prop-types'
import { SecondaryButtonIcon } from '@flomni/components/dist/components/secondary-button/icon'

export const ButtonWithLoader = ({
  size,
  text,
  view,
  onClick,
  cls,
  disabled,
  isLoading,
  spinnerSize,
  spinnerStrokeWidth,
  hideContentOnLoading,
  spinnerView,
  Icon
}) => {
  const css = useStyles(styles)

  return (
    <SecondaryButton
      view={view}
      classes={{ root: cls }}
      size={size}
      onClick={onClick}
      disabled={isLoading ? true : disabled}
    >
      <div className={css('content')}>
        {isLoading && (
          <div
            className={css('spinner')}
            style={{
              height: spinnerSize,
              width: spinnerSize
            }}
          >
            <Spinner strokeWidth={spinnerStrokeWidth} view={spinnerView} />
          </div>
        )}
        {(!isLoading || (isLoading && !hideContentOnLoading)) && (
          <>
            {text && <SecondaryButtonText>{text}</SecondaryButtonText>}
            {Icon && <SecondaryButtonIcon>{Icon}</SecondaryButtonIcon>}
          </>
        )}
      </div>
    </SecondaryButton>
  )
}

ButtonWithLoader.propTypes = {
  size: string,
  text: string,
  view: string,
  onClick: func,
  cls: string,
  disabled: bool,
  isLoading: bool,
  spinnerSize: number,
  spinnerStrokeWidth: number,
  hideContentOnLoading: bool,
  spinnerView: string,
  Icon: node
}

ButtonWithLoader.defaultProps = {
  view: 'usual',
  spinnerView: 'light'
}
