import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { func, object } from 'prop-types'
import { SvgIconLocation } from '@flomni/components/dist/components/svg/feathers/SvgIconLocation'
import { useTranslation } from 'react-i18next'
import EditLocation from './edit-location'
import { isMobileMode } from '../../../../../../../../../../services/helpers'
import MobileLocationDialog from '../../../mobile/reply/attachments-menu/location-dialog'

export const AttachmentLocation = ({ location, onChange }) => {
  const { t } = useTranslation()
  const css = useStyles(styles)
  const [showEdit, setShowEdit] = useState(false)

  const handlerChangeLocation = () => {
    setShowEdit(true)
  }

  const onLocationChange = (name, lat, lng) => {
    setShowEdit(false)
    onChange({
      name,
      lat,
      lng
    })
  }

  return (
    <>
      <div className={css('container')} onClick={handlerChangeLocation}>
        <div className={css('header')}>
          <SvgIconLocation />
          <div className={css('name')}>{location.name}</div>
        </div>
        <div className={css('coordinates')}>
          {location.lat}, {location.lng}
        </div>
        <div className={css('edit')}>{t('dlg:clickToEdit')}</div>
      </div>
      {showEdit && !isMobileMode && (
        <EditLocation location={location} onCancel={() => setShowEdit(false)} onEdited={onLocationChange} />
      )}
      {showEdit && isMobileMode && (
        <MobileLocationDialog
          location={location}
          onCancel={() => setShowEdit(false)}
          onEdited={onLocationChange}
        />
      )}
    </>
  )
}

AttachmentLocation.propTypes = {
  location: object.isRequired,
  onChange: func.isRequired
}
