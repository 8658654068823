import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from '../index.module.scss'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { Select } from '@flomni/components/dist/components/select'
import { useForm } from 'react-hook-form'
import { Meteor } from '../../../../../../../meteorAdapter';
import { useDispatch } from 'react-redux'
import i18next from 'i18next'
import { ButtonWithLoader } from '../../../../../shared/button-with-loader'
import { processError } from '../../../../../../services/helpers'
import { string } from 'prop-types'

export const AccountInterface = ({ currentUserTheme }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const [showSaveLoader, setShowSaveLoader] = useState(false)
  const [footerStatus, setFooterStatus] = useState('')

  const { register, watch, setValue, reset, formState, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      locale: i18next.language.replace('-', '_'),
      theme: currentUserTheme
    }
  })
  const locale = watch('locale')
  const theme = watch('theme')

  const themeOptions = [
    {
      label: t('dlg:light'),
      value: 'light'
    },
    {
      label: t('dlg:contrast'),
      value: 'contrast'
    }
  ]
  const selectedThemeOption = themeOptions.find((option) => option.value === theme)

  const localeOptions = [
    {
      label: t('dlg:languages.en'),
      value: 'en_US'
    },
    {
      label: t('dlg:languages.ru'),
      value: 'ru_RU'
    }
  ]
  const selectedLocaleOption = localeOptions.find((option) => option.value === locale)

  const onSave = () => {
    const { locale, theme } = getValues()
    setShowSaveLoader(true)

    setTimeout(() => {
      Meteor.invoke('users.updateProfile', {
        locale,
        theme
      })
        .then(() => {
          reset({
            locale,
            theme
          })
          setFooterStatus(t('dlg:yourChangesSaved'))
        })
        .catch((err) => {
          processError(err, main)
        })
        .finally(() => {
          setShowSaveLoader(false)
        })
    }, 800)
  }

  const onCancel = () => {
    reset({
      locale: i18next.language.replace('-', '_'),
      theme: currentUserTheme
    })
    setFooterStatus(t('dlg:yourChangesDiscarded'))
  }

  return (
    <>
      <input hidden name='locale' ref={register()} />
      <input hidden name='theme' ref={register()} />
      <div className={css('form')}>
        <div className={css('header')}>{t('dlg:interface')}</div>
        <div className={css('body')}>
          <div className={css('body-line')}>
            <div className={css('title')}>{t('dlg:language')}</div>
            <Select
              variation='secondary'
              options={localeOptions}
              value={selectedLocaleOption}
              onChange={(option) =>
                setValue('locale', option.value, { shouldDirty: true, shouldValidate: true })
              }
            />
          </div>
          <div className={css(['body-line', '--top'])}>
            <div className={css('title')}>{t('dlg:theme')}</div>
            <Select
              variation='secondary'
              options={themeOptions}
              value={selectedThemeOption}
              onChange={(option) =>
                setValue('theme', option.value, { shouldDirty: true, shouldValidate: true })
              }
            />
          </div>
        </div>
        <div className={css('footer')}>
          <div className={css('footer-status')}>{footerStatus}</div>
          <div className={css('footer-buttons')}>
            <SecondaryButton view='light' onClick={onCancel} disabled={!formState.isDirty}>
              <SecondaryButtonText>{t('dlg:cancel')}</SecondaryButtonText>
            </SecondaryButton>
            <ButtonWithLoader
              text={t('dlg:save')}
              onClick={onSave}
              disabled={!formState.isValid || !formState.isDirty}
              spinnerSize={10}
              spinnerStrokeWidth={14}
              isLoading={showSaveLoader}
            />
          </div>
        </div>
      </div>
    </>
  )
}

AccountInterface.propTypes = {
  currentUserTheme: string.isRequired
}
