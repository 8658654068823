import { isMobile, isTablet } from 'react-device-detect'

export const base64MimeType = (encoded) => {
  let result = null
  if (typeof encoded !== 'string') {
    return result
  }
  const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
  if (mime && mime.length) {
    result = mime[1]
  }
  return result
}

export const urlToFile = (url, filename, mimeType) => {
  return fetch(url)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], filename, { type: mimeType }))
}

export const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      resolve(null)
      return
    }
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = reject
  })
}

export const formatFileSize = (bytes, precision) => {
  if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) {
    return '-'
  }
  if (typeof precision === 'undefined') {
    precision = 1
  }
  const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB']
  const number = Math.floor(Math.log(bytes) / Math.log(1024))
  return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + units[number]
}

export const processError = (err, main) => {
  if (err) {
    console.error(err)
    if (err.error !== 'user-not-loggedin') {
      main.showWarningSystemNotification(err.reason || err.message)
    }
  }
}

export const isMobileMode = isMobile && !isTablet
