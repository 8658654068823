import { init } from '@rematch/core'
import * as models from './_models'

const store = init({
  models,
  redux: {
    rootReducers: {
    }
  }
})

export { store }
