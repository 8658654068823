import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { string } from 'prop-types'

export const DateSeparator = ({ title }) => {
  const css = useStyles(styles)

  return (
    <div className={css('container')}>
      <div className={css('line')} />
      <div className={css('message')}>
        <div>{title}</div>
      </div>
      <div className={css('line')} />
    </div>
  )
}

DateSeparator.propTypes = {
  title: string
}
