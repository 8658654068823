import React, { useContext, useEffect, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { array, func } from 'prop-types'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { SvgIconEdit } from '@flomni/components/dist/components/svg/feathers/SvgIconEdit'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import classnames from 'classnames'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { ButtonWithLoader } from '../../../../../../../shared/button-with-loader'
import { MultiSearchSelect } from '@flomni/components/dist/components/select/multi-search'
import { useDispatch, useSelector } from 'react-redux'
import { Meteor } from '../../../../../../../../../meteorAdapter';
import { processError } from '../../../../../../../../services/helpers'
import { store } from '../../../../../../../../state/dialogs'

export const InfoTags = ({ tagIds, onUpdated }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const currentUserGroupId = useSelector(({ main }) => main.currentUserGroupId)
  const { selectedDialogId } = useContext(store)
  const [isEdit, setIsEdit] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [allTags, setAllTags] = useState([])
  const [selectedTagIds, setSelectedTagIds] = useState(tagIds)

  const getTagById = (id) => {
    return allTags.find((t) => t.coreTagId === id || t._id === id)
  }

  const onUpdate = async () => {
    setShowLoader(true)
    await new Promise((resolve) => setTimeout(resolve, 800))
    try {
      const addedIds = selectedTagIds.filter((id) => !tagIds.includes(id)).map(id => getTagById(id)._id)
      if (addedIds.length) {
        await Meteor.invoke('dialogs.attachGroupTagsToDialog', {
          groupId: currentUserGroupId,
          dialogId: selectedDialogId,
          tags: addedIds
        })
      }

      const removedIds = tagIds.filter((id) => !selectedTagIds.includes(id)).map(id => getTagById(id) ? getTagById(id)._id : null).filter(t => t)
      if (removedIds.length) {
        await Meteor.invoke('dialogs.detachGroupTagsFromDialog', {
          groupId: currentUserGroupId,
          dialogId: selectedDialogId,
          tags: removedIds
        })
      }
      setShowLoader(false)
      setIsEdit(false)
      onUpdated(selectedTagIds)
    } catch (err) {
      setShowLoader(false)
      processError(err, main)
    }
  }

  const getTagName = (id) => {
    const tag = getTagById(id)
    return tag ? tag.name : null
  }

  useEffect(() => {
    if (!currentUserGroupId) {
      return
    }
    Meteor.invoke('groups.getGroupTags', { groupId: currentUserGroupId })
      .then(({ items }) => {
        setAllTags((items || []).filter((tag) => !['session', 'sessionGroup'].includes(tag.kind)))
      })
      .catch((err) => {
        processError(err, main)
      })
  }, [currentUserGroupId])

  const tagsOptions = allTags.map((tag) => {
    return {
      label: tag.name,
      value: tag.coreTagId || tag._id
    }
  })
  const selectedTagsOptions = tagsOptions.filter((option) => selectedTagIds.includes(option.value))

  return (
    <div className={css(classnames('item', isEdit ? '--edit' : null))}>
      <div className={css('title')}>{t('dlg:tags')}</div>
      {!isEdit && (
        <div className={css('value')}>
          <div className={css('text')}>
            {tagIds?.length > 0 ? (
              tagIds.map(getTagName).filter(t => t).join(', ')
            ) : (
              <span className={css('no-data')}>{t('dlg:noData')}</span>
            )}
          </div>
          <PlateButton classes={{ root: css('edit-btn') }} noBg onClick={() => setIsEdit(true)}>
            <PlateButtonIcon>
              <SvgIconEdit />
            </PlateButtonIcon>
          </PlateButton>
        </div>
      )}
      {isEdit && (
        <div>
          <MultiSearchSelect
            options={tagsOptions}
            value={selectedTagsOptions}
            placeholder={t('dlg:selectTag')}
            onChange={(options) => setSelectedTagIds(options ? options.map((option) => option.value) : [])}
          />
          <div className={css('edit-buttons')}>
            <SecondaryButton view='light' onClick={() => setIsEdit(false)}>
              <SecondaryButtonText>{t('dlg:cancel')}</SecondaryButtonText>
            </SecondaryButton>
            <ButtonWithLoader
              text={t('dlg:update')}
              onClick={onUpdate}
              spinnerSize={10}
              spinnerStrokeWidth={14}
              isLoading={showLoader}
            />
          </div>
        </div>
      )}
    </div>
  )
}

InfoTags.propTypes = {
  tagIds: array,
  onUpdated: func.isRequired
}
