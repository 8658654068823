import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { Trans } from 'react-i18next'
import { Reply } from './reply'
import { array, bool, func, object, string } from 'prop-types'
import { ReplyQueueChat } from './reply-queue-chat'
import { Meteor } from '../../../../../../../../meteorAdapter';
import { isMobileMode } from '../../../../../../../services/helpers'
import { MobileReply } from './mobile/reply'
import classnames from 'classnames'
import { ReplySuspendedChat } from './reply-suspended-chat'

export const ChatInput = ({
  state,
  templates,
  actions,
  updateTemplates,
  onAssignedCurrentUser,
  stuffUsers,
  passiveMembers,
  drafts,
  chatCaption,
  clientProfile,
  receiver,
  mbrain,
  chatbotEnabled,
  allowSuggestions,
  recipient,
  language
}) => {
  const css = useStyles(styles)
  const currentUserId = Meteor.userId()
  const isSuspendedChat = recipient?.sessionSuspended

  let isTeamQueue = false
  let isChatInQueue = ['NEW', 'BOT_DRIVEN'].includes(state)
  if (!isChatInQueue && !stuffUsers.includes(currentUserId)) {
    isTeamQueue = true
    isChatInQueue = true
  }
  let replyState = state
  if (isTeamQueue) {
    if (stuffUsers && stuffUsers.length > 0) replyState = 'TEAM_HAS_MEMBERS'
    else replyState = 'TEAM_NO_MEMBERS'
  }

  return (
    <div className={css(classnames('container', isMobileMode ? '--mobile' : null))}>
      <div className={css('chat-input')}>
        {isSuspendedChat && (
          <ReplySuspendedChat
            phone={recipient.lastMessenger?.mUid}
            prefferedMessenger={recipient.lastMessenger?.messenger}
          />
        )}
        {isChatInQueue && !isSuspendedChat && (
          <ReplyQueueChat
            chatbotEnabled={chatbotEnabled}
            onChange={onAssignedCurrentUser}
            state={replyState}
            originalState={state}
          />
        )}
        {!isSuspendedChat && !isChatInQueue && !isMobileMode && (
          <Reply
            chatCaption={chatCaption}
            clientProfile={clientProfile}
            receiver={receiver}
            passiveMembers={passiveMembers}
            drafts={drafts}
            templates={templates}
            updateTemplates={updateTemplates}
            actions={actions}
            mbrain={mbrain}
            allowSuggestions={allowSuggestions}
            language={language}
          />
        )}
        {!isSuspendedChat && !isChatInQueue && isMobileMode && (
          <MobileReply
            chatCaption={chatCaption}
            clientProfile={clientProfile}
            receiver={receiver}
            passiveMembers={passiveMembers}
            drafts={drafts}
            templates={templates}
            updateTemplates={updateTemplates}
            actions={actions}
            mbrain={mbrain}
            allowSuggestions={allowSuggestions}
          />
        )}
      </div>
      {!isMobileMode && (
        <div className={css('commands')}>
          <div>
            <Trans
              i18nKey='dlg:returnToSend'
              components={[<span className={css('command-mid')}>0</span>, 1]}
            />
          </div>
          <div>
            <Trans
              i18nKey='dlg:shiftReturnAddNewLine'
              components={[<span className={css('command-mid')}>0</span>, 1]}
            />
          </div>
        </div>
      )}
    </div>
  )
}

ChatInput.propTypes = {
  state: string,
  actions: array,
  templates: array,
  updateTemplates: func.isRequired,
  onAssignedCurrentUser: func.isRequired,
  stuffUsers: array,
  passiveMembers: array,
  chatCaption: string,
  clientProfile: object,
  drafts: object,
  receiver: string,
  mbrain: object,
  chatbotEnabled: bool,
  allowSuggestions: bool,
  language: string
}
