import React, { useState } from 'react'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { SvgIconPlus } from '@flomni/components/dist/components/svg/feathers/SvgIconPlus'
import DialogAddChat from './dialog'

export const AddChat = () => {
  const [opened, setOpened] = useState(false)

  return (
    <div>
      <PlateButton onClick={() => setOpened(true)}>
        <PlateButtonIcon>
          <SvgIconPlus />
        </PlateButtonIcon>
      </PlateButton>
      {opened && <DialogAddChat onCancel={() => setOpened(false)} />}
    </div>
  )
}

AddChat.propTypes = {
}
