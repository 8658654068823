import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonText } from '@flomni/components/dist/components/plate-button/text'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { SvgIconExportArrow } from '@flomni/components/dist/components/svg/feathers/SvgIconExportArrow'
import { Trans, useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { SecondaryButtonIcon } from '@flomni/components/dist/components/secondary-button/icon'
import { SvgIconPlus } from '@flomni/components/dist/components/svg/feathers/SvgIconPlus'
import { SvgIconButtonsFilled } from '@flomni/components/dist/components/svg/feathers/SvgIconButtonsFilled'
import { array, func } from 'prop-types'
import { useHistory } from 'react-router-dom'
import { ROUTE, routes } from '../../../../../../../../../configs/routes'
import ButtonForm from '../../../../../../settings/buttons/button-form'
import classnames from 'classnames'
import { isMobileMode } from '../../../../../../../../../services/helpers'

export const ButtonsList = ({ onButtonSelected, buttons, allButtons, actions }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const history = useHistory()
  const [showAddButton, setShowAddButton] = useState(false)

  return (
    <div className={css(classnames('container', isMobileMode ? '--mobile' : null))}>
      <div className={css('header')}>
        <div className={css('title')}>{t('dlg:buttons')}</div>
        {!isMobileMode && (
          <PlateButton
            view='usual'
            onClick={() => {
              history.push(routes[ROUTE.MESSAGE_BUTTONS])
            }}
          >
            <PlateButtonIcon>
              <SvgIconExportArrow />
            </PlateButtonIcon>
            <PlateButtonText>{t('dlg:editButtons')}</PlateButtonText>
          </PlateButton>
        )}
      </div>
      {allButtons.length === 0 && (
        <div className={css('no-items')}>
          <div className={css('no-items-header')}>
            {!isMobileMode && (
              <div className={css('no-items-icon')}>
                <SvgIconButtonsFilled width={16} height={20} />
              </div>
            )}
            <div>
              <div className={css('no-items-title')}>{t('dlg:youDontHaveButtons')}</div>
              <div className={css('no-items-description')}>
                <Trans
                  i18nKey='dlg:createButtonPath'
                  components={[0, <span className={css('no-items-description-mid')}>0</span>, 1]}
                />
              </div>
            </div>
          </div>
          {!isMobileMode && (
            <SecondaryButton
              classes={{ root: css('no-items-button') }}
              onClick={() => setShowAddButton(true)}
            >
              <SecondaryButtonIcon>
                <SvgIconPlus />
              </SecondaryButtonIcon>
              <SecondaryButtonText>{t('dlg:newActionButton')}</SecondaryButtonText>
            </SecondaryButton>
          )}
        </div>
      )}
      {buttons.length > 0 && (
        <div className={css('list')}>
          {buttons.map((item) => (
            <div key={item.id} className={css('item')} onClick={() => onButtonSelected(item)}>
              <div className={css('item-name')}>{item.name}</div>
              <div className={css('item-value')}>{item.value}</div>
              {!isMobileMode && (
                <div className={css('item-description')}>
                  {item.description ?? (
                    <span className={css('no-description')}>{t('dlg:noDescription')}</span>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {buttons.length === 0 && allButtons.length > 0 && <div>{t('dlg:allButtonsAdded')}</div>}
      {showAddButton && (
        <ButtonForm
          actions={actions}
          onSaved={() => setShowAddButton(false)}
          onCancel={() => setShowAddButton(false)}
        />
      )}
    </div>
  )
}

ButtonsList.propTypes = {
  buttons: array,
  allButtons: array,
  onButtonSelected: func.isRequired,
  actions: array
}
