import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { bool, object, node, string } from 'prop-types'
import { DateUtils } from '@flomni/modules/dist/services/date'
import classnames from 'classnames'

export const TextItem = ({ item, icon, iconClass, isLast }) => {
  const css = useStyles(styles)

  return (
    <div className={css('item')}>
      <div className={css('item-left')}>
        <div className={css('line')} />
        <div className={css(classnames('icon', iconClass))}>{icon}</div>
        {!isLast && <div className={css('line')} />}
      </div>
      <div className={css(classnames('item-right', isLast ? '--last' : null))}>
        <div className={css('item-body')}>{item.text}</div>
        <div className={css('item-footer')}>
          {DateUtils.format(item.createdAt, DateUtils.DATE_TIME_FORMAT)}
        </div>
      </div>
    </div>
  )
}

TextItem.propTypes = {
  item: object,
  isLast: bool,
  icon: node,
  iconClass: string
}
