import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { NavLink, Redirect } from 'react-router-dom'
import { ROUTE, routes } from '../../../../../configs/routes'
import { Meteor } from '../../../../../../meteorAdapter';
import { useDispatch, useSelector } from 'react-redux'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { ControlContainer } from '@flomni/components/dist/components/control-container'
import { Input } from '@flomni/components/dist/components/input'
import { ButtonWithLoader } from '../../../../shared/button-with-loader'
import { useForm } from 'react-hook-form'
import { processError } from '../../../../../services/helpers'
import { mainSelectors } from '../../../../../state/main/selectors'

export const WorkforceManagement = () => {
  const css = useStyles(styles)

  const isCurrentUserOperator = useSelector(mainSelectors.getIsUserOperator)

  const { t } = useTranslation()

  const { main } = useDispatch()

  const [showSaveLoader, setShowSaveLoader] = useState(false)
  const [footerStatus, setFooterStatus] = useState('')

  const [groupId, currentUserWorkforceSettings] = useSelector(({ main }) => [
    main.currentUserGroupId,
    main.currentUserWorkforceSettings
  ])

  const { register, errors, reset, formState, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      chatsNumberPerHour: currentUserWorkforceSettings.chatsNumberPerHour,
      percentOfGrowth: currentUserWorkforceSettings.percentOfGrowth
    }
  })

  const onSave = () => {
    const { chatsNumberPerHour, percentOfGrowth } = getValues()
    setShowSaveLoader(true)

    setTimeout(() => {
      Meteor.invoke('groups.updateGroupWorkforceSettings', {
        groupId,
        chatsNumberPerHour,
        percentOfGrowth
      })
        .then(() => {
          reset({
            chatsNumberPerHour,
            percentOfGrowth
          })
          setFooterStatus(t('dlg:yourChangesSaved'))
        })
        .catch((err) => {
          processError(err, main)
        })
        .finally(() => {
          setShowSaveLoader(false)
        })
    }, 800)
  }

  const onCancel = () => {
    reset({
      chatsNumberPerHour: currentUserWorkforceSettings.chatsNumberPerHour,
      percentOfGrowth: currentUserWorkforceSettings.percentOfGrowth
    })
    setFooterStatus(t('dlg:yourChangesDiscarded'))
  }

  if (isCurrentUserOperator) {
    return (
      <Redirect to={routes[ROUTE.SETTINGS]} />
    )
  }

  return (
    <div className={css('main')}>
      <div className={css('container')}>
        <div className={css('nav-bar')}>
          <NavLink className={css('link')} to={routes[ROUTE.SETTINGS]}>
            {t('dlg:dialogsSettings')}
          </NavLink>
          <div>></div>
          <div className={css('active')}>{t('dlg:workforceManagement')}</div>
        </div>
        <div className={css('form')}>
          <div className={css('header')}>{t('dlg:wfmForecastCoefficients')}</div>
          <div className={css('body')}>
            <div className={css('body-line')}>
              <div className={css('title')}>{t('dlg:averageChatProcessing')}</div>
              <ControlContainer
                variation='secondary'
                warning={errors.chatsNumberPerHour ? errors.chatsNumberPerHour.message : null}
              >
                <Input
                  type='number'
                  min='1'
                  max='1000'
                  variation='secondary'
                  name='chatsNumberPerHour'
                  placeholder={t('dlg:chatsNumberPerHour')}
                  ref={register({ required: t('dlg:valueIsRequired') })}
                />
              </ControlContainer>
            </div>
            <div className={css('line-description')}>{t('dlg:chatsNumberPerHourDescription')}</div>
            <div className={css('body-line')}>
              <div className={css('title')}>{t('dlg:chatsGrowth')}</div>
              <ControlContainer
                variation='secondary'
                warning={errors.percentOfGrowth ? errors.percentOfGrowth.message : null}
              >
                <Input
                  type='number'
                  min='0'
                  max='100'
                  variation='secondary'
                  name='percentOfGrowth'
                  placeholder={t('dlg:percentOfGrowth')}
                  ref={register({ required: t('dlg:valueIsRequired') })}
                />
              </ControlContainer>
            </div>
            <div className={css('line-description')}>{t('dlg:percentOfGrowthDescription')}</div>
          </div>
          <div className={css('footer')}>
            <div className={css('footer-status')}>{footerStatus}</div>
            <div className={css('footer-buttons')}>
              <SecondaryButton view='light' onClick={onCancel} disabled={!formState.isDirty}>
                <SecondaryButtonText>{t('dlg:cancel')}</SecondaryButtonText>
              </SecondaryButton>
              <ButtonWithLoader
                text={t('dlg:save')}
                onClick={onSave}
                disabled={!formState.isValid || !formState.isDirty}
                spinnerSize={10}
                spinnerStrokeWidth={14}
                isLoading={showSaveLoader}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
