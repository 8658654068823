/** @prettier */
// @ts-check

export function getMeteorWebsocketEndpoint(
  location = window.location,
  localStorage = window.localStorage,
  env = process.env
) {
  let endpoint = localStorage.getItem('DIALOGS_METEOR_ENDPOINT_OVERRIDE')

  if (endpoint) {
    console.warn('Using Meteor endpoint override from localStorage: ', endpoint)
  } else if (env.REACT_APP_METEOR_ENDPOINT) {
    endpoint = env.REACT_APP_METEOR_ENDPOINT
  }

  if (!endpoint) {
    endpoint = getComputed(location.href)
  }

  return endpoint
}

const reviewDeploymentRegex = /^44638115-review.+\.k8s\.flomni\.com$/
/**
 * @param {string} locationHref
 */
export function getComputed(locationHref) {
  const computed = new URL(locationHref)

  if (computed.hostname === 'localhost' || reviewDeploymentRegex.test(computed.hostname)) {
    return 'wss://d.dev.flomni.com/websocket'
  }

  // latest version of dialogs backend in production located on d.v2.flomni.com
  if (computed.hostname === 'dialogs.flomni.com') {
    return 'wss://d.v2.flomni.com/websocket'
  }

  computed.protocol = 'wss:'
  computed.hostname = computed.hostname.replace(/^([^.]+)/, 'd')
  computed.pathname = '/websocket'
  computed.search = ''
  computed.hash = ''

  return computed.href
}

export function getBackendBaseUrl(...args) {
  const url = new URL(getMeteorWebsocketEndpoint(...args))
  url.pathname = ''
  url.search = ''
  url.hash = ''
  url.protocol = url.protocol.replace('ws:', 'http:').replace('wss:', 'https:')

  return url.href
}
