import React, { useCallback, useContext, useState, useEffect } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { Tabs } from '@flomni/components/dist/components/tabs/tabs'
import { TabsHeaderDropdown } from '@flomni/components/dist/components/tabs/tabs-header-dropdown'
import { Tab } from '@flomni/components/dist/components/tabs/tab'
import { TabsBody } from '@flomni/components/dist/components/tabs/tabs-body'
import { Panel } from '@flomni/components/dist/components/tabs/panel'
import { useTranslation } from 'react-i18next'
import { ProfileTab } from './profile-tab'
import { getDialogName } from '../../../../../../utils/common'
import { Avatar } from '../../../../shared/avatar'
import { RelatedChatsTab } from './related-chats-tab'
import { Meteor, useTracker, Match } from '../../../../../../../meteorAdapter';
import { Tag } from '@flomni/components/dist/components/tag'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import Dialogs from '../../../../../../../collections/client/dialogs'
import { store } from '../../../../../../state/dialogs'
import { isMobileMode, processError } from '../../../../../../services/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { Staff } from '../../../../../../customStores'
import { SvgIconMenuDots } from '@flomni/components/dist/components/svg/feathers/SvgIconMenuDots'
import { Dropdown } from '@flomni/components/dist/components/dropdown'
import { SecondaryDropdownItem } from '@flomni/components/dist/components/secondary-dropdown-item/item'
import { SecondaryDropdownItemText } from '@flomni/components/dist/components/secondary-dropdown-item/text'
import EditNameDialog from './edit-name-dialog'
import BanClientDialog from './ban-client-dialog'
import { array, bool, func, object } from 'prop-types'
import classnames from 'classnames'
import { SvgIconCloseColumn } from '@flomni/components/dist/components/svg/feathers/SvgIconCloseColumn'
import { SvgIconOpenColumn } from '@flomni/components/dist/components/svg/feathers/SvgIconOpenColumn'
import { PERMISSIONS_PAGE } from '../../../../../../services/constants'
import { USER_ROLES } from '../../../../../../../constants'

export const RightPanel = ({
  banResult,
  updateBanResult,
  crmFields,
  relatedChats,
  recipient,
  metaDataLoading,
  setCrmFields,
  setRecipient
}) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { selectedDialogId } = useContext(store)
  const { main } = useDispatch()
  const [currentUserGroupId, isDetailsPanelCollapsed, currentUserPermissions, currentUserRoles, currentUserDepartmentId] = useSelector(({ main }) => [
    main.currentUserGroupId,
    main.isDetailsPanelCollapsed,
    main.currentUserPermissions,
    main.currentUserRoles,
    main.currentUserDepartmentId
  ])

  const [showMenu, setShowMenu] = useState(false)
  const [showEditName, setShowEditName] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const [showBanClient, setShowBanClient] = useState(false)
  const [isShow, setIsShow] = useState(false)

  const { comments, clientId, receiver, stuffInfo, clientProfile, chatCaption } = useTracker(() => {
    const dialog = Dialogs.findOne(selectedDialogId, {
      fields: {
        clientId: 1,
        comments: 1,
        receiver: 1,
        stuffInfo: 1,
        chatCaption: 1,
        clientProfile: 1
      }
    })
    const comments = dialog?.comments || []
    comments.sort((a, b) => (new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime() ? -1 : 1))
    return { ...dialog, comments }
  }, [selectedDialogId])
  const isSupervisor = currentUserRoles.includes(USER_ROLES.SUPERVISOR)

  const getStaffAvatar = (staffId) => {
    return staffId ? stuffInfo[staffId]?.avatarUrl : null
  }

  const getUserName = (userId) => {
    const staff = Staff.findOne(userId, {
      fields: {
        profile: 1
      }
    })
    return Match.isNonEmptyString(staff?.profile?.name) ? staff?.profile?.name : null
  }

  const scrollToNotes = () => {
    setTabIndex(0)
  }

  const onCrmFieldUpdated = (fieldKey, value) => {
    setCrmFields(crmFields.map((field) => (field.fieldKey === fieldKey ? { ...field, value } : field)))
  }

  const onTagsUpdated = (ids) => {
    setRecipient({
      ...(recipient || {}),
      tags: ids
    })
  }

  const onUnbanClient = () => {
    setShowMenu(false)
    main.showGlobalLoader(true)
    Meteor.invoke('clients.unbanClient', {
      receiver,
      groupId: currentUserGroupId
    })
      .then(() => {
        updateBanResult()
      })
      .catch((err) => {
        processError(err, main)
      })
      .finally(() => {
        main.showGlobalLoader(false)
      })
  }

  const onOpenClientCRM = () => {
    setShowMenu(false)
    const user = Meteor.user()
    window.parent.postMessage({
      event: 'open-crm',
      agent: {
        id: user._id,
        email: user.emails[0]?.address,
        departmentId: currentUserDepartmentId,
        groupId: currentUserGroupId,
      },
      chatId: receiver,
      metaData: {
        ...clientProfile,
        ...crmFields.reduce((a, field) => ({ ...a, [field.name]: field.value }), {})
      }
    }, '*');
  }

  const onOverlayClickHandler = useCallback(() => {
    setShowMenu(false)
  }, [])

  const pinnedComment = comments.length ? comments.find((comment) => comment.isPinned) || comments[0] : null
  const chatName = getDialogName(chatCaption, clientProfile, receiver, t)

  useEffect(() => {
    setIsShow(true)
  }, [])

  return (
    <div
      className={css(
        classnames(
          'container',
          isMobileMode ? '--mobile' : null,
          pinnedComment ? '--has-pinned-comment' : null,
          isDetailsPanelCollapsed ? '--collapsed' : null,
          isShow || isMobileMode ? '--show' : null
        )
      )}
    >
      {isDetailsPanelCollapsed && (
        <div>
          <PlateButton noBg onClick={() => main.collapseDetailsPanel(false)}>
            <PlateButtonIcon>
              <SvgIconOpenColumn />
            </PlateButtonIcon>
          </PlateButton>
        </div>
      )}
      {!isMobileMode && !isDetailsPanelCollapsed && (
        <div className={css('header')}>
          <Avatar url={clientProfile?.avatarUrl || getStaffAvatar(clientId)} colorName={chatName} />
          <div className={css('name')}>{chatName}</div>
          <div className={css('header-buttons')}>
            <div>
              <PlateButton noBg onClick={() => setShowMenu(true)}>
                <PlateButtonIcon>
                  <SvgIconMenuDots />
                </PlateButtonIcon>
              </PlateButton>
              {showMenu && (
                <Dropdown
                  active
                  variation='secondary'
                  position='right'
                  offsetTarget='iconButton'
                  onOverlayClick={onOverlayClickHandler}
                >
                  <SecondaryDropdownItem view='bright' onClick={() => setShowEditName(true)}>
                    <SecondaryDropdownItemText>{t('dlg:editClientsName')}</SecondaryDropdownItemText>
                  </SecondaryDropdownItem>
                  {!banResult && (isSupervisor || currentUserPermissions.includes(PERMISSIONS_PAGE.AGENTS_CAN_BAN_CLIENTS)) && (
                    <SecondaryDropdownItem view='warning' onClick={() => setShowBanClient(true)}>
                      <SecondaryDropdownItemText>{t('dlg:banClient')}</SecondaryDropdownItemText>
                    </SecondaryDropdownItem>
                  )}
                  {banResult && (
                    <SecondaryDropdownItem view='warning' onClick={onUnbanClient}>
                      <SecondaryDropdownItemText>{t('dlg:unbanClient')}</SecondaryDropdownItemText>
                    </SecondaryDropdownItem>
                  )}
                  {window.parent !== window && (
                    <SecondaryDropdownItem view='bright' onClick={onOpenClientCRM}>
                      <SecondaryDropdownItemText>{t('dlg:openClientCRM')}</SecondaryDropdownItemText>
                    </SecondaryDropdownItem>
                  )}
                </Dropdown>
              )}
            </div>
            <PlateButton noBg onClick={() => main.collapseDetailsPanel(true)}>
              <PlateButtonIcon>
                <SvgIconCloseColumn />
              </PlateButtonIcon>
            </PlateButton>
          </div>
        </div>
      )}
      {showEditName && (
        <EditNameDialog
          chatName={chatName}
          onCancel={() => setShowEditName(false)}
          onEdited={() => setShowEditName(false)}
        />
      )}
      {showBanClient && (
        <BanClientDialog
          clientProfile={clientProfile}
          receiver={receiver}
          onCancel={() => setShowBanClient(false)}
          onBanned={() => {
            updateBanResult()
            setShowBanClient(false)
          }}
        />
      )}
      {pinnedComment && (
        <div className={css('notice')}>
          <div className={css('notice-block')}>
            <div className={css('notice-text')}>{pinnedComment.text}</div>
            <div className={css('notice-author')}>
              {t('dlg:notedBy', { name: getUserName(pinnedComment.createdUserId) })}
            </div>
          </div>
          {comments.length === -1 && (
            <div className={css(['notice-block', 'notice-view-more'])}>
              <span className={css('notice-view-more-link')} onClick={scrollToNotes}>
                {t('dlg:viewMoreNotes', { count: comments.length - 1 })}
              </span>
            </div>
          )}
        </div>
      )}
      {!isDetailsPanelCollapsed && (
        <Tabs view='secondary' classes={{ root: css('tabs') }} state={[tabIndex, setTabIndex]}>
          <TabsHeaderDropdown classes={{ root: css('tabs-header') }}>
            <Tab>{t('dlg:profile')}</Tab>
            <Tab
              label={
                relatedChats.length > 0 ? (
                  <Tag color='gray' variation='secondary' view='filled'>
                    {relatedChats.length}
                  </Tag>
                ) : null
              }
            >
              {t('dlg:relatedChats')}
            </Tab>
            <Tab disabled>{t('dlg:media')}</Tab>
          </TabsHeaderDropdown>
          <TabsBody classes={{ root: css('tab-body') }}>
            <Panel>
              <ProfileTab
                crmFields={crmFields}
                receiver={receiver}
                comments={comments}
                recipient={recipient}
                metaDataLoading={metaDataLoading}
                onCrmFieldUpdated={onCrmFieldUpdated}
                onTagsUpdated={onTagsUpdated}
              />
            </Panel>
            <Panel>
              <RelatedChatsTab
                relatedChats={relatedChats}
                joinedMessageHistory={currentUserPermissions.includes(PERMISSIONS_PAGE.JOIN_RELATED_CHATS)}
              />
            </Panel>
            <Panel>3 </Panel>
          </TabsBody>
        </Tabs>
      )}
    </div>
  )
}

RightPanel.propTypes = {
  banResult: object,
  updateBanResult: func.isRequired,
  crmFields: array,
  relatedChats: array,
  recipient: object,
  metaDataLoading: bool,
  setCrmFields: func.isRequired,
  setRecipient: func.isRequired
}
