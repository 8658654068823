/** @prettier */
import { Spinner } from '@flomni/components/dist/components/spinner'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'

export function AppLoader() {
  const css = useStyles(styles)

  return (
    <div className={css('container')}>
      <div className={css('wrapper')}>
        <Spinner strokeWidth={5} />
      </div>
    </div>
  )
}
