import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { array, func, object } from 'prop-types'
import { useForm } from 'react-hook-form'
import { ControlContainer } from '@flomni/components/dist/components/control-container'
import { Input } from '@flomni/components/dist/components/input'
import { RadioOption } from '@flomni/components/dist/components/radio-option'
import { Select } from '@flomni/components/dist/components/select'
import { BUTTON_KIND } from '../../../../../../../services/constants'

const ButtonForm = ({ button, actions, onSave, onCancel }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()

  const { register, formState, errors, getValues, setValue, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: button?.name ?? '',
      value: button?.kind === BUTTON_KIND.CUSTOM ? button?.value : null,
      kind: button?.kind ?? BUTTON_KIND.CUSTOM,
      url: button?.kind === BUTTON_KIND.URL ? button?.value : ''
    }
  })

  const kind = watch('kind')
  const value = watch('value')

  const actionOptions = actions.map((action) => {
    return {
      label: action.name,
      value: action.actionId
    }
  })
  const selectedActionOption = actionOptions.find((option) => option.value === value) || null

  const onHandlerSave = (e) => {
    e.preventDefault()
    const values = getValues()
    onSave({
      name: values.name,
      value: values.kind === BUTTON_KIND.CUSTOM ? values.value : values.url,
      kind: values.kind
    })
  }

  const handlerClickOnCancel = () => {
    onCancel()
  }

  return (
    <form>
      <div className={css('group')}>
        <div className={css('title')}>{t('dlg:buttonName')}</div>
        <ControlContainer variation='secondary' warning={errors.name ? errors.name.message : null}>
          <Input
            variation='secondary'
            classes={{ root: css('field') }}
            placeholder={t('dlg:buttonName')}
            warning={!!errors.name}
            name='name'
            ref={register({
              required: t('dlg:nameRequired')
            })}
          />
        </ControlContainer>
      </div>
      <div className={css('group')}>
        <div className={css('title')}>{t('dlg:buttonType')}</div>
        <input hidden name='kind' ref={register()} />
        <div className={css('radio-group')}>
          <RadioOption
            variation='secondary'
            onChange={() => setValue('kind', BUTTON_KIND.CUSTOM, { shouldDirty: true, shouldValidate: true })}
            checked={kind === BUTTON_KIND.CUSTOM}
          >
            {t('dlg:action')}
          </RadioOption>
          <RadioOption
            variation='secondary'
            onChange={() => setValue('kind', BUTTON_KIND.URL, { shouldDirty: true, shouldValidate: true })}
            checked={kind === BUTTON_KIND.URL}
          >
            {t('dlg:url')}
          </RadioOption>
        </div>
        {kind === BUTTON_KIND.URL && (
          <ControlContainer variation='secondary' warning={errors.url ? errors.url.message : null}>
            <Input
              variation='secondary'
              classes={{ root: css('field') }}
              placeholder={t('dlg:enterUrl')}
              warning={!!errors.url}
              name='url'
              ref={register({
                required: t('dlg:urlRequired')
              })}
            />
          </ControlContainer>
        )}
        {kind === BUTTON_KIND.CUSTOM && (
          <>
            <input hidden name='value' ref={register({ required: true })} />
            <div className={css('field')}>
              <Select
                variation='secondary'
                options={actionOptions}
                value={selectedActionOption}
                menuPortalTarget={document.body}
                placeholder={t('dlg:selectChatbotEnterPoint')}
                onChange={(option) =>
                  setValue('value', option.value, { shouldDirty: true, shouldValidate: true })
                }
              />
            </div>
          </>
        )}
      </div>
      <div className={css(['group', '--buttons'])}>
        <SecondaryButton view='light' onClick={handlerClickOnCancel}>
          <SecondaryButtonText>{t('dlg:cancel')}</SecondaryButtonText>
        </SecondaryButton>
        <SecondaryButton disabled={!formState.isValid || !formState.isDirty} onClick={onHandlerSave}>
          <SecondaryButtonText>{button ? t('dlg:set') : t('dlg:add')}</SecondaryButtonText>
        </SecondaryButton>
      </div>
    </form>
  )
}

ButtonForm.propTypes = {
  button: object,
  actions: array,
  onSave: func.isRequired,
  onCancel: func.isRequired
}

export default ButtonForm
