import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { func, object } from 'prop-types'
import { ValueSelector } from '../shared/value-selector'

export const ChatRateFilterItem = ({ item, onChangeItemProperty }) => {
  const { t } = useTranslation()

  const RATES = ['1', '2', '3', '4', '5']
  const [selectedRate, setSelectedRate] = useState(item.rate ? item.rate.toString() : null)

  const onSelectRate = (rate) => {
    setSelectedRate(rate)
    onChangeItemProperty('rate', parseInt(rate))
  }

  return (
    <ValueSelector
      items={RATES}
      item={selectedRate}
      onChange={onSelectRate}
      placeholder={t('dlg:selectRate')}
      translate={false}
    />
  )
}

ChatRateFilterItem.propTypes = {
  onChangeItemProperty: func.isRequired,
  item: object.isRequired
}
