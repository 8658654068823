import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { array, string } from 'prop-types'
import { Tag } from '@flomni/components/dist/components/tag'
import { Tooltip } from '@flomni/components/dist/components/tooltip'
import { SvgIconMy } from '@flomni/components/dist/components/svg/feathers/SvgIconMy'
import { SvgIconDone } from '@flomni/components/dist/components/svg/feathers/SvgIconDone'
import { SvgIconBot } from '@flomni/components/dist/components/svg/feathers/SvgIconBot'
import { SvgIconAgent } from '@flomni/components/dist/components/svg/feathers/SvgIconAgent'
import { SvgIconNew } from '@flomni/components/dist/components/svg/feathers/SvgIconNew'
import { useTranslation } from 'react-i18next'

export const ChatTypeIcon = ({ state, type, currentUserId, stuffUsers }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()

  const inArchive = type === 'ARCHIVE'
  const isPersonal = Array.isArray(stuffUsers) && stuffUsers.includes(currentUserId)

  const IconComponent = ({ Icon, tooltip }) => {
    return (
      <div>
        <Tooltip variation='secondary' content={tooltip}>
          <div>
            <Tag classes={{ inner: css('tag') }} color='gray' variation='secondary'>
              <Icon />
            </Tag>
          </div>
        </Tooltip>
      </div>
    )
  }

  if (state === 'ARCHIVED' || inArchive) {
    return <IconComponent Icon={SvgIconDone} tooltip={t('dlg:done')} />
  }
  if (state === 'NEW') {
    return <IconComponent Icon={SvgIconNew} tooltip={t('dlg:new')} />
  }
  if (state === 'BOT_DRIVEN') {
    return <IconComponent Icon={SvgIconBot} tooltip={t('dlg:bot')} />
  }
  if (state === 'IN_WORK') {
    return isPersonal ? (
      <IconComponent Icon={SvgIconMy} tooltip={t('dlg:my')} />
    ) : (
      <IconComponent Icon={SvgIconAgent} tooltip={t('dlg:agent')} />
    )
  }
  return null
}

ChatTypeIcon.props = {
  state: string,
  type: string,
  stuffUsers: array,
  currentUserId: string
}
