import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { Input } from '@flomni/components/dist/components/input'
import { func, object } from 'prop-types'

export const ChatMessagesFilterItem = ({ item, onChangeItemProperty }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()

  const [message, setMessage] = useState(item.message)

  const onChangeMessage = (value) => {
    setMessage(value)
    onChangeItemProperty('message', value)
  }

  return (
    <div className={css('container')}>
      <div className={css('text')}>{t('dlg:contains')}</div>
      <Input
        shaded
        autoFocus
        variation='secondary'
        placeholder={t('dlg:keywordOrPhrase')}
        value={message || ''}
        onChange={(e) => onChangeMessage(e.target.value)}
      />
    </div>
  )
}

ChatMessagesFilterItem.propTypes = {
  onChangeItemProperty: func.isRequired,
  item: object.isRequired
}
