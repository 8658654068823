import React, { useCallback, useMemo, useState } from 'react'
import { Dropdown } from '@flomni/components/dist/components/dropdown'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonText } from '@flomni/components/dist/components/plate-button/text'
import { func, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { DatePicker } from '@flomni/components/dist/components/date-picker'
import moment from 'moment'
import { DateUtils } from '@flomni/modules/dist/services/date'
import { Localization } from '../../../../../../../../services/localization'

export const DateSelector = ({ date, onChange, filterDate }) => {
  const { t } = useTranslation()

  const getFormattedDate = (date, mask) => {
    return date ? moment(date, mask).startOf('day').format(DateUtils.DATE_FORMAT) : undefined
  }

  const [showSelector, setShowSelector] = useState(false)

  const onOverlaySelectorHandler = useCallback(() => {
    setShowSelector(false)
  }, [])

  const onSelectDate = (date) => {
    setShowSelector(false)
    onChange(getFormattedDate(date))
  }

  const selectedDate = useMemo(() => getFormattedDate(date, DateUtils.DATE_FORMAT), [date])

  return (
    <div>
      <PlateButton view={selectedDate ? 'usual' : 'bright'} onClick={() => setShowSelector(true)}>
        <PlateButtonText>{selectedDate || t('dlg:selectDate')}</PlateButtonText>
      </PlateButton>
      <Dropdown
        active={showSelector}
        variation='secondary'
        offsetTarget='iconButton'
        onOverlayClick={onOverlaySelectorHandler}
      >
        <DatePicker
          numberOfCalendars={1}
          inline
          onSelect={onSelectDate}
          selected={selectedDate ? moment(selectedDate, DateUtils.DATE_FORMAT).toDate() : null}
          variation='secondary'
          locale={Localization.getDatePickerLocale()}
          filterDate={filterDate}
        />
      </Dropdown>
    </div>
  )
}

DateSelector.propTypes = {
  date: string,
  onChange: func.isRequired,
  filterDate: func
}
