import { useContext, useEffect, useState } from 'react'
import { Meteor } from '../../../../meteorAdapter';
import { store } from '../../../state/dialogs'
import { useDispatch, useSelector } from 'react-redux'
import { processError } from '../../../services/helpers'
import { PERMISSIONS_PAGE } from '../../../services/constants'

export const useMetaData = () => {
  const { main } = useDispatch()
  const { selectedDialogId, dispatch } = useContext(store)
  const [crmFields, setCrmFields] = useState([])
  const [relatedChats, setRelatedChats] = useState([])
  const [recipient, setRecipient] = useState(null)
  const [metaDataLoading, setMetaDataLoading] = useState(false)

  const currentUserPermissions = useSelector(({ main }) => main.currentUserPermissions)

  useEffect(() => {
    if (selectedDialogId) {
      setMetaDataLoading(true)
      Meteor.invoke('dialogs.requestClientInfo', {
        dialogId: selectedDialogId
      })
        .then((clientInfo) => {
          setCrmFields(clientInfo.crmFields || [])
          setRelatedChats(clientInfo.unitedProfiles || [])
          if (currentUserPermissions.includes(PERMISSIONS_PAGE.JOIN_RELATED_CHATS)) {
            dispatch({ type: 'setRelatedDialogsIds', relatedDialogsIds: clientInfo.relatedDialogsIds })
          }
          setRecipient(clientInfo.recipient || null)
        })
        .catch((err) => {
          processError(err, main)
        })
        .finally(() => {
          setMetaDataLoading(false)
        })
    } else {
      setCrmFields([])
      setRelatedChats([])
      setRecipient(null)
    }
  }, [selectedDialogId])

  return { crmFields, relatedChats, recipient, metaDataLoading, setCrmFields, setRecipient }
}
