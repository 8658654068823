import React, { useCallback, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from '../index.module.scss'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { useForm } from 'react-hook-form'
import { Meteor } from '../../../../../../../meteorAdapter';
import { useDispatch } from 'react-redux'
import { ButtonWithLoader } from '../../../../../shared/button-with-loader'
import { processError } from '../../../../../../services/helpers'
import { RadioOption } from '@flomni/components/dist/components/radio-option'
import { object } from 'prop-types'

export const AccountTemplateSuggestions = ({ user }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const [showSaveLoader, setShowSaveLoader] = useState(false)
  const [footerStatus, setFooterStatus] = useState('')

  const { register, watch, setValue, reset, formState, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      allowSuggestions: (user?.profile?.allowSuggestions === true).toString()
    }
  })
  const allowSuggestions = watch('allowSuggestions')

  const onSave = () => {
    const { allowSuggestions } = getValues()
    setShowSaveLoader(true)

    setTimeout(() => {
      Meteor.invoke('users.updateProfile', {
        allowSuggestions: allowSuggestions === 'true'
      })
        .then(() => {
          reset({
            allowSuggestions
          })
          setFooterStatus(t('dlg:yourChangesSaved'))
        })
        .catch((err) => {
          processError(err, main)
        })
        .finally(() => {
          setShowSaveLoader(false)
        })
    }, 800)
  }

  const onCancel = useCallback(() => {
    reset({
      allowSuggestions: (user?.profile?.allowSuggestions === true).toString()
    })
    setFooterStatus(t('dlg:yourChangesDiscarded'))
  }, [user])

  return (
    <>
      <div className={css('form')}>
        <div className={css('header')}>
          <div>{t('dlg:templateSuggestions')}</div>
        </div>
        <input hidden name='allowSuggestions' ref={register()} />
        <div className={css('body')}>
          <div className={css('body-line')}>
            <div className={css('title')}>{t('dlg:suggestions')}</div>
            <div className={css('options')}>
              <div className={css('option')}>
                <RadioOption
                  variation='secondary'
                  onChange={(e) => {
                    setValue('allowSuggestions', e.target.value, { shouldDirty: true })
                  }}
                  value='true'
                  checked={allowSuggestions === 'true'}
                  size='small'
                >
                  {t('dlg:enable')}
                </RadioOption>
              </div>
              <div className={css('option')}>
                <RadioOption
                  variation='secondary'
                  onChange={(e) => {
                    setValue('allowSuggestions', e.target.value, { shouldDirty: true })
                  }}
                  value='false'
                  checked={allowSuggestions === 'false'}
                  size='small'
                >
                  {t('dlg:disable')}
                </RadioOption>
              </div>
            </div>
          </div>
        </div>
        <div className={css('footer')}>
          <div className={css('footer-status')}>{footerStatus}</div>
          <div className={css('footer-buttons')}>
            <SecondaryButton view='light' onClick={onCancel} disabled={!formState.isDirty}>
              <SecondaryButtonText>{t('dlg:cancel')}</SecondaryButtonText>
            </SecondaryButton>
            <ButtonWithLoader
              text={t('dlg:save')}
              onClick={onSave}
              disabled={!formState.isValid || !formState.isDirty}
              spinnerSize={10}
              spinnerStrokeWidth={14}
              isLoading={showSaveLoader}
            />
          </div>
        </div>
      </div>
    </>
  )
}

AccountTemplateSuggestions.propTypes = {
  user: object
}
