import i18next from 'i18next'
import { changeLanguage } from '@flomni/modules/dist/services/i18n/i18n'
import { DateUtils } from '@flomni/modules/dist/services/date'
import moment from 'moment-timezone'
import 'moment/locale/ru'
import ru from 'date-fns/locale/ru'

const locale = {
  ru: ru
}

export class Localization {
  static changeLocale = async (language) => {
    if (language) {
      const i18nextLanguage = language.replace('_', '-')
      if (i18nextLanguage !== i18next.language) {
        await changeLanguage(i18nextLanguage)
      }
      const shortLocale = language.slice(0, 2)
      if (shortLocale !== moment.locale()) {
        moment.locale(shortLocale)
      }
      DateUtils.localeChanged()
    }
  }

  static changeTimezone = (tz) => {
    if (tz) {
      moment.tz.setDefault(tz)
    }
  }

  static getCurrentLocale = () => {
    return moment.locale()
  }

  static getDatePickerLocale = () => {
    return locale[Localization.getCurrentLocale()]
  }
}
