import React, { useContext, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonText } from '@flomni/components/dist/components/plate-button/text'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { SvgIconPlus } from '@flomni/components/dist/components/svg/feathers/SvgIconPlus'
import { SvgIconFilter } from '@flomni/components/dist/components/svg/feathers/SvgIconFilter'
import { SvgIconUser } from '@flomni/components/dist/components/svg/feathers/SvgIconUser'
import { AddNote } from './add-note'
import { array, object } from 'prop-types'
import { CommentItem } from './comment-item'
import { TextItem } from './text-item'
import { store } from '../../../../../../../../state/dialogs'

export const Timeline = ({ comments, recipient }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { isEditMode, isSearchMode } = useContext(store)
  const [showAddNote, setShowAddNote] = useState(false)
  const [highlightedCommentId, setHighlightedCommentId] = useState(null)

  const pinnedComment = comments.find((comment) => comment.isPinned)
  const notPinnedComments = comments.filter((comment) => !comment.isPinned)
  const items = [
    ...notPinnedComments.map((comment) => {
      return { ...comment, isComment: true }
    })
  ]
  if (pinnedComment) {
    items.unshift({
      ...pinnedComment,
      isComment: true
    })
  }

  const onResetHighlightedComment = () => {
    setHighlightedCommentId(null)
  }

  const onAddNoteHandler = ([, id]) => {
    setHighlightedCommentId(id)
    setShowAddNote(false)
  }

  return (
    <>
      <div className={css('header')}>
        <div className={css('header-content')}>
          <div className={css('title')}>{t('dlg:timeline')}</div>
          <div className={css('header-buttons')}>
            <div>
              <PlateButton view='usual' style={{ display: 'none' }}>
                <PlateButtonIcon>
                  <SvgIconFilter />
                </PlateButtonIcon>
                <PlateButtonText>{t('dlg:filter')}</PlateButtonText>
              </PlateButton>
            </div>
            {(isEditMode || isSearchMode) && (
              <PlateButton view='usual' disabled={showAddNote} onClick={() => setShowAddNote(true)}>
                <PlateButtonIcon>
                  <SvgIconPlus />
                </PlateButtonIcon>
                <PlateButtonText>{t('dlg:addNote')}</PlateButtonText>
              </PlateButton>
            )}
          </div>
        </div>
        {showAddNote && <AddNote onAdd={onAddNoteHandler} onCancel={() => setShowAddNote(false)} />}
      </div>
      <div className={css('items')}>
        {items.map((item, index) => (
          <div key={item.id}>
            {item.isComment && (
              <CommentItem
                comment={item}
                isHighlighted={item.id === highlightedCommentId}
                onResetHighlighted={onResetHighlightedComment}
                pinnedComment={pinnedComment}
                isLast={!recipient && index === items.length - 1}
                hidePinOptions={comments.length === 1}
              />
            )}
          </div>
        ))}
        {recipient && (
          <TextItem
            icon={<SvgIconUser />}
            iconClass='--green'
            item={{ text: t('dlg:profileWasCreated'), createdAt: recipient.createdAt }}
            isLast
          />
        )}
      </div>
    </>
  )
}

Timeline.propTypes = {
  comments: array,
  recipient: object
}
