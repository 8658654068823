import React, { useEffect, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { PopupButtons } from '@flomni/components/dist/components/popup/buttons'
import { PopupContent } from '@flomni/components/dist/components/popup/content'
import { PopupTitle } from '@flomni/components/dist/components/popup/title'
import { array, bool, func, object } from 'prop-types'
import { useForm } from 'react-hook-form'
import { ControlContainer } from '@flomni/components/dist/components/control-container'
import { Input } from '@flomni/components/dist/components/input'
import { Tip } from '@flomni/components/dist/components/tip'
import { RadioOption } from '@flomni/components/dist/components/radio-option'
import { TEMPLATE_TYPE } from '../../../../../../../services/constants'
import { Select } from '@flomni/components/dist/components/select'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { SvgIconPlus } from '@flomni/components/dist/components/svg/feathers/SvgIconPlus'
import { PlateButtonText } from '@flomni/components/dist/components/plate-button/text'
import { SecondaryButtonIcon } from '@flomni/components/dist/components/secondary-button/icon'
import { SvgIconDelete } from '@flomni/components/dist/components/svg/feathers/SvgIconDelete'
import classnames from 'classnames'
import { Meteor } from '../../../../../../../../meteorAdapter';
import { processError } from '../../../../../../../services/helpers'
import { useDispatch } from 'react-redux'

const DialogTemplateFirstStep = ({ isEdit, template, onNext, onCancel, actions, isOperator }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const [showAddAction, setShowAddAction] = useState(false)
  const [showAddSuggestion, setShowAddSuggestion] = useState(false)
  const [topics, setTopics] = useState([])

  const { register, formState, errors, watch, setValue, getValues, unregister } = useForm({
    mode: 'onChange',
    defaultValues: {
      text: '',
      title: '',
      type: TEMPLATE_TYPE.USER,
      actionId: null,
      topicIds: null
    }
  })

  const type = watch('type')
  const actionId = watch('actionId')
  const topicIds = watch('topicIds')

  useEffect(() => {
    Meteor.invoke('mbrain.TextLabeling.stories.topics.get')
      .then((res) => {
        if (res && res.status !== 'error') {
          setTopics(res.msg.topics || [])
        }
      })
      .catch((err) => {
        processError(err, main)
      })

    register('topicIds')
    register('actionId')
    register('type')

    return () => {
      unregister('topicIds')
      unregister('actionId')
      unregister('type')
    }
  }, [])

  useEffect(() => {
    if (template) {
      setValue('text', template.text || '', { shouldDirty: true, shouldValidate: true })
      setValue('title', template.title || template.name || '', { shouldDirty: true, shouldValidate: true })
      setValue('type', template.type || TEMPLATE_TYPE.USER)
      const actionId = template.botAction ? template.botAction.id : null
      setShowAddAction(!!actionId)
      setValue('actionId', actionId)
      setShowAddSuggestion(!!template.suggestionsTopic)
      setValue('topicIds', template.suggestionsTopic)
    }
  }, [template])

  const onHandlerNext = (e) => {
    e.preventDefault()
    const values = getValues()
    const action = values.actionId ? actions.find((action) => action.actionId === values.actionId) : null
    let botAction = null
    if (action) {
      botAction = {
        id: action.actionId,
        name: action.name
      }
    }
    onNext({
      text: values.text,
      title: values.title,
      type: values.type,
      suggestionsTopic: values.topicIds,
      botAction
    })
  }

  const handlerClickOnCancel = () => {
    onCancel()
  }

  const actionOptions = actions.map((action) => {
    return {
      label: action.name,
      value: action.actionId
    }
  })
  const selectedActionOption = actionOptions.find((option) => option.value === actionId) || null

  const topicsOptions = topics.map((topic) => {
    return {
      label: topic.name,
      value: topic.id
    }
  })

  const selectedTopicOption = topicsOptions.filter((option) => {
    if (Array.isArray(topicIds)) {
      return topicIds?.includes(option.value)
    } else {
      return topicIds === option.value
    }
  }) || null

  return (
    <>
      <PopupTitle
        variation='secondary'
        title={isEdit ? t('dlg:editTemplate') : t('dlg:addNewTemplate')}
        description={isEdit ? null : t('dlg:enterNameTextType')}
      />
      <PopupContent variation='secondary' classes={{ root: css('content') }}>
        <div className={css('progress')}>
          <div className={css('progress-current')} />
          <div className={css('progress-remain')} />
        </div>
        <div className={css('group')}>
          <div className={css('title')}>{t('dlg:templateName')}</div>
          <ControlContainer variation='secondary' warning={errors.title ? errors.title.message : null}>
            <Input
              variation='secondary'
              classes={{ root: css('field') }}
              placeholder={t('dlg:templateName')}
              warning={!!errors.title}
              name='title'
              ref={register({
                required: t('dlg:nameRequired')
              })}
            />
          </ControlContainer>
        </div>
        <div className={css('group')}>
          <div className={css('title')}>{t('dlg:message')}</div>
          <ControlContainer variation='secondary' warning={errors.text ? errors.text.message : null}>
            <Input
              textArea
              variation='secondary'
              classes={{ root: css('field-textarea') }}
              placeholder={t('dlg:typeTextOfTemplate')}
              warning={!!errors.text}
              name='text'
              ref={register({
                required: t('dlg:messageRequired')
              })}
            />
          </ControlContainer>
        </div>
        {!isEdit && (
          <Tip view='info' variation='secondary' classes={{ root: css('tip') }}>
            <div className={css('tip-header')}>{t('dlg:referenceAboutSubstitutions')}</div>
            <div className={css('tip-description')}>{t('dlg:youCanUseSubstitutionsTemplates')}</div>
            <div className={css('tip-substitutions')}>
              <div>
                <div className={css('tip-substitutions-item')}>{t('dlg:agentId')}</div>
                <div className={css('tip-substitutions-item')}>{t('dlg:operatorName')}</div>
                <div className={css('tip-substitutions-item')}>{t('dlg:clientName')}</div>
                <div className={css('tip-substitutions-item')}>{t('dlg:dateDefaultFormat')}</div>
                <div className={css('tip-substitutions-item')}>{t('dlg:dateArbitraryFormat')}</div>
              </div>
              <div>
                <div className={css('tip-substitutions-template')}>{'@{agent}'}</div>
                <div className={css('tip-substitutions-template')}>{'@{agentName}'}</div>
                <div className={css('tip-substitutions-template')}>{'@{name}'}</div>
                <div className={css('tip-substitutions-template')}>{'@{date}'}</div>
                <div className={css('tip-substitutions-template')}>{'@{date,"DD MM YYYY hh:mm:ss"}'}</div>
              </div>
            </div>
          </Tip>
        )}
        {!isOperator && (
          <div className={css(classnames('group', isEdit ? null : '--shadow-top'))}>
            <div className={css('title')}>{t('dlg:securityType')}</div>
            <div className={css('radio-group')}>
              <RadioOption
                variation='secondary'
                onChange={() => setValue('type', TEMPLATE_TYPE.USER)}
                checked={type === TEMPLATE_TYPE.USER}
              >
                {t('dlg:private')}
              </RadioOption>
              <RadioOption
                variation='secondary'
                onChange={() => setValue('type', TEMPLATE_TYPE.GROUP)}
                checked={type === TEMPLATE_TYPE.GROUP}
              >
                {t('dlg:shared')}
              </RadioOption>
            </div>
          </div>
        )}
        <div className={css('group')}>
          <div className={css('title')}>
            <span>{t('dlg:chatbotAction')}</span>
            {!showAddAction && (
              <PlateButton view='usual' onClick={() => setShowAddAction(true)}>
                <PlateButtonIcon>
                  <SvgIconPlus />
                </PlateButtonIcon>
                <PlateButtonText>{t('dlg:addChatbotAction')}</PlateButtonText>
              </PlateButton>
            )}
          </div>
          {showAddAction && (
            <div className={css('action-select-container')}>
              <Select
                className={css('field')}
                variation='secondary'
                options={actionOptions}
                value={selectedActionOption}
                menuPlacement='top'
                menuPortalTarget={document.body}
                placeholder={t('dlg:selectChatbotEnterPoint')}
                onChange={(option) =>
                  setValue('actionId', option.value, {
                    shouldDirty: true,
                    shouldValidate: true
                  })
                }
              />
              <SecondaryButton
                view='light'
                disabled={!actionId}
                onClick={() => {
                  setValue('actionId', null, {
                    shouldDirty: true,
                    shouldValidate: true
                  })
                  setShowAddAction(false)
                }}
              >
                <SecondaryButtonIcon>
                  <SvgIconDelete />
                </SecondaryButtonIcon>
                <SecondaryButtonText>{t('dlg:remove')}</SecondaryButtonText>
              </SecondaryButton>
            </div>
          )}
        </div>
        <div className={css('group')}>
          <div className={css('title')}>
            <span>{t('dlg:suggestions')}</span>
            {!showAddSuggestion && (
              <PlateButton view='usual' onClick={() => setShowAddSuggestion(true)}>
                <PlateButtonIcon>
                  <SvgIconPlus />
                </PlateButtonIcon>
                <PlateButtonText>{t('dlg:connectWithMegabrain')}</PlateButtonText>
              </PlateButton>
            )}
          </div>
          {showAddSuggestion && (
            <div className={css('action-select-container')}>
              <Select
                isMulti
                className={css('field')}
                variation='secondary'
                options={topicsOptions}
                value={selectedTopicOption}
                menuPlacement='top'
                menuPortalTarget={document.body}
                placeholder={t('dlg:selectTopic')}
                onChange={(options) => {
                  setValue('topicIds', options.map((option) => option.value), {
                    shouldDirty: true,
                    shouldValidate: true
                  })
                }}
              />
              <SecondaryButton
                view='light'
                disabled={!topicIds}
                onClick={() => {
                  setValue('topicIds', null, {
                    shouldDirty: true,
                    shouldValidate: true
                  })
                  setShowAddSuggestion(false)
                }}
              >
                <SecondaryButtonIcon>
                  <SvgIconDelete />
                </SecondaryButtonIcon>
                <SecondaryButtonText>{t('dlg:remove')}</SecondaryButtonText>
              </SecondaryButton>
            </div>
          )}
        </div>
        {showAddSuggestion && (
          <Tip variation='secondary' view='info' classes={{ root: css('tip') }}>
            <div className={css('tip-header')}>{t('dlg:megabrainsSuggestions')}</div>
            <div className={css('tip-description')}>{t('dlg:megabrainsSuggestionsDescription')}</div>
          </Tip>
        )}
      </PopupContent>
      <PopupButtons variation='secondary'>
        <SecondaryButton view='light' onClick={handlerClickOnCancel}>
          <SecondaryButtonText>{t('dlg:cancel')}</SecondaryButtonText>
        </SecondaryButton>
        <SecondaryButton disabled={!formState.isValid || !formState.isDirty} onClick={onHandlerNext}>
          <SecondaryButtonText>{t('dlg:next')}</SecondaryButtonText>
        </SecondaryButton>
      </PopupButtons>
    </>
  )
}

DialogTemplateFirstStep.propTypes = {
  isEdit: bool,
  template: object,
  onNext: func.isRequired,
  onCancel: func.isRequired,
  actions: array,
  isOperator: bool
}

export default DialogTemplateFirstStep
