import React, { useEffect } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { func, object } from 'prop-types'
import { Popup } from '@flomni/components/dist/components/popup/popup'
import { SvgIconClose } from '@flomni/components/dist/components/svg/feathers/SvgIconClose'
import { useTranslation } from 'react-i18next'

export const LocationPreview = ({ attachment, onClose }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const position = [attachment.lat, attachment.lng]

  useEffect(() => {
    window.L.Icon.Default.imagePath = '/packages/bevanhunt_leaflet/images/'

    const map = window.L.map('map').setView(position, 17)
    window.L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map)
    window.L.marker(position).addTo(map)

    return () => {
      if (map) {
        map.off()
        map.remove()
      }
    }
  }, [])

  return (
    <Popup open variation='secondary' classes={{ content: css('container') }}>
      <div className={css('header')}>
        <div>
          <div className={css('name')}>{attachment.name || '-'}</div>
          <div className={css('location')}>
            {t('dlg:latLngCoordinates', {
              lat: attachment.lat.toString(),
              lng: attachment.lng.toString()
            })}
          </div>
        </div>
        <div className={css('cross')} onClick={onClose}>
          <SvgIconClose />
        </div>
      </div>
      <div id='map' className={css('content')} />
    </Popup>
  )
}

LocationPreview.propTypes = {
  attachment: object.isRequired,
  onClose: func.isRequired
}
