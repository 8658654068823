import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { func, object } from 'prop-types'
import { ValueSelector } from '../shared/value-selector'
import { useTracker } from '../../../../../../../../meteorAdapter';
import { useSelector } from 'react-redux'
import { Tags } from '../../../../../../../customStores'

export const TopicFilterItem = ({ item, onChangeItemProperty }) => {
  const { t } = useTranslation()

  const currentUserGroupId = useSelector(({ main }) => main.currentUserGroupId)
  const [selectedTopicId, setSelectedTopicId] = useState(item.topicId)
  const [topics, setTopics] = useState([])

  useTracker(() => {
    const tags = Tags.find(
      {
        sourceId: currentUserGroupId,
        sourceType: 'group'
      },
      {
        fields: {
          name: 1,
          kind: 1
        },
        sort: {
          name: 1
        }
      }
    )
      .map(({ _id, name, kind }) => ({
        _id,
        name,
        kind
      }))
      .filter(({ kind }) => kind === 'session')
      .map(({ _id, name }) => ({
        value: _id,
        label: name
      }))

    setTopics(tags)
  }, [currentUserGroupId])

  const onSelectTopicId = (topicId) => {
    setSelectedTopicId(topicId)
    onChangeItemProperty('topicId', topicId)
  }

  return (
    <ValueSelector
      items={topics}
      item={selectedTopicId}
      onChange={onSelectTopicId}
      placeholder={t('dlg:selectTopic')}
      translate={false}
    />
  )
}

TopicFilterItem.propTypes = {
  onChangeItemProperty: func.isRequired,
  item: object.isRequired
}
