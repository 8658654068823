import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { Tag } from '@flomni/components/dist/components/tag'
import { bool, func, object } from 'prop-types'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { getDialogName, fixedEncode } from '../../../../../../../../utils/common'
import { Avatar } from '../../../../../../shared/avatar'
import { LastMessageDate } from './last-message-date'
import { InactiveTimer } from './inactive-timer'
import { PreparedAtTimer } from './prepared-at-timer'
import { Meteor, useTracker, Match } from '../../../../../../../../../meteorAdapter';
import { Status } from './status'
import { SvgIconCalendar } from '@flomni/components/dist/components/svg/feathers/SvgIconCalendar'
import { SvgIconBack } from '@flomni/components/dist/components/svg/feathers/SvgIconBack'
import { useSelector } from 'react-redux'
import { ChatTypeIcon } from '../../../center-panel/header/chat-type-icon'
import { Staff } from '../../../../../../../../customStores'
import moment from 'moment'

export const ChatItem = ({
  isSelected,
  chat,
  onSelect,
  isInactiveTimerVisible,
  isPreparedAtTimerVisible,
  isInboundCountVisible,
  showChatTypeIcon
}) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const userId = useTracker(() => Meteor.user()?._id, [])
  const currentUserGroupTags = useSelector(({ main }) => main.currentUserGroupTags)

  const getStaffAvatar = (userId) => {
    const staff = Staff.findOne(userId, {
      fields: {
        profile: 1
      }
    })
    return Match.isNonEmptyString(staff?.profile?.avatarUrl) ? staff?.profile?.avatarUrl : null
  }

  const getLastMessageContent = () => {
    if (chat.lastMessageContentItem?.type === 'postback' && chat.lastMessageContentItem?.button?.name) {
      return chat.lastMessageContentItem.button.name
    }
    return chat.lastMessageContent
  }

  const isReminded = chat.markedAsNewByReason === 'REMINDER_TRIGGERED'
  const isGrabbedFromAgent = chat.state === 'NEW' && chat.prevState === 'IN_WORK'
  const isReopened = ['NEW', 'IN_WORK'].includes(chat.state) && chat.prevState === 'ARCHIVED'
  const isReopenedHot = isReopened && moment(chat.lastArchivedAt).diff(moment(), 'hour') < 1
  const isNew = chat.passiveMembers && chat.passiveMembers.includes(userId)
  const isAssigned = chat.invitedMembers && chat.invitedMembers.includes(userId)

  const { unreadMessagesCount = chat.messagesInboundCount, unreadRemindersCount = 0 } =
    chat.stuffCounts[userId] || {}
  const unreadMessages = unreadRemindersCount + unreadMessagesCount

  const showUnreadMessages =
    isInboundCountVisible &&
    unreadMessages > 0

  const getTagColor = (id) => {
    const tag = currentUserGroupTags.find(({ _id }) => _id === id)
    return tag ? tag.color : null
  }

  const chatName = getDialogName(chat.chatCaption, chat.clientProfile, chat.receiver, t)

  return (
    <div className={css(classnames('item', isSelected ? '--selected' : null))} onClick={onSelect}>
      <div className={css('left')}>
        <div className={css('avatars')}>
          <Avatar url={chat.clientProfile?.avatarUrl || getStaffAvatar(chat.clientId)} colorName={chatName} />
          {chat.stuffUsers.length > 0 && (
            <Avatar url={getStaffAvatar(chat.lastOutboundMessageUserId)} cls={css('avatar-small')} />
          )}
        </div>
        <Status chat={chat} />
      </div>
      <div>
        <div className={css('header')}>
          <div className={css('title')}>
            <div className={css('name')}>{chatName}</div>
            {showUnreadMessages && (
              <Tag color='green' variation='secondary' view='filled'>
                {unreadMessages}
              </Tag>
            )}
            {isPreparedAtTimerVisible && !isReminded && !isReopened && (
              <PreparedAtTimer preparedAt={chat.preparedAt} />
            )}
            {showChatTypeIcon && (
              <ChatTypeIcon
                state={chat.state}
                type={chat.type}
                stuffUsers={chat.stuffUsers}
                currentUserId={userId}
              />
            )}
          </div>
          <div className={css('date')}>
            <LastMessageDate chat={chat} />
          </div>
        </div>
        <div className={css('body')}>
          <div className={css('description')}>{fixedEncode(getLastMessageContent())}</div>
          <div className={css('body-right')}>
            {!isAssigned && isInactiveTimerVisible && <InactiveTimer chat={chat} />}
            {isAssigned && (
              <Tag color='yellow' variation='secondary' view='filled'>
                {t('dlg:assigned')}
              </Tag>
            )}
          </div>
        </div>
        <div className={css('tags')}>
          {isReminded && (
            <Tag color='yellow' variation='secondary' view='filled' classes={{ inner: css('tag-icon') }}>
              {t('dlg:reminded')}
              <SvgIconCalendar />
            </Tag>
          )}
          {isGrabbedFromAgent && (
            <Tag color='red' variation='secondary' view='filled' classes={{ inner: css('tag-icon') }}>
              {t('dlg:returnedToQueue')}
              <SvgIconBack />
            </Tag>
          )}
          {isReopened && !isReminded && !isReopenedHot && (
            <Tag color='gray' variation='secondary' view='filled' classes={{ inner: css('tag-icon') }}>
              {t('dlg:reopened')}
              <SvgIconBack />
            </Tag>
          )}
          {isReopenedHot && (
            <Tag color='yellow' variation='secondary' view='filled' classes={{ inner: css('tag-icon') }}>
              {t('dlg:reopened')}
              <SvgIconBack />
            </Tag>
          )}
          {isNew && (
            <Tag color='blue' variation='secondary' view='filled'>
              {t('dlg:new')}
            </Tag>
          )}
          {chat.tags && chat.tags.length > 0 && chat.tags.map((tag, index) => {
            const color = getTagColor(tag.tagId)
            return (
              <Tag
                color='gray'
                variation='secondary'
                view='stroked'
                key={index}
                classes={{ root: css('tag') }}
                rootStyle={{
                  color,
                  borderColor: color ? `${color}E5` : null
                }}
              >
                {tag.text}
              </Tag>
            )
          }
          )}
        </div>
      </div>
    </div>
  )
}

ChatItem.props = {
  isSelected: bool,
  chat: object,
  onSelect: func,
  isInactiveTimerVisible: bool,
  isPreparedAtTimerVisible: bool,
  isInboundCountVisible: bool,
  showChatTypeIcon: bool
}
