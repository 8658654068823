import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SvgIconBan } from '@flomni/components/dist/components/svg/feathers/SvgIconBan'
import { ButtonWithLoader } from '../../../../../../shared/button-with-loader'
import { func, object, string } from 'prop-types'
import { Meteor } from '../../../../../../../../meteorAdapter';
import { processError } from '../../../../../../../services/helpers'
import { useDispatch } from 'react-redux'
import { DateUtils } from '@flomni/modules/dist/services/date'
import moment from 'moment'

export const BanInfo = ({ receiver, groupId, banResult, updateBanResult }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const [showLoader, setShowLoader] = useState(false)

  const handlerClickUnban = async () => {
    setShowLoader(true)
    await new Promise((resolve) => setTimeout(resolve, 800))

    try {
      await Meteor.invoke('clients.unbanClient', {
        receiver,
        groupId
      })
      setShowLoader(false)
      updateBanResult()
    } catch (err) {
      setShowLoader(false)
      processError(err, main)
    }
  }

  return banResult ? (
    <div className={css('container')}>
      <div className={css('header')}>
        <SvgIconBan />
        <div>{t('dlg:thisClientWasBannedByName', { name: banResult.initiatedBy })}</div>
        <ButtonWithLoader
          view='light'
          text={t('dlg:unbanNow')}
          onClick={handlerClickUnban}
          spinnerSize={10}
          spinnerStrokeWidth={14}
          spinnerView='usual'
          isLoading={showLoader}
        />
      </div>
      <div className={css('info')}>
        <div className={css('line')}>
          <div>{t('dlg:wasBanned')}:</div>
          <div>
            {t('dlg:banDateForPeriod', {
              date: DateUtils.format(banResult.addedAt, DateUtils.DATE_TIME_FORMAT),
              period: moment.duration(banResult.ttl, 'seconds').humanize()
            })}
          </div>
        </div>
        <div className={css('line')}>
          <div>{t('dlg:willUnbanOn')}:</div>
          <div>
            {DateUtils.format(
              moment(banResult.addedAt).add(banResult.ttl, 'seconds'),
              DateUtils.DATE_TIME_FORMAT
            )}
          </div>
        </div>
        <div className={css('line')}>
          <div>{t('dlg:reason')}:</div>
          <div className={css('italic')}>{banResult.reason}</div>
        </div>
      </div>
    </div>
  ) : null
}

BanInfo.propTypes = {
  receiver: string,
  groupId: string,
  banResult: object,
  updateBanResult: func
}
