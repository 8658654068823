import React, { useContext } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { ChatItemInfo } from '../shared/chat-item-info'
import { ChatItem } from '../shared/chat-item'
import { array, bool } from 'prop-types'
import { Spinner } from '@flomni/components/dist/components/spinner'
import { store } from '../../../../../../../state/dialogs'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { SecondaryButtonIcon } from '@flomni/components/dist/components/secondary-button/icon'
import { SvgIconSearch } from '@flomni/components/dist/components/svg/feathers/SvgIconSearch'
import { ROUTE, routes } from '../../../../../../../configs/routes'
import { useHistory } from 'react-router-dom'
import { Meteor, Random } from '../../../../../../../../meteorAdapter';
import { AGENT_TYPE, DATE_SELECTOR, FILTER_PROPERTY, PERIOD, STATUS } from '../../../../search/search/constants'

export const CompletedList = ({ chats, loading }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { selectedDialogId, dispatch } = useContext(store)
  const history = useHistory()
  const currentUserId = Meteor.userId()

  const openCompletedInSearch = () => {
    history.push({
      pathname: routes[ROUTE.SEARCH],
      state: {
        filters: [
          {
            id: Random.id(),
            property: FILTER_PROPERTY.DATE,
            selector: DATE_SELECTOR.IS_TODAY,
            tz: 'UTC',
            period: PERIOD.DAYS
          },
          {
            id: Random.id(),
            property: FILTER_PROPERTY.CHAT_STATUS,
            status: STATUS.CLOSED
          },
          {
            id: Random.id(),
            property: FILTER_PROPERTY.AGENT,
            agentType: AGENT_TYPE.HUMAN,
            agentId: currentUserId
          }
        ]
      }
    })
  }

  return (
    <div className={css('completed-body')}>
      <div className={css('chat-items')}>
        {chats.slice(0, 20).map((chat) => (
          <ChatItem
            key={chat._id}
            chat={chat}
            isSelected={selectedDialogId === chat._id}
            isInboundCountVisible={false}
            onSelect={() => dispatch({ type: 'setSelectedDialog', selectedDialog: chat })}
          />
        ))}
        {!loading && chats.length === 0 && (
          <ChatItemInfo
            icon='hand'
            title={t('dlg:noCompletedChatsYet')}
            description={t('dlg:completedChatsShowHere')}
          />
        )}
        {loading && (
          <div className={css('loader')}>
            <div className={css('spinner')}>
              <Spinner strokeWidth={5} />
            </div>
          </div>
        )}
      </div>
      {chats.length > 20 && (
        <div className={css('footer')}>
          <span>{t('dlg:last20ChatsUseSearch')}</span>
          <SecondaryButton onClick={openCompletedInSearch}>
            <SecondaryButtonIcon>
              <SvgIconSearch />
            </SecondaryButtonIcon>
            <SecondaryButtonText>{t('dlg:search')}</SecondaryButtonText>
          </SecondaryButton>
        </div>
      )}
    </div>
  )
}

CompletedList.props = {
  chats: array,
  loading: bool
}
