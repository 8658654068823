import React, { useContext, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { func, object } from 'prop-types'
import { SvgIconClose } from '@flomni/components/dist/components/svg/feathers/SvgIconClose'
import { Meteor, useTracker } from '../../../../../../../../meteorAdapter';
import Dialogs from '../../../../../../../../collections/client/dialogs'
import { store } from '../../../../../../../state/dialogs'
import { getDialogName } from '../../../../../../../utils/common'
import { useTranslation } from 'react-i18next'
import { SecondaryDropdownItem } from '@flomni/components/dist/components/secondary-dropdown-item/item'
import { SecondaryDropdownItemText } from '@flomni/components/dist/components/secondary-dropdown-item/text'
import { useDispatch, useSelector } from 'react-redux'
import { processError } from '../../../../../../../services/helpers'
import EditNameDialog from '../../../Conversations/right-panel/edit-name-dialog'
import BanClientDialog from '../../../Conversations/right-panel/ban-client-dialog'

export const MenuPage = ({ banResult, onClose, updateBanResult }) => {
  const { t } = useTranslation()
  const css = useStyles(styles)
  const mainClass = css('main')

  const { main } = useDispatch()
  const { selectedDialogId } = useContext(store)
  const currentUserGroupId = useSelector(({ main }) => main.currentUserGroupId)
  const [showEditName, setShowEditName] = useState(false)
  const [showBanClient, setShowBanClient] = useState(false)

  const { receiver, clientProfile, chatCaption } = useTracker(() => {
    return Dialogs.findOne(selectedDialogId, {
      fields: {
        receiver: 1,
        clientProfile: 1,
        chatCaption: 1
      }
    })
  }, [selectedDialogId])

  const onUnbanClient = () => {
    main.showGlobalLoader(true)
    Meteor.invoke('clients.unbanClient', {
      receiver,
      groupId: currentUserGroupId
    })
      .then(() => {
        updateBanResult()
      })
      .catch((err) => {
        processError(err, main)
      })
      .finally(() => {
        main.showGlobalLoader(false)
      })
  }

  const onOverlayClick = (e) => {
    if (e.target.classList.contains(mainClass)) {
      onClose()
    }
  }

  return (
    <div className={mainClass} onClick={onOverlayClick}>
      <div className={css('header')}>
        <div className={css('side-container')} />
        <div className={css('name')}>{getDialogName(chatCaption, clientProfile, receiver, t)}</div>
        <div className={css('side-container')}>
          <div className={css('icon')} onClick={onClose}>
            <SvgIconClose />
          </div>
        </div>
      </div>
      <div className={css('body')}>
        <SecondaryDropdownItem view='bright' onClick={() => setShowEditName(true)}>
          <SecondaryDropdownItemText>{t('dlg:editClientsName')}</SecondaryDropdownItemText>
        </SecondaryDropdownItem>
        {!banResult && (
          <SecondaryDropdownItem view='warning' onClick={() => setShowBanClient(true)}>
            <SecondaryDropdownItemText>{t('dlg:banClient')}</SecondaryDropdownItemText>
          </SecondaryDropdownItem>
        )}
        {banResult && (
          <SecondaryDropdownItem view='warning' onClick={onUnbanClient}>
            <SecondaryDropdownItemText>{t('dlg:unbanClient')}</SecondaryDropdownItemText>
          </SecondaryDropdownItem>
        )}
      </div>
      {showEditName && (
        <EditNameDialog
          chatName={getDialogName(chatCaption, clientProfile, receiver, t)}
          onCancel={() => setShowEditName(false)}
          onEdited={() => setShowEditName(false)}
        />
      )}
      {showBanClient && (
        <BanClientDialog
          clientProfile={clientProfile}
          receiver={receiver}
          onCancel={() => setShowBanClient(false)}
          onBanned={() => {
            updateBanResult()
            setShowBanClient(false)
          }}
        />
      )}
    </div>
  )
}

MenuPage.propTypes = {
  onClose: func.isRequired,
  banResult: object,
  updateBanResult: func
}
