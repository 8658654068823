import React, { useCallback, useEffect, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { Dropdown } from '@flomni/components/dist/components/dropdown'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { RadioOption } from '@flomni/components/dist/components/radio-option'
import { object, bool, func } from 'prop-types'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { SvgIconDownAndUpArrows } from '@flomni/components/dist/components/svg/feathers/SvgIconDownArrowsTwo'
import { PlateButtonText } from '@flomni/components/dist/components/plate-button/text'

export const SortPopup = ({ config, onDone, onReset, disabled }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const [opened, setOpened] = useState(false)
  const [sortItems, setSortItems] = useState([])
  const [checkedItemId, setCheckedItemId] = useState(null)

  useEffect(() => {
    setCheckedItemId(config.checkedId)
    setSortItems(config.items)
  }, [config])

  const onDoneClickHandler = () => {
    setOpened(false)
    onDone({
      checkedId: checkedItemId,
      items: sortItems
    })
  }

  const onResetClickHandler = () => {
    setOpened(false)
    const resetItems = config.items.map((item) => {
      return {
        ...item,
        order: item.defaultOrder || item.order
      }
    })
    setSortItems(resetItems)
    setCheckedItemId(config.defaultCheckedId)
    onReset({
      checkedId: config.defaultCheckedId,
      items: resetItems
    })
  }

  const setOrder = (item, order) => {
    setSortItems(
      sortItems.map((sortItem) => {
        return {
          ...sortItem,
          order: item.id === sortItem.id ? order : sortItem.order
        }
      })
    )
  }

  const setChecked = (item) => {
    setCheckedItemId(item.id)
  }

  const onOverlayClick = useCallback(() => {
    setOpened(false)
  }, [])

  return (
    <div>
      <PlateButton onClick={() => setOpened(true)} disabled={disabled}>
        <PlateButtonIcon>
          <SvgIconDownAndUpArrows />
        </PlateButtonIcon>
        <PlateButtonText>{t('dlg:sort')}</PlateButtonText>
      </PlateButton>
      <Dropdown
        classes={{ root: css('dropdown'), content: css('content') }}
        variation='secondary'
        active={opened}
        onOverlayClick={onOverlayClick}
      >
        <div className={css('header')}>
          <div className={css('header-item')}>
            <SecondaryButton
              view='light'
              onClick={onResetClickHandler}
              classes={{ root: css('header-button') }}
            >
              <SecondaryButtonText>{t('dlg:reset')}</SecondaryButtonText>
            </SecondaryButton>
          </div>
          <div className={css(['header-item', 'header-title'])}>{t('dlg:sort')}</div>
          <div className={css(['header-item', '--right'])}>
            <SecondaryButton onClick={onDoneClickHandler} classes={{ root: css('header-button') }}>
              <SecondaryButtonText>{t('dlg:done')}</SecondaryButtonText>
            </SecondaryButton>
          </div>
        </div>
        <div className={css('items')}>
          {sortItems.map((item) => (
            <div key={item.id}>
              <div className={css('chk')}>
                <RadioOption
                  variation='secondary'
                  classes={{ label: css('chk-label') }}
                  checked={item.id === checkedItemId}
                  onChange={() => setChecked(item)}
                >
                  {item.title}
                </RadioOption>
              </div>
              {item.id === checkedItemId && (
                <div className={css('order')}>
                  <RadioOption
                    variation='secondary'
                    size='small'
                    onChange={() => setOrder(item, -1)}
                    checked={item.order === -1}
                  >
                    {item.ascLabel || 'ASC'}
                  </RadioOption>
                  <RadioOption
                    variation='secondary'
                    size='small'
                    onChange={() => setOrder(item, 1)}
                    checked={item.order === 1}
                  >
                    {item.descLabel || 'DESC'}
                  </RadioOption>
                </div>
              )}
            </div>
          ))}
        </div>
      </Dropdown>
    </div>
  )
}

SortPopup.propTypes = {
  config: object.isRequired,
  onDone: func.isRequired,
  onReset: func.isRequired,
  disabled: bool
}
