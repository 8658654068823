import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { Meteor, useTracker, Mongo } from '../../../../../../meteorAdapter';
import { NavLink } from 'react-router-dom'
import { ROUTE, routes } from '../../../../../configs/routes'
import { DateUtils } from '@flomni/modules/dist/services/date'

const __chatDistributions = new Mongo.Collection('__chatDistributions')

export const ChatDistribution = () => {
  const css = useStyles(styles)
  const { t } = useTranslation()

  const DISTRIBUTION_RECORDS_LIMIT = 20
  const NA_VALUE = '-'

  const isLoading = useTracker(() => {
    const handle = Meteor.subscribe('lastChatDistributions', DISTRIBUTION_RECORDS_LIMIT)
    return !handle.ready()
  }, [])

  const distributions = useTracker(() => {
    if (isLoading) {
      return []
    }

    return __chatDistributions
      .find(
        {},
        {
          sort: {
            addedAt: -1
          },
          limit: DISTRIBUTION_RECORDS_LIMIT
        }
      )
      .fetch()
  }, [isLoading])

  return (
    <div className={css('main')}>
      <div className={css('container')}>
        <div className={css('nav-bar')}>
          <NavLink className={css('link')} to={routes[ROUTE.SETTINGS]}>
            {t('dlg:dialogsSettings')}
          </NavLink>
          <div>></div>
          <div className={css('active')}>{t('dlg:chatsDistribution')}</div>
        </div>
        <div className={css('header')}>{t('dlg:chats')}</div>
        <div className={css('info')}>
          <div className={css(['item', '--header'])}>
            <div>{t('dlg:completedAt')}</div>
            <div>{t('dlg:numOfChats')}</div>
            <div>{t('dlg:numOfApplicants')}</div>
            <div>{t('dlg:agentPlace')}</div>
            <div>{t('dlg:chat')}</div>
          </div>
          {distributions.map((distribution) => (
            <div key={distribution._id} className={css('item')}>
              <div>
                {distribution.addedAt instanceof Date
                  ? DateUtils.format(distribution.addedAt, DateUtils.DATE_TIME_FULL_FORMAT)
                  : NA_VALUE}
              </div>
              <div>{distribution.queryLength ?? NA_VALUE}</div>
              <div>{Array.isArray(distribution.operators) ? distribution.operators.length : NA_VALUE}</div>
              <div>
                {typeof distribution.agentPlace === 'number' ? distribution.agentPlace + 1 : NA_VALUE}
              </div>
              <div>{typeof distribution.receiver === 'string' ? distribution.receiver : NA_VALUE}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
