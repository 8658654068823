import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { func, object } from 'prop-types'
import { ValueSelector } from '../shared/value-selector'
import { useDispatch, useSelector } from 'react-redux'
import { Meteor } from '../../../../../../../../meteorAdapter';
import { processError } from '../../../../../../../services/helpers'

export const TagFilterItem = ({ item, onChangeItemProperty }) => {
  const { t } = useTranslation()
  const { main } = useDispatch()

  const [selectedTagId, setSelectedTagId] = useState(item.tagId)
  const [tags, setTags] = useState([])
  const currentUserGroupId = useSelector(({ main }) => main.currentUserGroupId)

  useEffect(() => {
    if (!currentUserGroupId) {
      return
    }

    Meteor.invoke('groups.getGroupTags', { groupId: currentUserGroupId })
      .then(({ items }) => {
        setTags(
          (items || [])
            .filter((tag) => tag.kind !== 'session')
            .map((tag) => {
              return { label: tag.name, value: tag._id }
            })
        )
      })
      .catch((err) => {
        processError(err, main)
      })
  }, [currentUserGroupId])

  const onSelectTagId = (tagId) => {
    setSelectedTagId(tagId)
    onChangeItemProperty('tagId', tagId)
  }

  return (
    <ValueSelector
      items={tags}
      item={selectedTagId}
      onChange={onSelectTagId}
      placeholder={t('dlg:selectTag')}
      translate={false}
    />
  )
}

TagFilterItem.propTypes = {
  onChangeItemProperty: func.isRequired,
  item: object.isRequired
}
