import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SvgIconQueue } from '@flomni/components/dist/components/svg/feathers/SvgIconQueue'
import { ButtonWithLoader } from '../../../../../../../shared/button-with-loader'
import DialogAddChat from '../../../left-panel/add-chat/dialog'
import { string } from 'prop-types'

export const ReplySuspendedChat = ({ phone, prefferedMessenger }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const [openedAddChat, setOpenedAddChat] = useState(false)

  const resources = {
    title: t('dlg:thisChatSessionWasSuspended'),
    description: t('dlg:newSessionSendingMessages'),
    button: t('dlg:sendTemplatedMessage')
  }

  const addChat = () => {
    setOpenedAddChat(true)
  }

  return (
    <div className={css('no-items')}>
      <div className={css('no-items-header')}>
        <div className={css('no-items-icon')}>
          <SvgIconQueue width={22} height={22} />
        </div>
        <div>
          <div className={css('no-items-title')}>{resources.title}</div>
          <div className={css('no-items-description')}>{resources.description}</div>
        </div>
      </div>
      <ButtonWithLoader
        view='usual'
        cls={css('no-items-button')}
        text={resources.button}
        onClick={addChat}
        spinnerSize={10}
        spinnerStrokeWidth={14}
      />
      {openedAddChat && (
        <DialogAddChat
          onCancel={() => setOpenedAddChat(false)}
          initPhone={phone}
          prefferedMessenger={prefferedMessenger}
        />
      )}
    </div>
  )
}

ReplySuspendedChat.propTypes = {
  phone: string,
  prefferedMessenger: string
}
