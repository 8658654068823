import { Meteor, Match } from '../meteorAdapter';
import PubSub from 'pubsub-js'

const CUSTOM_WS_EVENT_PREFIX = '@event:'

Meteor.invoke = (methodName, ...params) => new Promise((resolve, reject) => {
  Meteor.call(methodName, ...params, (err, res) => {
    if (err) {
      err.__methodName = methodName
      err.__params = [...params]
      reject(err)
    } else {
      resolve(res)
    }
  })
})

Meteor.emitServerEvent = (eventName, params = {}, isTrustedSource = true) => {
  const currentUserId = Meteor.userId()
  if (
    isTrustedSource &&
    Match.isDocumentId(currentUserId) === false
  ) return

  const eventData = {
    ...params,
    $event: eventName,
    $user: currentUserId
  }
  const customMessage = `${CUSTOM_WS_EVENT_PREFIX}${JSON.stringify(eventData)}`

  Meteor.getData().waitDdpConnected(() => {
    Meteor.getData().ddp.socket.rawSocket.send(customMessage)
  })
}

// TODO: reconnections bleat!
Meteor.getData().waitDdpConnected(() => {
  const socket = Meteor.getData().ddp.socket.rawSocket

  const _onmessage = socket.onmessage.bind(socket)

  socket.onmessage = event => {
    const {
      type,
      data = ''
    } = event

    if (
        type === 'message' &&
        data.indexOf(CUSTOM_WS_EVENT_PREFIX) === 0
    ) {
      const eventBody = data.slice(CUSTOM_WS_EVENT_PREFIX.length)
      const event = eventBody.startsWith('{')
          ? JSON.parse(eventBody)
          : eventBody

      const [
        eventName,
        params = {}
      ] = typeof event === 'string'
          ? [event]
          : [event.__event, event.params]

      PubSub.publish(eventName, params)
    } else {
      _onmessage(event)
    }
  }
})
