export const useEmptyQueue = (emptyQueue, setEmptyQueue) => {
  const now = +new Date();
  let emptyQueueTimeMs = emptyQueue.timeMs;
  let emptyQueueNoActiveChatsMs = emptyQueue.noActiveChatsMs;
  let emptyQueueSlotsAvailableMs = emptyQueue.slotsAvailableMs;
  if (emptyQueue.time) {
    emptyQueueTimeMs += now - emptyQueue.time;
  }
  if (emptyQueue.noActiveChats) {
    emptyQueueNoActiveChatsMs += now - emptyQueue.noActiveChats;
  }
  if (emptyQueue.slotsAvailable) {
    emptyQueueSlotsAvailableMs += now - emptyQueue.slotsAvailable;
  }
  setEmptyQueue({
    timeMs: 0,
    noActiveChatsMs: 0,
    slotsAvailableMs: 0,
    time: emptyQueue.time ? now : null,
    noActiveChats: emptyQueue.noActiveChats ? now : null,
    slotsAvailable: emptyQueue.slotsAvailable ? now : null
  });
  return { emptyQueueTimeMs, emptyQueueNoActiveChatsMs, emptyQueueSlotsAvailableMs };
}
