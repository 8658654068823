import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { Header } from '../header'
import { Switch, Route, Redirect } from 'react-router-dom'
import { ROUTE, routes } from '../../../configs/routes'
import { Conversations } from '../pages/conversations'
import { Settings } from '../pages/settings'
import { Profile } from '../pages/settings/profile'
import { ApplicationInfo } from '../pages/settings/application-info'
import { NetworkConnection } from '../pages/settings/network-connection'
import { ChatDistribution } from '../pages/settings/chat-distribution'
import { Account } from '../pages/settings/account'
import { Notifications } from '../pages/settings/notifications'
import { AgentsTeam } from '../pages/settings/team'
import { AgentGroups } from '../pages/settings/group'
import { Search } from '../pages/search'
import { Events } from '../pages/events'
import { MessageTemplates } from '../pages/settings/templates'
import { MessageButtons } from '../pages/settings/buttons'
import { Dashboard } from '../pages/dashboard'
import { Chatbot } from '../pages/chatbot'
import { bool, number, object, func } from 'prop-types'
import { WorkforceManagement } from '../pages/settings/workforce-management'

export const DialogsPageDesktop = ({
  inWorkDialogsCount,
  newDialogsCount,
  newMyDialogsCount,
  emptyQueue,
  setEmptyQueue,
  totalDialogsCount,
  hasSearch,
  hasAllChats,
  hasDashboardWFM
}) => {
  const css = useStyles(styles)

  return (
    <div className={css('main')}>
      <Header
        dialogsCount={inWorkDialogsCount + newMyDialogsCount}
        hasSearch={hasSearch}
        hasAllChats={hasAllChats}
        emptyQueue={emptyQueue}
        setEmptyQueue={setEmptyQueue}
      />
      <Switch>
        <Route exact path={routes[ROUTE.DASHBOARD]}>
          <Dashboard inWorkDialogsCount={inWorkDialogsCount} hasDashboardWFM={hasDashboardWFM} />
        </Route>
        <Route exact path={routes[ROUTE.SEARCH]}>
          {hasSearch ? <Search /> : <Redirect to={routes[ROUTE.CONVERSATIONS]} />}
        </Route>
        <Route exact path={routes[ROUTE.CONVERSATIONS]}>
          <Conversations inWorkDialogsCount={inWorkDialogsCount} newDialogsCount={newMyDialogsCount} />
        </Route>
        <Route exact path={routes[ROUTE.CHATBOT]}>
          {hasAllChats ? (
            <Chatbot
              newDialogsCount={newDialogsCount}
              teamDialogsCount={totalDialogsCount - inWorkDialogsCount}
            />
          ) : (
            <Redirect to={routes[ROUTE.CONVERSATIONS]} />
          )}
        </Route>
        <Route exact path={routes[ROUTE.EVENTS]}>
          <Events />
        </Route>
        <Route exact path={routes[ROUTE.SETTINGS]}>
          <Settings />
        </Route>
        <Route exact path={routes[ROUTE.PROFILE]}>
          <Profile />
        </Route>
        <Route exact path={routes[ROUTE.APPLICATION_INFO]}>
          <ApplicationInfo />
        </Route>
        <Route exact path={routes[ROUTE.NETWORK_CONNECTION]}>
          <NetworkConnection />
        </Route>
        <Route exact path={routes[ROUTE.CHAT_DISTRIBUTION]}>
          <ChatDistribution />
        </Route>
        <Route exact path={routes[ROUTE.ACCOUNT]}>
          <Account />
        </Route>
        <Route exact path={routes[ROUTE.NOTIFICATIONS]}>
          <Notifications />
        </Route>
        <Route exact path={routes[ROUTE.TEAM]}>
          <AgentsTeam />
        </Route>
        <Route exact path={routes[ROUTE.GROUP]}>
          <AgentGroups />
        </Route>
        <Route exact path={routes[ROUTE.MESSAGE_TEMPLATES]}>
          <MessageTemplates />
        </Route>
        <Route exact path={routes[ROUTE.MESSAGE_BUTTONS]}>
          <MessageButtons />
        </Route>
        <Route exact path={routes[ROUTE.WORKFORCE_MANAGEMENT]}>
          <WorkforceManagement />
        </Route>
        <Redirect from='*' to={routes[ROUTE.CONVERSATIONS]} />
      </Switch>
    </div>
  )
}

DialogsPageDesktop.propTypes = {
  inWorkDialogsCount: number,
  newDialogsCount: number,
  newMyDialogsCount: number,
  emptyQueue: object,
  setEmptyQueue: func,
  totalDialogsCount: number,
  hasSearch: bool,
  hasAllChats: bool,
  hasDashboardWFM: bool
}
