import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { DateSelector } from './date-selector'
import { Input } from '@flomni/components/dist/components/input'
import { func, object } from 'prop-types'
import classnames from 'classnames'
import { ValueSelector } from '../shared/value-selector'
import { DATE_SELECTOR, PERIOD } from '../../constants'
import { Meteor, useTracker } from '../../../../../../../../meteorAdapter';
import moment from 'moment'
import { DateUtils } from '@flomni/modules/dist/services/date'
import { useDispatch } from 'react-redux'

export const DateFilterItem = ({ item, onChangeItemProperty }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()

  const user = useTracker(() => Meteor.user(), [])

  const timezones = [user?.profile?.tz || '', 'UTC']

  const [selectedSelector, setSelectedSelector] = useState(item.selector)
  const [selectedTimezone, setSelectedTimezone] = useState(item.tz)
  const [selectedPeriod, setSelectedPeriod] = useState(item.period)
  const [selectedPeriodValue, setSelectedPeriodValue] = useState(item.periodValue)
  const [selectedFrom, setSelectedFrom] = useState(item.from)
  const [selectedFromHours, setSelectedFromHours] = useState(
    item.from ? moment(item.from, DateUtils.DATE_TIME_FORMAT).format('HH:mm') : '00:00'
  )
  const [selectedTo, setSelectedTo] = useState(item.to)
  const [selectedToHours, setSelectedToHours] = useState(
    item.to ? moment(item.to, DateUtils.DATE_TIME_FORMAT).format('HH:mm') : '23:59'
  )

  const MAX_PERIOD_MONTHS = 1

  const onSelectSelector = (selector) => {
    setSelectedSelector(selector)
    onChangeItemProperty('selector', selector)
    onDateFromToValidate(selectedFrom, selectedTo, selector, true)
  }

  const onSelectTimezone = (tz) => {
    setSelectedTimezone(tz)
    onChangeItemProperty('tz', tz)
  }

  const onSelectPeriod = (period) => {
    setSelectedPeriod(period)
    onChangeItemProperty('period', period)
  }

  const onSelectPeriodValue = (periodValue) => {
    setSelectedPeriodValue(periodValue)
    onChangeItemProperty('periodValue', periodValue)
  }

  const onDateFromToValidate = (from, to, selector, skipMessage = false) => {
    if (selector === DATE_SELECTOR.IS_BETWEEN && from && to) {
      const momentFrom = moment(from, DateUtils.DATE_FORMAT).startOf('day')
      const momentTo = moment(to, DateUtils.DATE_FORMAT).startOf('day')
      if (momentTo.diff(momentFrom, 'months', true) > MAX_PERIOD_MONTHS) {
        onSelectTo(
          momentFrom.add(MAX_PERIOD_MONTHS, 'months').subtract(1, 'days').format(DateUtils.DATE_FORMAT)
        )
        if (!skipMessage) {
          main.showWarningSystemNotification(t('dlg:periodCanBeNotMoreThanOneMonth'))
        }
      }
    }
  }

  const onSelectFrom = (from) => {
    setSelectedFrom(from)
    const dateTime = moment(from, DateUtils.DATE_FORMAT).startOf('day')
    if (selectedFromHours) {
      const [hours, minutes] = selectedFromHours.split(':')
      dateTime.add(parseInt(hours), 'hours').add(parseInt(minutes), 'minutes')
    }
    onChangeItemProperty('from', DateUtils.format(dateTime, DateUtils.DATE_TIME_FORMAT))
    onDateFromToValidate(from, selectedTo, selectedSelector)
  }

  const onSelectFromHours = (time) => {
    setSelectedFromHours(time)
    if (selectedFrom) {
      const dateTime = moment(selectedFrom, DateUtils.DATE_FORMAT).startOf('day')
      if (time) {
        const [hours, minutes] = time.split(':')
        dateTime.add(parseInt(hours), 'hours').add(parseInt(minutes), 'minutes')
      }
      onChangeItemProperty('from', DateUtils.format(dateTime, DateUtils.DATE_TIME_FORMAT))
    }
  }

  const onSelectTo = (to) => {
    setSelectedTo(to)
    const dateTime = moment(to, DateUtils.DATE_FORMAT).startOf('day')
    if (selectedToHours) {
      const [hours, minutes] = selectedToHours.split(':')
      dateTime.add(parseInt(hours), 'hours').add(parseInt(minutes), 'minutes')
    }
    onChangeItemProperty('to', DateUtils.format(dateTime, DateUtils.DATE_TIME_FORMAT))
    onDateFromToValidate(selectedFrom, to, selectedSelector)
  }

  const onSelectToHours = (time) => {
    setSelectedToHours(time)
    if (selectedTo) {
      const dateTime = moment(selectedTo, DateUtils.DATE_FORMAT).startOf('day')
      if (time) {
        const [hours, minutes] = time.split(':')
        dateTime.add(parseInt(hours), 'hours').add(parseInt(minutes), 'minutes')
      }
      onChangeItemProperty('to', DateUtils.format(dateTime, DateUtils.DATE_TIME_FORMAT))
    }
  }

  return (
    <div
      className={css(
        classnames(
          'container',
          [
            DATE_SELECTOR.IS_IN_THE_LAST,
            DATE_SELECTOR.IS_EQUAL_TO,
            DATE_SELECTOR.IS_AFTER,
            DATE_SELECTOR.IS_BEFORE
          ].includes(selectedSelector)
            ? '--five'
            : null,
          [DATE_SELECTOR.IS_BETWEEN].includes(selectedSelector) ? '--eight' : null
        )
      )}
    >
      <div>
        <ValueSelector
          placeholder={t('dlg:select')}
          items={Object.values(DATE_SELECTOR)}
          item={selectedSelector}
          onChange={onSelectSelector}
        />
      </div>
      {selectedSelector === DATE_SELECTOR.IS_BETWEEN && (
        <>
          <DateSelector date={selectedFrom} onChange={onSelectFrom} />
          <Input
            shaded
            variation='secondary'
            type='time'
            classes={{ input: css('time') }}
            value={selectedFromHours}
            onChange={(e) => onSelectFromHours(e.target.value)}
          />
          <span className={css('text-separator')}>{t('dlg:and')}</span>
          <DateSelector date={selectedTo} onChange={onSelectTo} />
          <Input
            shaded
            variation='secondary'
            type='time'
            classes={{ input: css('time') }}
            value={selectedToHours}
            onChange={(e) => onSelectToHours(e.target.value)}
          />
        </>
      )}
      {[DATE_SELECTOR.IS_EQUAL_TO, DATE_SELECTOR.IS_AFTER, DATE_SELECTOR.IS_BEFORE].includes(
        selectedSelector
      ) && <DateSelector date={selectedFrom} onChange={onSelectFrom} />}
      {selectedSelector === DATE_SELECTOR.IS_IN_THE_LAST && (
        <>
          <Input
            shaded
            autoFocus
            type='number'
            min='0'
            classes={{ root: css('period'), input: css('time') }}
            variation='secondary'
            placeholder={t(`dlg:${selectedPeriod}`)}
            value={selectedPeriodValue || ''}
            onChange={(e) => onSelectPeriodValue(e.target.value)}
          />
          <ValueSelector items={Object.values(PERIOD)} item={selectedPeriod} onChange={onSelectPeriod} />
        </>
      )}
      <span className={css('text-separator')}>{t('dlg:in')}</span>
      <ValueSelector
        items={timezones}
        item={selectedTimezone}
        onChange={onSelectTimezone}
        translate={false}
      />
    </div>
  )
}

DateFilterItem.propTypes = {
  onChangeItemProperty: func.isRequired,
  item: object.isRequired
}
