export const main = {
  state: {
    isShowGlobalLoader: false,
    isShowProgressLoader: false,
    isDetailsPanelCollapsed: false,
    systemNotificationMessages: [],
    currentUserGroupId: null,
    currentUserDepartmentId: null,
    currentUserRoles: [],
    currentUserMegabrainSettings: {},
    currentUserArchiveSettings: {},
    currentUserWorkforceSettings: {},
    currentUserPermissions: null,
    currentUserGroupTags: [],
    currentUserTheme: null,
    distributionRequest: {
      auto: false,
      manual: false
    },
    selectedContextId: null,
    currentUserSounds: null,
    currentUserTranslationSettings: {}
  },

  reducers: {
    showGlobalLoader: (state, isShow) => ({ ...state, isShowGlobalLoader: isShow }),
    showProgressLoader: (state, isShow) => ({ ...state, isShowProgressLoader: isShow }),
    collapseDetailsPanel: (state, isCollapsed) => ({ ...state, isDetailsPanelCollapsed: isCollapsed }),
    showSystemNotification: (state, message) => ({
      ...state,
      systemNotificationMessages: [...state.systemNotificationMessages, { message }]
    }),

    showSuccessSystemNotification: (state, message) => ({
      ...state,
      systemNotificationMessages: [...state.systemNotificationMessages, { message, view: 'usual' }]
    }),

    showWarningSystemNotification: (state, message) => ({
      ...state,
      systemNotificationMessages: [...state.systemNotificationMessages, { message, view: 'warning' }]
    }),

    removeSystemNotification: (state) => ({
      ...state,
      systemNotificationMessages:
        state.systemNotificationMessages.length > 1 ? state.systemNotificationMessages.slice(1) : []
    }),
    setCurrentUserGroupId: (state, currentUserGroupId) => ({ ...state, currentUserGroupId }),
    setCurrentUserDepartmentId: (state, currentUserDepartmentId) => ({ ...state, currentUserDepartmentId }),
    setCurrentUserRoles: (state, currentUserRoles) => ({ ...state, currentUserRoles }),
    setCurrentUserMegabrainSettings: (state, currentUserMegabrainSettings) => ({
      ...state,
      currentUserMegabrainSettings
    }),
    setCurrentUserArchiveSettings: (state, currentUserArchiveSettings) => ({
      ...state,
      currentUserArchiveSettings
    }),
    setCurrentUserWorkforceSettings: (state, currentUserWorkforceSettings) => ({
      ...state,
      currentUserWorkforceSettings
    }),
    setCurrentUserPermissions: (state, currentUserPermissions) => ({
      ...state,
      currentUserPermissions
    }),
    setCurrentUserTranslationSettings: (state, currentUserTranslationSettings) => ({
      ...state,
      currentUserTranslationSettings
    }),
    setCurrentUserSounds: (state, currentUserSounds) => ({ ...state, currentUserSounds }),
    setDistributionRequest: (state, distributionRequest) => ({ ...state, distributionRequest }),
    setCurrentUserGroupTags: (state, currentUserGroupTags) => ({ ...state, currentUserGroupTags }),
    setSelectedContextId: (state, selectedContextId) => ({ ...state, selectedContextId }),
    setCurrentTheme: (state, currentUserTheme) => ({ ...state, currentUserTheme }),
    logout: (state) => ({ ...state, selectedContextId: null })
  }
}
