import React, { useCallback, useContext, useEffect, useState, useRef, useMemo } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { Meteor, useTracker } from '../../../../../../../../meteorAdapter';
import { InboundMessage } from './inbound'
import { OutboundMessage } from './outbound'
import { EventMessage } from './event'
import { Spinner } from '@flomni/components/dist/components/spinner'
import ScrollToBottom, { useAtTop, useObserveScrollPosition, useScrollToEnd } from 'react-scroll-to-bottom'
import { DateSeparator } from './date'
import { ChatMessages, VisibleMessagesCount } from '../../../../../../../customStores'
import { store } from '../../../../../../../state/dialogs'
import { array, bool, func, object, string } from 'prop-types'
import classnames from 'classnames'
import { DateUtils } from '@flomni/modules/dist/services/date'
import { Tooltip } from '@flomni/components/dist/components/tooltip'
import { useTranslation } from 'react-i18next'
import { DraftMessage } from './draft'
import { isMobileMode } from '../../../../../../../services/helpers'
import { ROUTE, routes } from '../../../../../../../configs/routes'

const EventsBlock = ({ events }) => {
  const MAX_COUNT_VIEW_EVENT = 3
  const COUNT_EVENT_SHOW_ALL = 6

  const css = useStyles(styles)
  const { t } = useTranslation()
  const [hide, setHide] = useState(true)
  const showAll = events.length > COUNT_EVENT_SHOW_ALL

  return (
    <div key={events._id}>
      {events.map((event, index) => (
        (!showAll || !hide || index < MAX_COUNT_VIEW_EVENT) && <EventMessage key={event._id} message={event} />
      ))}
      {showAll && hide && (
        <div className={css('event-block')}>
          <span className={css('events-more')}>
            {t('dlg:eventsMore', { count: events.length - MAX_COUNT_VIEW_EVENT })}
            <span className={css('blue')} onClick={() => setHide(false)}>{t('dlg:showAll')}</span>
          </span>
        </div>
      )}
    </div>
  )
}

const MessagesList = ({
  chatCaption,
  clientProfile,
  receiver,
  lastDeliveredAt,
  lastSeenAt,
  lastInboundMessageAt,
  stuffUsers,
  chatbotEnabled,
  selectedDialogId,
  loading,
  messages,
  updateTemplates,
  actions,
  onStartScroll,
  onStopScroll,
  onLoadMore,
  onUpdateMbrain,
  allowSuggestions,
  loadedAll,
  language
}) => {
  const css = useStyles(styles)
  const [isAllowedLoadMore, setIsAllowedLoadMore] = useState(true)
  const [atTop] = useAtTop()
  const [stopWheel, setStopWheel] = useState(false)
  const [startWheel, setStartWheel] = useState(false)
  const timerWheel = useRef(null)
  let events = []

  let scrollTimeOut = null
  const observer = useCallback(() => {
    if (!scrollTimeOut) {
      onStartScroll()
    }
    if (scrollTimeOut) {
      clearTimeout(scrollTimeOut)
    }
    scrollTimeOut = setTimeout(() => {
      onStopScroll()
      scrollTimeOut = null
    }, 100)
  }, [])

  useObserveScrollPosition(observer)

  useEffect(() => {
    if (!atTop) {
      setIsAllowedLoadMore(true)
    }
    if (atTop && isAllowedLoadMore && !loading) {
      setIsAllowedLoadMore(false)
      setStopWheel(true)
      onLoadMore()
    }
  }, [atTop])

  const handlerWheel = useCallback((e) => {
    if (atTop && !loading && e.nativeEvent.deltaY < 0) {
      clearTimeout(timerWheel.current)
      setStartWheel(true)
      timerWheel.current = setTimeout(() => {
        setStartWheel(false)
        !stopWheel ? onLoadMore() : setStopWheel(false)
      }, 100)
    }
  }, [atTop, stopWheel, loading])

  const isInboundType = useCallback((message) => {
    return message?.type === 'inbound' || message?.type === 'in'
  }, [])

  const isOutboundType = useCallback((message) => {
    return message?.type === 'outbound' || message?.type === 'out'
  }, [])

  const hasSameType = useCallback((message, nextMessage) => {
    return (isInboundType(message) && isInboundType(nextMessage)) || (isOutboundType(message) && isOutboundType(nextMessage))
  }, [])

  const hasSameTime = useCallback((message, nextMessage) => {
    return message?.time?.getMinutes() === nextMessage?.time?.getMinutes() && nextMessage?.time - message?.time < 60000
  }, [])

  const getDateSeparator = useCallback((message, prevMessage) => {
    const messageDate = DateUtils.format(message?.time, DateUtils.DATE_FORMAT)
    const prevMessageDate = DateUtils.format(prevMessage?.time, DateUtils.DATE_FORMAT)
    return prevMessageDate !== messageDate ? <DateSeparator title={messageDate} /> : null
  }, [])

  const getItem = useCallback((message, index, nextMessage) => {
    if (isInboundType(message)) {
      return (
        <InboundMessage
          chatCaption={chatCaption}
          clientProfile={clientProfile}
          receiver={receiver}
          message={message}
          hideAvatar={nextMessage ? isInboundType(nextMessage) : false}
          showTime={hasSameType(message, nextMessage) && hasSameTime(message, nextMessage)}
          lastInboundMessageAt={lastInboundMessageAt}
          stuffUsers={stuffUsers}
          chatbotEnabled={chatbotEnabled}
          onUpdateMbrain={onUpdateMbrain}
          allowSuggestions={allowSuggestions}
          language={language}
        />
      )
    }
    if (isOutboundType(message)) {
      return (
        <OutboundMessage
          message={message}
          dialogId={selectedDialogId}
          receiver={receiver}
          lastDeliveredAt={lastDeliveredAt}
          lastSeenAt={lastSeenAt}
          hideAvatar={nextMessage ? isOutboundType(nextMessage) : false}
          showTime={hasSameType(message, nextMessage) && hasSameTime(message, nextMessage)}
          updateTemplates={updateTemplates}
          actions={actions}
        />
      )
    }
    return <div key={message._id}>Unknown: {message.text}</div>
  }, [
    chatCaption,
    clientProfile,
    receiver,
    lastInboundMessageAt,
    stuffUsers,
    chatbotEnabled,
    onUpdateMbrain,
    allowSuggestions,
    selectedDialogId,
    lastDeliveredAt,
    lastSeenAt,
    updateTemplates,
    actions
  ])

  const renderLoading = useMemo(() => {
    if (!loading && (!startWheel || loadedAll)) {
      return null
    }
    return (
      <div className={css('loader')}>
        <div className={css('spinner')}>
          <Spinner strokeWidth={5} />
        </div>
      </div>
    )
  }, [loading, startWheel, loadedAll])

  const renderMessages = useMemo(() => {
    return messages.map((message, index) => {
      if (message.kind === 'event') {
        events.push(message)
        if (!messages[index + 1] || (messages[index + 1] && messages[index + 1].kind !== 'event')) {
          const eventMessages = [...events]
          events = []
          return (
            <React.Fragment key={eventMessages[0]._id}>
              {getDateSeparator(eventMessages[0], index > 0 ? messages[eventMessages.length - 1] : null)}
              {<EventsBlock events={eventMessages} />}
            </React.Fragment>
          )
        } else {
          return null
        }
      }
      return (
        <React.Fragment key={message._id}>
          {getDateSeparator(message, index > 0 ? messages[index - 1] : null)}
          {getItem(message, index, index < messages.length ? messages[index + 1] : null)}
        </React.Fragment>
      )
    })
  }, [
    messages,
    chatCaption,
    clientProfile,
    receiver,
    lastInboundMessageAt,
    stuffUsers,
    chatbotEnabled,
    onUpdateMbrain,
    allowSuggestions,
    selectedDialogId,
    lastDeliveredAt,
    lastSeenAt,
    updateTemplates,
    actions
  ])

  return (
    <>
      {
        renderLoading
      }
      <div className={css(classnames('messages', isMobileMode ? '--mobile' : null))} onWheel={handlerWheel}>
        {renderMessages}
        <DraftMessage selectedDialogId={selectedDialogId} messages={messages} />
      </div>
    </>
  )
}

const MessagesCount = ({ isCounterVisible, loading, numVisibleMessages, totalVisibleMessages }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const CounterTooltipContent = useCallback(() => {
    return (
      <div className={css('counter-tooltip')}>
        <div className={css('counter-tooltip-line')}>
          <div className={css('counter-tooltip-title')}>{t('dlg:totalMessages')}</div>
          <div className={css('counter-tooltip-value')}>{totalVisibleMessages}</div>
        </div>
        <div className={css('counter-tooltip-line')}>
          <div className={css('counter-tooltip-title')}>{t('dlg:loadedMessages')}</div>
          <div className={css('counter-tooltip-value')}>{numVisibleMessages}</div>
        </div>
      </div>
    )
  }, [totalVisibleMessages, numVisibleMessages])

  return (
    <div
      className={css(classnames('counter-container', isCounterVisible && !loading ? '--visible' : null))}
    >
      <div>
        <Tooltip variation='secondary' content={<CounterTooltipContent />}>
          <div className={css('counter')}>
            {numVisibleMessages}/{totalVisibleMessages}
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

export const Messages = ({
  lastDeliveredAt,
  lastSeenAt,
  chatCaption,
  clientProfile,
  receiver,
  actions,
  updateTemplates,
  lastInboundMessageAt,
  stuffUsers,
  chatbotEnabled,
  onUpdateMbrain,
  allowSuggestions,
  language
}) => {
  const COUNT_MESSAGES_IN_BATCH = 20
  const css = useStyles(styles)
  const { selectedDialogId, relatedDialogIds } = useContext(store)
  const [isCounterVisible, setIsCounterVisible] = useState(true)
  const [limit, setLimit] = useState(COUNT_MESSAGES_IN_BATCH)
  const [dialogId, setDialogId] = useState(null)
  const [relatedDialogs, setRelatedDialogs] = useState([])
  const scrollToEnd = useScrollToEnd()
  const hashTime = window.location.hash.replace('#', '')
  const showHash = useRef(false)
  const [maxScroll, setMaxScroll] = useState(Infinity)
  const params = new URLSearchParams(window.location.search)

  useEffect(() => {
    setLimit(COUNT_MESSAGES_IN_BATCH)
    setDialogId(selectedDialogId)
  }, [selectedDialogId])

  useEffect(() => {
    setRelatedDialogs(relatedDialogIds)
  }, [relatedDialogIds])

  const [loading, messages] = useTracker(() => {
    if (!dialogId) {
      return [false, []]
    }

    const handle = Meteor.subscribe('fetchDialogMessages', dialogId, limit, relatedDialogs)
    const loading = !handle.ready()

    const filter = Array.isArray(relatedDialogs) ? {
      dialogId: {
        $in: [dialogId, ...relatedDialogs]
      }
    } : {
      dialogId
    }

    const messages = ChatMessages.find(
      filter,
      {
        sort: {
          time: 1
        }
      }
    ).fetch()

    if (!showHash.current && window.location.pathname === routes[ROUTE.SEARCH] && params.get('receiver') && hashTime) {
      setMaxScroll(0)
      if (!loading && +messages[0]?.time > +hashTime) {
        const findMessage = messages.find((msg) => +msg.time === +hashTime)
        if (!findMessage) {
          setLimit(messages.length + COUNT_MESSAGES_IN_BATCH)
        } else {
          setMaxScroll(Infinity)
          showHash.current = true
        }
      }
    }

    return [loading, messages]
  }, [dialogId, limit, relatedDialogs])

  const messagesEndRef = useRef(null)
  const previousDialogId = useRef(null)

  useEffect(() => {
    if (messages?.length && (previousDialogId.current !== dialogId) && (showHash.current || !hashTime)) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'auto' })
      scrollToEnd()
      previousDialogId.current = dialogId
    }
  }, [messages])

  const totalVisibleMessages = useTracker(() => VisibleMessagesCount.get(dialogId))
  const numVisibleMessages = messages.reduce((acc, { isInvisible }) => acc + (isInvisible ? 0 : 1), 0)

  return (
    <div className={css('root-container')}>
      {totalVisibleMessages && (
        <MessagesCount
          isCounterVisible={isCounterVisible}
          loading={loading}
          numVisibleMessages={numVisibleMessages}
          totalVisibleMessages={totalVisibleMessages}
        />
      )}
      <ScrollToBottom
        className={css('container')}
        followButtonClassName={css('follow-button')}
        scroller={() => maxScroll}
        initialScrollBehavior='auto'
      >
        <MessagesList
          chatCaption={chatCaption}
          clientProfile={clientProfile}
          receiver={receiver}
          lastDeliveredAt={lastDeliveredAt}
          lastSeenAt={lastSeenAt}
          lastInboundMessageAt={lastInboundMessageAt}
          stuffUsers={stuffUsers}
          chatbotEnabled={chatbotEnabled}
          selectedDialogId={selectedDialogId}
          loading={loading}
          messages={messages}
          updateTemplates={updateTemplates}
          actions={actions}
          allowSuggestions={allowSuggestions}
          onStartScroll={() => {
            setIsCounterVisible(false)
          }}
          onStopScroll={() => {
            setIsCounterVisible(true)
          }}
          onLoadMore={() => {
            if (totalVisibleMessages <= numVisibleMessages) {
              return
            }
            setLimit(messages.length + COUNT_MESSAGES_IN_BATCH)
          }}
          onUpdateMbrain={onUpdateMbrain}
          loadedAll={totalVisibleMessages <= numVisibleMessages}
          language={language}
        />
        <div ref={messagesEndRef} />
      </ScrollToBottom>
    </div>
  )
}

Messages.propTypes = {
  chatCaption: string,
  clientProfile: object,
  receiver: string,
  lastDeliveredAt: object,
  lastSeenAt: object,
  lastInboundMessageAt: object,
  stuffUsers: array,
  chatbotEnabled: bool,
  actions: array,
  updateTemplates: func.isRequired,
  onUpdateMbrain: func.isRequired,
  allowSuggestions: bool,
  language: string
}
