import React, { useState, useMemo, useCallback } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SecondaryButtonIcon } from '@flomni/components/dist/components/secondary-button/icon'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { PopupButtons } from '@flomni/components/dist/components/popup/buttons'
import { PopupContent } from '@flomni/components/dist/components/popup/content'
import { PopupTitle } from '@flomni/components/dist/components/popup/title'
import { CheckboxOption } from '@flomni/components/dist/components/checkbox-option'
import { array, string, func } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Meteor } from '../../../../../../../../../../meteorAdapter';
import { processError } from '../../../../../../../../../services/helpers'
import { ButtonWithLoader } from '../../../../../../../../shared/button-with-loader'
import { Tip } from '@flomni/components/dist/components/tip'
import { ControlContainer } from '@flomni/components/dist/components/control-container'
import { Input } from '@flomni/components/dist/components/input'
import { Select } from '@flomni/components/dist/components/select'
import { SvgIconArrowLeft } from '@flomni/components/dist/components/svg/feathers/SvgIconArrowLeft'
import { SvgIconArrowRight } from '@flomni/components/dist/components/svg/feathers/SvgIconArrowRight'
import { SvgIconDelete } from '@flomni/components/dist/components/svg/feathers/SvgIconDelete'
import { fixedEncode } from '../../../../../../../../../utils/common'
import { Avatar } from '../../../../../../../shared/avatar'
import ReactDOMServer from 'react-dom/server'
import processStrings from 'react-process-string'
import { processUrlConfig } from '../../../../../../../../../../common'
import dompurify from 'dompurify'
import { ROUTE, routes } from '../../../../../../../../../configs/routes'

const DialogAddChatSecondStep = ({ onBack, onCancel, phone, receiverIds, templates, prefferedMessenger }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const stringProcessor = processStrings(processUrlConfig)
  const sanitizer = dompurify.sanitize
  const history = useHistory()
  const [showSaveLoader, setShowSaveLoader] = useState(false)
  const [template, setTemplate] = useState(templates?.length === 1 ? {
    label: templates[0].name,
    value: templates[0]._id,
    vars: templates[0].vars,
    out: templates[0].out
  } : null)
  const { main } = useDispatch()
  const [currentOut, setCurrentOut] = useState(0)
  const [values, setValues] = useState({})
  const [joinChat, setJoinChat] = useState(false)
  const ALLOW_SEND_INVITE_MESSAGE = 'allowSendInviteMessage'
  const [currentUserPermissions] = useSelector(({ main }) => [main.currentUserPermissions])

  const onHandlerCreate = async (e) => {
    e.preventDefault()
    try {
      const data = {
        contactType: 'phoneNum',
        contactValue: phone,
        templateId: template.value,
        templateVars: template.vars.map((item) => ({ keyName: item.keyName, value: values[item.keyName] })),
        joinChat: joinChat,
        prefferedMessenger
      }
      setShowSaveLoader(true)
      await new Promise((resolve) => setTimeout(resolve, 800))
      const response = await Meteor.invoke('dialogs.sendInviteMessage', data)
      history.push({
        pathname: routes[ROUTE.SEARCH],
        search: `?receiver=${response.receiverId}`
      })
    } catch (err) {
      processError(err, main)
      setShowSaveLoader(false)
    }
  }

  const onHandlerShowChat = async (e) => {
    e.preventDefault()
    history.push({
      pathname: routes[ROUTE.SEARCH],
      search: `?receiver=${receiverIds.join(',')}`
    })
  }

  const handlerClickOnCancel = (e) => {
    e.preventDefault()
    onCancel()
  }

  const handlerClickOnBack = (e) => {
    e.preventDefault()
    onBack()
  }

  const optionsTemplates = useMemo(() => {
    return templates?.map((template) => {
      return {
        label: template.name,
        value: template._id,
        vars: template.vars,
        out: template.out
      }
    })
  }, [templates])

  const changeBot = useCallback((diff) => {
    setCurrentOut(currentOut + diff)
  }, [template, currentOut])

  const getVars = useCallback((text) => text?.match(/(#.[^#]*#)/gmi) || [], [])

  const replaceVars = useCallback((text) => {
    const vars = getVars(text)
    vars.forEach((item) => {
      text = text.replaceAll(item, ReactDOMServer.renderToString(
        <span className={css('values')}>{values[item.replaceAll('#', '')] || item}</span>
      ))
    })
    return text
  }, [values])

  const hasValueVars = useMemo(() => {
    return template?.vars?.map((item) => values[item.keyName]).filter(Boolean).length === template?.vars?.length
  }, [values, template])

  return (
    <>
      <PopupTitle variation='secondary' title={t('dlg:addChat')} />
      <PopupContent variation='secondary' classes={{ root: css('content') }}>
        <div className={css('progress')}>
          <div className={css('progress-current')} />
          <div className={css('progress-remain')} />
        </div>
        <div className={css('group')}>
          <div className={css('title')}>{t('dlg:phoneNum')}</div>
          <div className={css('wrapper')}>
            <ControlContainer variation='secondary'>
              <Input
                readOnly
                variation='secondary'
                classes={{ root: css('field') }}
                placeholder={t('dlg:phoneNum')}
                value={phone}
              />
            </ControlContainer>
            <SecondaryButton view='light' onClick={handlerClickOnBack}>
              <SecondaryButtonIcon>
                <SvgIconDelete />
              </SecondaryButtonIcon>
              <SecondaryButtonText>{t('dlg:reset')}</SecondaryButtonText>
            </SecondaryButton>
          </div>
        </div>
        {!receiverIds?.length && (!templates || !currentUserPermissions?.includes(ALLOW_SEND_INVITE_MESSAGE)) && (
          <Tip variation='secondary' view='warning' classes={{ root: css('tip') }}>
            <div className={css('tip-header')}>{t('dlg:unableCreateChat')}</div>
            <div className={css('tip-description')}>{t('dlg:unableCreateChatTip')}</div>
          </Tip>
        )}
        {receiverIds?.length === 1 && (
          <Tip variation='secondary' view='usual' classes={{ root: css('tip') }}>
            <div className={css('tip-header')}>{t('dlg:acceptedContactFound')}</div>
            <div className={css('tip-description')}>{t('dlg:acceptedContactFoundTip')}</div>
          </Tip>
        )}
        {receiverIds?.length > 1 && (
          <Tip variation='secondary' view='usual' classes={{ root: css('tip') }}>
            <div className={css('tip-header')}>{t('dlg:severalAcceptedContactFound', { count: receiverIds.length })}</div>
            <div className={css('tip-description')}>{t('dlg:severalAcceptedContactFoundTip')}</div>
          </Tip>
        )}
        {!receiverIds?.length && templates && currentUserPermissions?.includes(ALLOW_SEND_INVITE_MESSAGE) && (
          <>
            <Tip variation='secondary' view='usual' classes={{ root: css('tip') }}>
              <div className={css('tip-header')}>{t('dlg:youCanSendMessage')}</div>
              <div className={css('tip-description')}>{t('dlg:youCanSendMessageTip')}</div>
              <CheckboxOption
                classes={{ root: css('tip-chk') }}
                checked={joinChat}
                variation='secondary'
                onChange={(e) => setJoinChat(e.target.checked)}
              >
                {t('dlg:addMeToChat')}
              </CheckboxOption>
            </Tip>
            <div className={css(['group', '--shadow-top'])}>
              <div className={css('title')}>{t('dlg:messageTemplate')}</div>
              <div className={css('field')}>
                <Select
                  variation='secondary'
                  placeholder={t('dlg:messageTemplate')}
                  options={optionsTemplates}
                  value={template}
                  menuPortalTarget={document.body}
                  onChange={(option) => {
                    setTemplate(option)
                  }}
                />
              </div>
            </div>
            {!!template && (
              <>
                <div className={css(['group', '--shadow-top'])}>
                  {template?.vars?.map((item) => {
                    return (
                      <div className={css('item')} key={item.keyName}>
                        <div className={css('title')}>{item.title}</div>
                        <div className={css(item.component === 'textarea' ? 'field-textarea' : 'field')}>
                          {(item.component === 'input' || item.component === 'textarea') && (
                            <Input
                              textArea={item.component === 'textarea'}
                              textAreaHeight={4}
                              variation='secondary'
                              classes={{ root: css(item.component === 'textarea' ? 'field-textarea' : 'field') }}
                              value={values[item.keyName] || ''}
                              onChange={(e) => setValues({ ...values, [item.keyName]: e.target.value })}
                              name={item.keyName}
                            />
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className={css(['group', '--preview'])}>
                  <div className={css('preview')}>
                    <div className={css('head')}>
                      <div className={css('caption')}>{t('dlg:preview')}</div>
                      {template?.out?.length > 1 && (
                        <div className={css('slider')}>
                          <div
                            className={css(['nav', currentOut === 0 ? '--not-active' : null])}
                            onClick={() => changeBot(-1)}
                          >
                            <SvgIconArrowLeft />
                          </div>
                          <div className={css('bot')}>
                            {t(`dlg:channelType.${template.out[currentOut].type}`)}
                          </div>
                          <div
                            className={css(['nav', currentOut === template.out.length - 1 ? '--not-active' : null])}
                            onClick={() => changeBot(1)}
                          >
                            <SvgIconArrowRight />
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      <div className={css('container')}>
                        <div className={css('container-message')}>
                          <div className={css('container-text')}>
                            <div className={css('message-root')}>
                              <div className={css('message')}>
                                <div>
                                  <span
                                    className={css('text')}
                                    dangerouslySetInnerHTML={{
                                      __html: replaceVars(ReactDOMServer.renderToString(
                                        stringProcessor(fixedEncode(sanitizer(template.out[currentOut].text)))
                                      ))
                                    }}
                                  />
                                </div>
                                <div className={css('arrow')} />
                              </div>
                            </div>
                          </div>
                          <Avatar url={'/images/bot.svg'} cls={css('logo')} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </PopupContent>
      <PopupButtons variation='secondary' classes={{ root: css('buttons') }}>
        <div className={css('right-buttons')}>
          <SecondaryButton view='light' onClick={handlerClickOnCancel}>
            <SecondaryButtonText>{t('dlg:close')}</SecondaryButtonText>
          </SecondaryButton>
          {(receiverIds?.length || (templates && currentUserPermissions?.includes(ALLOW_SEND_INVITE_MESSAGE))) && (
            <ButtonWithLoader
              text={
                receiverIds?.length === 1
                  ? t('dlg:goToDialogue')
                  : (receiverIds?.length > 1 ? t('dlg:viewListDialogues') : t('dlg:sendMessage'))
              }
              onClick={receiverIds?.length ? onHandlerShowChat : onHandlerCreate}
              disabled={receiverIds?.length ? false : (!template || !hasValueVars)}
              spinnerSize={10}
              spinnerStrokeWidth={14}
              isLoading={showSaveLoader}
            />
          )}
        </div>
      </PopupButtons>
    </>
  )
}

DialogAddChatSecondStep.propTypes = {
  onBack: func.isRequired,
  onCancel: func.isRequired,
  phone: string.isRequired,
  receiverIds: array,
  templates: array,
  prefferedMessenger: string
}

export default DialogAddChatSecondStep
