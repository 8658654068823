import React, { useEffect, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { object } from 'prop-types'
import { useTracker } from '../../../../../../../../../../meteorAdapter';
import { ClientsActivityStore } from '../../../../../../../../../customStores'

export const Status = ({ chat }) => {
  const css = useStyles(styles)
  const [showStatus, setShowStatus] = useState(false)
  const MAX_SECONDS_TO_INACTIVE = 20

  const lastActivityAt = useTracker(() => ClientsActivityStore.get(chat.receiver) ?? 0, [chat])
  const updateStatus = () => {
    setShowStatus(
      lastActivityAt
        ? (Date.now().valueOf() - lastActivityAt.valueOf()) / 1000 >= MAX_SECONDS_TO_INACTIVE
        : false
    )
  }

  useEffect(() => {
    updateStatus()
    const timer = setInterval(updateStatus, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [chat])

  return showStatus ? <div className={css('status')} /> : null
}

Status.props = {
  chat: object
}
