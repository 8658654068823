import React, { useCallback, useContext, useEffect, useState, useRef } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { bool, object, string, func, array } from 'prop-types'
import { Meteor, Match } from '../../../../../../../../../meteorAdapter';
import { Avatar } from '../../../../../../shared/avatar'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { SvgIconBookmark } from '@flomni/components/dist/components/svg/feathers/SvgIconBookmark'
import { SvgIconMenuDots } from '@flomni/components/dist/components/svg/feathers/SvgIconMenuDots'
import { Dropdown } from '@flomni/components/dist/components/dropdown'
import { SecondaryDropdownItem } from '@flomni/components/dist/components/secondary-dropdown-item/item'
import { SecondaryDropdownItemText } from '@flomni/components/dist/components/secondary-dropdown-item/text'
import { Tag } from '@flomni/components/dist/components/tag'
import { Tooltip } from '@flomni/components/dist/components/tooltip'
import { SecondaryDropdownItemIcon } from '@flomni/components/dist/components/secondary-dropdown-item/icon'
import { SvgIconDelete } from '@flomni/components/dist/components/svg/feathers/SvgIconDelete'
import { SvgIconEdit } from '@flomni/components/dist/components/svg/feathers/SvgIconEdit'
import { SvgIconChatIdFilled } from '@flomni/components/dist/components/svg/feathers/SvgIconChatIdFilled'
import { SvgIconAlertTriangle } from '@flomni/components/dist/components/svg/feathers/SvgIconAlertTriangle'
import { SvgIconResend } from '@flomni/components/dist/components/svg/feathers/SvgIconResend'
import { AttachmentsMessage } from '../attachments'
import { MessageTime } from '../message-time'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import DeleteMessage from './delete-message'
import { useDispatch } from 'react-redux'
import { NotSentIcon, OneArrowIcon, SendingIcon, TwoArrowsIcon } from './icons'
import { store } from '../../../../../../../../state/dialogs'
import DialogTemplate from '../../../../../settings/templates/dialog-template'
import { isMobileMode, processError } from '../../../../../../../../services/helpers'
import { Staff } from '../../../../../../../../customStores'
import processStrings from 'react-process-string'
import { processUrlConfig } from '../../../../../../../../../common'
import dompurify from 'dompurify'
import { SvgIconArrowLeft } from '@flomni/components/dist/components/svg/feathers/SvgIconArrowLeft'
import { SvgIconArrowRight } from '@flomni/components/dist/components/svg/feathers/SvgIconArrowRight'
import { DateUtils } from '@flomni/modules/dist/services/date'
import ReactDOMServer from 'react-dom/server'
import { ROUTE, routes } from '../../../../../../../../configs/routes'
import { fixedEncode } from '../../../../../../../../utils/common'
import URI from 'urijs'

export const OutboundMessage = ({
  message,
  hideAvatar,
  showTime,
  lastDeliveredAt,
  lastSeenAt,
  dialogId,
  receiver,
  updateTemplates,
  actions
}) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const stringProcessor = processStrings(processUrlConfig)
  const sanitizer = dompurify.sanitize
  const [showMenu, setShowMenu] = useState(false)
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false)
  const { main } = useDispatch()
  const { isEditMode, editingMessage, dispatch } = useContext(store)
  const [showAddTemplate, setShowAddTemplate] = useState(false)
  const [selectedPrevVer, setSelectedPrevVer] = useState(null)
  const [selectedPrevVerIndex, setSelectedPrevVerIndex] = useState(-1)
  const hash = window.location.hash
  const messageRef = useRef(null)
  const [isTranslate, setIsTranslate] = useState(false)

  useEffect(() => {
    if (`#${+message.time}` === hash) {
      messageRef.current?.scrollIntoView({ block: 'center', behavior: 'auto' })
    }
  }, [])

  useEffect(() => {
    setSelectedPrevVer(selectedPrevVerIndex === -1 ? null : message.prevVers[selectedPrevVerIndex])
  }, [selectedPrevVerIndex])

  const buttons = message.attachments?.length
    ? message.attachments.filter((attachment) => attachment.type === 'button')
    : []
  const otherAttachments = message.attachments?.length
    ? message.attachments.filter((attachment) => attachment.type !== 'button')
    : []

  const getAvatarUrl = () => {
    const staff = Staff.findOne(message.userId, {
      fields: {
        profile: 1
      }
    })
    return Match.isNonEmptyString(staff?.profile?.avatarUrl) ? staff?.profile?.avatarUrl : null
  }

  const onOverlayClickHandler = useCallback(() => {
    setShowMenu(false)
  }, [])

  const onEditMessage = () => {
    setShowMenu(false)
    dispatch({ type: 'setEditingMessage', editingMessage: message })
  }

  const onResendMessage = () => {
    setShowMenu(false)
    Meteor.invoke('messages.resyncChatMessage', {
      dialogId,
      messageId: message._id
    }).catch((err) => {
      processError(err, main)
    })
  }

  const onMessageDeleted = () => {
    setShowRemoveConfirm(false)
    if (editingMessage?._id === message._id) {
      dispatch({ type: 'setEditingMessage', editingMessage: null })
    }
  }

  let messageStatus = null
  if (message.syncState) {
    if (message.syncState === 'not_sent') {
      messageStatus = 'notSent'
    }
    if (message.syncState === 'sending') {
      messageStatus = 'sending'
    }
    if (message.syncState === 'sent' && message.time > lastDeliveredAt) {
      messageStatus = 'notDelivered'
    }
    if (message.syncState === 'sent' && message.time <= lastDeliveredAt) {
      messageStatus = 'delivered'
    }
    if (message.syncState === 'sent' && message.time <= lastSeenAt) {
      messageStatus = 'seen'
    }
  } else {
    messageStatus = message.status
    if (typeof messageStatus !== 'string') {
      if (Match.isDate(lastDeliveredAt) && message.time <= lastDeliveredAt) {
        messageStatus = 'delivered'
      }
      if (Match.isDate(lastSeenAt) && message.time <= lastSeenAt) {
        messageStatus = 'seen'
      }
    }
  }

  const isNotSent = messageStatus === 'notSent'
  const currentUserId = Meteor.userId()
  const canBeEdited = !isNotSent && message.userId === currentUserId
  const isEdited = !!message.prevVers?.length

  const renderStatus = () => {
    return (
      <div className={css(classnames('status', !showTime ? '--show' : null))}>
        {message.isRemoved && (
          <>
            <Tag variation='secondary' view='filled' color='red'>
              {t('dlg:removed')}
            </Tag>
            <div className={css('separator')} />
          </>
        )}
        {isEdited && !message.isRemoved && (
          <>
            <Tag variation='secondary' view='filled' color='blue'>
              {t('dlg:edited')}
            </Tag>
            <div className={css('separator')} />
          </>
        )}
        {message.service?.bot && (
          <>
            <div className={css('channel')}>{t(`dlg:channelType.${message.service.bot}`)}</div>
            <div className={css('separator')} />
          </>
        )}
        {message.translation && (
          <>
            <div onClick={() => setIsTranslate((isTranslate) => !isTranslate)}>
              <Tag variation='secondary' view='filled' color='yellow' classes={{ root: css('tag-clicked') }}>
                <span className={css(classnames(!isTranslate ? 'text-bold' : null))}>
                  {message.translation.originalLanguage}
                </span>
                <span className={css('direction')}>-></span>
                <span className={css(classnames(isTranslate ? 'text-bold' : null))}>
                  {message.translation.targetLanguage}
                </span>
              </Tag>
            </div>
            <div className={css('separator')} />
          </>
        )}
        <MessageTime message={message} isNotSent={isNotSent} />

        {isNotSent && (
          <div className={css('status-icon')}>
            <NotSentIcon />
          </div>
        )}
        {messageStatus === 'sending' && (
          <div className={css('status-icon')}>
            <SendingIcon />
          </div>
        )}
        {messageStatus === 'notDelivered' && (
          <div className={css('status-icon')}>
            <OneArrowIcon />
          </div>
        )}
        {messageStatus === 'delivered' && (
          <div className={css(['status-icon', '--delivered'])}>
            <TwoArrowsIcon />
          </div>
        )}
        {messageStatus === 'seen' && (
          <div className={css(['status-icon', '--seen'])}>
            <TwoArrowsIcon />
          </div>
        )}
      </div>
    )
  }

  const renderButtons = () => {
    if (!buttons.length) {
      return null
    }

    return (
      <div
        className={css(
          classnames(
            'buttons-container',
            message.isRemoved ? '--removed' : null,
            isMobileMode ? '--mobile' : null
          )
        )}
      >
        <div className={css('buttons')}>
          {buttons.map((button) => (
            <Tag
              key={button.id}
              variation='secondary'
              view='stroked'
              color='gray'
              classes={{ root: css('button') }}
            >
              {button.name}
            </Tag>
          ))}
        </div>
      </div>
    )
  }

  const renderAttachments = () => {
    if (!otherAttachments.length) {
      return null
    }
    return <AttachmentsMessage attachments={otherAttachments} message={message} />
  }

  const copyAnchorLink = useCallback(() => {
    try {
      const APP_URL = Meteor.absoluteUrl(routes[ROUTE.SEARCH])
      const link = URI(APP_URL).setQuery('receiver', receiver).toString()
      navigator.clipboard.writeText(`${link}#${+message.time}`)
      main.showSuccessSystemNotification(t('dlg:copied'))
      setShowMenu(false)
    } catch (e) {
      main.showWarningSystemNotification(t('dlg:cantCopy'))
    }
  }, [message])

  return (
    <>
      <div className={css('container')} ref={messageRef}>
        <div className={css(classnames('container-message', isMobileMode ? '--mobile' : null))}>
          <div className={css('container-text')}>
            {(!message.isRemoved && isEditMode) ? (
              <div className={css('commands-container')}>
                <div
                  className={css(
                    classnames('commands', showMenu ? '--opened' : null, isNotSent ? '--not-sent' : null)
                  )}
                >
                  {!isNotSent && (
                    <div>
                      <Tooltip variation='secondary' content={t('dlg:addTemplate')}>
                        <div>
                          <PlateButton noBg onClick={() => setShowAddTemplate(true)}>
                            <PlateButtonIcon>
                              <SvgIconBookmark />
                            </PlateButtonIcon>
                          </PlateButton>
                        </div>
                      </Tooltip>
                    </div>
                  )}
                  <div>
                    <PlateButton
                      noBg
                      hoverType={isNotSent ? 'warning' : 'usual'}
                      onClick={() => setShowMenu(true)}
                    >
                      <PlateButtonIcon>
                        {isNotSent && (
                          <div className={css('alert-icon')}>
                            <SvgIconAlertTriangle />
                          </div>
                        )}
                        {!isNotSent && <SvgIconMenuDots />}
                      </PlateButtonIcon>
                    </PlateButton>
                    <Dropdown
                      active={showMenu}
                      variation='secondary'
                      offsetTarget='iconButton'
                      onOverlayClick={onOverlayClickHandler}
                      position={isMobileMode ? 'right' : 'left'}
                    >
                      {isNotSent && (
                        <SecondaryDropdownItem view='bright' onClick={onResendMessage}>
                          <SecondaryDropdownItemIcon>
                            <SvgIconResend />
                          </SecondaryDropdownItemIcon>
                          <SecondaryDropdownItemText>{t('dlg:resend')}</SecondaryDropdownItemText>
                        </SecondaryDropdownItem>
                      )}
                      <SecondaryDropdownItem view='bright' onClick={copyAnchorLink}>
                        <SecondaryDropdownItemIcon>
                          <SvgIconChatIdFilled />
                        </SecondaryDropdownItemIcon>
                        <SecondaryDropdownItemText>{t('dlg:copyAnchorLink')}</SecondaryDropdownItemText>
                      </SecondaryDropdownItem>
                      {canBeEdited && (
                        <SecondaryDropdownItem view='bright' onClick={onEditMessage}>
                          <SecondaryDropdownItemIcon>
                            <SvgIconEdit />
                          </SecondaryDropdownItemIcon>
                          <SecondaryDropdownItemText>{t('dlg:edit')}</SecondaryDropdownItemText>
                        </SecondaryDropdownItem>
                      )}
                      <SecondaryDropdownItem view='warning' onClick={() => setShowRemoveConfirm(true)}>
                        <SecondaryDropdownItemIcon>
                          <SvgIconDelete />
                        </SecondaryDropdownItemIcon>
                        <SecondaryDropdownItemText>{t('dlg:remove')}</SecondaryDropdownItemText>
                      </SecondaryDropdownItem>
                    </Dropdown>
                    {showRemoveConfirm && (
                      <DeleteMessage
                        message={message}
                        onCancel={() => setShowRemoveConfirm(false)}
                        onDeleted={onMessageDeleted}
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className={css('commands-container')}>
                <div className={css(classnames('commands', showMenu ? '--opened' : null, isNotSent ? '--not-sent' : null))}>
                  <div>
                    <PlateButton noBg hoverType={isNotSent ? 'warning' : 'usual'} onClick={() => setShowMenu(true)}>
                      <PlateButtonIcon>
                        {isNotSent && (
                          <div className={css('alert-icon')}>
                            <SvgIconAlertTriangle />
                          </div>
                        )}
                        {!isNotSent && <SvgIconMenuDots />}
                      </PlateButtonIcon>
                    </PlateButton>
                    <Dropdown
                      active={showMenu}
                      variation='secondary'
                      offsetTarget='iconButton'
                      onOverlayClick={onOverlayClickHandler}
                      position={isMobileMode ? 'right' : 'left'}
                    >
                      <SecondaryDropdownItem view='bright' onClick={copyAnchorLink}>
                        <SecondaryDropdownItemIcon>
                          <SvgIconChatIdFilled />
                        </SecondaryDropdownItemIcon>
                        <SecondaryDropdownItemText>{t('dlg:copyAnchorLink')}</SecondaryDropdownItemText>
                      </SecondaryDropdownItem>
                    </Dropdown>
                  </div>
                </div>
              </div>)
            }
            <div className={css('message-root')}>
              <div
                className={css(
                  classnames(
                    'message',
                    isEdited ? '--edited' : null,
                    message.isRemoved ? '--removed' : null,
                    editingMessage?._id === message._id ? '--editing' : null,
                    `#${+message.time}` === hash ? '--hash' : null
                  )
                )}
              >
                <div>
                  {isEdited && (
                    <div className={css('vers')}>
                      <div className={css('vers-controls')}>
                        <div
                          className={css(
                            classnames('vers-arr', selectedPrevVerIndex === -1 ? '--disabled' : null)
                          )}
                          onClick={() =>
                            selectedPrevVerIndex > -1
                              ? setSelectedPrevVerIndex(selectedPrevVerIndex - 1)
                              : null
                          }
                        >
                          <SvgIconArrowLeft />
                        </div>
                        <div className={css('vers-num')}>
                          <span>{selectedPrevVerIndex + 2}</span>
                          <span>/</span>
                          <span>{message.prevVers.length + 1}</span>
                        </div>
                        <div
                          className={css(
                            classnames(
                              'vers-arr',
                              selectedPrevVerIndex === message.prevVers.length - 1 ? '--disabled' : null
                            )
                          )}
                          onClick={() =>
                            selectedPrevVerIndex < message.prevVers.length - 1
                              ? setSelectedPrevVerIndex(selectedPrevVerIndex + 1)
                              : null
                          }
                        >
                          <SvgIconArrowRight />
                        </div>
                      </div>
                      {selectedPrevVer && selectedPrevVer.contentUpdatedAt && (
                        <div className={css('vers-date')}>
                          {DateUtils.format(selectedPrevVer.contentUpdatedAt, DateUtils.DATE_TIME_FORMAT)}
                        </div>
                      )}
                    </div>
                  )}
                  <span
                    className={css('text')}
                    dangerouslySetInnerHTML={{
                      __html: ReactDOMServer.renderToString(
                        stringProcessor(
                          fixedEncode(
                            sanitizer(
                              selectedPrevVer
                                ? selectedPrevVer.text
                                : message.translation && !isTranslate
                                ? message.translation.text
                                : message.text
                            )
                          )
                        )
                      )
                    }}
                  />
                </div>
                {!hideAvatar && <div className={css('arrow')} />}
              </div>
            </div>
          </div>
          {!hideAvatar && (
            <Avatar url={message.userId ? getAvatarUrl() : '/images/bot.svg'} cls={css('logo')} />
          )}
          {hideAvatar && <div className={css('logo')} />}
        </div>
        {renderAttachments()}
        {renderButtons()}
        {renderStatus()}
      </div>

      {showAddTemplate && (
        <DialogTemplate
          actions={actions}
          text={message.text}
          onCancel={() => setShowAddTemplate(false)}
          onFinished={() => {
            setShowAddTemplate(false)
            updateTemplates()
          }}
        />
      )}
    </>
  )
}

OutboundMessage.propTypes = {
  message: object.isRequired,
  hideAvatar: bool,
  showTime: bool,
  lastDeliveredAt: object,
  lastSeenAt: object,
  dialogId: string,
  updateTemplates: func.isRequired,
  actions: array
}
