import React, { useContext } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { Meteor, useTracker } from '../../../../../../../meteorAdapter';
import { array, func } from 'prop-types'
import { ChatItem } from '../../../conversations/Conversations/left-panel/shared/chat-item'
import { store } from '../../../../../../state/dialogs'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { SvgIconSearch } from '@flomni/components/dist/components/svg/feathers/SvgIconSearch'
import { PlateButtonText } from '@flomni/components/dist/components/plate-button/text'
import { Spinner } from '@flomni/components/dist/components/spinner'

export const SearchResultsPanel = ({ chats, chatsCounter, onReset, loading }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { selectedDialogId, dispatch } = useContext(store)

  useTracker(() => {
    const receivers = chats.map(({ receiver }) => receiver)
    Meteor.subscribe('fetchClientsActivity', receivers)
  }, [chats])

  return (
    <div className={css('container')}>
      <div className={css('header')}>
        <div className={css('title')}>{t('dlg:browse')}</div>
        <PlateButton view='bright' onClick={onReset}>
          <PlateButtonIcon>
            <SvgIconSearch />
          </PlateButtonIcon>
          <PlateButtonText>{t('dlg:changeSearchRequest')}</PlateButtonText>
        </PlateButton>
      </div>
      <div className={css('description')}>
        {t('dlg:countChatsSearch', { count: chatsCounter })}
      </div>
      <div className={css('items-body')}>
        <div className={css('chat-items')}>
          {chats.map((chat) => (
            <ChatItem
              key={chat._id}
              chat={chat}
              isSelected={selectedDialogId === chat._id}
              onSelect={() => {
                dispatch({ type: 'setSelectedDialog', selectedDialog: chat })
              }}
              showChatTypeIcon
            />
          ))}
          {loading && (
            <div className={css('loader')}>
              <div className={css('spinner')}>
                <Spinner strokeWidth={5} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

SearchResultsPanel.propTypes = {
  chats: array,
  onReset: func.isRequired
}
