import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { Input } from '@flomni/components/dist/components/input'
import { ControlContainer } from '@flomni/components/dist/components/control-container'
import { func, object } from 'prop-types'
import { useForm } from 'react-hook-form'
import { SvgIconClose } from '@flomni/components/dist/components/svg/feathers/SvgIconClose'
import { createPortal } from 'react-dom'

const MobileLocationDialog = ({ location, onAdded, onEdited, onCancel }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()

  const { register, formState, errors, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: location?.name ?? '',
      lat: location?.lat ?? '',
      lng: location?.lng ?? ''
    }
  })

  const handlerClickAttach = async (event) => {
    event.preventDefault()
    const values = getValues()
    if (location) {
      onEdited(values.name, values.lat, values.lng)
    } else {
      onAdded(values.name, values.lat, values.lng)
    }
  }

  return createPortal(
    <div className={css('main')}>
      <div onClick={onCancel} />
      <div className={css('header')}>
        <div className={css('name')}>
          {location ? t('dlg:editAttachedLocation') : t('dlg:attachLocation')}
        </div>
        <div className={css('icon')} onClick={onCancel}>
          <SvgIconClose />
        </div>
      </div>
      <div className={css('body')}>
        <div className={css('line')}>
          <div className={css('title')}>{t('dlg:locationName')}</div>
          <ControlContainer variation='secondary' warning={errors.name ? errors.name.message : null}>
            <Input
              variation='secondary'
              tall
              classes={{ root: css('field') }}
              placeholder={t('dlg:locationName')}
              warning={!!errors.name}
              name='name'
              ref={register({ required: t('dlg:locationNameRequired') })}
            />
          </ControlContainer>
        </div>
        <div className={css('line')}>
          <div className={css('title')}>{t('dlg:latitude')}</div>
          <div>
            <ControlContainer variation='secondary' warning={errors.lat ? errors.lat.message : null}>
              <Input
                variation='secondary'
                tall
                classes={{ root: css('field') }}
                placeholder={t('dlg:latitude')}
                warning={!!errors.lat}
                name='lat'
                type='number'
                ref={register({ required: t('dlg:latitudeIsRequired'), valueAsNumber: true })}
              />
            </ControlContainer>
          </div>
        </div>
        <div className={css('line')}>
          <div className={css('title')}>{t('dlg:longitude')}</div>
          <div>
            <ControlContainer variation='secondary' warning={errors.lng ? errors.lng.message : null}>
              <Input
                variation='secondary'
                tall
                classes={{ root: css('field') }}
                placeholder={t('dlg:longitude')}
                warning={!!errors.lng}
                name='lng'
                type='number'
                ref={register({ required: t('dlg:longitudeIsRequired'), valueAsNumber: true })}
              />
            </ControlContainer>
          </div>
        </div>
        <div className={css('save-btn-block')}>
          <SecondaryButton
            view='usual'
            size='full'
            onClick={handlerClickAttach}
            disabled={!formState.isValid || !formState.isDirty}
          >
            <SecondaryButtonText>{location ? t('dlg:save') : t('dlg:attach')}</SecondaryButtonText>
          </SecondaryButton>
        </div>
      </div>
    </div>,
    document.body
  )
}

MobileLocationDialog.propTypes = {
  location: object,
  onAdded: func,
  onEdited: func,
  onCancel: func.isRequired
}

export default MobileLocationDialog
