import React, { useEffect, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { bool, object } from 'prop-types'
import { DateUtils } from '@flomni/modules/dist/services/date'
import { Tooltip } from '@flomni/components/dist/components/tooltip'
import moment from 'moment'
import classnames from 'classnames'

export const MessageTime = ({ message, isNotSent }) => {
  const css = useStyles(styles)
  // const { t } = useTranslation()
  const [value, setValue] = useState(null)

  const updateValue = () => {
    setValue(getTime())
  }

  const getTime = () => {
    const timeAt = moment(message.time)
    return timeAt.format('HH:mm')
    // const duration = moment.duration(moment().diff(timeAt))
    // if (duration.asMonths() >= 1) {
    //   return t('dlg:monthsShort', { value: duration.months() })
    // }
    // if (duration.asWeeks() >= 1) {
    //   return t('dlg:weeksShort', { value: duration.weeks() })
    // }
    // if (duration.asDays() >= 1) {
    //   return t('dlg:daysShort', { value: duration.days() })
    // }
    // if (duration.asHours() >= 1) {
    //   return t('dlg:hoursShort', { value: duration.hours() })
    // }
    // if (duration.asMinutes() >= 1) {
    //   return t('dlg:minutesShort', { value: duration.minutes() })
    // }
    // return t('dlg:secondsShort', { value: duration.seconds() })
  }

  useEffect(() => {
    updateValue()

    // const timer = setInterval(updateValue, 1000)
    // return () => {
    //   clearInterval(timer)
    // }
  }, [message])

  return (
    <div className={css(classnames('container', isNotSent ? '--not-sent' : null))}>
      <Tooltip
        variation='secondary'
        dropdownProps={{ direction: 'up' }}
        content={<div>{DateUtils.format(message.time, DateUtils.DATE_TIME_FULL_FORMAT)}</div>}
      >
        <div>{value}</div>
      </Tooltip>
    </div>
  )
}

MessageTime.propTypes = {
  message: object,
  isNotSent: bool
}
