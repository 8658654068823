import React, { useEffect, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { Popup } from '@flomni/components/dist/components/popup/popup'
import { PopupButtons } from '@flomni/components/dist/components/popup/buttons'
import { PopupContent } from '@flomni/components/dist/components/popup/content'
import { PopupTitle } from '@flomni/components/dist/components/popup/title'
import { func, object } from 'prop-types'
import { Tip } from '@flomni/components/dist/components/tip'
import { Tag } from '@flomni/components/dist/components/tag'
import classnames from 'classnames'
import { Select } from '@flomni/components/dist/components/select'
import { Meteor } from '../../../../../../../../../../../meteorAdapter';
import { processError } from '../../../../../../../../../../services/helpers'
import { useDispatch } from 'react-redux'
import { ButtonWithLoader } from '../../../../../../../../../shared/button-with-loader'

const MegabrainSuggestions = ({ message, mbrain, onUpdateMbrain, onCancel }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const [showTopic, setShowTopic] = useState(false)
  const [topicId, setTopicId] = useState(null)
  const [topics, setTopics] = useState([])
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    Meteor.invoke('mbrain.TextLabeling.stories.topics.get')
      .then((res) => {
        if (res.status !== 'error') {
          setTopics(res.msg.topics || [])
        }
      })
      .catch((err) => {
        processError(err, main)
      })
  }, [])

  const handlerClickOnCancel = (event) => {
    event.preventDefault()
    onCancel()
  }

  const sendToMegabrain = () => {
    setShowLoader(true)
    Meteor.invoke('mbrain.TextLabeling.messages.post', {
      messages: [message.text],
      topics: [topicId]
    })
      .then(() => {
        onCancel()
      })
      .catch((err) => {
        processError(err, main)
      })
      .finally(() => {
        setShowLoader(false)
      })
  }

  const topicOptions = topics.map((topic) => {
    return {
      label: topic.name,
      value: topic.id
    }
  })
  const selectedTopicOption = topicOptions.find((option) => option.value === topicId) || null

  return (
    <Popup open variation='secondary'>
      <PopupTitle title={t('dlg:megabrainsSuggestion')} />
      <PopupContent classes={{ root: css('content') }}>
        <div className={css('line')}>
          <div className={css('title')}>{t('dlg:usersMessage')}</div>
          <div className={css('message')}>{message.text}</div>
        </div>
        <div className={css('line')}>
          <div className={css('title')}>{t('dlg:suggestions')}</div>
          <div className={css('table')}>
            <div className={css(['table-row', '--header'])}>
              <div className={css('table-item')}>{t('dlg:type')}</div>
              <div className={css('table-item')}>{t('dlg:value')}</div>
              <div className={css('table-item')}>{t('dlg:probability')}</div>
            </div>
            {mbrain.topics.map((topic, index) => (
              <div key={index} className={css('table-row')} onClick={() => {
                onUpdateMbrain({ ...mbrain, selectTopic: topic })
                onCancel()
              }}>
                <div className={css('table-item')}>{t('dlg:topic')}</div>
                <div className={css('table-item')}>{topic.topicName}</div>
                <div className={css('table-item')}>{Math.round(topic.matchedProbability)}%</div>
              </div>
            ))}
            {mbrain.emotions.map((emotion, index) => (
              <div className={css('table-row')}>
                <div className={css('table-item')}>{t('dlg:tone')}</div>
                <div className={css('table-item')}>{emotion.topicName}</div>
                <div className={css('table-item')}>{Math.round(emotion.matchedProbability)}%</div>
              </div>
            ))}
            {mbrain.entities.map((entity, index) => (
              <div key={index} className={css('table-row')}>
                <div className={css('table-item')}>{t('dlg:entity')}</div>
                <div className={css(['table-item', '--two'])}>
                  <Tag variation='secondary'>{entity.entityName}</Tag>
                  <span>{entity.value}</span>
                </div>
                <div className={css('table-item')}>{Math.round(entity.matchedProbability)}%</div>
              </div>
            ))}
          </div>
        </div>
        <div className={css('tip-block')}>
          <Tip variation='secondary' view='usual' classes={{ root: css('tip') }}>
            <div className={css(classnames('tip-container', !showTopic ? '--two' : null))}>
              <div>
                <div className={css('tip-header')}>{t('dlg:manageSuggestionsFromDialogs')}</div>
                <div className={css('tip-description')}>
                  {t('dlg:manageSuggestionsFromDialogsDescription')}
                </div>
              </div>
              {!showTopic && (
                <SecondaryButton view='light' onClick={() => setShowTopic(true)}>
                  <SecondaryButtonText>{t('dlg:clarifySuggestion')}</SecondaryButtonText>
                </SecondaryButton>
              )}
            </div>
          </Tip>
        </div>
        {showTopic && (
          <div className={css('line')}>
            <div className={css('title')}>{t('dlg:topic')}</div>
            <div className={css('field')}>
              <Select
                variation='secondary'
                placeholder={t('dlg:selectTopic')}
                options={topicOptions}
                value={selectedTopicOption}
                menuPortalTarget={document.body}
                onChange={(option) => {
                  setTopicId(option.value)
                }}
              />
            </div>
            <div className={css('send-btn')}>
              <ButtonWithLoader
                view='usual'
                text={t('dlg:sendToMegabrain')}
                disabled={!topicId}
                onClick={sendToMegabrain}
                spinnerSize={10}
                spinnerStrokeWidth={14}
                isLoading={showLoader}
              />
            </div>
          </div>
        )}
      </PopupContent>
      <PopupButtons>
        <SecondaryButton view='light' onClick={handlerClickOnCancel}>
          <SecondaryButtonText>{t('dlg:close')}</SecondaryButtonText>
        </SecondaryButton>
      </PopupButtons>
    </Popup>
  )
}

MegabrainSuggestions.propTypes = {
  message: object.isRequired,
  mbrain: object.isRequired,
  onUpdateMbrain: func.isRequired,
  onCancel: func.isRequired
}

export default MegabrainSuggestions
