import React, { useRef } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { func, object } from 'prop-types'
import { SvgIconSheet } from '@flomni/components/dist/components/svg/feathers/SvgIconSheet'
import { useTranslation } from 'react-i18next'
import { formatFileSize } from '../../../../../../../../../../services/helpers'

export const AttachmentFile = ({ file, onChange }) => {
  const { t } = useTranslation()
  const css = useStyles(styles)
  const inputEl = useRef(null)

  const handlerChangeFile = (event) => {
    event.preventDefault()
    inputEl.current.click()
  }

  const onFileChange = (event) => {
    if (event.target.files.length) {
      onChange(event.target.files.item(0))
    }
  }

  return (
    <>
      <input ref={inputEl} type='file' className={css('input')} onChange={onFileChange} />
      <div className={css('container')} onClick={handlerChangeFile}>
        <div className={css('header')}>
          <SvgIconSheet />
          <div className={css('name')}>{file.name}</div>
        </div>
        <div className={css('size')}>{formatFileSize(file.size)}</div>
        <div className={css('edit')}>{t('dlg:clickToChangeFile')}</div>
      </div>
    </>
  )
}

AttachmentFile.propTypes = {
  file: object.isRequired,
  onChange: func.isRequired
}
