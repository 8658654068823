import React, { useContext, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { Popup } from '@flomni/components/dist/components/popup/popup'
import { PopupButtons } from '@flomni/components/dist/components/popup/buttons'
import { PopupContent } from '@flomni/components/dist/components/popup/content'
import { PopupTitle } from '@flomni/components/dist/components/popup/title'

import { func } from 'prop-types'
import { Tip } from '@flomni/components/dist/components/tip'
import { ButtonWithLoader } from '../../../../../../../shared/button-with-loader'
import { Meteor } from '../../../../../../../../../meteorAdapter';
import { useDispatch } from 'react-redux'
import { store } from '../../../../../../../../state/dialogs'
import { processError } from '../../../../../../../../services/helpers'

const ReopenChatDialog = ({ onReopen, onCancel }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const { selectedDialogId } = useContext(store)
  const [showLoader, setShowLoader] = useState(false)

  const handlerClickSave = async (event) => {
    event.preventDefault()
    setShowLoader(true)
    await new Promise((resolve) => setTimeout(resolve, 800))

    try {
      await Meteor.invoke('dialogs.unarchiveDialog', {
        dialogId: selectedDialogId
      })
      setShowLoader(false)
      onReopen()
    } catch (err) {
      setShowLoader(false)
      processError(err, main)
    }
  }

  const handlerClickOnCancel = (event) => {
    event.preventDefault()
    onCancel()
  }

  return (
    <Popup open variation='secondary'>
      <PopupTitle title={t('dlg:reopenChat')} />
      <PopupContent classes={{ root: css('content') }}>
        <Tip variation='secondary' view='info' classes={{ root: css('tip') }}>
          {t('dlg:reopenChatTip')}
        </Tip>
      </PopupContent>
      <PopupButtons>
        <SecondaryButton view='light' onClick={handlerClickOnCancel}>
          <SecondaryButtonText>{t('dlg:close')}</SecondaryButtonText>
        </SecondaryButton>
        <ButtonWithLoader
          view='usual'
          text={t('dlg:reopenChat')}
          onClick={handlerClickSave}
          spinnerSize={10}
          spinnerStrokeWidth={14}
          isLoading={showLoader}
        />
      </PopupButtons>
    </Popup>
  )
}

ReopenChatDialog.propTypes = {
  onReopen: func.isRequired,
  onCancel: func.isRequired
}

export default ReopenChatDialog
