import React, { useEffect, useState, useCallback } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { ROUTE, routes } from '../../../../../configs/routes'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { SecondaryButtonIcon } from '@flomni/components/dist/components/secondary-button/icon'
import { Input } from '@flomni/components/dist/components/input'
import { Tag } from '@flomni/components/dist/components/tag'
import { SvgIconPlus } from '@flomni/components/dist/components/svg/feathers/SvgIconPlus'
import { SvgIconSearch } from '@flomni/components/dist/components/svg/feathers/SvgIconSearch'
import { Meteor } from '../../../../../../meteorAdapter';
import { FiltersPopup } from '../../../shared/filters-popup'
import { SortPopup } from '../../../shared/sort-popup'
import { Spinner } from '@flomni/components/dist/components/spinner'
import { SvgIconBookmarkFilled } from '@flomni/components/dist/components/svg/feathers/SvgIconBookmarkFilled'
import DeleteTemplate from './delete-template'
import { SvgIconEdit } from '@flomni/components/dist/components/svg/feathers/SvgIconEdit'
import { SvgIconDelete } from '@flomni/components/dist/components/svg/feathers/SvgIconDelete'
import { SvgIconShareable } from '@flomni/components/dist/components/svg/feathers/SvgIconShareable'
import { TEMPLATE_TYPE } from '../../../../../services/constants'
import { useDispatch, useSelector } from 'react-redux'
import DialogTemplate from './dialog-template'
import { processError } from '../../../../../services/helpers'
import { USER_ROLES } from '../../../../../../constants'

export const MessageTemplates = () => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const [currentUserGroupId, currentUserRoles] = useSelector(({ main }) => [
    main.currentUserGroupId,
    main.currentUserRoles
  ])
  const [templates, setTemplates] = useState([])
  const [filteredTemplates, setFilteredTemplates] = useState([])
  const [search, setSearch] = useState('')
  const [filterGroups, setFilterGroups] = useState(null)
  const [sortFields, setSortFields] = useState(null)
  const [filterItems, setFilterItems] = useState([])
  const [showLoader, setShowLoader] = useState(true)
  const [showAdd, setShowAdd] = useState(false)
  const [editTemplate, setEditTemplate] = useState(null)
  const [deleteTemplate, setDeleteTemplate] = useState(null)
  const [sortItems] = useState({
    checkedId: null,
    items: []
  })
  const [actions, setActions] = useState([])
  const isOperator = currentUserRoles.includes(USER_ROLES.OPERATOR)
  const [topics, setTopics] = useState([])

  const updateTemplates = () => {
    setShowLoader(true)
    const types = ['user']
    if (!isOperator) {
      types.push('group')
    }
    Meteor.invoke('templates.getTemplates', {
      groupId: currentUserGroupId,
      type: types
    })
      .then((templates = []) => {
        const sortedTemplates = templates.sort(({ name: n1 = '' }, { name: n2 = '' }) => n1.localeCompare(n2))
        setTemplates(sortedTemplates)
        applyFilters(sortedTemplates, filterGroups, sortFields)
      })
      .catch((err) => {
        processError(err, main)
      })
      .finally(() => {
        setShowLoader(false)
      })
  }

  useEffect(() => {
    if (!currentUserGroupId) {
      return
    }
    Meteor.invoke('groups.getGroupBotActions', {
      groupId: currentUserGroupId
    })
      .then((actions) => {
        setActions(actions.items)
      })
      .catch((err) => {
        processError(err, main)
      })
  }, [currentUserGroupId])

  useEffect(() => {
    Meteor.invoke('mbrain.TextLabeling.stories.topics.get')
      .then((res) => {
        if (res && res.status !== 'error') {
          setTopics(res.msg.topics || [])
        }
      })
      .catch((err) => {
        processError(err, main)
      })
  }, [])

  useEffect(() => {
    if (currentUserGroupId) {
      updateTemplates()
      setSortFields(sortItems)
      setFilterItems([])
    }
  }, [currentUserGroupId])

  useEffect(() => {
    applyFilters(templates, filterGroups, sortFields)
  }, [search])

  const applyFilters = (list, filterGroupsIds, sortItems) => {
    let filteredList = [...list]
    if (search) {
      const lowerSearch = search.toLowerCase()
      filteredList = filteredList.filter(
        (item) =>
          item.name.toLowerCase().indexOf(lowerSearch) !== -1 ||
          item.text.toLowerCase().indexOf(lowerSearch) !== -1
      )
    }
    setFilteredTemplates(filteredList)
  }

  const onSetFilter = () => {
    setFilterGroups([])
    applyFilters(templates, [], sortFields)
  }

  const onSetSort = (items) => {
    setSortFields(items)
    applyFilters(templates, filterGroups, items)
  }

  const chatTopic = useCallback((id) => {
    return topics?.find((topic) => topic.id === id)?.name || ''
  }, [topics])

  return (
    <div className={css('main')}>
      <div className={css('container')}>
        <div className={css('nav-bar')}>
          <NavLink className={css('link')} to={routes[ROUTE.SETTINGS]}>
            {t('dlg:dialogsSettings')}
          </NavLink>
          <div>></div>
          <div className={css('active')}>{t('dlg:messageTemplates')}</div>
        </div>
        <div className={css('header')}>
          <div className={css('title')}>{t('dlg:templates')}</div>
          {!showLoader && templates.length > 0 && (
            <SecondaryButton onClick={() => setShowAdd(true)} classes={{ inner: css('btn-icon-with-text') }}>
              <SecondaryButtonIcon>
                <SvgIconPlus />
              </SecondaryButtonIcon>
              <SecondaryButtonText>{t('dlg:newTemplate')}</SecondaryButtonText>
            </SecondaryButton>
          )}
        </div>
        {showLoader && (
          <div className={css('spinner')}>
            <Spinner strokeWidth={5} />
          </div>
        )}
        {!showLoader && templates.length === 0 && (
          <div className={css('no-items')}>
            <div className={css('no-items-icon')}>
              <SvgIconBookmarkFilled width={28} height={36} />
            </div>
            <div className={css('no-items-title')}>{t('dlg:youDontHaveTemplates')}</div>
            <div className={css('no-items-description')}>{t('dlg:createFirstTemplateSeeingHere')}</div>
            <SecondaryButton classes={{ root: css('no-items-button') }} onClick={() => setShowAdd(true)}>
              <SecondaryButtonIcon>
                <SvgIconPlus />
              </SecondaryButtonIcon>
              <SecondaryButtonText>{t('dlg:newTemplate')}</SecondaryButtonText>
            </SecondaryButton>
          </div>
        )}
        {!showLoader && templates.length > 0 && (
          <div className={css('info')}>
            <div className={css('toolbox')}>
              <Input
                icons={[<SvgIconSearch />]}
                variation='secondary'
                shaded
                placeholder={t('dlg:searchByNameOrText')}
                onChange={(e) => setSearch(e.target.value)}
              />
              <div style={{ display: 'none' }}>
                <SortPopup disabled config={sortItems} onDone={onSetSort} onReset={onSetSort} />
                <FiltersPopup disabled items={filterItems} onDone={onSetFilter} onReset={onSetFilter} />
              </div>
            </div>
            <div className={css('table-header')}>
              <div />
              <div>{t('dlg:templateName')}</div>
              <div>{t('dlg:messageText')}</div>
              <div>{t('dlg:chatTopic')}</div>
              <div>{t('dlg:buttons')}</div>
            </div>
            <div>
              {filteredTemplates.map((item) => (
                <div key={item.id} className={css('item')}>
                  <div className={css('item-icon')}>
                    {item.type === TEMPLATE_TYPE.GROUP && <SvgIconShareable />}
                  </div>
                  <div className={css('item-name')}>{item.title || item.name}</div>
                  <div className={css('item-name')}>{item.text}</div>
                  <div className={css('item-name')}>
                    {(Array.isArray(item.suggestionsTopic) ? item.suggestionsTopic : [item.suggestionsTopic]).map(
                      (id) => chatTopic(id)
                    ).join(', ')}
                  </div>
                  <div className={css('item-attachment-buttons')}>
                    {item.attachments?.length > 0 &&
                      item.attachments.map((attachment) => (
                        <Tag key={attachment.id} color='gray' view='stroked' variation='secondary'>
                          {attachment.name}
                        </Tag>
                      ))}
                    {!item.attachments?.length && (
                      <span className={css('no-buttons')}>{t('dlg:thereIsNoButtons')}</span>
                    )}
                  </div>
                  <div className={css('item-buttons')}>
                    <SecondaryButton view='light' onClick={() => setEditTemplate(item)}>
                      <SecondaryButtonIcon>
                        <SvgIconEdit />
                      </SecondaryButtonIcon>
                      <SecondaryButtonText>{t('dlg:edit')}</SecondaryButtonText>
                    </SecondaryButton>
                    <SecondaryButton view='light' onClick={() => setDeleteTemplate(item)}>
                      <SecondaryButtonIcon>
                        <SvgIconDelete />
                      </SecondaryButtonIcon>
                      <SecondaryButtonText>{t('dlg:remove')}</SecondaryButtonText>
                    </SecondaryButton>
                  </div>
                </div>
              ))}
            </div>
            <div className={css('table-footer')}>
              <div className={css('count')}>
                {t('dlg:countTemplates', { count: filteredTemplates.length })}
              </div>
            </div>
          </div>
        )}
      </div>
      {deleteTemplate && (
        <DeleteTemplate
          onCancel={() => setDeleteTemplate(null)}
          onDeleted={() => {
            setDeleteTemplate(null)
            updateTemplates()
          }}
          template={deleteTemplate}
        />
      )}
      {editTemplate && (
        <DialogTemplate
          isOperator={isOperator}
          actions={actions}
          onCancel={() => setEditTemplate(null)}
          onFinished={() => {
            setEditTemplate(null)
            updateTemplates()
          }}
          template={editTemplate}
          isEdit
        />
      )}
      {showAdd && (
        <DialogTemplate
          isOperator={isOperator}
          actions={actions}
          onCancel={() => setShowAdd(false)}
          onFinished={() => {
            setShowAdd(false)
            updateTemplates()
          }}
        />
      )}
    </div>
  )
}
