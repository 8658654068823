import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import { bool, number } from 'prop-types'
import { WorkforceDashboard } from './workforce'
import { PersonalDashboard } from './personal'
import { TeamDashboard } from './team'

export const Dashboard = ({ inWorkDialogsCount, hasDashboardWFM }) => {
  momentDurationFormatSetup(moment)

  const css = useStyles(styles)

  return (
    <div className={css('page')}>
      <PersonalDashboard inWorkDialogsCount={inWorkDialogsCount} />
      <TeamDashboard />
      {hasDashboardWFM && <WorkforceDashboard />}
      {/* <AgentsStatuses /> */}
    </div>
  )
}

Dashboard.propTypes = {
  inWorkDialogsCount: number,
  hasDashboardWFM: bool
}
