import { Meteor, getBackendBaseUrl } from '../../meteorAdapter';
import URI from 'urijs'

const MAX_AVATAR_SIZE = 1048576 * 20 // 20MB
const MAX_FILE_SIZE = 1048576 * 100 // 100MB
const MAX_IMAGE_SIZE = 1048576 * 100 // 100MB

async function putFile ({
  userId,
  dialogId,
  file,
  kind
}) {
  const res = await Meteor.invoke('fileStore.registerFileUpload', {
    fileName: file.name,
    fileSize: file.size,
    fileType: file.type,
    kind,
    mode: 'PUT',
    userId,
    dialogId
  })
  if (Array.isArray(res) === false) return

  const [id, presignedUrl] = res

  const uploadUrl = URI(getBackendBaseUrl())
    .directory('fs-upload-proxy')
    .setQuery('url', presignedUrl)
    .toString()

  await fetch(uploadUrl, {
    method: 'PUT',
    body: file
  })

  const resp = await Meteor.invoke('fileStore.confirmFileUpload', {
    fileId: id,
    url: presignedUrl,
    userId
  })

  return {
    ...resp,
    fileId: id
  }
}

// async function postFile ({
//   userId,
//   dialogId,
//   file,
//   kind
// }) {
//   const res = await Meteor.invoke('fileStore.registerFileUpload', {
//     fileName: file.name,
//     fileSize: file.size,
//     fileType: file.type,
//     kind,
//     mode: 'POST',
//     userId,
//     dialogId
//   })
//   if (res == null) return
//
//   const [
//     id,
//     presignedUrl,
//     formData
//   ] = res
//
//   const uploadFormData = new FormData()
//   Object.entries(formData).forEach(([k, v]) => {
//     uploadFormData.append(k, v)
//   })
//   uploadFormData.append('file', file, file.name)
//   uploadFormData.append('Content-Type', file.type)
//
//   await fetch(presignedUrl, {
//     method: 'POST',
//     body: uploadFormData
//   })
//
//   const resp = await Meteor.invoke('fileStore.confirmFileUpload', {
//     fileId: id,
//     url: presignedUrl,
//     userId
//   }) ?? {}
//
//   return {
//     ...resp,
//     fileId: id
//   }
// }

const isImage = file => file.type.startsWith('image/')
const hasAllowableAvatarSize = file => file.size >= 0 && file.size <= MAX_AVATAR_SIZE
const hasAllowableFileSize = file => file.size >= 0 && file.size <= MAX_FILE_SIZE
const hasAllowableImageSize = file => file.size >= 0 && file.size <= MAX_IMAGE_SIZE

export default class UploaderHelpers {
  static async uploadAvatar (userId, file) {
    if (isImage(file) === false) return
    if (hasAllowableAvatarSize(file) === false) {
      throw new Error(`You tried to upload a file that is too large (the limit is ${MAX_AVATAR_SIZE} bytes).`)
    }

    const res = await putFile({
      userId,
      file,
      kind: 'avatar'
    })
    if (res == null) throw new Error('Your file is not uploaded')

    const {
      url,
      fileId
    } = res

    await Meteor.invoke('users.updateUserAvatar', { url, userId })

    return {
      url,
      fileId
    }
  }

  static async uploadFile (userId, dialogId, file) {
    if (hasAllowableFileSize(file) === false) {
      throw new Error(`You tried to upload a file that is too large (the limit is ${MAX_FILE_SIZE} bytes).`)
    }

    const res = await putFile({
      userId,
      dialogId,
      file,
      kind: 'file'
    })
    if (res == null) throw new Error('Your file is not uploaded')

    return {
      url: res.url,
      fileId: res.fileId
    }
  }

  static async uploadImage (userId, dialogId, file) {
    if (isImage(file) === false) return
    if (hasAllowableImageSize(file) === false) {
      throw new Error(`You tried to upload a file that is too large (the limit is ${MAX_IMAGE_SIZE} bytes).`)
    }

    const res = await putFile({
      userId,
      dialogId,
      file,
      kind: 'image'
    })
    if (res == null) throw new Error('Your file is not uploaded')

    return {
      url: res.url,
      fileId: res.fileId
    }
  }
}
