import React, { useCallback, useContext, useEffect, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { getDialogName } from '../../../../../../../utils/common'
import { PreparedAtTimer } from '../../left-panel/shared/chat-item/prepared-at-timer'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { SvgIconMenuDots } from '@flomni/components/dist/components/svg/feathers/SvgIconMenuDots'
import { SvgIconTick } from '@flomni/components/dist/components/svg/feathers/SvgIconTick'
import { SvgIconLanguage } from '@flomni/components/dist/components/svg/feathers/SvgIconLanguage'
import { PlateButtonText } from '@flomni/components/dist/components/plate-button/text'
import { store } from '../../../../../../../state/dialogs'
import { Dropdown } from '@flomni/components/dist/components/dropdown'
import { SecondaryDropdownItem } from '@flomni/components/dist/components/secondary-dropdown-item/item'
import { SecondaryDropdownItemText } from '@flomni/components/dist/components/secondary-dropdown-item/text'
import TopicDialog from './topic-dialog'
import CompleteChatDialog from './complete-chat-dialog'
import { Meteor, useTracker, Match } from '../../../../../../../../meteorAdapter';
import { Tag } from '@flomni/components/dist/components/tag'
import { SvgIconCalendar } from '@flomni/components/dist/components/svg/feathers/SvgIconCalendar'
import { SvgIconQuestionStroke } from '@flomni/components/dist/components/svg/feathers/SvgIconQuestionStroke'
import { DateUtils } from '@flomni/modules/dist/services/date'
import RemindersDialog from './reminders-dialog'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar } from '../../../../../shared/avatar'
import AgentsDialog from './agents-dialog'
import { SvgIconUser } from '@flomni/components/dist/components/svg/feathers/SvgIconUser'
import { ROUTE, routes } from '../../../../../../../configs/routes'
import copy from 'copy-to-clipboard'
import URI from 'urijs'
import { TotalTime } from './total-time'
import ReopenChatDialog from './reopen-chat-dialog'
import { array, bool, func, object, string } from 'prop-types'
import { DialogReminders, Staff, Tags } from '../../../../../../../customStores'
import { processError } from '../../../../../../../services/helpers'
import { Spinner } from '@flomni/components/dist/components/spinner'
import DialogHelpers from '../../../../../../../services/dialogHelpers'

export const Header = ({
  subjects,
  stuffUsers,
  receiver,
  state,
  stuffInfo,
  preparedAt,
  lastMessageAt,
  createdAt,
  chatCaption,
  clientProfile,
  onChanged,
  allowSuggestions,
  allowSuggestionsForProfile,
  crmFields,
  language
}) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const [
    currentUserGroupId,
    distributionRequest,
    currentUserArchiveSettings,
    currentUserSounds,
    currentUserMegabrainSettings
  ] = useSelector(({ main }) => [
    main.currentUserGroupId,
    main.distributionRequest,
    main.currentUserArchiveSettings,
    main.currentUserSounds,
    main.currentUserMegabrainSettings
  ])
  const { selectedDialogId, isEditMode, dispatch } = useContext(store)
  const [showMenu, setShowMenu] = useState(false)
  const [showTopicDialog, setShowTopicDialog] = useState(false)
  const [showReopenDialog, setShowReopenDialog] = useState(false)
  const [showCompleteDialog, setShowCompleteDialog] = useState(false)
  const [showRemindersDialog, setShowRemindersDialog] = useState(false)
  const [showAgentsDialog, setShowAgentsDialog] = useState(false)
  const [showCompleteLoader, setShowCompleteLoader] = useState(false)
  const [topic, setTopic] = useState(null)
  const currentUserId = Meteor.userId()
  const [translationSettings] = useSelector(({ main }) => [main.currentUserTranslationSettings])
  const [showLanguages, setShowLanguages] = useState(false)

  const reminders = useTracker(() => {
    Meteor.subscribe('reminders.activeReminders', selectedDialogId)
    return DialogReminders.find(
      {
        dialogId: selectedDialogId,
        isActive: true
      },
      {
        sort: {
          intendedAt: 1
        }
      }
    ).fetch()
  }, [selectedDialogId])

  const topics = useTracker(() => {
    return Tags.find(
      {
        sourceId: currentUserGroupId,
        sourceType: 'group',
        kind: 'session'
      },
      {
        fields: {
          name: 1,
          parentId: 1
        }
      }
    ).map(({ _id, name, parentId }) => ({
      id: _id,
      name,
      parentId
    }))
  }, [])

  const topicsGroup = useTracker(() => {
    return Tags.find(
      {
        sourceId: currentUserGroupId,
        sourceType: 'group',
        kind: 'sessionGroup'
      },
      {
        fields: {
          name: 1
        }
      }
    ).map(({ _id, name }) => ({
      id: _id,
      name
    }))
  }, [])

  useEffect(() => {
    setTopic(topics.find((topic) => subjects === topic.id) || null)
  }, [topics, subjects])

  const onOverlayClickHandler = useCallback(() => {
    setShowMenu(false)
  }, [])

  const onOverlayClickHandlerLanguages = useCallback(() => {
    setShowLanguages(false)
  }, [])

  const onChangeAgents = () => {
    setShowAgentsDialog(false)
    onChanged()
  }

  const copyChatLink = () => {
    const APP_URL = Meteor.absoluteUrl(routes[ROUTE.SEARCH])
    const link = URI(APP_URL).setQuery('receiver', receiver).toString()
    if (copy(link)) {
      main.showSuccessSystemNotification(t('dlg:chatLinkCopied'))
    } else {
      main.showWarningSystemNotification(t('dlg:cantCopy'))
    }
    setShowMenu(false)
  }

  const setClientLanguage = async (ln) => {
    await Meteor.invoke('setClientLanguage', selectedDialogId, ln)
    setShowLanguages(false)
  }

  const onCompleteDialog = (topicId) => {
    setShowCompleteDialog(false)
    setTopic(topics.find((topic) => topicId === topic.id) || null)
    afterCompleteDialog()
  }

  const afterCompleteDialog = () => {
    if (isEditMode) {
      dispatch({ type: 'setSelectedDialog', selectedDialog: null })
    }
    onChanged()
  }

  const onReopenDialog = () => {
    setShowReopenDialog(false)
    if (isEditMode) {
      window.setTimeout(() => {
        dispatch({ type: 'setSelectedDialog', selectedDialog: null })
      }, 100)
    }
    onChanged()
  }

  const onChangedTopic = (topicId) => {
    setShowTopicDialog(false)
    setTopic(topics.find((topic) => topicId === topic.id) || null)
    onChanged()
  }

  const handlerClickComplete = async () => {
    const hasDelayActions =
      !!currentUserArchiveSettings.delay_actions && !!currentUserArchiveSettings.delay_actions_period
    const hasCrmFieldsToFill = crmFields.length && crmFields.some((field) => !!field.passToStats)
    if ((topics && topics.length) || hasDelayActions || hasCrmFieldsToFill) {
      setShowCompleteDialog(true)
    } else {
      setShowCompleteLoader(true)
      await new Promise((resolve) => setTimeout(resolve, 800))
      try {
        await Meteor.invoke('dialogs.archiveDialog', {
          dialogId: selectedDialogId
        })

        DialogHelpers.requestHottestDialog(distributionRequest, main, currentUserSounds, 'Archive chat')

        setShowCompleteLoader(false)
        afterCompleteDialog()
      } catch (err) {
        setShowCompleteLoader(false)
        processError(err, main)
      }
    }
  }

  const toggleSuggestionsInDialog = () => {
    setShowMenu(false)
    Meteor.call('dialogs.toggleSuggestionsInDialog', {
      groupId: currentUserGroupId,
      dialogId: selectedDialogId,
      allowSuggestions: !allowSuggestions
    })
  }

  const getAvatarUrl = (userId) => {
    const staff = Staff.findOne(userId, {
      fields: {
        profile: 1
      }
    })
    return Match.isNonEmptyString(staff?.profile?.avatarUrl) ? staff?.profile?.avatarUrl : null
  }

  const isArchived = state === 'ARCHIVED'

  return (
    <div className={css('container')}>
      <div className={css('info')}>
        <div className={css('name')}>{getDialogName(chatCaption, clientProfile, receiver, t)}</div>
        {!isArchived && <PreparedAtTimer preparedAt={preparedAt} />}
        {isArchived && <TotalTime createdAt={createdAt} lastMessageAt={lastMessageAt} />}
        {reminders.length > 0 && (
          <Tag classes={{ inner: css('tag') }} color='gray' variation='secondary' view='filled'>
            <SvgIconCalendar />
            {DateUtils.format(reminders[0].intendedAt, DateUtils.DATE_TIME_FORMAT)}
          </Tag>
        )}
        {topic && (
          <Tag classes={{ inner: css('tag') }} color='gray' variation='secondary' view='stroked'>
            <SvgIconQuestionStroke />
            <div className={css('topic-name')}>{topic.name}</div>
          </Tag>
        )}
      </div>
      <div>
        <div className={css('buttons')}>
          {translationSettings?.translationEnabled && (
            <div>
              <PlateButton onClick={() => setShowLanguages(true)}>
                <PlateButtonIcon>
                  <SvgIconLanguage />
                </PlateButtonIcon>
                <PlateButtonText>
                  {t(`dlg:languages.${language || translationSettings.defaultLanguage}`)}
                </PlateButtonText>
              </PlateButton>
              {showLanguages && (
                <Dropdown
                  active
                  variation='secondary'
                  position='right'
                  offsetTarget='iconButton'
                  onOverlayClick={onOverlayClickHandlerLanguages}
                >
                  <SecondaryDropdownItem view='header'>
                    <SecondaryDropdownItemText>{t('dlg:availableLanguages')}</SecondaryDropdownItemText>
                  </SecondaryDropdownItem>
                  {translationSettings?.availableLanguages.map((ln) => (
                    <SecondaryDropdownItem
                      key={ln}
                      view='bright'
                      active={ln === language}
                      thin={ln !== language}
                      onClick={() => setClientLanguage(ln)}
                    >
                      <SecondaryDropdownItemText>{t(`dlg:languages.${ln}`)}</SecondaryDropdownItemText>
                    </SecondaryDropdownItem>
                  ))}
                </Dropdown>
              )}
            </div>
          )}
          <PlateButton onClick={() => setShowAgentsDialog(true)}>
            {stuffUsers.slice(0, 3).map((stuffUserId) => (
              <Avatar key={stuffUserId} cls={css('avatar-small')} url={getAvatarUrl(stuffUserId)} />
            ))}
            {stuffUsers.length > 1 && (
              <PlateButtonText>
                {stuffUsers.length > 3 ? (
                  <span>
                    {t('dlg:countAgentsPlus', {
                      count: stuffUsers.length - 3
                    })}
                  </span>
                ) : (
                  <span>{t('dlg:countAgents', { count: stuffUsers.length })}</span>
                )}
              </PlateButtonText>
            )}
            {stuffUsers.length === 1 && (
              <PlateButtonText>
                {stuffUsers[0] === currentUserId ? t('dlg:you') : stuffInfo[stuffUsers[0]]?.name || ''}
              </PlateButtonText>
            )}
            {stuffUsers.length === 0 && (
              <>
                <PlateButtonIcon>
                  <SvgIconUser />
                </PlateButtonIcon>
                <PlateButtonText>{t('dlg:assignAnAgent')}</PlateButtonText>
              </>
            )}
          </PlateButton>
          {!isArchived && (
            <PlateButton onClick={handlerClickComplete} disabled={showCompleteLoader}>
              {showCompleteLoader && (
                <div className={css('plate-button-spinner')}>
                  <Spinner strokeWidth={14} />
                </div>
              )}
              {!showCompleteLoader && (
                <PlateButtonIcon>
                  <SvgIconTick />
                </PlateButtonIcon>
              )}
              <PlateButtonText>{t('dlg:completeChat')}</PlateButtonText>
            </PlateButton>
          )}
          {isArchived && (
            <PlateButton onClick={() => setShowReopenDialog(true)}>
              <PlateButtonIcon>
                <SvgIconTick />
              </PlateButtonIcon>
              <PlateButtonText>{t('dlg:reopenChat')}</PlateButtonText>
            </PlateButton>
          )}
          <div>
            <PlateButton onClick={() => setShowMenu(true)}>
              <PlateButtonIcon>
                <SvgIconMenuDots />
              </PlateButtonIcon>
            </PlateButton>
            {showMenu && (
              <Dropdown
                active
                variation='secondary'
                position='right'
                offsetTarget='iconButton'
                onOverlayClick={onOverlayClickHandler}
              >
                <SecondaryDropdownItem view='header'>
                  <SecondaryDropdownItemText>{t('dlg:actions')}</SecondaryDropdownItemText>
                </SecondaryDropdownItem>
                <SecondaryDropdownItem view='bright' onClick={copyChatLink}>
                  <SecondaryDropdownItemText>{t('dlg:shareChat')}</SecondaryDropdownItemText>
                </SecondaryDropdownItem>
                <SecondaryDropdownItem
                  view='bright'
                  onClick={() => {
                    setShowRemindersDialog(true)
                    setShowMenu(false)
                  }}
                >
                  <SecondaryDropdownItemText>
                    {reminders.length === 0 ? t('dlg:setReminder') : t('dlg:editReminders')}
                  </SecondaryDropdownItemText>
                </SecondaryDropdownItem>
                {topics && topics.length > 0 && (
                  <SecondaryDropdownItem
                    view='bright'
                    onClick={() => {
                      setShowTopicDialog(true)
                      setShowMenu(false)
                    }}
                  >
                    <SecondaryDropdownItemText>
                      {topic ? t('dlg:changeChatTopic') : t('dlg:chooseChatTopic')}
                    </SecondaryDropdownItemText>
                  </SecondaryDropdownItem>
                )}
                {!isArchived && (
                  <SecondaryDropdownItem
                    view='bright'
                    onClick={() => {
                      setShowMenu(false)
                      handlerClickComplete()
                    }}
                  >
                    <SecondaryDropdownItemText>{t('dlg:completeChat')}</SecondaryDropdownItemText>
                  </SecondaryDropdownItem>
                )}
                {isArchived && (
                  <SecondaryDropdownItem
                    view='bright'
                    onClick={() => {
                      setShowReopenDialog(true)
                      setShowMenu(false)
                    }}
                  >
                    <SecondaryDropdownItemText>{t('dlg:reopenChat')}</SecondaryDropdownItemText>
                  </SecondaryDropdownItem>
                )}
                <SecondaryDropdownItem
                  view='bright'
                  onClick={() => {
                    setShowAgentsDialog(true)
                    setShowMenu(false)
                  }}
                >
                  <SecondaryDropdownItemText>{t('dlg:manageAgents')}</SecondaryDropdownItemText>
                </SecondaryDropdownItem>
                {currentUserMegabrainSettings.allowSuggestions && allowSuggestionsForProfile && (
                  <SecondaryDropdownItem view='bright' onClick={toggleSuggestionsInDialog}>
                    <SecondaryDropdownItemText>
                      {allowSuggestions ? t('dlg:disableSuggestions') : t('dlg:enableSuggestions')}
                    </SecondaryDropdownItemText>
                  </SecondaryDropdownItem>
                )}
              </Dropdown>
            )}
          </div>
        </div>
        {showTopicDialog && (
          <TopicDialog
            topic={topic}
            topics={topics}
            onSaved={onChangedTopic}
            onCancel={() => setShowTopicDialog(false)}
          />
        )}
        {showCompleteDialog && (
          <CompleteChatDialog
            topic={topic}
            topics={topics}
            topicsGroup={topicsGroup}
            crmFields={crmFields}
            onComplete={onCompleteDialog}
            onCancel={() => setShowCompleteDialog(false)}
          />
        )}
        {showReopenDialog && (
          <ReopenChatDialog onReopen={onReopenDialog} onCancel={() => setShowReopenDialog(false)} />
        )}
        {showRemindersDialog && (
          <RemindersDialog reminders={reminders} onCancel={() => setShowRemindersDialog(false)} />
        )}
        {showAgentsDialog && (
          <AgentsDialog
            receiver={receiver}
            chatCaption={chatCaption}
            clientProfile={clientProfile}
            agentIds={stuffUsers}
            onCancel={() => setShowAgentsDialog(false)}
            onSaved={onChangeAgents}
          />
        )}
      </div>
    </div>
  )
}

Header.propTypes = {
  subjects: string,
  stuffUsers: array,
  receiver: string,
  state: string,
  stuffInfo: object,
  preparedAt: object,
  lastMessageAt: object,
  createdAt: object,
  chatCaption: string,
  clientProfile: object,
  onChanged: func.isRequired,
  allowSuggestions: bool,
  allowSuggestionsForProfile: bool,
  crmFields: array
}
