export const TEMPLATE_TYPE = {
  USER: 'user',
  GROUP: 'group'
}

export const BUTTON_KIND = {
  CUSTOM: 'custom',
  URL: 'url'
}

export const PERMISSIONS_PAGE = {
  SEARCH: 'search',
  ALL_CHATS: 'allChats',
  ALLOW_MEGABRAIN_TRAINING: 'allowMegabrainTraining',
  JOIN_RELATED_CHATS: 'joinedMessageHistory',
  AGENTS_CAN_BAN_CLIENTS: 'agentsCanBanClients',
  ALLOW_DISPUTE_RATES: 'allowDisputeRates'
}
