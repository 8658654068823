import React, { useEffect, useMemo, useState, useCallback } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { ROUTE, routes } from '../../../../../configs/routes'
import { Avatar } from '../../../shared/avatar'
import classnames from 'classnames'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { SecondaryButtonIcon } from '@flomni/components/dist/components/secondary-button/icon'
import { Input } from '@flomni/components/dist/components/input'
import { Tag } from '@flomni/components/dist/components/tag'
import { SvgIconPlus } from '@flomni/components/dist/components/svg/feathers/SvgIconPlus'
import { SvgIconExportArrow } from '@flomni/components/dist/components/svg/feathers/SvgIconExportArrow'
import { SvgIconSearch } from '@flomni/components/dist/components/svg/feathers/SvgIconSearch'
import { Meteor, useTracker } from '../../../../../../meteorAdapter';
import BlockMember from './block-member'
import Export from './export'
import AddMember from './add-member'
import EditMember from './edit-member'
import moment from 'moment'
import { FiltersPopup } from '../../../shared/filters-popup'
import { SortPopup } from '../../../shared/sort-popup'
import { Spinner } from '@flomni/components/dist/components/spinner'
import { SvgIconEdit } from '@flomni/components/dist/components/svg/feathers/SvgIconEdit'
import { processError } from '../../../../../services/helpers'
import { useDispatch, useSelector } from 'react-redux'
import UnblockMember from './unblock-member'
import { Staff } from '../../../../../customStores'

export const AgentsTeam = () => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const [users, setUsers] = useState([])
  const [departments, setDepartments] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])
  const [editMember, setEditMember] = useState(null)
  const [blockMember, setBlockMember] = useState(null)
  const [unblockMember, setUnblockMember] = useState(null)
  const [showExport, setShowExport] = useState(false)
  const [showAdd, setShowAdd] = useState(false)
  const [search, setSearch] = useState('')
  const [filterGroupsIds, setFilterGroupsIds] = useState(null)
  const [filterStatusIds, setFilterStatusIds] = useState(null)
  const [sortFieldName, setSortFieldName] = useState('lastLoginAt')
  const [sortFieldOrder, setSortFieldOrder] = useState(-1)
  const [showLoader, setShowLoader] = useState(false)
  const currentUserGroupId = useSelector(({ main }) => main.currentUserGroupId)
  const params = new URLSearchParams(window.location.search)

  const filterItems = useMemo(() => {
    const departmentIds = users.map(({ departmentId }) => departmentId)
    const usersDepartments = departments.filter(({ _id }) => departmentIds.includes(_id))
    return [
      {
        name: t('dlg:group'),
        id: 'group',
        isChecked: filterGroupsIds !== null,
        defaultValue: false,
        items: usersDepartments.map((department) => {
          return {
            name: department.name,
            id: department._id,
            isChecked: filterGroupsIds ? filterGroupsIds.includes(department._id) : false,
            defaultValue: false
          }
        })
      },
      {
        name: t('dlg:status'),
        id: 'status',
        isChecked: filterStatusIds !== null,
        defaultValue: false,
        items: [
          { name: 'online' },
          { name: 'away' },
          { name: 'doNotDisturb' },
          { name: 'haveNotLoginYet' },
          { name: 'blocked' }
        ].map((item) => {
          return {
            name: t(`dlg:${item.name}`),
            id: item.name,
            isChecked: filterStatusIds ? filterStatusIds.includes(item.name) : false,
            defaultValue: false
          }
        })
      }
    ]
  }, [filterGroupsIds, filterStatusIds, departments, users])

  const sortItems = useMemo(() => {
    return {
      checkedId: sortFieldName,
      defaultCheckedId: 'lastLoginAt',
      items: [
        {
          title: t('dlg:lastLogin'),
          id: 'lastLoginAt',
          ascLabel: t('dlg:recently'),
          descLabel: t('dlg:longAgo'),
          order: sortFieldName === 'lastLoginAt' ? sortFieldOrder : -1,
          defaultOrder: -1
        },
        {
          title: t('dlg:createdDate'),
          id: 'createdAt',
          ascLabel: t('dlg:newestFirst'),
          descLabel: t('dlg:oldestFirst'),
          order: sortFieldName === 'createdAt' ? sortFieldOrder : -1,
          defaultOrder: -1
        }
      ]
    }
  }, [sortFieldName, sortFieldOrder])

  const updateUsers = () => {
    !users?.length && setShowLoader(true)
    Meteor.invoke('users.getUsers', {})
      .then(({ list = [] }) => {
        setUsers(list)
        applyFilters(list, filterGroupsIds, filterStatusIds, sortFieldOrder, sortFieldName)
      })
      .catch((err) => {
        processError(err, main)
      })
      .finally(() => {
        setShowLoader(false)
      })
  }

  useEffect(() => {
    if (currentUserGroupId && departments.length === 0) {
      Meteor.invoke('groups.getGroupDepartments', { groupId: currentUserGroupId })
        .then(({ items }) => {
          setDepartments(items || [])
        })
        .catch((err) => {
          processError(err, main)
        })
    }
  }, [currentUserGroupId])

  useEffect(() => {
    applyFilters(users, filterGroupsIds, filterStatusIds, sortFieldOrder, sortFieldName)
  }, [search])

  const getDepartmentName = (item) => {
    const department = departments.find((department) => department._id === item.departmentId)
    return department ? department.name : ''
  }

  const applyFilters = (list, filterGroupsIds, filterStatusIds, sortFieldOrder, sortFieldName) => {
    let filteredList = [...list]
    if (search) {
      const lowerSearch = search.toLowerCase()
      filteredList = filteredList.filter(
        (item) =>
          item.email.toLowerCase().indexOf(lowerSearch) !== -1 ||
          item.name.toLowerCase().indexOf(lowerSearch) !== -1
      )
    }
    if (filterGroupsIds) {
      filteredList = filteredList.filter((item) => filterGroupsIds.includes(item.departmentId))
    }
    if (filterStatusIds) {
      filteredList = filteredList.filter((item) => {
        const lastLoginDate = item.changedStatusAt ? moment(item.changedStatusAt).fromNow() : null
        return (
          (item.isRemoved && filterStatusIds.includes('blocked')) ||
          (!item.isRemoved &&
            lastLoginDate &&
            item.status === 'online' &&
            filterStatusIds.includes('online')) ||
          (!item.isRemoved && !lastLoginDate && filterStatusIds.includes('haveNotLoginYet')) ||
          ((item.status === 'standby' || (!item.isRemoved && lastLoginDate && item.status !== 'online')) &&
            filterStatusIds.includes('away')) ||
          (item.doNotDisturb && filterStatusIds.includes('doNotDisturb'))
        )
      })
    }
    filteredList = filteredList.sort((a, b) => {
      const m1 = a[sortFieldName] ? moment(a[sortFieldName]).unix() : 0
      const m2 = b[sortFieldName] ? moment(b[sortFieldName]).unix() : 0
      return sortFieldOrder > -1 ? m1 - m2 : m2 - m1
    })
    setFilteredUsers(filteredList)
  }

  const onSetFilter = (items) => {
    const getSelectedFilterIds = (name) => {
      let selectedIds = null
      const filter = items.find((item) => item.id === name)
      if (filter && filter.isChecked) {
        selectedIds = []
        filter.items.forEach((item) => {
          if (item.isChecked) {
            selectedIds.push(item.id)
          }
        })
      }
      return selectedIds
    }
    const selectedGroupsIds = getSelectedFilterIds('group')
    setFilterGroupsIds(selectedGroupsIds)
    const selectedStatusIds = getSelectedFilterIds('status')
    setFilterStatusIds(selectedStatusIds)
    applyFilters(users, selectedGroupsIds, selectedStatusIds, sortFieldOrder, sortFieldName)
  }

  const onSetSort = (config) => {
    const item = config.items.find((item) => item.id === config.checkedId)
    setSortFieldOrder(item.order)
    setSortFieldName(config.checkedId)
    applyFilters(users, filterGroupsIds, filterStatusIds, item.order, config.checkedId)
  }

  useTracker(() => {
    if (!currentUserGroupId) {
      return 0
    }

    const handle = Meteor.subscribe('fetchGroupMembers', currentUserGroupId)
    const loading = !handle.ready()

    if (loading) {
      return 0
    }

    updateUsers()
    Staff.find(
      {
        groupsIds: currentUserGroupId
      },
      {
        fields: {
          status: 1,
          profile: 1,
          doNotDisturb: 1
        }
      }
    ).map(({ _id, profile = {}, status, doNotDisturb }) => ({
      id: _id,
      status,
      profile,
      doNotDisturb
    }))
  }, [currentUserGroupId])

  useEffect(() => {
    if (params.get('group')) {
      setFilterGroupsIds([params.get('group')])
    }
  }, [])

  const getDepartamentLimitPersonalChats = useCallback((id) => {
    return departments.find((item) => item._id === id)?.limitPersonalChats
  })

  return (
    <div className={css('main')}>
      <div className={css('container')}>
        <div className={css('nav-bar')}>
          <NavLink className={css('link')} to={routes[ROUTE.SETTINGS]}>
            {t('dlg:dialogsSettings')}
          </NavLink>
          <div>></div>
          <div className={css('active')}>{t('dlg:agentsTeam')}</div>
        </div>
        <div className={css('header')}>{t('dlg:team')}</div>
        <div className={css('info')}>
          <div className={css('toolbox')}>
            <div className={css('toolbox-left')}>
              <Input
                icons={[<SvgIconSearch />]}
                variation='secondary'
                shaded
                placeholder={t('dlg:searchByNameOrEmail')}
                onChange={(e) => setSearch(e.target.value)}
              />
              <SortPopup config={sortItems} onDone={onSetSort} onReset={onSetSort} />
              <FiltersPopup items={filterItems} onDone={onSetFilter} onReset={onSetFilter} />
            </div>
            <div className={css('toolbox-buttons')}>
              <SecondaryButton
                view='light'
                onClick={() => setShowExport(true)}
                classes={{ inner: css('btn-icon-with-text') }}
              >
                <SecondaryButtonIcon>
                  <SvgIconExportArrow />
                </SecondaryButtonIcon>
                <SecondaryButtonText>{t('dlg:exportTeam')}</SecondaryButtonText>
              </SecondaryButton>
              <SecondaryButton
                onClick={() => setShowAdd(true)}
                classes={{ inner: css('btn-icon-with-text') }}
              >
                <SecondaryButtonIcon>
                  <SvgIconPlus />
                </SecondaryButtonIcon>
                <SecondaryButtonText>{t('dlg:newAgent')}</SecondaryButtonText>
              </SecondaryButton>
            </div>
          </div>
          <div className={css('table-header')}>
            <div>{t('dlg:teamMember')}</div>
            <div>{t('dlg:group')}</div>
            <div>{t('dlg:lastActive')}</div>
          </div>
          {showLoader && (
            <div className={css('spinner')}>
              <Spinner strokeWidth={5} />
            </div>
          )}
          {!showLoader && (
            <>
              <div>
                {filteredUsers.map((item) => {
                  const groupName = getDepartmentName(item)
                  const lastLoginDate = item.changedStatusAt ? moment(item.changedStatusAt).fromNow() : null

                  return (
                    <div key={item.userId} className={css('item')}>
                      <div className={css(classnames(['item-info', (!item.isRemoved && !item.isBlocked) ? null : '--empty']))}>
                        <Avatar url={item.avatarUrl} />
                        <div>
                          <div className={css('item-name')}>{item.name}</div>
                          <div className={css('item-email')}>{item.email}</div>
                        </div>
                      </div>
                      <div
                        className={css(
                          classnames('item-group', groupName && (!item.isRemoved && !item.isBlocked) ? null : '--empty')
                        )}
                      >
                        <div>{groupName || t('dlg:noGroup')}</div>
                        <div>
                          <Tag color={item.chatDistributionLimit ? 'blue' : 'gray'} variation='secondary'>
                            {t('dlg:countChatsSearch_plural', {
                              count:
                                item.chatDistributionLimit ||
                                getDepartamentLimitPersonalChats(item.departmentId) ||
                                '∞'
                            })}
                          </Tag>
                        </div>
                      </div>
                      <div>
                        {(item.isRemoved || item.isBlocked) && (
                          <Tag color='red' variation='secondary' view='filled'>
                            {t('dlg:blocked')}
                          </Tag>
                        )}
                        {!item.isRemoved && !item.isBlocked && lastLoginDate && (
                          <Tag
                            color={item.status === 'online' ? 'green' : 'gray'}
                            variation='secondary'
                            view='filled'
                          >
                            {item.status === 'online' ? t('dlg:online') : lastLoginDate}
                          </Tag>
                        )}
                        {!item.isRemoved && !item.isBlocked && !lastLoginDate && (
                          <div className={css(['item-group', '--empty'])}>{t('dlg:haveNotLoginYet')}</div>
                        )}
                        {item.status === 'standby' && !item.isBlocked && (
                          <Tag color='yellow' variation='secondary'>
                            {t('dlg:away')}
                          </Tag>
                        )}
                        {item.doNotDisturb && !item.isBlocked && (
                          <Tag color='red' variation='secondary'>
                            {t('dlg:doNotDisturb')}
                          </Tag>
                        )}
                      </div>
                      <div className={css('item-buttons')}>
                        <SecondaryButton
                          view='light'
                          disabled={item.isRemoved || item.isBlocked}
                          onClick={() => setEditMember(item)}
                        >
                          <SecondaryButtonIcon>
                            <SvgIconEdit />
                          </SecondaryButtonIcon>
                          <SecondaryButtonText>{t('dlg:edit')}</SecondaryButtonText>
                        </SecondaryButton>
                        {!item.isBlocked && (
                          <SecondaryButton view='light' onClick={() => setBlockMember(item)}>
                            <SecondaryButtonText>{t('dlg:block')}</SecondaryButtonText>
                          </SecondaryButton>
                        )}
                        {item.isBlocked && (
                          <SecondaryButton view='light' onClick={() => setUnblockMember(item)}>
                            <SecondaryButtonText>{t('dlg:unblock')}</SecondaryButtonText>
                          </SecondaryButton>
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className={css('table-footer')}>
                <div className={css('count-members')}>
                  {t('dlg:countMembers', { count: filteredUsers.length })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {blockMember && (
        <BlockMember
          onCancel={() => setBlockMember(null)}
          onBlocked={() => {
            setBlockMember(null)
            updateUsers()
          }}
          member={blockMember}
        />
      )}
      {unblockMember && (
        <UnblockMember
          onCancel={() => setUnblockMember(null)}
          onUnblocked={() => {
            setUnblockMember(null)
            updateUsers()
          }}
          member={unblockMember}
        />
      )}
      {showExport && (
        <Export
          departments={departments}
          users={filteredUsers}
          onCancel={() => setShowExport(false)}
          onExported={() => setShowExport(false)}
        />
      )}
      {showAdd && (
        <AddMember
          groupId={currentUserGroupId}
          departments={departments}
          onCancel={() => setShowAdd(false)}
          onAdded={() => {
            setShowAdd(false)
            updateUsers()
          }}
        />
      )}
      {editMember && (
        <EditMember
          departments={departments}
          onCancel={() => setEditMember(null)}
          onEdited={() => {
            setEditMember(null)
            updateUsers()
          }}
          user={editMember}
        />
      )}
    </div>
  )
}
