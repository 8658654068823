import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonText } from '@flomni/components/dist/components/plate-button/text'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { SvgIconExportArrow } from '@flomni/components/dist/components/svg/feathers/SvgIconExportArrow'
import { Trans, useTranslation } from 'react-i18next'
import { Tag } from '@flomni/components/dist/components/tag'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { SecondaryButtonIcon } from '@flomni/components/dist/components/secondary-button/icon'
import { SvgIconPlus } from '@flomni/components/dist/components/svg/feathers/SvgIconPlus'
import { SvgIconBookmarkFilled } from '@flomni/components/dist/components/svg/feathers/SvgIconBookmarkFilled'
import { SvgIconSearch } from '@flomni/components/dist/components/svg/feathers/SvgIconSearch'
import { array, func } from 'prop-types'
import { useHistory } from 'react-router-dom'
import { ROUTE, routes } from '../../../../../../../../../configs/routes'
import DialogTemplate from '../../../../../../settings/templates/dialog-template'
import classnames from 'classnames'
import { isMobileMode } from '../../../../../../../../../services/helpers'

export const TemplatesList = ({ onTemplateSelected, templates, actions, allTemplates, onAddedTemplate }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const history = useHistory()
  const [showAddTemplate, setShowAddTemplate] = useState(false)

  return (
    <div className={css(classnames('container', isMobileMode ? '--mobile' : null))}>
      <div className={css('header')}>
        <div className={css('title')}>{t('dlg:templates')}</div>
        {!isMobileMode && (
          <PlateButton
            view='usual'
            onClick={() => {
              history.push(routes[ROUTE.MESSAGE_TEMPLATES])
            }}
          >
            <PlateButtonIcon>
              <SvgIconExportArrow />
            </PlateButtonIcon>
            <PlateButtonText>{t('dlg:editTemplates')}</PlateButtonText>
          </PlateButton>
        )}
      </div>
      {allTemplates.length === 0 && (
        <div className={css('no-items')}>
          <div className={css('no-items-header')}>
            {!isMobileMode && (
              <div className={css('no-items-icon')}>
                <SvgIconBookmarkFilled width={16} height={20} />
              </div>
            )}
            <div>
              <div className={css('no-items-title')}>{t('dlg:youDontHaveTemplates')}</div>
              <div className={css('no-items-description')}>
                <Trans
                  i18nKey='dlg:createTemplatePath'
                  components={[0, <span className={css('no-items-description-mid')}>0</span>, 1]}
                />
              </div>
            </div>
          </div>
          {!isMobileMode && (
            <SecondaryButton
              classes={{ root: css('no-items-button') }}
              onClick={() => setShowAddTemplate(true)}
            >
              <SecondaryButtonIcon>
                <SvgIconPlus />
              </SecondaryButtonIcon>
              <SecondaryButtonText>{t('dlg:newTemplate')}</SecondaryButtonText>
            </SecondaryButton>
          )}
        </div>
      )}
      {templates.length > 0 && (
        <div className={css('list')}>
          {templates.map((item) => (
            <div key={item.id} className={css('item')} onClick={() => onTemplateSelected(item)}>
              <div className={css('item-command')}>/{item.title || item.name}</div>
              <div>
                <div className={css('item-text')}>{item.text}</div>
                {item.attachments && (
                  <div className={css('item-buttons')}>
                    {item.attachments.map((attachment) => {
                      return attachment.type === 'button' ? (
                        <Tag
                          key={attachment.id}
                          variation='secondary'
                          view='stroked'
                          color='gray'
                          classes={{ root: css('item-button') }}
                        >
                          {attachment.name}
                        </Tag>
                      ) : null
                    })}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      {templates.length === 0 && allTemplates.length > 0 && (
        <div className={css('no-results')}>
          <SvgIconSearch />
          <div className={css('no-results-text')}>{t('dlg:noSearchResults')}</div>
        </div>
      )}
      {showAddTemplate && (
        <DialogTemplate
          actions={actions}
          onCancel={() => setShowAddTemplate(false)}
          onFinished={() => {
            setShowAddTemplate(false)
            onAddedTemplate()
          }}
        />
      )}
    </div>
  )
}

TemplatesList.propTypes = {
  actions: array,
  templates: array,
  allTemplates: array,
  onTemplateSelected: func.isRequired,
  onAddedTemplate: func.isRequired
}
