import React, { useCallback, useEffect, useState } from 'react'
import { Dropdown } from '@flomni/components/dist/components/dropdown'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonText } from '@flomni/components/dist/components/plate-button/text'
import { bool, func, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { DateUtils } from '@flomni/modules/dist/services/date'
import { DatePicker } from '@flomni/components/dist/components/date-picker'
import { Localization } from '../../../../../../../../services/localization'

export const TimeSelector = ({ time, onChange, disablePastHours }) => {
  const { t } = useTranslation()
  const [showSelector, setShowSelector] = useState(false)
  const [selectedTime, setSelectedTime] = useState(time)

  const onOverlaySelectorHandler = useCallback(() => {
    setShowSelector(false)
  }, [])

  const onSelectTime = (date) => {
    setShowSelector(false)
    setSelectedTime(date.getHours())
  }

  const getFormattedTime = (hours) => {
    return moment().startOf('day').add(hours, 'hours').format(DateUtils.TIME_FORMAT)
  }

  const filterTime = (date) => {
    return disablePastHours ? new Date(date).getTime() > new Date().getTime() : true
  }

  useEffect(() => {
    if (selectedTime !== undefined) {
      onChange(selectedTime)
    }
  }, [selectedTime])

  useEffect(() => {
    if (time !== selectedTime) {
      setSelectedTime(time)
    }
  }, [time])

  return (
    <div>
      <PlateButton view={selectedTime ? 'usual' : 'bright'} onClick={() => setShowSelector(true)}>
        <PlateButtonText>
          {selectedTime ? getFormattedTime(parseInt(selectedTime)) : t('dlg:selectTime')}
        </PlateButtonText>
      </PlateButton>
      <Dropdown
        active={showSelector}
        variation='secondary'
        offsetTarget='iconButton'
        onOverlayClick={onOverlaySelectorHandler}
      >
        <DatePicker
          variation='secondary'
          selected={
            selectedTime ? moment().startOf('day').add(parseInt(selectedTime), 'hours').toDate() : null
          }
          onChange={onSelectTime}
          locale={Localization.getDatePickerLocale()}
          inline
          timeCaption={null}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={60}
          filterTime={filterTime}
        />
      </Dropdown>
    </div>
  )
}

TimeSelector.propTypes = {
  disablePastHours: bool,
  time: string,
  onChange: func.isRequired
}
