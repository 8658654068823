import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { array, bool, func, object, string } from 'prop-types'
import { Timeline } from './timeline'
import { DateUtils } from '@flomni/modules/dist/services/date'
import { CopyField } from '../../../../../shared/copy-field'
import { Spinner } from '@flomni/components/dist/components/spinner'
import { ChannelItem } from '../../../../../shared/channel-item'
import { SvgIconInfo } from '@flomni/components/dist/components/svg/feathers/SvgIconInfo'
import { Tooltip } from '@flomni/components/dist/components/tooltip'
import { InfoTags } from './info-tags'
import { InfoCrmField } from './info-crm-field'

export const ProfileTab = ({
  crmFields,
  receiver,
  comments,
  recipient,
  metaDataLoading,
  onCrmFieldUpdated,
  onTagsUpdated
}) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const [showFilledFields, setShowFilledFields] = useState(true)

  let messengers = []
  if (recipient?.messengers?.length) {
    messengers = [...recipient.messengers].sort((a, b) =>
      new Date(a.lastContactIn).valueOf() > new Date(b.lastContactIn).valueOf() ? -1 : 1
    )
  }

  const ChannelsTooltipContent = ({ items }) => {
    return (
      <>
        <div className={css('channels-header')}>{t('dlg:previousActiveChannels')}</div>
        <div>
          <div className={css(['channels-item', '--header'])}>
            <div className={css('channels-item-value')}>{t('dlg:channel').toUpperCase()}</div>
            <div className={css('channels-item-value')}>{t('dlg:lastActive').toUpperCase()}</div>
          </div>
          {items.map((item) => (
            <div key={item._id} className={css('channels-item')}>
              <div className={css('channels-item-value')}>
                <ChannelItem messenger={item.messenger} />
              </div>
              <div className={css('channels-item-value')}>
                {DateUtils.format(item.lastContactIn, DateUtils.DATE_TIME_FORMAT)}
              </div>
            </div>
          ))}
        </div>
      </>
    )
  }

  return (
    <div className={css('container')}>
      <div className={css('block')}>
        {metaDataLoading && (
          <div className={css('loader')}>
            <div className={css('spinner')}>
              <Spinner strokeWidth={5} />
            </div>
          </div>
        )}
        {!metaDataLoading && (
          <>
            {recipient && recipient.profile && recipient.profile.name && (
              <div className={css('info-item')}>
                <div className={css('info-item-title')}>{t('dlg:profileName')}</div>
                <div className={css('info-item-value')}>{recipient.profile.name}</div>
              </div>
            )}
            {recipient &&
              Array.isArray(recipient.transactions) &&
              recipient.transactions.map((field) =>
                field &&
                field.type === 'phoneNum' &&
                (!showFilledFields ||
                  (showFilledFields && field.value !== undefined && field.value !== null)) ? (
                  <div className={css('info-item')}>
                    <div className={css('info-item-title')}>{t('dlg:phoneNum')}</div>
                    <div className={css('info-item-value')}>{field.value}</div>
                  </div>
                ) : null
              )}
            {(!showFilledFields || (showFilledFields && !!recipient?.system?.session?.till)) && (
              <div className={css('info-item')}>
                <div className={css('info-item-title')}>{t('dlg:lastOnline')}</div>
                <div className={css('info-item-value')}>
                  {recipient?.system?.session?.till ? (
                    DateUtils.format(recipient.system.session.till, DateUtils.DATE_TIME_FORMAT)
                  ) : (
                    <span className={css('no-data')}>{t('dlg:noData')}</span>
                  )}
                </div>
              </div>
            )}
            {(!showFilledFields || (showFilledFields && !!recipient?.system?.lastKnownIp)) && (
              <div className={css('info-item')}>
                <div className={css('info-item-title')}>{t('dlg:location')}</div>
                <div className={css('info-item-value')}>
                  {recipient?.system?.lastKnownIp ?? (
                    <span className={css('no-data')}>{t('dlg:noData')}</span>
                  )}
                </div>
              </div>
            )}
            {(!showFilledFields || (showFilledFields && !!recipient?.tags?.length)) && (
              <InfoTags tagIds={recipient?.tags || []} onUpdated={onTagsUpdated} />
            )}
            <div className={css('info-item')}>
              <div className={css('info-item-title')}>{t('dlg:receiverId')}</div>
              <div className={css('info-item-value')}>
                <CopyField value={receiver} />
              </div>
            </div>
            {messengers.length > 0 && (
              <div className={css('info-item')}>
                <div className={css(['info-item-title', '--with-icon'])}>
                  <div>{t('dlg:activeChannel')}</div>
                  <div>
                    {messengers.length > 1 && (
                      <Tooltip
                        variation='secondary'
                        content={<ChannelsTooltipContent items={messengers.splice(1)} />}
                      >
                        <div className={css('info-item-icon')}>
                          <SvgIconInfo />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
                <div className={css('info-item-value')}>
                  <ChannelItem messenger={messengers[0].messenger} />
                </div>
              </div>
            )}
            {messengers.find((m) => ['wab', 'waba', 'vb'].includes(m.messenger)) && (
              <div className={css('info-item')}>
                <div className={css('info-item-title')}>{t('dlg:phoneNum')}</div>
                <div className={css('info-item-value')}>
                  <CopyField
                    value={messengers.find((m) => ['wab', 'waba', 'vb'].includes(m.messenger)).mUid}
                  />
                </div>
              </div>
            )}
            {(!showFilledFields || (showFilledFields && !!recipient?.system?.language)) && (
              <div className={css('info-item')}>
                <div className={css('info-item-title')}>{t('dlg:language')}</div>
                <div className={css('info-item-value')}>
                  {recipient?.system?.language ?? <span className={css('no-data')}>{t('dlg:noData')}</span>}
                </div>
              </div>
            )}
            {(!showFilledFields || (showFilledFields && !!recipient?.threadName)) && (
              <div className={css('info-item')}>
                <div className={css('info-item-title')}>{t('dlg:thread')}</div>
                <div className={css('info-item-value')}>
                  {recipient?.threadName ?? <span className={css('no-data')}>{t('dlg:noData')}</span>}
                </div>
              </div>
            )}
            {crmFields.map((field) =>
              !showFilledFields || (showFilledFields && !field.hidden) ? (
                <InfoCrmField key={field._id || field.name} field={field} onUpdated={onCrmFieldUpdated} />
              ) : null
            )}
            {!showFilledFields && (
              <div className={css(['link', 'info-items-btn'])} onClick={() => setShowFilledFields(true)}>
                {t('dlg:showLess')}
              </div>
            )}
            {showFilledFields && (
              <div className={css(['link', 'info-items-btn'])} onClick={() => setShowFilledFields(false)}>
                {t('dlg:showMore')}
              </div>
            )}
          </>
        )}
      </div>
      <div className={css('block')}>
        <Timeline comments={comments} recipient={recipient} />
      </div>
    </div>
  )
}

ProfileTab.propTypes = {
  metaDataLoading: bool,
  crmFields: array,
  comments: array,
  recipient: object,
  receiver: string,
  onCrmFieldUpdated: func.isRequired,
  onTagsUpdated: func.isRequired
}
