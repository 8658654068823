import React, { useState } from 'react'
import { Meteor } from '../../../../../../../meteorAdapter';
import { useDispatch } from 'react-redux'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { Popup } from '@flomni/components/dist/components/popup/popup'
import { PopupButtons } from '@flomni/components/dist/components/popup/buttons'
import { PopupContent } from '@flomni/components/dist/components/popup/content'
import { PopupTitle } from '@flomni/components/dist/components/popup/title'
import { array, func, object } from 'prop-types'
import { useForm } from 'react-hook-form'
import { ControlContainer } from '@flomni/components/dist/components/control-container'
import { Input } from '@flomni/components/dist/components/input'
import { RadioOption } from '@flomni/components/dist/components/radio-option'
import { BUTTON_KIND } from '../../../../../../services/constants'
import { Select } from '@flomni/components/dist/components/select'
import { ButtonWithLoader } from '../../../../../shared/button-with-loader'
import { processError } from '../../../../../../services/helpers'

const ButtonForm = ({ button, actions, onSaved, onCancel }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const [showSaveLoader, setShowSaveLoader] = useState(false)

  const { register, formState, errors, watch, setValue, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: button?.name ?? '',
      value: button?.kind === BUTTON_KIND.CUSTOM ? button?.value : null,
      description: button?.description ?? '',
      kind: button?.kind ?? BUTTON_KIND.CUSTOM,
      url: button?.kind === BUTTON_KIND.URL ? button?.value : ''
    }
  })

  const kind = watch('kind')
  const value = watch('value')

  const actionOptions = actions.map((action) => {
    return {
      label: action.name,
      value: action.actionId
    }
  })
  const selectedActionOption = actionOptions.find((option) => option.value === value) || null

  const handlerClickSave = async (e) => {
    try {
      e.preventDefault()
      const values = getValues()
      const data = {
        name: values.name,
        value: values.kind === BUTTON_KIND.CUSTOM ? values.value : values.url,
        description: values.description,
        kind: values.kind
      }
      setShowSaveLoader(true)
      await new Promise((resolve) => setTimeout(resolve, 800))
      if (button) {
        await Meteor.invoke('users.updateUserButton', {
          ...data,
          id: button.id
        })
      } else {
        await Meteor.invoke('users.addUserButton', data)
      }
      setShowSaveLoader(false)
      onSaved()
    } catch (err) {
      setShowSaveLoader(false)
      processError(err, main)
    }
  }

  const handlerClickOnCancel = () => {
    onCancel()
  }

  return (
    <Popup open variation='secondary'>
      <PopupTitle title={t('dlg:addNewButton')} description={t('dlg:enterNameTypeDescription')} />
      <PopupContent classes={{ root: css('content') }}>
        <div className={css('group')}>
          <div className={css('title')}>{t('dlg:buttonName')}</div>
          <ControlContainer variation='secondary' warning={errors.name ? errors.name.message : null}>
            <Input
              variation='secondary'
              classes={{ root: css('field') }}
              placeholder={t('dlg:buttonName')}
              warning={!!errors.name}
              name='name'
              ref={register({
                required: t('dlg:nameRequired')
              })}
            />
          </ControlContainer>
        </div>
        <div className={css('group')}>
          <div className={css('title')}>{t('dlg:buttonType')}</div>
          <input hidden name='kind' ref={register()} />
          <div className={css('radio-group')}>
            <RadioOption
              variation='secondary'
              onChange={() =>
                setValue('kind', BUTTON_KIND.CUSTOM, { shouldDirty: true, shouldValidate: true })
              }
              checked={kind === BUTTON_KIND.CUSTOM}
            >
              {t('dlg:action')}
            </RadioOption>
            <RadioOption
              variation='secondary'
              onChange={() => setValue('kind', BUTTON_KIND.URL, { shouldDirty: true, shouldValidate: true })}
              checked={kind === BUTTON_KIND.URL}
            >
              {t('dlg:url')}
            </RadioOption>
          </div>
          {kind === BUTTON_KIND.URL && (
            <ControlContainer variation='secondary' warning={errors.url ? errors.url.message : null}>
              <Input
                variation='secondary'
                classes={{ root: css('field') }}
                placeholder={t('dlg:enterUrl')}
                warning={!!errors.url}
                name='url'
                ref={register({
                  required: t('dlg:urlRequired'),
                  pattern: {
                    value:
                      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/i,
                    message: t('dlg:invalidUrl')
                  }
                })}
              />
            </ControlContainer>
          )}
          {kind === BUTTON_KIND.CUSTOM && (
            <>
              <input hidden name='value' ref={register({ required: true })} />
              <div className={css('field')}>
                <Select
                  variation='secondary'
                  options={actionOptions}
                  value={selectedActionOption}
                  menuPortalTarget={document.body}
                  placeholder={t('dlg:selectChatbotEnterPoint')}
                  onChange={(option) =>
                    setValue('value', option.value, { shouldDirty: true, shouldValidate: true })
                  }
                />
              </div>
            </>
          )}
        </div>
        <div className={css('group')}>
          <div className={css('title')}>{t('dlg:description')}</div>
          <Input
            textArea
            variation='secondary'
            classes={{ root: css('field-textarea') }}
            placeholder={t('dlg:typeDescriptionText')}
            warning={!!errors.description}
            name='description'
            ref={register()}
          />
        </div>
      </PopupContent>
      <PopupButtons>
        <SecondaryButton view='light' onClick={handlerClickOnCancel}>
          <SecondaryButtonText>{t('dlg:cancel')}</SecondaryButtonText>
        </SecondaryButton>
        <ButtonWithLoader
          text={button ? t('dlg:update') : t('dlg:addButton')}
          onClick={handlerClickSave}
          disabled={!formState.isValid || !formState.isDirty}
          spinnerSize={10}
          spinnerStrokeWidth={14}
          isLoading={showSaveLoader}
        />
      </PopupButtons>
    </Popup>
  )
}

ButtonForm.propTypes = {
  button: object,
  actions: array,
  onSaved: func.isRequired,
  onCancel: func.isRequired
}

export default ButtonForm
