import React, { useEffect, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { object } from 'prop-types'
import { Spinner } from '@flomni/components/dist/components/spinner'

export const AttachmentImage = ({ file }) => {
  const css = useStyles(styles)
  const [src, setSrc] = useState(null)
  const [loading, setLoading] = useState(false)
  const isImage = /^image\//g.test(file.type)
  const isVideo = /^video\//g.test(file.type)

  useEffect(() => {
    const reader = new FileReader()
    reader.onload = (e) => {
      setSrc(e.target.result)
    }
    setLoading(true)
    reader.readAsDataURL(file)
  }, [file])

  const onLoaded = () => {
    setLoading(false)
  }

  return (
    <>
      {loading && (
        <div className={css('loader')}>
          <div className={css('spinner')}>
            <Spinner strokeWidth={5} />
          </div>
        </div>
      )}
      {src && (
        <div className={css('container')}>
          {isImage && <img onLoad={onLoaded} className={css('content')} src={src} />}
          {isVideo && (
            <video
              className={css('content')}
              autoPlay
              loop
              preload='auto'
              muted
              playsInline
              onLoadedData={onLoaded}
            >
              <source src={src} type='video/mp4' />
            </video>
          )}
        </div>
      )}
    </>
  )
}

AttachmentImage.propTypes = {
  file: object.isRequired
}
