import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { Input } from '@flomni/components/dist/components/input'
import { ControlContainer } from '@flomni/components/dist/components/control-container'
import { Popup } from '@flomni/components/dist/components/popup/popup'
import { PopupButtons } from '@flomni/components/dist/components/popup/buttons'
import { PopupContent } from '@flomni/components/dist/components/popup/content'
import { PopupTitle } from '@flomni/components/dist/components/popup/title'

import { func, object } from 'prop-types'
import { useForm } from 'react-hook-form'

const EditLocation = ({ location, onEdited, onCancel }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()

  const { register, formState, errors, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: location.name,
      lat: location.lat,
      lng: location.lng
    }
  })

  const handlerClickSave = async (event) => {
    event.preventDefault()
    const values = getValues()
    onEdited(values.name, values.lat, values.lng)
  }

  const handlerClickOnCancel = (event) => {
    event.preventDefault()
    onCancel()
  }

  return (
    <Popup open variation='secondary'>
      <PopupTitle title={t('dlg:editAttachedLocation')} />
      <form>
        <PopupContent classes={{ root: css('content') }}>
          <div className={css('line')}>
            <div className={css('title')}>{t('dlg:locationName')}</div>
            <ControlContainer variation='secondary' warning={errors.name ? errors.name.message : null}>
              <Input
                variation='secondary'
                classes={{ root: css('field') }}
                placeholder={t('dlg:locationName')}
                warning={!!errors.name}
                name='name'
                ref={register({ required: t('dlg:locationNameRequired') })}
              />
            </ControlContainer>
          </div>
          <div className={css('line')}>
            <div className={css('title')}>{t('dlg:latitude')}</div>
            <div>
              <ControlContainer variation='secondary' warning={errors.lat ? errors.lat.message : null}>
                <Input
                  variation='secondary'
                  classes={{ root: css('field') }}
                  placeholder={t('dlg:latitude')}
                  warning={!!errors.lat}
                  name='lat'
                  ref={register({ required: t('dlg:latitudeIsRequired') })}
                />
              </ControlContainer>
            </div>
          </div>
          <div className={css('line')}>
            <div className={css('title')}>{t('dlg:longitude')}</div>
            <div>
              <ControlContainer variation='secondary' warning={errors.lng ? errors.lng.message : null}>
                <Input
                  variation='secondary'
                  classes={{ root: css('field') }}
                  placeholder={t('dlg:longitude')}
                  warning={!!errors.lng}
                  name='lng'
                  ref={register({ required: t('dlg:longitudeIsRequired') })}
                />
              </ControlContainer>
            </div>
          </div>
        </PopupContent>
        <PopupButtons>
          <SecondaryButton view='light' onClick={handlerClickOnCancel}>
            <SecondaryButtonText>{t('dlg:close')}</SecondaryButtonText>
          </SecondaryButton>
          <SecondaryButton
            view='usual'
            onClick={handlerClickSave}
            disabled={!formState.isValid || !formState.isDirty}
          >
            <SecondaryButtonText>{t('dlg:save')}</SecondaryButtonText>
          </SecondaryButton>
        </PopupButtons>
      </form>
    </Popup>
  )
}

EditLocation.propTypes = {
  location: object.isRequired,
  onEdited: func.isRequired,
  onCancel: func.isRequired
}

export default EditLocation
