import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { array, func, object } from 'prop-types'
import { Tag } from '@flomni/components/dist/components/tag'
import { SecondaryButtonIcon } from '@flomni/components/dist/components/secondary-button/icon'
import { SvgIconEdit } from '@flomni/components/dist/components/svg/feathers/SvgIconEdit'
import { SvgIconDelete } from '@flomni/components/dist/components/svg/feathers/SvgIconDelete'
import RemoveButtonForm from '../remove-button-form'
import ButtonForm from '../button-form'

const ButtonItem = ({ button, actions, onRemove, onEdit }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const [showRemoveForm, setShowRemoveForm] = useState(false)
  const [showEditForm, setShowEditForm] = useState(false)

  return (
    <div className={css('button')}>
      {!showRemoveForm && !showEditForm && (
        <>
          <div className={css('button-name')}>
            <Tag color='gray' variation='secondary' view='stroked'>
              {button.name}
            </Tag>
          </div>
          <div className={css('button-controls')}>
            <SecondaryButton
              view='light'
              onClick={() => {
                setShowEditForm(true)
              }}
            >
              <SecondaryButtonIcon>
                <SvgIconEdit />
              </SecondaryButtonIcon>
              <SecondaryButtonText>{t('dlg:edit')}</SecondaryButtonText>
            </SecondaryButton>
            <SecondaryButton
              view='light'
              onClick={() => {
                setShowRemoveForm(true)
              }}
            >
              <SecondaryButtonIcon>
                <SvgIconDelete />
              </SecondaryButtonIcon>
              <SecondaryButtonText>{t('dlg:remove')}</SecondaryButtonText>
            </SecondaryButton>
          </div>
        </>
      )}
      {showEditForm && (
        <ButtonForm
          onSave={(fields) => {
            setShowEditForm(false)
            onEdit(fields)
          }}
          onCancel={() => {
            setShowEditForm(false)
          }}
          button={button}
          actions={actions}
        />
      )}
      {showRemoveForm && (
        <RemoveButtonForm onCancel={() => setShowRemoveForm(false)} onRemove={onRemove} button={button} />
      )}
    </div>
  )
}

ButtonItem.propTypes = {
  button: object,
  actions: array,
  onRemove: func.isRequired,
  onEdit: func.isRequired
}

export default ButtonItem
