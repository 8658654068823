import { Meteor, useTracker } from '../../../../meteorAdapter';
import { useIdleTimer } from 'react-idle-timer'
import { USER_STATUSES } from '../../../../constants'
import { object, func } from 'prop-types'
import { useEmptyQueue } from '../../dialogs/shared/useEmptyQueue'

export const UserIdleTimer = ({ emptyQueue, setEmptyQueue }) => {
  const USER_IDLE_TIMEOUT = 0.5 * 60 * 1000 // 30 secs

  const status = useTracker(() => {
    const user = Meteor.user()
    return user?.status
  }, [])

  const updateUserPresence = (status) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { emptyQueueTimeMs, emptyQueueNoActiveChatsMs, emptyQueueSlotsAvailableMs} = useEmptyQueue(emptyQueue, setEmptyQueue)
    Meteor.invoke('users.updateUserPresence', {
      status: status,
      emptyQueueTimeMs,
      emptyQueueNoActiveChatsMs,
      emptyQueueSlotsAvailableMs
    })
  }

  const handleActive = () => {
    if (typeof status === 'string' && status !== USER_STATUSES.ONLINE) {
      updateUserPresence('online')
    }
  }

  const handleOnIdle = () => {
    if (typeof status === 'string' && status !== USER_STATUSES.STANDBY) {
      updateUserPresence('away')
    }
  }

  useIdleTimer({
    timeout: USER_IDLE_TIMEOUT,
    onIdle: handleOnIdle,
    onActive: handleActive,
    debounce: 250
  })

  return null
}

UserIdleTimer.propTypes = {
  emptyQueue: object.isRequired,
  setEmptyQueue: func.isRequired
}
