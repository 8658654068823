import React from 'react'

export const TwoArrowsIcon = () => (
  <svg width={14} height={14} viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3 7L5.33333 9L10 5'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8 9L12.6667 5'
      stroke='currentColor'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export const OneArrowIcon = () => (
  <svg width={14} height={14} viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3 7L5.33333 9L10 5'
      stroke='#A3ACB9'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export const SendingIcon = () => (
  <svg width={14} height={14} viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6.43404 6.62028C7.42801 5.39194 8.55466 4 10.2571 4C11.6889 4 12.3586 4.73363 12.6682 5.34872C12.8886 5.78672 13.0002 6.31058 13 6.9061C13 7.84112 12.3649 9.75612 10.4009 9.75612C8.78514 9.75612 7.92411 8.71997 7.64083 8.37907L7.64073 8.37896L8.38747 7.46951C8.44179 7.516 8.48245 7.56496 8.53892 7.63302C8.7562 7.89448 9.33319 8.58865 10.4011 8.58865C10.9315 8.58865 11.2984 8.36674 11.5569 7.89C11.7836 7.4715 11.8326 7.00675 11.8326 6.91409C11.8328 6.75249 11.8329 6.28606 11.6256 5.87387C11.3862 5.39889 10.9385 5.16771 10.2571 5.16771C9.112 5.16771 8.25215 6.22992 7.34196 7.35432L7.34157 7.35479C6.35751 8.57084 5.33974 9.82818 3.80609 9.82818C2.15245 9.82818 1 8.26872 1 6.86911C1 5.51224 2.20054 4.00008 3.92311 4.00008C5.20923 4.00008 6.01249 4.77515 6.3476 5.18541L5.53209 6.04258C5.50477 5.99992 4.96301 5.16763 3.92311 5.16763C2.85142 5.16763 2.16763 6.1755 2.16763 6.86903C2.16763 7.61705 2.79095 8.66046 3.80617 8.66046C4.78288 8.66046 5.58481 7.66961 6.43404 6.62028Z'
      fill='#A3ACB9'
    />
  </svg>
)

export const NotSentIcon = () => (
  <svg width={14} height={14} viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.63397 3.5C6.01887 2.83333 6.98113 2.83333 7.36603 3.5L10.8301 9.5C11.215 10.1667 10.7339 11 9.9641 11H3.0359C2.2661 11 1.78497 10.1667 2.16987 9.5L5.63397 3.5Z'
      stroke='#ED5F74'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <rect x={6} y={5.5} width={1} height={2.8} rx={0.5} fill='#ED5F74' />
    <rect x={6} y={8.69995} width={1} height={1} rx={0.5} fill='#ED5F74' />
  </svg>
)
