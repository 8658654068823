import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { bool, func, object } from 'prop-types'
import { ButtonWithLoader } from '../../../../../../../../shared/button-with-loader'
import { SvgIconUser } from '@flomni/components/dist/components/svg/feathers/SvgIconUser'

const GroupItem = ({ item, onTransfer, showLoader, isDisabled }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()

  return (
    <div className={css('container')}>
      <div className={css('group-name')}>
        <div className={css('name')}>{item.name}</div>
        <div className={css('users-count-block')}>
          <SvgIconUser height='10px' width='10px' />
          <span className={css('users-count')}>{item._nMembers && `${item.onlineCount}/${item._nMembers}`}</span>
        </div>
      </div>
      <ButtonWithLoader
        view='usual'
        text={t('dlg:transfer')}
        onClick={onTransfer}
        spinnerSize={10}
        spinnerStrokeWidth={14}
        isLoading={showLoader}
        disabled={isDisabled}
      />
    </div>
  )
}

GroupItem.propTypes = {
  item: object,
  showLoader: bool,
  isDisabled: bool,
  onTransfer: func
}

export default GroupItem
