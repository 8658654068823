import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { Input } from '@flomni/components/dist/components/input'
import { ControlContainer } from '@flomni/components/dist/components/control-container'
import { Popup } from '@flomni/components/dist/components/popup/popup'
import { PopupButtons } from '@flomni/components/dist/components/popup/buttons'
import { PopupContent } from '@flomni/components/dist/components/popup/content'
import { PopupTitle } from '@flomni/components/dist/components/popup/title'

import { func } from 'prop-types'
import { useForm } from 'react-hook-form'

const AddContact = ({ onAdded, onCancel }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()

  const { register, formState, errors, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      phone: ''
    }
  })

  const handlerClickAttach = async (event) => {
    event.preventDefault()
    const values = getValues()
    onAdded(values.name, values.phone)
  }

  const handlerClickOnCancel = (event) => {
    event.preventDefault()
    onCancel()
  }

  return (
    <Popup open variation='secondary'>
      <PopupTitle title={t('dlg:attachContact')} />
      <form>
        <PopupContent classes={{ root: css('content') }}>
          <div className={css('line')}>
            <div className={css('title')}>{t('dlg:fullName')}</div>
            <ControlContainer variation='secondary' warning={errors.name ? errors.name.message : null}>
              <Input
                variation='secondary'
                classes={{ root: css('field') }}
                placeholder={t('dlg:fullName')}
                warning={!!errors.name}
                name='name'
                ref={register({ required: t('dlg:fullNameRequired') })}
              />
            </ControlContainer>
          </div>
          <div className={css('line')}>
            <div className={css('title')}>{t('dlg:phoneNumber')}</div>
            <ControlContainer variation='secondary' warning={errors.phone ? errors.phone.message : null}>
              <Input
                variation='secondary'
                classes={{ root: css('field') }}
                placeholder={t('dlg:phoneNumber')}
                warning={!!errors.phone}
                name='phone'
                ref={register({
                  required: t('dlg:phoneNumberRequired'),
                  pattern: {
                    value: /^[+]?[0-9]{5,15}$/,
                    message: t('dlg:phoneNumberIsInvalid')
                  }
                })}
              />
            </ControlContainer>
          </div>
        </PopupContent>
        <PopupButtons>
          <SecondaryButton view='light' onClick={handlerClickOnCancel}>
            <SecondaryButtonText>{t('dlg:close')}</SecondaryButtonText>
          </SecondaryButton>
          <SecondaryButton
            view='usual'
            onClick={handlerClickAttach}
            disabled={!formState.isValid || !formState.isDirty}
          >
            <SecondaryButtonText>{t('dlg:attach')}</SecondaryButtonText>
          </SecondaryButton>
        </PopupButtons>
      </form>
    </Popup>
  )
}

AddContact.propTypes = {
  onAdded: func.isRequired,
  onCancel: func.isRequired
}

export default AddContact
