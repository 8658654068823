import React, { useContext, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SvgIconQueue } from '@flomni/components/dist/components/svg/feathers/SvgIconQueue'
import { bool, func, string } from 'prop-types'
import { Meteor } from '../../../../../../../../../meteorAdapter';
import { useDispatch } from 'react-redux'
import { store } from '../../../../../../../../state/dialogs'
import { ButtonWithLoader } from '../../../../../../../shared/button-with-loader'
import { processError } from '../../../../../../../../services/helpers'

export const ReplyQueueChat = ({ state, originalState, onChange, chatbotEnabled }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const { selectedDialogId } = useContext(store)
  const [showLoader, setShowLoader] = useState(false)

  const resources = {
    NEW: {
      title: t('dlg:thisChatInQueue'),
      description: t('dlg:pickItUpOrAssignAgent'),
      button: t('dlg:pickUpTheChat')
    },
    BOT_DRIVEN: {
      title: t('dlg:currentChatProcessedByChatbot'),
      description: t('dlg:joinChatOrAssignAgent'),
      button: t('dlg:join')
    },
    TEAM_HAS_MEMBERS: {
      title: t('dlg:currentChatProcessedByOtherAgents'),
      description: t('dlg:joinChatStartChatting'),
      button: t('dlg:join')
    },
    TEAM_NO_MEMBERS: {
      title: t('dlg:currentChatNoOtherAgents'),
      description: t('dlg:joinChatStartChatting'),
      button: t('dlg:join')
    }
  }

  const assignToCurrentUser = async () => {
    setShowLoader(true)
    await new Promise((resolve) => setTimeout(resolve, 800))
    try {
      await Meteor.invoke('addOwnUserToChat', selectedDialogId)
      if (chatbotEnabled) {
        await Meteor.invoke('toggleChatbot', selectedDialogId, false)
      }
      if (originalState === 'ARCHIVED') {
        await Meteor.invoke('dialogs.unarchiveDialog', {
          dialogId: selectedDialogId
        })
      }
      setShowLoader(false)
      onChange()
    } catch (err) {
      setShowLoader(false)
      processError(err, main)
    }
  }

  return (
    <div className={css('no-items')}>
      <div className={css('no-items-header')}>
        <div className={css('no-items-icon')}>
          <SvgIconQueue width={22} height={22} />
        </div>
        <div>
          <div className={css('no-items-title')}>{resources[state].title}</div>
          <div className={css('no-items-description')}>{resources[state].description}</div>
        </div>
      </div>
      <ButtonWithLoader
        view='usual'
        cls={css('no-items-button')}
        text={resources[state].button}
        onClick={assignToCurrentUser}
        spinnerSize={10}
        spinnerStrokeWidth={14}
        isLoading={showLoader}
      />
    </div>
  )
}

ReplyQueueChat.propTypes = {
  originalState: string,
  state: string,
  onChange: func,
  chatbotEnabled: bool
}
