import debounce from 'lodash.debounce'
import { Howl } from 'howler'

const SOUND_NOTIFICATION_DELAY = 500 // ms

// We took some sounds from Notification Sounds
// [https://notificationsounds.com/notification-sounds]
const SOUND_LIBRARY = {
  online: new Howl({ src: ['/sounds/notification-online.mp3'] }),
  standby: new Howl({ src: ['/sounds/notification-standby.mp3'] }),
  offline: new Howl({ src: ['/sounds/notification-standby.mp3'] }),
  inboundMessage: new Howl({ src: ['/sounds/notification-when.ogg'] }),
  newDialog: new Howl({ src: ['/sounds/notification-unconvinced.ogg'] })
}

export default class SoundHelpers {
  static playSoundNotification = (soundType = 'online') => {
    if (soundType in SOUND_LIBRARY) {
      SOUND_LIBRARY[soundType].play()
    }
  }

  static playOnlineNotification = debounce(() => {
    SoundHelpers.playSoundNotification('online')
  }, SOUND_NOTIFICATION_DELAY)

  static playStandByNotification = debounce(() => {
    SoundHelpers.playSoundNotification('standby')
  }, SOUND_NOTIFICATION_DELAY)

  static playOfflineNotification = debounce(() => {
    SoundHelpers.playSoundNotification('offline')
  }, SOUND_NOTIFICATION_DELAY)

  static playInboundMessageNotification = () => {
    SoundHelpers.playSoundNotification('inboundMessage')
  }

  static playNewDialogNotification = debounce(() => {
    SoundHelpers.playSoundNotification('newDialog')
  }, SOUND_NOTIFICATION_DELAY)
}
