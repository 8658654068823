import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import { Chart } from '@flomni/components/dist/components/chart'
import React, { useCallback, useEffect, useState } from 'react'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonText } from '@flomni/components/dist/components/plate-button/text'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { SvgIconSheet } from '@flomni/components/dist/components/svg/feathers/SvgIconSheet'
import moment from 'moment'
import { processError } from '../../../../../../services/helpers'
import { useDispatch } from 'react-redux'
import { Spinner } from '@flomni/components/dist/components/spinner'
import {
  chartOptions,
  getChartLegend,
  getEmptyDatesData,
  getPeriodDates,
  getPreviousPeriodDates,
  loadDates
} from '../../shared/utils'
import { Dropdown } from '@flomni/components/dist/components/dropdown'
import { SecondaryDropdownItem } from '@flomni/components/dist/components/secondary-dropdown-item/item'
import { SecondaryDropdownItemText } from '@flomni/components/dist/components/secondary-dropdown-item/text'

export const TeamDashboardChart = ({ period }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()

  const ITEMS = {
    chatsCount: t('dlg:chatsCount'),
    avgQueueTime: t('dlg:waitingTime'),
    newDialogs: t('dlg:pendingChats'),
    rateScore: t('dlg:rate'),
    rateCount: t('dlg:ratesCount')
  }

  const { main } = useDispatch()
  const [showLoader, setShowLoader] = useState(false)
  const [showList, setShowList] = useState(false)
  const [datesCurrent, setDatesCurrent] = useState([])
  const [datesPrevious, setDatesPrevious] = useState([])
  const [dtPeriodCurrent, setDtPeriodCurrent] = useState(null)
  const [dtPeriodPrevious, setDtPeriodPrevious] = useState(null)
  const [selectedProperty, setSelectedProperty] = useState('avgQueueTime')

  useEffect(() => {
    setShowLoader(true)
    const [startCurrent, endCurrent] = getPeriodDates(period)
    const [startPrevious, endPrevious] = getPreviousPeriodDates(period)
    setDtPeriodCurrent([startCurrent, endCurrent])
    setDtPeriodPrevious([startPrevious, endPrevious])
    Promise.all([loadDates('dialog.chat.conversation', startCurrent, endCurrent), loadDates('dialog.chat.conversation', startPrevious, endPrevious)])
      .then(([datesCurrent, datesPrevious]) => {
        setDatesCurrent(datesCurrent)
        setDatesPrevious(datesPrevious)
      })
      .catch((err) => {
        processError(err, main)
      })
      .finally(() => {
        setShowLoader(false)
      })
  }, [period])

  const getChartData = (dates, dtPeriod) => {
    let data = []
    if (selectedProperty === 'chatsCount') {
      data = dates
        .filter((item) => item.eventType === 'dialog.chat.conversation')
    }
    if (selectedProperty === 'avgQueueTime') {
      data = dates
        .filter((item) => item.eventType === 'dialog.chat.conversation')
        .map((item) => {
          const avgQueueTime = item.metrics.find(({ name }) => name === 'avgQueueTime')
          return {
            dtPeriod: item.dtPeriod,
            count: avgQueueTime?.value ?? 0,
            tooltip: avgQueueTime?.value ? moment.utc(avgQueueTime.value).format('HH:mm:ss.SSS') : '0'
          }
        })
    }
    if (selectedProperty === 'rateScore') {
      data = dates
        .filter((item) => item.eventType === 'core.rate')
        .map((item) => {
          const rateScore = item.metrics.find(({ name }) => name === 'rateScore')
          return {
            dtPeriod: item.dtPeriod,
            count: rateScore?.value ?? 0
          }
        })
    }
    if (selectedProperty === 'rateCount') {
      data = dates
        .filter((item) => item.eventType === 'core.rate')
    }
    if (selectedProperty === 'newDialogs') {
      data = dates
        .filter((item) => item.eventType === 'dialogs.chat.status')
        .map((item) => {
          const newDialogs = item.metrics.find(({ name }) => name === 'newDialogs')
          return {
            dtPeriod: item.dtPeriod,
            count: newDialogs?.value ?? 0
          }
        })
    }

    data = data.concat(getEmptyDatesData(data, dtPeriod))

    const datasets = data.map((item) => {
      const dt = new Date(item.dtPeriod)
      return {
        x: dtPeriod ? moment(dt).diff(dtPeriod[0], 'hours') : 0,
        y: item.count,
        tooltip: item.tooltip,
        dt
      }
    })
    datasets.sort((a, b) => (a.dt.getTime() - b.dt.getTime() > 0 ? 1 : -1))
    return datasets
  }

  const onOverlayHandler = useCallback(() => {
    setShowList(false)
  }, [])

  const onSelectProperty = (property) => {
    setShowList(false)
    setSelectedProperty(property)
  }

  if (showLoader) {
    return (
      <div className={css('spinner-container')}>
        <div className={css('spinner')}>
          <Spinner strokeWidth={5} />
        </div>
      </div>
    )
  }

  return (
    <div className={css(['chart-container', '--pb-10'])}>
      <div className={css('chart-header')}>
        <div>
          <PlateButton view='usual' onClick={() => setShowList(true)}>
            <PlateButtonIcon>
              <SvgIconSheet />
            </PlateButtonIcon>
            <PlateButtonText>{ITEMS[selectedProperty]}</PlateButtonText>
          </PlateButton>
          <Dropdown
            active={showList}
            variation='secondary'
            offsetTarget='iconButton'
            onOverlayClick={onOverlayHandler}
          >
            {Object.keys(ITEMS).map((key) => (
              <SecondaryDropdownItem
                key={key}
                view='usual'
                active={selectedProperty === key}
                onClick={() => onSelectProperty(key)}
              >
                <SecondaryDropdownItemText>{ITEMS[key]}</SecondaryDropdownItemText>
              </SecondaryDropdownItem>
            ))}
          </Dropdown>
        </div>
      </div>
      <div>
        <Chart
          type='line'
          options={chartOptions}
          data={{
            labels: getChartLegend(dtPeriodCurrent),
            datasets: [
              {
                label: 'current',
                data: getChartData(datesCurrent, dtPeriodCurrent),
                fill: false,
                lineTension: 0,
                backgroundColor: 'rgb(100, 190, 132)',
                borderColor: 'rgb(100, 190, 132)'
              },
              {
                label: 'previous',
                data: getChartData(datesPrevious, dtPeriodPrevious),
                fill: false,
                lineTension: 0,
                backgroundColor: 'grey',
                borderColor: 'grey'
              }
            ]
          }}
        />
      </div>
    </div>
  )
}
