import React, { useEffect, useState } from 'react'
import { Popup } from '@flomni/components/dist/components/popup/popup'
import { array, bool, func, object, string } from 'prop-types'
import DialogTemplateFirstStep from './first-step'
import DialogTemplateSecondStep from './second-step'

const DialogTemplate = ({ text, isEdit, template, onFinished, onCancel, actions, isOperator }) => {
  const [stepNumber, setStepNumber] = useState(1)
  const [templateData, setTemplateData] = useState(null)

  useEffect(() => {
    if (isEdit) {
      setTemplateData({ ...template, templateType: template.type })
    } else if (text) {
      setTemplateData({ text })
    }
  }, [])

  const handlerClickNextStep = (template) => {
    setTemplateData({
      ...(templateData || {}),
      ...template
    })
    setStepNumber(2)
  }

  const handlerClickBackStep = (attachments) => {
    setTemplateData({
      ...(templateData || {}),
      attachments
    })
    setStepNumber(1)
  }

  const handlerClickOnCancel = () => {
    onCancel()
  }

  return (
    <Popup open variation='secondary'>
      {stepNumber === 1 && (
        <DialogTemplateFirstStep
          isEdit={isEdit}
          actions={actions}
          template={templateData}
          onNext={handlerClickNextStep}
          onCancel={handlerClickOnCancel}
          isOperator={isOperator}
        />
      )}
      {stepNumber === 2 && (
        <DialogTemplateSecondStep
          isEdit={isEdit}
          actions={actions}
          templateData={templateData}
          onFinished={onFinished}
          onCancel={handlerClickOnCancel}
          onBack={handlerClickBackStep}
        />
      )}
    </Popup>
  )
}

DialogTemplate.propTypes = {
  isEdit: bool,
  template: object,
  text: string,
  onFinished: func.isRequired,
  onCancel: func.isRequired,
  actions: array,
  isOperator: bool
}

export default DialogTemplate
