import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { Trans, useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { Popup } from '@flomni/components/dist/components/popup/popup'
import { PopupButtons } from '@flomni/components/dist/components/popup/buttons'
import { PopupContent } from '@flomni/components/dist/components/popup/content'
import { PopupTitle } from '@flomni/components/dist/components/popup/title'
import { func, object } from 'prop-types'
import { Meteor } from '../../../../../../../meteorAdapter';
import { useDispatch, useSelector } from 'react-redux'
import { ButtonWithLoader } from '../../../../../shared/button-with-loader'
import { processError } from '../../../../../../services/helpers'

const DeleteTemplate = ({ onDeleted, onCancel, template }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const [showSaveLoader, setShowSaveLoader] = useState(false)
  const groupId = useSelector(({ main }) => main.currentUserGroupId)

  const handlerClickRemove = async (event) => {
    event.preventDefault()
    try {
      setShowSaveLoader(true)
      await new Promise((resolve) => setTimeout(resolve, 800))
      await Meteor.invoke('templates.removeTemplate', {
        templateId: template.id,
        type: template.type,
        groupId
      })
      setShowSaveLoader(false)
      onDeleted()
    } catch (err) {
      setShowSaveLoader(false)
      processError(err, main)
    }
  }

  const handlerClickOnCancel = () => {
    onCancel()
  }

  return (
    <Popup open variation='secondary'>
      <PopupTitle title={t('dlg:removeTemplate')} />
      <form>
        <PopupContent classes={{ root: css('content') }}>
          <div className={css('tip')}>
            <Trans
              i18nKey='dlg:templateWillBeRemoved'
              values={{ name: template.name }}
              components={[<span className={css('text-bold')}>0</span>, 1]}
            />
          </div>
        </PopupContent>
        <PopupButtons>
          <SecondaryButton view='light' onClick={handlerClickOnCancel}>
            <SecondaryButtonText>{t('dlg:cancel')}</SecondaryButtonText>
          </SecondaryButton>
          <ButtonWithLoader
            view='warning'
            text={t('dlg:remove')}
            onClick={handlerClickRemove}
            spinnerSize={10}
            spinnerStrokeWidth={14}
            isLoading={showSaveLoader}
          />
        </PopupButtons>
      </form>
    </Popup>
  )
}

DeleteTemplate.propTypes = {
  onDeleted: func.isRequired,
  onCancel: func.isRequired,
  template: object.isRequired
}

export default DeleteTemplate
