import React from 'react'
import { useTracker } from '../../../../../../../../../meteorAdapter';
import Dialogs from '../../../../../../../../../collections/client/dialogs'
import { array, string } from 'prop-types'
import { EventMessage } from '../event'

export const DraftMessage = ({ messages, selectedDialogId }) => {
  const { mid, text } =
    useTracker(() => {
      const { clientDraftMessage } =
        Dialogs.findOne(selectedDialogId, {
          fields: {
            clientDraftMessage: 1
          }
        }) ?? {}
      return clientDraftMessage
    }, [selectedDialogId]) ?? {}

  if (!text) {
    return null
  }

  const isPostedMessage = !!messages.find((msg) => msg.mid === mid)
  if (isPostedMessage) {
    return null
  }

  const message = {
    event: {
      token: 'clientTypingText',
      placeholders: {
        text
      }
    }
  }

  return <EventMessage message={message} />
}

DraftMessage.propTypes = {
  selectedDialogId: string,
  messages: array.isRequired
}
