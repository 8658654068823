import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { object } from 'prop-types'
import { SecondaryButtonIcon } from '@flomni/components/dist/components/secondary-button/icon'
import { SvgIconEdit } from '@flomni/components/dist/components/svg/feathers/SvgIconEdit'
import { SvgIconDelete } from '@flomni/components/dist/components/svg/feathers/SvgIconDelete'
import ReminderForm from '../reminder-form'
import RemoveReminderForm from '../remove-reminder-form'
import { DateUtils } from '@flomni/modules/dist/services/date'

const ReminderItem = ({ reminder }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const [showRemoveForm, setShowRemoveForm] = useState(false)
  const [showEditForm, setShowEditForm] = useState(false)

  return (
    <div className={css('reminder')}>
      {!showRemoveForm && !showEditForm && (
        <div className={css('item')}>
          <div className={css('date')}>
            <div>{DateUtils.format(reminder.intendedAt, 'DD MMM')}</div>
            <div>{DateUtils.format(reminder.intendedAt, DateUtils.TIME_FORMAT)}</div>
          </div>
          <div className={css('message')}>{reminder.message}</div>
          <div className={css('controls')}>
            <SecondaryButton view='light' onClick={() => setShowEditForm(true)}>
              <SecondaryButtonIcon>
                <SvgIconEdit />
              </SecondaryButtonIcon>
              <SecondaryButtonText>{t('dlg:edit')}</SecondaryButtonText>
            </SecondaryButton>
            <SecondaryButton view='light' onClick={() => setShowRemoveForm(true)}>
              <SecondaryButtonIcon>
                <SvgIconDelete />
              </SecondaryButtonIcon>
              <SecondaryButtonText>{t('dlg:remove')}</SecondaryButtonText>
            </SecondaryButton>
          </div>
        </div>
      )}
      {showEditForm && (
        <ReminderForm
          onSave={() => setShowEditForm(false)}
          onCancel={() => setShowEditForm(false)}
          reminder={reminder}
        />
      )}
      {showRemoveForm && <RemoveReminderForm onCancel={() => setShowRemoveForm(false)} reminder={reminder} />}
    </div>
  )
}

ReminderItem.propTypes = {
  reminder: object
}

export default ReminderItem
