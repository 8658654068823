import React, { useContext } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { Meteor, useTracker, Match } from '../../../../../meteorAdapter';
import { DialogsProvider, store } from '../../../../state/dialogs'
import { CenterPanel } from '../conversations/Conversations/center-panel'
import { RightPanel } from '../conversations/Conversations/right-panel'
import { LeftPanel } from './left-panel'
import { ROUTE, routes } from '../../../../configs/routes'
import { useHistory } from 'react-router-dom'
import { number } from 'prop-types'
import { useBanClient } from '../../shared/useBanClient'
import { useMetaData } from '../../shared/useMetaData'
import { useSelector } from 'react-redux'

const ChatbotPage = ({ newDialogsCount, teamDialogsCount }) => {
  const css = useStyles(styles)
  const { selectedDialogId } = useContext(store)
  const { banResult, clientId, updateBanResult } = useBanClient()
  const { crmFields, relatedChats, recipient, metaDataLoading, setCrmFields, setRecipient } = useMetaData()
  const history = useHistory()
  const isDetailsPanelCollapsed = useSelector(({ main }) => main.isDetailsPanelCollapsed)

  useTracker(() => {
    if (Match.isDocumentId(selectedDialogId)) {
      Meteor.subscribe('fetchOneDialogData', selectedDialogId)
    }
  }, [selectedDialogId])

  const onAssignedCurrentUser = () => {
    history.push({
      pathname: routes[ROUTE.CONVERSATIONS]
    })
  }

  return (
    <div className={css(['container', isDetailsPanelCollapsed ? '--collapsed' : null])}>
      <LeftPanel newDialogsCount={newDialogsCount} teamDialogsCount={teamDialogsCount} />
      {selectedDialogId && (
        <>
          <CenterPanel
            banResult={banResult}
            updateBanResult={updateBanResult}
            clientId={clientId}
            onChanged={() => {}}
            onAssignedCurrentUser={onAssignedCurrentUser}
            crmFields={crmFields}
          />
          <RightPanel
            banResult={banResult}
            updateBanResult={updateBanResult}
            crmFields={crmFields}
            relatedChats={relatedChats}
            recipient={recipient}
            metaDataLoading={metaDataLoading}
            setCrmFields={setCrmFields}
            setRecipient={setRecipient}
          />
        </>
      )}
    </div>
  )
}

ChatbotPage.propTypes = {
  newDialogsCount: number,
  teamDialogsCount: number
}

export const Chatbot = ({ newDialogsCount, teamDialogsCount }) => {
  return (
    <DialogsProvider isEditMode>
      <ChatbotPage newDialogsCount={newDialogsCount} teamDialogsCount={teamDialogsCount} />
    </DialogsProvider>
  )
}

Chatbot.propTypes = {
  newDialogsCount: number,
  teamDialogsCount: number
}
