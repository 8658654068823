import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { Popup } from '@flomni/components/dist/components/popup/popup'
import { PopupButtons } from '@flomni/components/dist/components/popup/buttons'
import { PopupContent } from '@flomni/components/dist/components/popup/content'
import { PopupTitle } from '@flomni/components/dist/components/popup/title'
import { array, func } from 'prop-types'
import { Select } from '@flomni/components/dist/components/select'
import { CheckboxOption } from '@flomni/components/dist/components/checkbox-option'
import { json2csv } from 'json-2-csv'
import { DateUtils } from '@flomni/modules/dist/services/date'

const Export = ({ onExported, onCancel, users, departments }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const [selectedColumn, setSelectedColumn] = useState('default')
  const [selectedCustomFields, setSelectedCustomFields] = useState({
    email: true,
    name: true,
    group: true,
    lastLogin: true,
    joined: true
  })

  const keys = []
  if (selectedColumn === 'custom') {
    Object.keys(selectedCustomFields).forEach((field) => {
      if (selectedCustomFields[field]) {
        keys.push(field)
      }
    })
  } else {
    keys.push('email', 'name', 'group', 'lastLogin')
  }

  const handlerClickExport = (e) => {
    e.preventDefault()

    const items = users.map((user) => {
      const department = departments.find((department) => department._id === user.departmentId)
      return {
        ...user,
        lastLogin: DateUtils.format(user.lastLoginAt, DateUtils.DATE_TIME_FULL_FORMAT),
        joined: DateUtils.format(user.createdAt, DateUtils.DATE_TIME_FULL_FORMAT),
        group: department ? department.name : '-'
      }
    })

    json2csv(
      items,
      (err, csv) => {
        if (!err) {
          const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csv}`)
          const link = document.createElement('a')
          link.setAttribute('href', encodedUri)
          link.setAttribute('download', 'team.csv')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          onExported()
        }
      },
      {
        keys,
        delimiter: {
          field: ';'
        }
      }
    )
  }

  const handlerClickOnCancel = () => {
    onCancel()
  }

  const updateSelectedCustomField = (name, isChecked) => {
    setSelectedCustomFields({
      ...selectedCustomFields,
      [name]: isChecked
    })
  }

  const columns = [
    {
      value: 'default',
      label: t('dlg:default4')
    },
    {
      value: 'custom',
      label: t('dlg:custom')
    }
  ]
  const selectedColumnsOption = columns.find((option) => option.value === selectedColumn)

  return (
    <Popup open variation='secondary'>
      <PopupTitle title={t('dlg:exportTeam')} />
      <form>
        <PopupContent>
          <div className={css('title')}>{t('dlg:columns')}</div>
          <div className={css('select')}>
            <Select
              variation='secondary'
              options={columns}
              menuPortalTarget={document.body}
              value={selectedColumnsOption}
              onChange={(option) => setSelectedColumn(option.value)}
            />
          </div>
          {selectedColumn === 'default' && (
            <div className={css('description')}>{t('dlg:defaultColumnsList')}</div>
          )}
          {selectedColumn === 'custom' && (
            <div className={css('fields')}>
              <CheckboxOption
                checked={selectedCustomFields.email}
                variation='secondary'
                onChange={(e) => updateSelectedCustomField('email', e.target.checked)}
              >
                {t('dlg:email')}
              </CheckboxOption>
              <CheckboxOption
                checked={selectedCustomFields.lastLogin}
                variation='secondary'
                onChange={(e) => updateSelectedCustomField('lastLogin', e.target.checked)}
              >
                {t('dlg:lastLoginUTC')}
              </CheckboxOption>
              <CheckboxOption
                checked={selectedCustomFields.name}
                variation='secondary'
                onChange={(e) => updateSelectedCustomField('name', e.target.checked)}
              >
                {t('dlg:fullName')}
              </CheckboxOption>
              <CheckboxOption
                checked={selectedCustomFields.joined}
                variation='secondary'
                onChange={(e) => updateSelectedCustomField('joined', e.target.checked)}
              >
                {t('dlg:joinedUTC')}
              </CheckboxOption>
              <CheckboxOption
                checked={selectedCustomFields.group}
                variation='secondary'
                onChange={(e) => updateSelectedCustomField('group', e.target.checked)}
              >
                {t('dlg:group')}
              </CheckboxOption>
            </div>
          )}
        </PopupContent>
        <PopupButtons>
          <SecondaryButton view='light' onClick={handlerClickOnCancel}>
            <SecondaryButtonText>{t('dlg:cancel')}</SecondaryButtonText>
          </SecondaryButton>
          <SecondaryButton disabled={keys.length === 0} onClick={handlerClickExport}>
            <SecondaryButtonText>{t('dlg:export')}</SecondaryButtonText>
          </SecondaryButton>
        </PopupButtons>
      </form>
    </Popup>
  )
}

Export.propTypes = {
  onExported: func.isRequired,
  onCancel: func.isRequired,
  users: array.isRequired,
  departments: array
}

export default Export
