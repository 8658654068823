import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { Input } from '@flomni/components/dist/components/input'
import { func, object } from 'prop-types'
import { ValueSelector } from '../shared/value-selector'

export const ChatDurationFilterItem = ({ item, onChangeItemProperty }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()

  const durations = ['moreThan', 'lessThan']
  const [selectedDuration, setSelectedDuration] = useState(item.duration)
  const [selectedDurationValue, setSelectedDurationValue] = useState(item.durationValue)

  const onSelectDuration = (duration) => {
    setSelectedDuration(duration)
    onChangeItemProperty('duration', duration)
  }

  const onSelectDurationValue = (durationValue) => {
    setSelectedDurationValue(durationValue)
    onChangeItemProperty('durationValue', durationValue)
  }

  return (
    <div className={css('container')}>
      <ValueSelector items={durations} item={selectedDuration} onChange={onSelectDuration} />
      <Input
        shaded
        autoFocus
        type='number'
        min='0'
        classes={{ root: css('duration') }}
        variation='secondary'
        placeholder={t('dlg:minutes')}
        value={selectedDurationValue || ''}
        onChange={(e) => onSelectDurationValue(e.target.value)}
      />
    </div>
  )
}

ChatDurationFilterItem.propTypes = {
  onChangeItemProperty: func.isRequired,
  item: object.isRequired
}
