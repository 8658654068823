export const conversationChannelsMapping = {
  "vbr": "Viber",
  "vb": "Viber Business",
  "waba": "Whatsapp Business",
  "wa": "Whatsapp",
  "tlgr": "Telegram",
  "vk": "VKontakte",
  "ok": "Odnoklassniki",
  "fb": "Facebook",
  "abc": "Apple Business Chat",
  "voicer": "Voice bot",
  "email": "Email",
  "mobile": "Mobile app",
  "web": "Online chat",
  "ig": "Instagram"
}