import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { array, bool } from 'prop-types'
import { RelatedChatItem } from './chat-item'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonText } from '@flomni/components/dist/components/plate-button/text'
import { SvgIconRelatedChats } from '@flomni/components/dist/components/svg/feathers/SvgIconRelatedChats'

export const RelatedChatsTab = ({ relatedChats, joinedMessageHistory }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()

  return (
    <div className={css('container')}>
      {relatedChats.length === 0 && (
        <div className={css('no-items')}>
          <div className={css('no-items-header')}>
            <div className={css('no-items-icon')}>
              <SvgIconRelatedChats width={22} height={22} />
            </div>
            <div>
              <div className={css('no-items-title')}>{t('dlg:thereAreNoRelatedChats')}</div>
              <div className={css('no-items-description')}>{t('dlg:relatedChatsDescription')}</div>
            </div>
          </div>
        </div>
      )}
      {relatedChats.length > 0 && (
        <>
          <div className={css('header')}>
            <div className={css('title')}>{t('dlg:chatSessions')}</div>
            <PlateButton disabled>
              <PlateButtonText>{joinedMessageHistory === true ? t('dlg:joinedMessageHistoryEnabled') : t('dlg:joinedMessageHistoryDisabled')}</PlateButtonText>
            </PlateButton>
          </div>
          <div className={css('chat-items')}>
            {relatedChats.map((chat) => (
              <RelatedChatItem chat={chat} key={chat.userHash} />
            ))}
          </div>
        </>
      )}
    </div>
  )
}

RelatedChatsTab.propTypes = {
  relatedChats: array,
  joinedMessageHistory: bool
}
