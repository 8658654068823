import React, { useEffect, useRef, useCallback } from 'react'
import { Meteor, useTracker, Match, Mongo } from '../../../meteorAdapter';
import { Localization } from '../../services/localization'
import { useDispatch, useSelector } from 'react-redux'
import Groups from '../../../collections/client/groups'
import FaviconHelpers from '../../helpers/faviconHelpers'
import { NotificationEvents, PersonalDialogs, Tags } from '../../customStores'
import SoundHelpers from '../../services/soundHelpers'
import AgentHelpers from '../../services/agentHelpers'
import DialogHelpers from '../../services/dialogHelpers'
import { PERMISSIONS_PAGE } from '../../services/constants'
import { USER_ROLES } from '../../../constants'
import { isMobileMode, processError } from '../../services/helpers'
import { DialogsPageMobile } from './mobile'
import { DialogsPageDesktop } from './desktop'
import moment from 'moment'
import { ROUTE, routes } from '../../configs/routes'
import { useHistory } from 'react-router-dom'
import { getDialogName } from '../../utils/common'
import { useTranslation } from 'react-i18next'
import Dialogs from '../../../collections/client/dialogs'
import { object, func } from 'prop-types'
import { useEmptyQueue } from './shared/useEmptyQueue'

const __pings = new Mongo.Collection('__pings')
const subsPool = {}
const { FLOMNI_DISABLE_FORCED_DISTRIBUTION, FLOMNI_FORCED_DISTRIBUTION_INTERVAL } =
  Meteor.settings.public || {}

export const DialogsPage = ({ emptyQueue, setEmptyQueue }) => {
  const { t } = useTranslation()
  const { main } = useDispatch()
  const history = useHistory()
  const notification = useRef(null)
  const distributionRequest = useRef(null)

  const [currentUserRoles] = useSelector(({ main }) => [main.currentUserRoles])

  const { locale, theme, tz, sounds, roles } = useTracker(() => {
    const currentUserId = Meteor.userId()
    const user = Meteor.users.findOne(currentUserId, {
      fields: {
        profile: 1,
        roles: 1
      }
    })
    return {
      theme: user?.profile?.theme,
      locale: user?.profile?.locale,
      tz: user?.profile?.tz,
      sounds: user?.profile?.sounds,
      roles: user?.roles
    }
  }, [])

  const [currentUserGroupId, currentUserDepartmentId, newMyDialogsCount, inWorkDialogsCount, userEmails] =
    useTracker(() => {
      const {
        groupsIds = [],
        departmentId,
        numHotChats,
        numPersonalChats,
        profile,
        emails
      } = Meteor.user({
        fields: {
          groupsIds: 1,
          departmentId: 1,
          numHotChats: 1,
          numPersonalChats: 1,
          'profile.sounds': 1,
          emails: 1
        }
      }) ?? {}

      const currentUserGroupId = groupsIds[0]
      if (Match.isMongoId(currentUserGroupId)) {
        Meteor.subscribe('fetchGroupAgentStatuses', currentUserGroupId)
        Meteor.subscribe('fetchGroupMembers', currentUserGroupId)
        Meteor.subscribe('fetchGroupTags', currentUserGroupId)
        Meteor.subscribe('watchGroupEvents', currentUserGroupId, profile)
      }
      return [currentUserGroupId, departmentId, numHotChats, numPersonalChats, emails]
    }, [])

  const [workforceSettings, permissions, megabrain, archiveSettings, translationSettings] = useTracker(() => {
    if (!currentUserGroupId) {
      return [null, null]
    }
    const group =
      Groups.findOne(currentUserGroupId, {
        fields: {
          workforceSettings: 1,
          agentVisibleInterfaceElements: 1,
          megabrain: 1,
          archiveSettings: 1,
          translationSettings: 1
        }
      }) || {}

    return [
      group.workforceSettings,
      group.agentVisibleInterfaceElements,
      group.megabrain,
      group.archiveSettings,
      group.translationSettings
    ]
  }, [currentUserGroupId])

  const newDialogsCount = useTracker(() => {
    if (!currentUserGroupId) {
      return 0
    }

    const { totalHotChats = 0 } =
      Groups.findOne(currentUserGroupId, {
        fields: {
          totalHotChats: 1
        }
      }) || {}
    return totalHotChats
  }, [currentUserGroupId])

  const totalDialogsCount = useTracker(() => {
    if (!currentUserGroupId) {
      return 0
    }

    Meteor.subscribe('fetchGroupInWorkChats', currentUserGroupId)
    const { totalInWorkChats = 0 } =
      Groups.findOne(currentUserGroupId, {
        fields: {
          totalInWorkChats: 1
        }
      }) || {}
    return totalInWorkChats
  }, [currentUserGroupId])

  const groupTags = useTracker(() => {
    if (!currentUserGroupId) {
      return []
    }

    return Tags.find(
      {
        sourceId: currentUserGroupId,
        sourceType: 'group'
      },
      {
        fields: {
          name: 1,
          sourceId: 1,
          sourceType: 1,
          color: 1,
          kind: 1
        },
        sort: {
          name: 1
        }
      }
    ).map(({ _id, name, sourceId, sourceType, color, kind = 'client' }) => ({
      _id,
      name,
      groupId: sourceId,
      type: sourceType,
      color,
      kind
    }))
  }, [currentUserGroupId])

  const [unreadMyChats, loadingChats = true] = useTracker(() => {
    if (window.parent === window) {
      return []
    }
    const currentUserId = Meteor.userId()
    const handle = Meteor.subscribe('fetchGroupDialogs', {
      user: {
        groupId: currentUserGroupId,
        departmentId: currentUserDepartmentId,
        roles: currentUserRoles
      },
      types: ['PERSONAL']
    })
    const loading = !handle.ready()
    const chats = Dialogs.find({
      state: 'IN_WORK'
    }).fetch()
    return [
      chats.map((chat) => chat.stuffCounts[currentUserId]?.unreadMessagesCount).filter(Boolean).length,
      loading
    ]
  }, [currentUserGroupId, currentUserDepartmentId, currentUserRoles])

  useTracker(() => {
    const currentUserId = Meteor.userId()
    const event = NotificationEvents.findOne(currentUserId, {
      fields: {
        newDialogEvent: 1
      }
    })
    if (!event) {
      return
    }

    const { recipients = [], _kind, dialog, message } = event.newDialogEvent ?? {}

    if (_kind === 'inbound') {
      if (sounds === 'PERSONAL') {
        if (recipients.includes(currentUserId)) {
          SoundHelpers.playInboundMessageNotification()
        }
      } else if (sounds === 'ALL') {
        SoundHelpers.playInboundMessageNotification()
      }

      if (
        (window.location.pathname !== routes[ROUTE.CONVERSATIONS] || document.hidden) &&
        'Notification' in window &&
        Notification.permission === 'granted'
      ) {
        if (notification.current && notification.current.close) {
          notification.current.close()
        }
        setTimeout(() => {
          const chatName = getDialogName(dialog.chatCaption, dialog.clientProfile, dialog.receiver, t)
          notification.current = new Notification(chatName, {
            body: `${moment(message.time).format('HH:mm')} ${message.text}`,
            icon: dialog.clientProfile?.avatarUrl
          })
          notification.current.onclick = () => {
            notification.current.close()
            history.push({
              pathname: routes[ROUTE.CONVERSATIONS]
            })
          }
        }, 100)
      }
    } else if (_kind === 'event') {
      if (sounds === 'PERSONAL') {
        if (recipients.includes(currentUserId)) {
          SoundHelpers.playOnlineNotification()
        }
      } else if (sounds === 'ALL') {
        SoundHelpers.playOnlineNotification()
      }
    }
  }, [])

  useTracker(() => {
    const ping = __pings.findOne(Meteor.userId(), {
      fields: {
        pingMs: 1
      }
    })
    if (ping === null || ping === undefined) {
      return
    }

    Meteor.invoke('users.pingUser', {
      pingMs: ping.pingMs
    }).catch((err) => {
      console.error('users.pingUser has error', err)
    })
  }, [])

  useTracker(() => {
    distributionRequest.current = {
      ...AgentHelpers.canAcceptHotChats(inWorkDialogsCount, 'auto'),
      currentUserDepartmentId
    }
    main.setDistributionRequest(distributionRequest.current)
    return distributionRequest.current
  }, [inWorkDialogsCount, currentUserDepartmentId])

  useEffect(() => {
    main.setCurrentUserGroupTags(groupTags)
  }, [groupTags])

  useEffect(() => {
    main.setCurrentUserSounds(sounds)
  }, [sounds])

  useEffect(() => {
    main.setCurrentTheme(theme || 'light')
  }, [theme])

  useEffect(() => {
    main.setCurrentUserGroupId(currentUserGroupId)
  }, [currentUserGroupId])

  useEffect(() => {
    main.setCurrentUserDepartmentId(currentUserDepartmentId)
  }, [currentUserDepartmentId])

  useEffect(() => {
    main.setCurrentUserRoles(roles || [])
  }, [roles])

  useEffect(() => {
    main.setCurrentUserMegabrainSettings(megabrain || {})
  }, [megabrain])

  useEffect(() => {
    main.setCurrentUserArchiveSettings(archiveSettings || {})
  }, [archiveSettings])

  useEffect(() => {
    main.setCurrentUserWorkforceSettings(workforceSettings || {})
  }, [workforceSettings])

  useEffect(() => {
    main.setCurrentUserPermissions(permissions || [])
  }, [permissions])

  useEffect(() => {
    main.setCurrentUserTranslationSettings(translationSettings || {})
  }, [translationSettings])

  useEffect(() => {
    Localization.changeLocale(locale)
  }, [locale])

  useEffect(() => {
    Localization.changeTimezone(tz)
  }, [tz])

  useEffect(() => {
    FaviconHelpers.updateCounter(inWorkDialogsCount + newMyDialogsCount)
  }, [inWorkDialogsCount, newMyDialogsCount])

  useEffect(() => {
    if (!sounds) {
      return
    }
    let timeoutId = null

    const __distribute = () => {
      timeoutId = Meteor.setTimeout(() => {
        DialogHelpers.requestHottestDialog(distributionRequest.current, main, sounds, 'Cron')
        __distribute()
      }, FLOMNI_FORCED_DISTRIBUTION_INTERVAL * 1000)
    }

    if (FLOMNI_DISABLE_FORCED_DISTRIBUTION === false) {
      __distribute()
    }

    PersonalDialogs.find({}).observe({
      added: () => {
        if (subsPool.personalDialogs.ready() && sounds !== 'DISABLED') {
          SoundHelpers.playOfflineNotification()
        }
      },
      removed: () => {
        DialogHelpers.requestHottestDialog(distributionRequest.current, main, sounds, 'Personal chat removed')
      }
    })

    return () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId)
        timeoutId = null
      }
    }
  }, [sounds])

  const hasSearch =
    !roles || !permissions || !roles.includes(USER_ROLES.OPERATOR)
      ? true
      : permissions.includes(PERMISSIONS_PAGE.SEARCH)

  const hasAllChats =
    !roles || !permissions || !roles.includes(USER_ROLES.OPERATOR)
      ? true
      : permissions.includes(PERMISSIONS_PAGE.ALL_CHATS)

  const hasDashboardWFM = !roles || !permissions || !roles.includes(USER_ROLES.OPERATOR)

  if (isMobileMode) {
    return <DialogsPageMobile inWorkDialogsCount={inWorkDialogsCount} newDialogsCount={newMyDialogsCount} />
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const receiveMessage = useCallback((event) => {
    if (event.origin !== window.origin) {
      if (event.data?.action === 'change-agent-status' && event.data.data?.status) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { emptyQueueTimeMs, emptyQueueNoActiveChatsMs, emptyQueueSlotsAvailableMs } = useEmptyQueue(
          emptyQueue,
          setEmptyQueue
        )
        main.showGlobalLoader(true)
        Meteor.invoke('users.changeAgentStatus', {
          status: event.data.data.status,
          emptyQueueTimeMs,
          emptyQueueNoActiveChatsMs,
          emptyQueueSlotsAvailableMs
        })
          .catch((err) => {
            processError(err, main)
          })
          .finally(() => {
            main.showGlobalLoader(false)
          })
      }
    }
  }, [])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (window.parent !== window) {
      window.addEventListener('message', receiveMessage, false)
    }
    return () => {
      window.removeEventListener('message', receiveMessage)
    }
  }, [])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (window.parent !== window && !loadingChats) {
      const currentUserId = Meteor.userId()
      window.parent.postMessage(
        {
          event: 'counters-updated',
          agent: {
            id: currentUserId,
            email: userEmails[0]?.address,
            departmentId: currentUserDepartmentId,
            groupId: currentUserGroupId
          },
          counters: {
            newChats: newMyDialogsCount || 0,
            myChats: inWorkDialogsCount || 0,
            unreadMyChats: unreadMyChats
          }
        },
        '*'
      )
    }
  }, [newMyDialogsCount, inWorkDialogsCount, unreadMyChats, loadingChats])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const now = +new Date()
    const isEmptyQueue = newMyDialogsCount === 0
    setEmptyQueue((prev) => ({
      timeMs: prev.time ? prev.timeMs + (now - prev.time) : prev.timeMs,
      noActiveChatsMs: prev.noActiveChats ? prev.noActiveChatsMs + (now - prev.time) : prev.noActiveChatsMs,
      slotsAvailableMs: prev.slotsAvailable
        ? prev.slotsAvailableMs + (now - prev.time)
        : prev.slotsAvailableMs,
      time: isEmptyQueue ? now : null,
      noActiveChats: isEmptyQueue && !inWorkDialogsCount ? now : null,
      slotsAvailable:
        isEmptyQueue && AgentHelpers.canAcceptHotChats(inWorkDialogsCount, 'auto').auto ? now : null
    }))
  }, [newMyDialogsCount, inWorkDialogsCount])

  return (
    <DialogsPageDesktop
      inWorkDialogsCount={inWorkDialogsCount}
      newDialogsCount={newDialogsCount}
      newMyDialogsCount={newMyDialogsCount}
      emptyQueue={emptyQueue}
      setEmptyQueue={setEmptyQueue}
      hasSearch={hasSearch}
      hasAllChats={hasAllChats}
      hasDashboardWFM={hasDashboardWFM}
      totalDialogsCount={totalDialogsCount}
    />
  )
}

DialogsPage.propTypes = {
  emptyQueue: object.isRequired,
  setEmptyQueue: func.isRequired
}
