import React, { useContext, useEffect, useMemo, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { Trans, useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { Popup } from '@flomni/components/dist/components/popup/popup'
import { PopupButtons } from '@flomni/components/dist/components/popup/buttons'
import { PopupContent } from '@flomni/components/dist/components/popup/content'
import { PopupTitle } from '@flomni/components/dist/components/popup/title'

import { Meteor, useTracker } from '../../../../../../../../../meteorAdapter';
import { array, func, object, string } from 'prop-types'
import { store } from '../../../../../../../../state/dialogs'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs } from '@flomni/components/dist/components/tabs/tabs'
import { TabsHeader } from '@flomni/components/dist/components/tabs/tabs-header'
import { Tab } from '@flomni/components/dist/components/tabs/tab'
import { TabsBody } from '@flomni/components/dist/components/tabs/tabs-body'
import { Panel } from '@flomni/components/dist/components/tabs/panel'
import AgentItem from './agent-item'
import { processError } from '../../../../../../../../services/helpers'
import { Input } from '@flomni/components/dist/components/input'
import { SvgIconSearch } from '@flomni/components/dist/components/svg/feathers/SvgIconSearch'
import { SortPopup } from '../../../../../../shared/sort-popup'
import { FiltersPopup } from '../../../../../../shared/filters-popup'
import { Staff } from '../../../../../../../../customStores'
import { SvgIconUser } from '@flomni/components/dist/components/svg/feathers/SvgIconUser'
import { getDialogName } from '../../../../../../../../utils/common'
import GroupItem from './group-item'
import { ButtonWithLoader } from '../../../../../../../shared/button-with-loader'
import { USER_STATUSES } from '../../../../../../../../../constants'

const AgentsDialog = ({ agentIds, receiver, chatCaption, clientProfile, onSaved, onCancel }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const { selectedDialogId } = useContext(store)
  const currentUserGroupId = useSelector(({ main }) => main.currentUserGroupId)
  const currentUserId = Meteor.userId()
  const [showTransferConfirmGroupId, setShowTransferConfirmGroupId] = useState(null)
  const [selectedAgentIds, setSelectedAgentIds] = useState(agentIds)
  const [departments, setDepartments] = useState([])
  const [tags, setTags] = useState([])
  const [isDirty, setIsDirty] = useState(false)
  const [showLoader, setShowLoader] = useState(null)
  const [search, setSearch] = useState('')
  const [groupSearch, setGroupSearch] = useState('')
  const [filterStatuses, setFilterStatuses] = useState(null)
  const [filterAllowedTags, setFilterAllowedTags] = useState(null)
  const [filterDisallowedTags, setFilterDisallowedTags] = useState(null)

  const filterItems = useMemo(
    () => [
      {
        name: t('dlg:status'),
        id: 'status',
        isChecked: filterStatuses !== null,
        defaultValue: false,
        items: [
          {
            name: t('dlg:online'),
            id: 'online',
            isChecked: filterStatuses ? filterStatuses.includes('online') : false,
            defaultValue: false
          },
          {
            name: t('dlg:away'),
            id: 'standby',
            isChecked: filterStatuses ? filterStatuses.includes('standby') : false,
            defaultValue: false
          },
          {
            name: t('dlg:offline'),
            id: 'offline',
            isChecked: filterStatuses ? filterStatuses.includes('offline') : false,
            defaultValue: false
          }
        ]
      }
    ],
    [filterStatuses]
  )

  const filterGroupItems = useMemo(() => {
    return [
      {
        name: t('dlg:allowedTags'),
        id: 'allowedTags',
        isChecked: filterAllowedTags !== null,
        defaultValue: false,
        items: tags.map((tag) => {
          return {
            name: tag.name,
            id: tag._id,
            isChecked: filterAllowedTags ? filterAllowedTags.includes(tag._id) : false,
            defaultValue: false
          }
        })
      },
      {
        name: t('dlg:disallowedTags'),
        id: 'disallowedTags',
        isChecked: filterDisallowedTags !== null,
        defaultValue: false,
        items: tags.map((tag) => {
          return {
            name: tag.name,
            id: tag._id,
            isChecked: filterDisallowedTags ? filterDisallowedTags.includes(tag._id) : false,
            defaultValue: false
          }
        })
      }
    ]
  }, [tags, filterAllowedTags, filterDisallowedTags])

  const [sortFieldName, setSortFieldName] = useState('status')
  const [sortFieldOrder, setSortFieldOrder] = useState(-1)

  const sortItems = useMemo(() => {
    return {
      checkedId: sortFieldName,
      defaultCheckedId: 'status',
      items: [
        {
          title: t('dlg:status'),
          id: 'status',
          defaultOrder: -1,
          ascLabel: t('dlg:onlineFirst'),
          descLabel: t('dlg:offlineFirst'),
          order: sortFieldName === 'status' ? sortFieldOrder : -1
        },
        {
          title: t('dlg:fullName'),
          id: 'name',
          defaultOrder: -1,
          ascLabel: 'A-Z',
          descLabel: 'Z-A',
          order: sortFieldName === 'name' ? sortFieldOrder : -1
        }
      ]
    }
  }, [sortFieldName, sortFieldOrder])

  const [sortGroupFieldName, setSortGroupFieldName] = useState('name')
  const [sortGroupFieldOrder, setSortGroupFieldOrder] = useState(-1)

  const sortGroupItems = useMemo(() => {
    return {
      checkedId: sortGroupFieldName,
      defaultCheckedId: 'name',
      items: [
        {
          title: t('dlg:groupName'),
          id: 'name',
          defaultOrder: -1,
          ascLabel: 'A-Z',
          descLabel: 'Z-A',
          order: sortGroupFieldName === 'name' ? sortGroupFieldOrder : -1
        }
      ]
    }
  }, [sortGroupFieldName, sortGroupFieldOrder])

  const users = useTracker(() => {
    return Staff.find(
      {
        groupsIds: currentUserGroupId
      },
      {
        fields: {
          status: 1,
          profile: 1,
          doNotDisturb: 1,
          departmentId: 1
        },
        sort: {
          'profile.name': 1
        }
      }
    )
      .map(({ _id, profile = {}, status, doNotDisturb, departmentId }) => ({
        id: _id,
        status,
        profile,
        doNotDisturb,
        departmentId
      }))
      .filter(({ profile }) => !profile.isBlocked)
  }, [])

  useEffect(() => {
    if (currentUserGroupId) {
      Meteor.invoke('groups.getGroupDepartments', { groupId: currentUserGroupId })
        .then(({ items }) => {
          setDepartments(items || [])
        })
        .catch((err) => {
          processError(err, main)
        })
      Meteor.invoke('groups.getGroupTags', { groupId: currentUserGroupId })
        .then(({ items }) => {
          setTags((items || []).filter((tag) => tag.kind !== 'session'))
        })
        .catch((err) => {
          processError(err, main)
        })
    }
  }, [currentUserGroupId])

  const handlerClickOnCancel = () => {
    if (isDirty) {
      onSaved(selectedAgentIds)
    } else {
      onCancel()
    }
  }

  const onRemove = async (user) => {
    setShowLoader(user.id)
    await new Promise((resolve) => setTimeout(resolve, 800))
    try {
      await Meteor.invoke('toggleDialogUser', selectedDialogId, user.id)
      setShowLoader(null)
      setSelectedAgentIds(selectedAgentIds.filter((agentId) => agentId !== user.id))
      setIsDirty(true)
    } catch (err) {
      setShowLoader(null)
      processError(err, main)
    }
  }

  const onInvite = async (user) => {
    setShowLoader(user.id)
    await new Promise((resolve) => setTimeout(resolve, 800))
    try {
      await Meteor.invoke('toggleDialogUser', selectedDialogId, user.id)
      main.showSuccessSystemNotification(t('dlg:userInvited', { name: user.profile?.name || user.id }))
      setShowLoader(null)
      setSelectedAgentIds([...selectedAgentIds, user.id])
      setIsDirty(true)
    } catch (err) {
      setShowLoader(null)
      processError(err, main)
    }
  }

  const onTransfer = async (group, leaveDialog = false) => {
    setShowLoader(group._id)
    await new Promise((resolve) => setTimeout(resolve, 800))
    try {
      await Meteor.invoke('dialogs.assignDialogDepartments', {
        dialogId: selectedDialogId,
        groupId: currentUserGroupId,
        departments: [group._id],
        leaveDialog
      })
      main.showSuccessSystemNotification(t('dlg:transferredToGroup', { name: group.name }))
      setShowLoader(null)
      onCancel()
    } catch (err) {
      setShowLoader(null)
      processError(err, main)
    }
  }

  const onSetFilter = (items) => {
    let selectedStatuses = null
    const statusFilter = items.find((item) => item.id === 'status')
    if (statusFilter && statusFilter.isChecked) {
      selectedStatuses = []
      statusFilter.items.forEach((status) => {
        if (status.isChecked) {
          selectedStatuses.push(status.id)
        }
      })
    }
    setFilterStatuses(selectedStatuses)
  }

  const onSetGroupFilter = (items) => {
    let selectedAllowedTags = null
    let selectedDisallowedTags = null
    items.forEach((filterItem) => {
      if (filterItem.isChecked) {
        if (filterItem.id === 'allowedTags') {
          selectedAllowedTags = filterItem.items.filter((item) => item.isChecked).map(({ id }) => id)
        }
        if (filterItem.id === 'disallowedTags') {
          selectedDisallowedTags = filterItem.items.filter((item) => item.isChecked).map(({ id }) => id)
        }
      }
    })
    setFilterAllowedTags(selectedAllowedTags)
    setFilterDisallowedTags(selectedDisallowedTags)
  }

  const onSetSort = (config) => {
    const item = config.items.find((item) => item.id === config.checkedId)
    setSortFieldOrder(item.order)
    setSortFieldName(config.checkedId)
  }

  const onSetGroupSort = (config) => {
    const item = config.items.find((item) => item.id === config.checkedId)
    setSortGroupFieldOrder(item.order)
    setSortGroupFieldName(config.checkedId)
  }

  const isGroupVisibleByFilters = (group) => {
    let isVisibleBySearch = true
    if (groupSearch) {
      const lowerSearch = groupSearch.toLowerCase()
      isVisibleBySearch = group.name.toLowerCase().indexOf(lowerSearch) !== -1
    }
    const isVisibleByAllowedTags = filterAllowedTags
      ? group.allowedTags.some((id) => filterAllowedTags.includes(id))
      : true
    const isVisibleByDisallowedTags = filterDisallowedTags
      ? group.disallowedTags.some((id) => filterDisallowedTags.includes(id))
      : true
    return isVisibleBySearch && isVisibleByAllowedTags && isVisibleByDisallowedTags
  }

  const isVisibleByFilters = (user) => {
    let isVisibleBySearch = true
    if (search) {
      const lowerSearch = search.toLowerCase()
      const name = user.profile?.name || user.id
      isVisibleBySearch = name.toLowerCase().indexOf(lowerSearch) !== -1
    }
    const isVisibleByFilter = filterStatuses ? filterStatuses.includes(user.status) : true
    return isVisibleBySearch && isVisibleByFilter
  }

  let usersToInvite = []
  const usersAdded = []
  users.forEach((user) => {
    if (selectedAgentIds.includes(user.id)) {
      if (user.id === currentUserId) {
        usersAdded.unshift(user)
      } else {
        usersAdded.push(user)
      }
    } else if (isVisibleByFilters(user)) {
      usersToInvite.push(user)
    }
  })
  if (sortFieldName === 'status') {
    usersToInvite = [
      ...usersToInvite.filter((user) =>
        sortFieldOrder === -1 ? user.status !== 'offline' : user.status === 'offline'
      ),
      ...usersToInvite.filter((user) =>
        sortFieldOrder === -1 ? user.status === 'offline' : user.status !== 'offline'
      )
    ]
  }
  if (sortFieldName === 'name') {
    usersToInvite.sort((a, b) => {
      const aName = a.profile?.name || a.id
      const bName = b.profile?.name || b.id
      return sortFieldOrder === -1 ? aName.localeCompare(bName) : bName.localeCompare(aName)
    })
  }

  const groupsToTransfers = departments.filter(isGroupVisibleByFilters).map((department) => {
    return {
      ...department,
      onlineCount: users.filter((user) => {
        return user.status === USER_STATUSES.ONLINE && !user.doNotDisturb && user.departmentId === department._id
      }).length
    }
  })
  if (sortGroupFieldName === 'name') {
    groupsToTransfers.sort((a, b) =>
      sortGroupFieldOrder === -1 ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
    )
  }

  const chatName = getDialogName(chatCaption, clientProfile, receiver, t)

  return (
    <Popup open variation='secondary'>
      <PopupTitle title={t('dlg:chatParticipants')} />
      <PopupContent classes={{ root: css('content') }}>
        <Tabs view='secondary'>
          <TabsHeader classes={{ root: css('tabs-header') }}>
            <Tab>{t('dlg:inChat')}</Tab>
            <Tab>{t('dlg:invitePerson')}</Tab>
            <Tab>{t('dlg:transferToGroup')}</Tab>
          </TabsHeader>
          <TabsBody>
            <Panel>
              {usersAdded.length > 0 && (
                <>
                  <div className={css('header')}>
                    <div className={css('title')}>{t('dlg:chatParticipants')}</div>
                    <div className={css('description')}>{t('dlg:invitedAndPendingParticipants')}</div>
                  </div>
                  <div>
                    {usersAdded.map((user) => (
                      <AgentItem
                        key={user.id}
                        item={user}
                        showLoader={showLoader === user.id}
                        isDisabled={!!showLoader}
                        isInvite={false}
                        currentUserId={currentUserId}
                        onRemove={() => onRemove(user)}
                        chatName={chatName}
                      />
                    ))}
                  </div>
                </>
              )}
              {usersAdded.length === 0 && (
                <div className={css('no-items')}>
                  <div className={css('no-items-header')}>
                    <div className={css('no-items-icon')}>
                      <SvgIconUser width={22} height={22} />
                    </div>
                    <div>
                      <div className={css('no-items-title')}>{t('dlg:noParticipantsInThisChat')}</div>
                      <div className={css('no-items-description')}>
                        <Trans
                          i18nKey='dlg:youCanInviteAgents'
                          components={[0, <span className={css('no-items-description-mid')}>0</span>, 1]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Panel>
            <Panel>
              {users.length > 0 && (
                <>
                  <div className={css('header')}>
                    <div className={css('title')}>{t('dlg:invitePeopleToThisChat')}</div>
                    <div className={css('description')}>{t('dlg:invitePeopleToThisChatDescription')}</div>
                    <div className={css('filter')}>
                      <Input
                        icons={[<SvgIconSearch />]}
                        variation='secondary'
                        shaded
                        value={search}
                        placeholder={t('dlg:searchByMemberName')}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <SortPopup config={sortItems} onDone={onSetSort} onReset={onSetSort} />
                      <FiltersPopup items={filterItems} onDone={onSetFilter} onReset={onSetFilter} />
                    </div>
                  </div>
                  <div>
                    {usersToInvite.map((user) => (
                      <AgentItem
                        key={user.id}
                        item={user}
                        showLoader={showLoader === user.id}
                        isDisabled={!!showLoader}
                        isInvite
                        currentUserId={currentUserId}
                        onInvite={() => onInvite(user)}
                        chatName={chatName}
                      />
                    ))}
                  </div>
                </>
              )}
              {usersToInvite.length === 0 && (
                <div className={css('no-items')}>
                  <div className={css('no-items-header')}>
                    <div className={css('no-items-icon')}>
                      <SvgIconUser width={22} height={22} />
                    </div>
                    <div>
                      <div className={css('no-items-title')}>{t('dlg:noAvailableAdminsToInvite')}</div>
                      <div className={css('no-items-description')}>
                        {t('dlg:youDontHaveAdminsOrAlreadyAddAllAvailableAdmins')}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Panel>
            <Panel>
              <div className={css('header')}>
                <div className={css('title')}>{t('dlg:transferToGroup')}</div>
                <div className={css('description')}>{t('dlg:transferToGroupDescription')}</div>
                <div className={css('filter')}>
                  <Input
                    icons={[<SvgIconSearch />]}
                    variation='secondary'
                    shaded
                    value={groupSearch}
                    placeholder={t('dlg:searchByGroupName')}
                    onChange={(e) => setGroupSearch(e.target.value)}
                  />
                  <SortPopup config={sortGroupItems} onDone={onSetGroupSort} onReset={onSetGroupSort} />
                  <FiltersPopup
                    items={filterGroupItems}
                    onDone={onSetGroupFilter}
                    onReset={onSetGroupFilter}
                  />
                </div>
              </div>
              <div>
                {groupsToTransfers.map((group) => (
                  <React.Fragment key={group._id}>
                    <GroupItem
                      item={group}
                      showLoader={showLoader === group._id}
                      isDisabled={!!showLoader}
                      onTransfer={() =>
                        selectedAgentIds.includes(currentUserId)
                          ? setShowTransferConfirmGroupId(group._id)
                          : onTransfer(group)
                      }
                    />
                    {group._id === showTransferConfirmGroupId && (
                      <div className={css('confirm-block')}>
                        <div className={css('confirm-block-title')}>{t('dlg:transferConfirmMessage')}</div>
                        <div className={css('confirm-block-buttons')}>
                          <ButtonWithLoader
                            view='light'
                            text={t('dlg:noStay')}
                            onClick={() => onTransfer(group)}
                            spinnerSize={10}
                            spinnerStrokeWidth={14}
                            disabled={!!showLoader}
                          />
                          <ButtonWithLoader
                            view='warning'
                            text={t('dlg:yesLeave')}
                            onClick={() => onTransfer(group, true)}
                            spinnerSize={10}
                            spinnerStrokeWidth={14}
                            disabled={!!showLoader}
                          />
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </Panel>
          </TabsBody>
        </Tabs>
      </PopupContent>
      <PopupButtons>
        <SecondaryButton view='light' onClick={handlerClickOnCancel}>
          <SecondaryButtonText>{t('dlg:close')}</SecondaryButtonText>
        </SecondaryButton>
      </PopupButtons>
    </Popup>
  )
}

AgentsDialog.propTypes = {
  agentIds: array,
  receiver: string,
  chatCaption: string,
  clientProfile: object,
  onSaved: func.isRequired,
  onCancel: func.isRequired
}

export default AgentsDialog
