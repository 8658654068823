import React, { useContext, useEffect, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { func, object } from 'prop-types'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { SvgIconEdit } from '@flomni/components/dist/components/svg/feathers/SvgIconEdit'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import classnames from 'classnames'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { ButtonWithLoader } from '../../../../../../../shared/button-with-loader'
import { Input } from '@flomni/components/dist/components/input'
import { useForm } from 'react-hook-form'
import { Meteor } from '../../../../../../../../../meteorAdapter';
import { store } from '../../../../../../../../state/dialogs'
import { processError } from '../../../../../../../../services/helpers'
import { useDispatch } from 'react-redux'
import { Select } from '@flomni/components/dist/components/select'
import { Tooltip } from '@flomni/components/dist/components/tooltip'
import { CopyField } from '../../../../../../shared/copy-field'

const EditableCrmField = ({ field, fieldValue, onUpdated, onClose }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { selectedDialogId } = useContext(store)
  const { main } = useDispatch()
  const [showLoader, setShowLoader] = useState(false)
  const { register, unregister, setValue, watch, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      value: fieldValue
    }
  })
  const { isValid, isDirty } = formState

  const value = watch('value')

  useEffect(() => {
    if (field.valuesList.length > 0) {
      register('value')
    }

    return () => {
      unregister('value')
    }
  }, [])

  const onCancel = () => {
    setValue('value', fieldValue)
    onClose()
  }

  const handleEnter = (e) => {
    if (e.key === 'Enter' && isValid && isDirty) {
      e.preventDefault()
      onUpdate()
    }
  }

  const onUpdate = async () => {
    setShowLoader(true)
    await new Promise((resolve) => setTimeout(resolve, 800))
    try {
      await Meteor.invoke('dialogs.updateClientInfo', {
        dialogId: selectedDialogId,
        fieldKey: field.fieldKey,
        value
      })
      setShowLoader(false)
      onUpdated(value)
    } catch (err) {
      setShowLoader(false)
      processError(err, main)
    }
  }

  return (
    <form>
      {['Number', 'String', 'URL'].includes(field.type) && field.valuesList.length === 0 && (
        <Input
          type={field.type === 'Number' ? 'number' : 'text'}
          variation='secondary'
          placeholder={t('dlg:typeValue')}
          onKeyPress={handleEnter}
          name='value'
          ref={register()}
        />
      )}
      {field.valuesList.length > 0 && (
        <Select
          variation='secondary'
          options={field.valuesList.map((val) => ({ value: val, label: val }))}
          value={value ? { value, label: value } : null}
          menuPortalTarget={document.body}
          placeholder={field.name}
          onChange={(option) => setValue('value', option.value, { shouldDirty: true })}
          isCreatable={field.allowNotListed}
          formatCreateLabel={(value) => t('dlg:createNewValueLabel', { value })}
        />
      )}
      <div className={css('edit-buttons')}>
        <SecondaryButton view='light' onClick={onCancel}>
          <SecondaryButtonText>{t('dlg:cancel')}</SecondaryButtonText>
        </SecondaryButton>
        <ButtonWithLoader
          disabled={!isValid || !isDirty}
          text={t('dlg:update')}
          onClick={(e) => {
            e.preventDefault()
            onUpdate()
          }}
          spinnerSize={10}
          spinnerStrokeWidth={14}
          isLoading={showLoader}
        />
      </div>
    </form>
  )
}

export const InfoCrmField = ({ field, onUpdated }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const [isEdit, setIsEdit] = useState(false)

  const fieldValue = field.value !== undefined && field.value !== null ? field.value.toString() : null
  const isEditable = field.type !== 'Boolean' && !!field.editableByAgents

  const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children

  const ClickableLink = (url) => (
    <a href={url} target='_blank' rel="noreferrer">{url}</a>
  )

  const FormatedValue = () => (fieldValue && field.type === 'URL' ? ClickableLink(fieldValue) : fieldValue ||
    <span className={css('no-data')}>{t('dlg:noData')}</span>)

  return (
    <div className={css(classnames('item', isEdit ? '--edit' : null))}>
      <div className={css('title')}>{t(`dlg:${field.name}`)}</div>
      {!isEdit && isEditable && (
        <ConditionalWrapper
          condition={fieldValue && fieldValue.length > 20}
          wrapper={children => <Tooltip variation='secondary' content={fieldValue}>{children}</Tooltip>}
        >
          <div className={css(classnames('value', isEditable ? '--editable' : null))}>
            <div className={css('text')}>{FormatedValue()}</div>
            <PlateButton classes={{ root: css('edit-btn') }} noBg onClick={() => setIsEdit(true)}>
              <PlateButtonIcon>
                <SvgIconEdit />
              </PlateButtonIcon>
            </PlateButton>
          </div>
        </ConditionalWrapper>
      )}
      {!isEdit && !isEditable && (
        <CopyField value={FormatedValue()} />
      )}
      {isEdit && (
        <EditableCrmField
          field={field}
          fieldValue={fieldValue}
          onClose={() => setIsEdit(false)}
          onUpdated={(value) => {
            setIsEdit(false)
            onUpdated(field.fieldKey, value)
          }}
        />
      )}
    </div>
  )
}

InfoCrmField.propTypes = {
  field: object,
  onUpdated: func.isRequired
}
