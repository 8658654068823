import React, { useContext, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { store } from '../../../../../state/dialogs'
import { array, bool, func, number, object, string } from 'prop-types'
import { DialogsList } from './dialogs-list'
import { MessagesList } from './messages-list'
import { ClientDetails } from './client-details'

export const ConversationsPageMobile = ({
  inWorkDialogsCount,
  newDialogsCount,
  onAssignedCurrentUser,
  banResult,
  clientId,
  updateBanResult,
  crmFields,
  relatedChats,
  recipient,
  metaDataLoading,
  setCrmFields,
  setRecipient
}) => {
  const css = useStyles(styles)
  const { selectedDialogId } = useContext(store)
  const [showDetails, setShowDetails] = useState(false)

  return (
    <div className={css('container')}>
      {!selectedDialogId && (
        <DialogsList inWorkDialogsCount={inWorkDialogsCount} newDialogsCount={newDialogsCount} />
      )}
      {selectedDialogId && !showDetails && (
        <MessagesList
          selectedDialogId={selectedDialogId}
          banResult={banResult}
          updateBanResult={updateBanResult}
          clientId={clientId}
          onAssignedCurrentUser={onAssignedCurrentUser}
          onOpenDetails={() => setShowDetails(true)}
          crmFields={crmFields}
        />
      )}
      {selectedDialogId && showDetails && (
        <ClientDetails
          banResult={banResult}
          updateBanResult={updateBanResult}
          onClose={() => setShowDetails(false)}
          crmFields={crmFields}
          relatedChats={relatedChats}
          recipient={recipient}
          metaDataLoading={metaDataLoading}
          setCrmFields={setCrmFields}
          setRecipient={setRecipient}
        />
      )}
    </div>
  )
}

ConversationsPageMobile.propTypes = {
  inWorkDialogsCount: number,
  newDialogsCount: number,
  onAssignedCurrentUser: func,
  banResult: object,
  clientId: string,
  updateBanResult: func,
  crmFields: array,
  relatedChats: array,
  recipient: object,
  metaDataLoading: bool,
  setCrmFields: func.isRequired,
  setRecipient: func.isRequired
}
