import React, { useContext, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { func, object } from 'prop-types'
import { useForm } from 'react-hook-form'
import { ControlContainer } from '@flomni/components/dist/components/control-container'
import { Input } from '@flomni/components/dist/components/input'
import { ButtonWithLoader } from '../../../../../../../../shared/button-with-loader'
import { DateSelector } from '../../../../../../search/search/filter/date/date-selector'
import { TimeSelector } from '../../../../../../search/search/filter/date/time-selector'
import moment from 'moment'
import { DateUtils } from '@flomni/modules/dist/services/date'
import { Meteor } from '../../../../../../../../../../meteorAdapter';
import { store } from '../../../../../../../../../state/dialogs'
import { useDispatch, useSelector } from 'react-redux'
import { processError } from '../../../../../../../../../services/helpers'

const ReminderForm = ({ reminder, onSave, onCancel }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { selectedDialogId } = useContext(store)
  const { main } = useDispatch()
  const currentUserGroupId = useSelector(({ main }) => main.currentUserGroupId)
  const [showLoader, setShowLoader] = useState(false)

  const reminderIntendedAt = reminder ? moment(reminder.intendedAt) : null

  const { register, formState, errors, getValues, setValue, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      date: reminderIntendedAt ? reminderIntendedAt.format(DateUtils.DATE_FORMAT) : null,
      time: reminderIntendedAt ? reminderIntendedAt.hours().toString() : null,
      message: reminder?.message || null
    }
  })

  const date = watch('date')
  const time = watch('time')

  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const todayTimestamp = today.getTime()

  const isTodaySelected = date === moment().format(DateUtils.DATE_FORMAT)

  const onHandlerSave = async (e) => {
    e.preventDefault()
    setShowLoader(true)
    await new Promise((resolve) => setTimeout(resolve, 800))

    const values = getValues()
    const intendedAt = moment(values.date, DateUtils.DATE_FORMAT)
      .add(parseInt(values.time), 'hours')
      .toISOString()

    try {
      const values = getValues()
      if (reminder) {
        await Meteor.invoke('reminders.updateReminder', {
          groupId: currentUserGroupId,
          dialogId: selectedDialogId,
          reminderId: reminder._id,
          intendedAt,
          message: values.message
        })
      } else {
        await Meteor.invoke('reminders.createReminder', {
          groupId: currentUserGroupId,
          dialogId: selectedDialogId,
          intendedAt,
          message: values.message
        })
      }
      setShowLoader(false)
      onSave()
    } catch (err) {
      setShowLoader(false)
      processError(err, main)
    }
  }

  const handlerClickOnCancel = (e) => {
    e.preventDefault()
    onCancel()
  }

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className={css(['group', '--pickers'])}>
        <input hidden name='date' ref={register({ required: true })} />
        <input hidden name='time' ref={register({ required: true })} />
        <div className={css('picker-item')}>
          <div>{t('dlg:date')}</div>
          <DateSelector
            date={date}
            onChange={(date) => {
              setValue('date', date, { shouldDirty: true, shouldValidate: true })
              setValue('time', undefined, { shouldDirty: true, shouldValidate: true })
            }}
            filterDate={(date) => date.getTime() >= todayTimestamp}
          />
        </div>
        <div className={css('picker-item')}>
          <div>{t('dlg:time')}</div>
          <TimeSelector
            disablePastHours={isTodaySelected}
            time={time}
            onChange={(time) => setValue('time', time, { shouldDirty: true, shouldValidate: true })}
          />
        </div>
      </div>
      <div className={css('group')}>
        <div className={css('title')}>{t('dlg:message')}</div>
        <ControlContainer variation='secondary' warning={errors.message ? errors.message.message : null}>
          <Input
            textArea
            variation='secondary'
            placeholder={t('dlg:typeMessage')}
            warning={!!errors.message}
            name='message'
            ref={register({
              required: t('dlg:messageRequired'),
              minLength: 3
            })}
          />
        </ControlContainer>
      </div>
      <div className={css(['group', '--buttons'])}>
        <SecondaryButton view='light' onClick={handlerClickOnCancel}>
          <SecondaryButtonText>{t('dlg:cancel')}</SecondaryButtonText>
        </SecondaryButton>
        <ButtonWithLoader
          text={t('dlg:set')}
          onClick={onHandlerSave}
          disabled={!formState.isValid || !formState.isDirty}
          spinnerSize={10}
          spinnerStrokeWidth={14}
          isLoading={showLoader}
        />
      </div>
    </form>
  )
}

ReminderForm.propTypes = {
  reminder: object,
  onSave: func.isRequired,
  onCancel: func.isRequired
}

export default ReminderForm
