import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { object } from 'prop-types'
import { Avatar } from '../../../../../../shared/avatar'
import { Tag } from '@flomni/components/dist/components/tag'
import { DateUtils } from '@flomni/modules/dist/services/date'

export const RelatedChatItem = ({ chat }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()

  return (
    <div key={chat.userHash} className={css('chat-item')}>
      <div className={css('chat-item-body')}>
        <div className={css('left')}>
          <div className={css('avatars')}>
            <Avatar url={chat.profile?.avatarUrl} colorName={chat.userHash} />
            <Avatar url={null} cls={css('avatar-small')} />
          </div>
        </div>
        <div>
          <div className={css('header')}>
            <div className={css('title')}>
              <div className={css('name')}>{chat.userHash}</div>
            </div>
            <div className={css('date')}>
              {DateUtils.format(chat.lastActionAt, DateUtils.DATE_TIME_FORMAT)}
            </div>
          </div>
          {chat.tags && chat.tags.length > 0 && (
            <div className={css('tags')}>
              {chat.tags.map((tag, index) => (
                <Tag
                  color='gray'
                  variation='secondary'
                  view='stroked'
                  key={index}
                  classes={{ root: css('tag') }}
                >
                  {tag}
                </Tag>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className={css('chat-item-footer')}>
        <div>
          <div className={css('footer-item-header')}>{t('dlg:thread')}</div>
          <div className={css('footer-item-body')}>
            <span>{chat.threadName || '-'}</span>
            {chat.system?.sessionHistory?.length && (
              <Tag color='gray' variation='secondary' view='filled'>
                {chat.system.sessionHistory.length}
              </Tag>
            )}
          </div>
        </div>
        <div className={css('separator')} />
        <div>
          <div className={css('footer-item-header')}>{t('dlg:channel')}</div>
          <div className={css('footer-item-body')}>
            <span>{chat.messenger}</span>
          </div>
        </div>
        <div className={css('separator')} />
        <div>
          <div className={css('footer-item-header')}>{t('dlg:language')}</div>
          <div className={css('footer-item-body')}>
            <span>{chat.system?.language || '-'}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

RelatedChatItem.propTypes = {
  chat: object
}
