import React, { useContext } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { ChatItemInfo } from '../shared/chat-item-info'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { useDispatch, useSelector } from 'react-redux'
import { ChatItem } from '../shared/chat-item'
import { array, bool, number } from 'prop-types'
import { Spinner } from '@flomni/components/dist/components/spinner'
import { store } from '../../../../../../../state/dialogs'
import DialogHelpers from '../../../../../../../services/dialogHelpers'

export const ProgressList = ({ chats, queueCount, loading }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const { selectedDialogId, dispatch } = useContext(store)
  const [distributionRequest, currentUserSounds] = useSelector(({ main }) => [
    main.distributionRequest,
    main.currentUserSounds
  ])

  const addExtraChat = () => {
    DialogHelpers.requestHottestDialog(
      distributionRequest,
      main,
      currentUserSounds,
      'Manual request',
      'manual'
    )
  }

  return (
    <div className={css('progress-body')}>
      <div className={css('chat-items')}>
        {chats.map((chat) => (
          <ChatItem
            key={chat._id}
            chat={chat}
            isInactiveTimerVisible
            isInboundCountVisible
            isSelected={selectedDialogId === chat._id}
            onSelect={() => dispatch({ type: 'setSelectedDialog', selectedDialog: chat })}
          />
        ))}
        {!loading && chats.length === 0 && (
          <ChatItemInfo
            icon='ball'
            title={t('dlg:allNowCompleted')}
            description={t('dlg:newChatsShowHere')}
          />
        )}
        {loading && (
          <div className={css('loader')}>
            <div className={css('spinner')}>
              <Spinner strokeWidth={5} />
            </div>
          </div>
        )}
      </div>
      {queueCount > 0 && (
        <div className={css('footer')}>
          <span>{t('dlg:countChatsInQueue', { count: queueCount })}</span>
          <SecondaryButton onClick={addExtraChat}>
            <SecondaryButtonText>{t('dlg:addExtraChat')}</SecondaryButtonText>
          </SecondaryButton>
        </div>
      )}
    </div>
  )
}

ProgressList.props = {
  chats: array,
  queueCount: number,
  loading: bool
}
