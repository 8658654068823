import { Meteor, Match } from '../../meteorAdapter';
import SoundHelpers from './soundHelpers'
import { processError } from './helpers'

export default class DialogHelpers {
  static requestHottestDialog = (
    { auto: canAcceptHotChats, chatsNeededQuantity, currentUserDepartmentId } = {},
    main,
    sounds,
    triggerDesc,
    mode = 'auto'
  ) => {
    const currentUserId = Meteor.userId()

    const skipDistributionRequest =
      !!DialogHelpers.requestHottestDialog.__inProgress ||
      (canAcceptHotChats !== true && mode === 'auto') ||
      Match.isDocumentId(currentUserId) === false

    // TODO Remove debug log
    console.log(
      `Distribution requested from ${triggerDesc} for department ${currentUserDepartmentId}, chats needed - ${chatsNeededQuantity}, skip - ${skipDistributionRequest}`
    )
    if (skipDistributionRequest) {
      return
    }

    DialogHelpers.requestHottestDialog.__inProgress = true
    Meteor.invoke('dialogs.requestHotChatsDistribution', {
      departmentId: currentUserDepartmentId,
      mode
    })
      .then((dialogId) => {
        if (Match.isDocumentId(dialogId)) {
          if (sounds !== 'DISABLED') {
            SoundHelpers.playOnlineNotification()
          }

          if (mode === 'auto' && chatsNeededQuantity > 1) {
            Meteor.defer(() => {
              DialogHelpers.requestHottestDialog(
                { auto: true, chatsNeededQuantity: chatsNeededQuantity - 1, currentUserDepartmentId },
                main,
                sounds,
                'Eager distribution'
              )
            })
          }
        }
      })
      .catch((err) => {
        processError(err, main)
      })
      .finally(() => {
        DialogHelpers.requestHottestDialog.__inProgress = false
      })
  }
}
