import React, { useEffect, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { Trans, useTranslation } from 'react-i18next'
import { Tag } from '@flomni/components/dist/components/tag'
import { SvgIconShow } from '@flomni/components/dist/components/svg/feathers/SvgIconShow'
import { Meteor } from '../../../../../../meteorAdapter';
import { Spinner } from '@flomni/components/dist/components/spinner'
import { DateUtils } from '@flomni/modules/dist/services/date'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { SvgIconArrowLeft } from '@flomni/components/dist/components/svg/feathers/SvgIconArrowLeft'
import { SvgIconArrowRight } from '@flomni/components/dist/components/svg/feathers/SvgIconArrowRight'
import { Select } from '@flomni/components/dist/components/select'
import { SvgIconArchive } from '@flomni/components/dist/components/svg/feathers/SvgIconArchive'
import { ROUTE, routes } from '../../../../../configs/routes'
import { useHistory } from 'react-router-dom'
import { processError } from '../../../../../services/helpers'
import { useDispatch } from 'react-redux'

export const ArchivedChatsTab = ({ userId, startDate, endDate }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const history = useHistory()
  const [events, setEvents] = useState([])
  const [showLoader, setShowLoader] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalEvents, setTotalEvents] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [countOnPage, setCountOnPage] = useState(10)

  const markAsRead = (notificationId) => {
    return Meteor.invoke('agentNotifications.markNotificationAsRead', {
      notificationId
    })
  }

  const markAllAsRead = (events) => {
    const newEvents = events.filter((ev) => !ev.read)
    if (newEvents.length) {
      const tasks = newEvents.map(({ _id }) => markAsRead(_id))
      Promise.all(tasks)
        .then(() => {
          const updatedEvents = events.map((event) => {
            return {
              ...event,
              read: true
            }
          })
          setEvents(updatedEvents)
        })
        .catch((err) => {
          processError(err, main)
        })
    }
  }

  const updateEvents = () => {
    setShowLoader(true)
    Meteor.invoke('agentNotifications.fetchNotifications', {
      kind: 'autoarchiving',
      offset: currentPage * countOnPage,
      limit: countOnPage,
      userId,
      startDate,
      endDate
    })
      .then((response) => {
        setEvents(response.notifications)
        const total = response.total || 0
        const totalPages = Math.ceil(total / countOnPage)

        setTotalEvents(total || 0)
        setTotalPages(totalPages < 1 ? 1 : totalPages)

        window.setTimeout(() => {
          markAllAsRead(response.notifications)
        }, 1000)
      })
      .catch((err) => {
        processError(err, main)
      })
      .finally(() => {
        setShowLoader(false)
      })
  }

  useEffect(() => {
    updateEvents()
  }, [])

  useEffect(() => {
    updateEvents()
  }, [currentPage, countOnPage, userId, startDate, endDate])

  const getReceiver = (item) => {
    return item.templateData?.receiver
  }

  const getChatName = (item) => {
    return item.templateData?.chatName || getReceiver(item)
  }

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1)
  }

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  const openChat = (item) => {
    const receiver = getReceiver(item)
    if (receiver) {
      history.push({
        pathname: routes[ROUTE.SEARCH],
        search: `?receiver=${receiver}`
      })
    }
  }

  return (
    <>
      {events.length === 0 && (
        <>
          {showLoader && (
            <div className={css('spinner')}>
              <Spinner strokeWidth={5} />
            </div>
          )}
          {!showLoader && (
            <div className={css('no-items')}>
              <div className={css('no-items-icon')}>
                <SvgIconArchive width={44} height={44} />
              </div>
              <div className={css('no-items-title')}>{t('dlg:youDontHaveArchivedChats')}</div>
              <div className={css('no-items-description')}>{t('dlg:theyAppearAutomaticallyHere')}</div>
            </div>
          )}
        </>
      )}
      {events.length > 0 && (
        <>
          <div className={css('table-header')}>
            <div>{t('dlg:state')}</div>
            <div>{t('dlg:chatWith')}</div>
            <div>{t('dlg:chatTopic')}</div>
          </div>
          {showLoader && (
            <div className={css('spinner')}>
              <Spinner strokeWidth={5} />
            </div>
          )}
          {!showLoader && (
            <>
              <div>
                {events.map((item) => {
                  return (
                    <div key={item._id} className={css('item')}>
                      <div>
                        <Tag variation='secondary' color={item.read ? 'gray' : 'blue'}>
                          {item.read ? t('dlg:read') : t('dlg:new')}
                        </Tag>
                      </div>
                      <div>
                        <div className={css('item-receiver')} onClick={() => openChat(item)}>
                          <div className={css('item-receiver-name')}>{getChatName(item)}</div>
                          <div className={css('item-receiver-eye')}>
                            <SvgIconShow />
                          </div>
                        </div>
                      </div>
                      <div className={css('item-message')}>
                        {item.chatTopic || <span className={css('not-set')}>{t('dlg:haveNotSetYet')}</span>}
                      </div>
                      <div className={css('item-date')}>
                        {DateUtils.format(item.createdAt, DateUtils.DATE_TIME_FORMAT)}
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className={css('table-footer')}>
                <div className={css('count-events')}>
                  <Trans
                    i18nKey='dlg:countEvents'
                    values={{
                      from: currentPage * countOnPage + 1,
                      to:
                        (currentPage + 1) * countOnPage > totalEvents
                          ? totalEvents
                          : (currentPage + 1) * countOnPage,
                      total: totalEvents
                    }}
                    components={[
                      <span className={css('text-semibold')}>0</span>,
                      1,
                      <span className={css('text-semibold')}>2</span>,
                      3,
                      <span className={css('text-semibold')}>4</span>,
                      5
                    ]}
                  />
                </div>
                <div className={css('right-block')}>
                  <div className={css('count-pages')}>
                    <span>{t('dlg:display')}</span>
                    <Select
                      variation='secondary'
                      options={[10, 20, 50].map((value) => {
                        return {
                          label: value,
                          value
                        }
                      })}
                      value={{
                        label: countOnPage,
                        value: countOnPage
                      }}
                      onChange={(option) => {
                        setCountOnPage(option.value)
                        setCurrentPage(0)
                      }}
                    />
                  </div>
                  <div className={css('pager')}>
                    <PlateButton noBg view='bright' onClick={handlePrevPage} disabled={currentPage === 0}>
                      <PlateButtonIcon>
                        <SvgIconArrowLeft />
                      </PlateButtonIcon>
                    </PlateButton>
                    <div className={css('pages')}>
                      <span className={css('current-page')}>{currentPage + 1}</span>
                      <span>/</span>
                      <span>{totalPages}</span>
                    </div>
                    <PlateButton
                      noBg
                      view='bright'
                      onClick={handleNextPage}
                      disabled={currentPage + 1 === totalPages}
                    >
                      <PlateButtonIcon>
                        <SvgIconArrowRight />
                      </PlateButtonIcon>
                    </PlateButton>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}
