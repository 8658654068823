import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { string } from 'prop-types'
import { Tooltip } from '@flomni/components/dist/components/tooltip'
import { SvgIconCopy } from '@flomni/components/dist/components/svg/feathers/SvgIconCopy'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import copy from 'copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

export const CopyField = ({ value }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()

  const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children

  const onCopy = () => {
    if (copy(value)) {
      main.showSuccessSystemNotification(t('dlg:copied'))
      return
    }
    main.showWarningSystemNotification(t('dlg:cantCopy'))
  }

  return (
    <div>
      <ConditionalWrapper
        condition={value && value.length > 20}
        wrapper={children => (
          <Tooltip variation='secondary' dropdownProps={{ position: 'right' }} content={value}>{children}</Tooltip>
        )}
      >
        <div className={css('value')} onClick={onCopy}>
          <div className={css('value-text')}>{value}</div>
          <div>
            <PlateButton noBg>
              <PlateButtonIcon>
                <SvgIconCopy />
              </PlateButtonIcon>
            </PlateButton>
          </div>
        </div>
      </ConditionalWrapper>
    </div>
  )
}

CopyField.propTypes = {
  value: string
}
