import React, { useEffect, useMemo, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { array, bool, func, object } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@flomni/components/dist/components/tooltip'
import MegabrainSuggestions from './suggestions'
import { Meteor } from '../../../../../../../../../../meteorAdapter';
import { isMobileMode, processError } from '../../../../../../../../../services/helpers'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import { PERMISSIONS_PAGE } from '../../../../../../../../../services/constants'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { SvgIconForward } from '@flomni/components/dist/components/svg/feathers/SvgIconForward'
import MegabrainSendMessage from './send-message'

export const MegabrainTip = ({
  message,
  isLastInbound,
  stuffUsers,
  chatbotEnabled,
  onUpdateMbrain,
  allowSuggestions
}) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [showSendMessage, setShowSendMessage] = useState(false)
  const [mbrain, setMbrain] = useState(null)
  const { main } = useDispatch()
  const [currentUserMegabrainSettings, selectedContextId, currentUserPermissions] = useSelector(
    ({ main }) => [main.currentUserMegabrainSettings, main.selectedContextId, main.currentUserPermissions]
  )
  const currentUserId = Meteor.userId()

  const isAllowToLoadPredictions = useMemo(() => {
    return (
      isLastInbound &&
      currentUserMegabrainSettings.allowSuggestions &&
      currentUserMegabrainSettings.suggestionsStoryId &&
      allowSuggestions &&
      !chatbotEnabled &&
      stuffUsers.includes(currentUserId)
    )
  }, [isLastInbound, currentUserMegabrainSettings, allowSuggestions, chatbotEnabled, stuffUsers, currentUserId])

  useEffect(() => {
    if (!message.mbrain && isAllowToLoadPredictions && message.text) {
      Meteor.invoke('mbrain.TextClassifier.predictions.post', {
        text: message.text,
        contextId: selectedContextId
      })
        .then((res) => {
          if (res && res.status !== 'error' && res.labels?.length) {
            const mbrain = {
              topics: res.labels
                .sort((a, b) => b.probability - a.probability)
                .map(({ id, text, probability }) => {
                  return {
                    topicId: id,
                    topicName: text,
                    matchedProbability: probability
                  }
                }),
              entities: [],
              emotions: [],
              text: message.text
            }
            setMbrain(mbrain)
            onUpdateMbrain(mbrain)
          }
        })
        .catch((err) => {
          processError(err, main)
        })
    } else if (message.mbrain) {
      setMbrain({
        ...message.mbrain,
        entities: message.mbrain.entities?.length ? [...message.mbrain.entities] : [],
        emotions: message.mbrain.emotions?.length ? [...message.mbrain.emotions] : [],
        topics: message.mbrain.topics?.length
          ? [...message.mbrain.topics].sort((a, b) => b.matchedProbability - a.matchedProbability)
          : []
      })
    } else {
      setMbrain(null)
    }
  }, [message?.text, message?.mbrain, selectedContextId, isAllowToLoadPredictions])

  const TopicTipContent = ({ topic }) => {
    return (
      <div>
        <div className={css('topic-tip-header')}>{t('dlg:megabrainsSuggestion')}</div>
        <div className={css('topic-tip-content')}>
          <div>
            <div className={css('topic-tip-title')}>{t('dlg:topic')}</div>
            <div className={css('topic-tip-value')}>{topic.topicName}</div>
          </div>
          <div>
            <div className={css('topic-tip-title')}>{t('dlg:probability')}</div>
            <div className={css('topic-tip-value')}>{Math.round(topic.matchedProbability)}%</div>
          </div>
        </div>
      </div>
    )
  }

  const count = useMemo(() => {
    if (!mbrain) {
      return 0
    }
    let count = mbrain.topics.length + mbrain.entities.length + mbrain.emotions.length
    if (mbrain.topics.length > 0) {
      count -= 1
    }
    return count
  }, [mbrain])

  if (mbrain) {
    return (
      <div className={css(classnames('mb-container', isMobileMode ? '--mobile' : null))}>
        {mbrain.topics.length > 0 && (
          <div className={css('mb-topic')}>
            <Tooltip
              variation='secondary'
              dropdownProps={{ direction: 'up' }}
              content={<TopicTipContent topic={mbrain.topics[0]} />}
            >
              <div className={css('mb-topic-name')}>{mbrain.topics[0].topicName}</div>
            </Tooltip>
          </div>
        )}
        {((count > 0 && mbrain.topics.length === 0) || (count === 0 && mbrain.topics.length > 0)) && <>
          <div className={css('mb-more-suggestions')} onClick={() => setShowSuggestions(true)}>
            {count > 0 &&
              mbrain.topics.length > 0 &&
              t('dlg:viewMoreSuggestions', {
                count
              })}
            {count > 0 && mbrain.topics.length === 0 && t('dlg:viewSuggestions')}
            {count === 0 && mbrain.topics.length > 0 && t('dlg:view')}
          </div>
          {showSuggestions && (
            <MegabrainSuggestions
              message={message}
              mbrain={mbrain}
              onUpdateMbrain={onUpdateMbrain}
              onCancel={() => setShowSuggestions(false)}
            />
          )}
        </>}
      </div>
    )
  }

  if (currentUserPermissions && currentUserPermissions.includes(PERMISSIONS_PAGE.ALLOW_MEGABRAIN_TRAINING)) {
    return (
      (currentUserMegabrainSettings.allowSendingIncomingMessage || showSendMessage) ? (
        <div className={css('send-inbox')}>
          {currentUserMegabrainSettings.allowSendingIncomingMessage && (
            <PlateButton noBg onClick={() => setShowSendMessage(true)}>
              <PlateButtonIcon>
                <SvgIconForward />
              </PlateButtonIcon>
            </PlateButton>
          )}
          {showSendMessage && (
            <MegabrainSendMessage message={message} onCancel={() => setShowSendMessage(false)} />
          )}
        </div>
      ) : null
    )
  }

  return null
}

MegabrainTip.propTypes = {
  message: object,
  isLastInbound: bool,
  stuffUsers: array,
  chatbotEnabled: bool,
  onUpdateMbrain: func.isRequired,
  allowSuggestions: bool
}
