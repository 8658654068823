import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { string } from 'prop-types'
import { SvgChannelApple } from '@flomni/components/dist/components/svg/channels/SvgChannelApple'
import { SvgChannelFacebook } from '@flomni/components/dist/components/svg/channels/SvgChannelFacebook'
import { SvgChannelInstagram } from '@flomni/components/dist/components/svg/channels/SvgChannelInstagram'
import { SvgChannelTelegram } from '@flomni/components/dist/components/svg/channels/SvgChannelTelegram'
import { SvgChannelViber } from '@flomni/components/dist/components/svg/channels/SvgChannelViber'
import { SvgChannelVoicer } from '@flomni/components/dist/components/svg/channels/SvgChannelVoicer'
import { SvgChannelWhatsApp } from '@flomni/components/dist/components/svg/channels/SvgChannelWhatsApp'
import { SvgChannelMobile } from '@flomni/components/dist/components/svg/channels/SvgChannelMobile'
import { SvgChannelVk } from '@flomni/components/dist/components/svg/channels/SvgChannelVk'
import { SvgChannelOk } from '@flomni/components/dist/components/svg/channels/SvgChannelOk'
import { SvgChannelEmail } from '@flomni/components/dist/components/svg/channels/SvgChannelEmail'
import { SvgChannelWeb } from '@flomni/components/dist/components/svg/channels/SvgChannelWeb'
import { useTranslation } from 'react-i18next'

export const ChannelItem = ({ messenger }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()

  const map = {
    abc: SvgChannelApple,
    fb: SvgChannelFacebook,
    ig: SvgChannelInstagram,
    tlgr: SvgChannelTelegram,
    vbr: SvgChannelViber,
    vb: SvgChannelViber,
    vk: SvgChannelVk,
    email: SvgChannelEmail,
    wa: SvgChannelWhatsApp,
    wab: SvgChannelWhatsApp,
    waba: SvgChannelWhatsApp,
    voicer: SvgChannelVoicer,
    mobile: SvgChannelMobile,
    ok: SvgChannelOk,
    web: SvgChannelWeb
  }

  const Logo = map[messenger]

  return (
    <div className={css('item')}>
      <div className={css('icon')}>{Logo ? <Logo width={'1rem'} height={'1rem'} /> : null}</div>
      <div className={css('text')}>{t(`dlg:channelType.${messenger}`)}</div>
    </div>
  )
}

ChannelItem.propTypes = {
  messenger: string
}
