import React from 'react'

export const processUrlConfig = [
  {
    regex: /(http|https):\/\/(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim, // eslint-disable-line no-useless-escape
    fn: (key, result) => (
      <span key={key}>
        <a
          target='_blank'
          rel="noreferrer"
          href={`${result[1]}://${result[2]}.${result[3]}${result[4]}`}
        >
          {result[2]}.{result[3]}
          {result[4]}
        </a>
        {result[5]}
      </span>
    )
  },
  {
    regex: /(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim, // eslint-disable-line no-useless-escape
    fn: (key, result) => (
      <span key={key}>
        <a
          target='_blank'
          href={`http://${result[1]}.${result[2]}${result[3]}`} rel="noreferrer"
        >
          {result[1]}.{result[2]}
          {result[3]}
        </a>
        {result[4]}
      </span>
    )
  }
]
