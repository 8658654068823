export const FILTER_PROPERTY = {
  DATE: 'date',
  AGENT: 'agent',
  TAG: 'tag',
  METADATA_FIELD: 'metadataField',
  TOPIC: 'topic',
  CHAT_DURATION: 'chatDuration',
  CHAT_RATE: 'chatRate',
  CHAT_STATUS: 'chatStatus',
  CHAT_ID: 'chatId',
  CHAT_MESSAGES: 'chatMessages',
  CHANNEL: 'channel'
}

export const DATE_SELECTOR = {
  IS_TODAY: 'isToday',
  IS_IN_THE_LAST: 'isInTheLast',
  IS_EQUAL_TO: 'isEqualTo',
  IS_BETWEEN: 'isBetween',
  IS_AFTER: 'isAfter',
  IS_BEFORE: 'isBefore'
}

export const AGENT_TYPE = {
  CHATBOT: 'chatbot',
  HUMAN: 'human'
}

export const STATUS = {
  ACTIVE: 'active',
  CLOSED: 'closed'
}

export const PERIOD = {
  DAYS: 'days',
  MONTHS: 'months'
}

export const LOCAL_STORAGE = {
  SEARCHES: 'FlomniDialogs.searches'
}
