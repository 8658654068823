/** @prettier */
import { Meteor, connect } from './meteorAdapter'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { init } from '@flomni/modules/dist/services/i18n/i18n'
import resources from './i18n/resources'
import '@flomni/components/src/styles/vars.scss'
import { BrowserRouter } from 'react-router-dom'
import { AppLoader } from './ui/components/app/app-loader'
import { App } from './ui/components/app'
import { Provider } from 'react-redux'
import { store } from './ui/state/store'
import './startup/extensions'

init(resources, null, Meteor.settings.public.FLOMNI_DEFAULT_LOCALE)

connect()

ReactDOM.render(
  <Suspense fallback={<AppLoader />}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </Suspense>,
  document.getElementById('root')
)
