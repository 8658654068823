import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { Trans, useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { Popup } from '@flomni/components/dist/components/popup/popup'
import { PopupButtons } from '@flomni/components/dist/components/popup/buttons'
import { PopupContent } from '@flomni/components/dist/components/popup/content'
import { PopupTitle } from '@flomni/components/dist/components/popup/title'
import { func, object } from 'prop-types'
import { Meteor } from '../../../../../../../meteorAdapter';
import { useDispatch } from 'react-redux'
import { processError } from '../../../../../../services/helpers'
import { ButtonWithLoader } from '../../../../../shared/button-with-loader'

const UnblockMember = ({ onUnblocked, onCancel, member }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const [showSaveLoader, setShowSaveLoader] = useState(false)

  const handlerClickUnblock = async (event) => {
    event.preventDefault()
    try {
      setShowSaveLoader(true)
      await new Promise((resolve) => setTimeout(resolve, 800))
      await Meteor.invoke('users.restoreUser', {
        groupId: member.groupsIds[0],
        userId: member.userId
      })
      setShowSaveLoader(false)
      onUnblocked()
    } catch (err) {
      setShowSaveLoader(false)
      processError(err, main)
    }
  }

  const handlerClickOnCancel = () => {
    onCancel()
  }

  return (
    <Popup open variation='secondary'>
      <PopupTitle title={t('dlg:unblockAgent')} />
      <PopupContent classes={{ root: css('content') }}>
        <div className={css('tip')}>
          <Trans
            i18nKey='dlg:memberWillAccessThisAccount'
            values={{ email: member.email }}
            components={[<span className={css('text-bold')}>0</span>, 1]}
          />
        </div>
      </PopupContent>
      <PopupButtons>
        <SecondaryButton view='light' onClick={handlerClickOnCancel}>
          <SecondaryButtonText>{t('dlg:cancel')}</SecondaryButtonText>
        </SecondaryButton>
        <ButtonWithLoader
          view='warning'
          text={t('dlg:unblockAgent')}
          onClick={handlerClickUnblock}
          spinnerSize={10}
          spinnerStrokeWidth={14}
          isLoading={showSaveLoader}
        />
      </PopupButtons>
    </Popup>
  )
}

UnblockMember.propTypes = {
  onUnblocked: func.isRequired,
  onCancel: func.isRequired,
  member: object.isRequired
}

export default UnblockMember
