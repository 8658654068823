import React, { useContext, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { Popup } from '@flomni/components/dist/components/popup/popup'
import { PopupButtons } from '@flomni/components/dist/components/popup/buttons'
import { PopupContent } from '@flomni/components/dist/components/popup/content'
import { PopupTitle } from '@flomni/components/dist/components/popup/title'

import { array, func, object } from 'prop-types'
import { useForm } from 'react-hook-form'
import { Select } from '@flomni/components/dist/components/select'
import { Meteor } from '../../../../../../../../../meteorAdapter';
import { store } from '../../../../../../../../state/dialogs'
import { useDispatch } from 'react-redux'
import { ButtonWithLoader } from '../../../../../../../shared/button-with-loader'
import { processError } from '../../../../../../../../services/helpers'

const TopicDialog = ({ topic, topics, onSaved, onCancel }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const { selectedDialogId } = useContext(store)
  const [showLoader, setShowLoader] = useState(false)

  const { register, formState, getValues, setValue, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      topicId: topic?.id || null
    }
  })

  const topicId = watch('topicId')

  const handlerClickSave = async (event) => {
    event.preventDefault()
    const values = getValues()
    setShowLoader(true)
    await new Promise((resolve) => setTimeout(resolve, 800))
    try {
      await Meteor.invoke('dialogs.saveDialogSubjects', {
        dialogId: selectedDialogId,
        tagId: values.topicId
      })
      setShowLoader(false)
      onSaved(values.topicId)
    } catch (err) {
      setShowLoader(false)
      processError(err, main)
    }
  }

  const handlerClickOnCancel = (event) => {
    event.preventDefault()
    onCancel()
  }

  const topicOptions = topics.map((topic) => {
    return {
      label: topic.name,
      value: topic.id
    }
  })
  const selectedTopicOption = topicOptions.find((option) => option.value === topicId) || null

  return (
    <Popup open variation='secondary'>
      <PopupTitle title={topic ? t('dlg:changeChatTopic') : t('dlg:chooseChatTopic')} />
      <form>
        <input hidden name='topicId' ref={register({ required: true })} />
        <PopupContent classes={{ root: css('content') }}>
          <div className={css('line')}>
            <div className={css('title')}>{t('dlg:topic')}</div>
            <Select
              variation='secondary'
              className={css('field')}
              options={topicOptions}
              value={selectedTopicOption}
              menuPortalTarget={document.body}
              placeholder={t('dlg:selectTopic')}
              onChange={(option) =>
                setValue('topicId', option.value, { shouldDirty: true, shouldValidate: true })
              }
            />
          </div>
        </PopupContent>
        <PopupButtons>
          <SecondaryButton view='light' onClick={handlerClickOnCancel}>
            <SecondaryButtonText>{t('dlg:close')}</SecondaryButtonText>
          </SecondaryButton>
          <ButtonWithLoader
            view='usual'
            text={topic ? t('dlg:change') : t('dlg:choose')}
            disabled={!formState.isValid || !formState.isDirty}
            onClick={handlerClickSave}
            spinnerSize={10}
            spinnerStrokeWidth={14}
            isLoading={showLoader}
          />
        </PopupButtons>
      </form>
    </Popup>
  )
}

TopicDialog.propTypes = {
  topics: array,
  topic: object,
  onSaved: func.isRequired,
  onCancel: func.isRequired
}

export default TopicDialog
