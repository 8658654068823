import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import React, { useCallback, useEffect, useState } from 'react'
import EmbeddedLoader from '@flomni/modules/dist/components/Loaders/EmbeddedLoader'
import { Snackbar } from '@flomni/components/dist/components/snackbar'
import { Login } from '../login'
import Selectorable from '../system/selectorable'
import ProgressLoader from '@flomni/modules/dist/components/Loaders/ProgressLoader'
import { useDispatch, useSelector } from 'react-redux'
import { Meteor, useTracker } from '../../../meteorAdapter';
import classnames from 'classnames'
import { DialogsPage } from '../dialogs'
import { UserIdleTimer } from './user-idle-timer'
import { ConnectionLost } from './connection-lost'
import { AppLoader } from './app-loader'
import { UpdateAlert } from './update-alert'
const { FLOMNI_AGENT_HEARTBEAT_INTERVAL } = Meteor.settings.public || {}

const HEARTBEAT_INTERVAL = FLOMNI_AGENT_HEARTBEAT_INTERVAL * 1000

export const App = () => {
  const css = useStyles(styles)
  const [isCustomScroll, setIsCustomScroll] = useState(false)
  const { main } = useDispatch()
  const currentUserTheme = useSelector(({ main }) => main.currentUserTheme)
  const [emptyQueue, setEmptyQueue] = useState({
    time: null,
    noActiveChats: null,
    slotsAvailable: null,
    timeMs: 0,
    noActiveChatsMs: 0,
    slotsAvailableMs: 0
  })
  const [isMeteorInitialized, setIsMeteorInitialized] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const { meteorIsConnected, meteorIsLoggingIn, userId } = useTracker(() => {
    const meteorIsConnected = Meteor.status().connected
    const meteorIsLoggingIn = Meteor.loggingIn()
    const userId = Meteor.userId()

    return { meteorIsConnected, meteorIsLoggingIn, userId }
  })

  useEffect(() => {
    if (!isMeteorInitialized && meteorIsConnected && !meteorIsLoggingIn) {
      setIsMeteorInitialized(true)
    }
  }, [isMeteorInitialized, meteorIsConnected, meteorIsLoggingIn])

  useEffect(() => {
    if (userId) {
      setIsLoggedIn(true)
    } else {
      setIsLoggedIn(false)
    }
  }, [userId])

  const handlerSnackBarClose = useCallback(() => main.removeSystemNotification(), [])

  const __userHeartbeat = () => {
    Meteor.setTimeout(() => {
      Meteor.emitServerEvent('users.user.renewPresence', {
        hiddenTab: document.hidden
      })
      __userHeartbeat()
    }, HEARTBEAT_INTERVAL)
  }

  useEffect(() => {
    const isMobileDevice = /Mobi/i.test(window.navigator.userAgent)
    const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)

    if (!isMobileDevice) {
      let rootFontSize = 12
      if (window.screen.width >= 1366) {
        rootFontSize = 13
      }
      if (window.screen.width >= 1440) {
        rootFontSize = 14
      }
      if (window.screen.width >= 1600) {
        rootFontSize = 16
      }
      document.querySelector('html').style['fontSize'] = `${rootFontSize}px`
    }

    if (!isMacLike) {
      setIsCustomScroll(true)
    }
    __userHeartbeat()

    const appHeight = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
    window.addEventListener('resize', appHeight)
    appHeight()
  }, [])

  return (
    <div
      className={css(
        classnames(
          'app',
          isCustomScroll ? 'custom-scroll' : null,
          currentUserTheme === 'contrast'
            ? '_node_modules_flomni_components_src_styles__vars__contrast'
            : null
        )
      )}
    >
      {
        isMeteorInitialized
          ? (
              isLoggedIn
                ? <DialogsPage emptyQueue={emptyQueue} setEmptyQueue={setEmptyQueue} />
                : <Login />
            )
          : <AppLoader />
      }
      <Selectorable
        selector='main.isShowProgressLoader'
        children={(isShowProgressLoader) => <ProgressLoader show={isShowProgressLoader} />}
      />
      <Selectorable
        selector='main.isShowGlobalLoader'
        children={(isShowGlobalLoader) => <EmbeddedLoader show={isShowGlobalLoader} global />}
      />
      <Selectorable selector='main.systemNotificationMessages'>
        {(systemNotificationMessages) => (
          <Snackbar
            variation='secondary'
            classes={{ root: css('snackbar') }}
            messages={systemNotificationMessages}
            onCloseEnd={handlerSnackBarClose}
          />
        )}
      </Selectorable>
      <UpdateAlert />
      <ConnectionLost />
      <UserIdleTimer emptyQueue={emptyQueue} setEmptyQueue={setEmptyQueue} />
    </div>
  )
}
