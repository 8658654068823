import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { PopupButtons } from '@flomni/components/dist/components/popup/buttons'
import { PopupContent } from '@flomni/components/dist/components/popup/content'
import { PopupTitle } from '@flomni/components/dist/components/popup/title'
import { array, bool, func, object } from 'prop-types'
import ButtonsList from '../../shared/buttons-list'
import { Meteor } from '../../../../../../../../meteorAdapter';
import { ButtonWithLoader } from '../../../../../../shared/button-with-loader'
import { useDispatch, useSelector } from 'react-redux'
import { processError } from '../../../../../../../services/helpers'

const DialogTemplateSecondStep = ({ onBack, onCancel, onFinished, templateData, actions, isEdit }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const [showAddButton, setShowAddButton] = useState(false)
  const [showSaveLoader, setShowSaveLoader] = useState(false)
  const [buttons, setButtons] = useState(templateData?.attachments || [])
  const { main } = useDispatch()
  const groupId = useSelector(({ main }) => main.currentUserGroupId)

  const onHandlerCreate = async (e) => {
    e.preventDefault()
    try {
      const data = {
        ...(templateData || {}),
        attachments: buttons,
        groupId
      }
      if (isEdit) {
        data.templateId = templateData.id
      }
      setShowSaveLoader(true)
      await new Promise((resolve) => setTimeout(resolve, 800))
      await Meteor.invoke(isEdit ? 'templates.updateTemplate' : 'templates.createTemplate', data)
      setShowSaveLoader(false)
      onFinished()
    } catch (err) {
      processError(err, main)
      setShowSaveLoader(false)
    }
  }

  const handlerClickOnCancel = (e) => {
    e.preventDefault()
    onCancel()
  }

  const handlerClickOnBack = (e) => {
    e.preventDefault()
    onBack(buttons)
  }

  return (
    <>
      <PopupTitle
        variation='secondary'
        title={isEdit ? t('dlg:editTemplate') : t('dlg:addButtonsToTemplate')}
        description={isEdit ? null : t('dlg:addOneOrMoreActionButtons')}
      />
      <PopupContent variation='secondary' classes={{ root: css('content') }}>
        <div className={css('progress')} />
        <ButtonsList
          actions={actions}
          onButtonsUpdate={setButtons}
          onShowAddButton={setShowAddButton}
          buttons={buttons}
        />
      </PopupContent>
      <PopupButtons variation='secondary' classes={{ root: css('buttons') }}>
        <div>
          <SecondaryButton view='light' onClick={handlerClickOnBack}>
            <SecondaryButtonText>{t('dlg:back')}</SecondaryButtonText>
          </SecondaryButton>
        </div>
        <div className={css('right-buttons')}>
          <SecondaryButton view='light' onClick={handlerClickOnCancel}>
            <SecondaryButtonText>{t('dlg:cancel')}</SecondaryButtonText>
          </SecondaryButton>
          <ButtonWithLoader
            text={isEdit ? t('dlg:update') : t('dlg:createTemplate')}
            onClick={onHandlerCreate}
            disabled={showAddButton}
            spinnerSize={10}
            spinnerStrokeWidth={14}
            isLoading={showSaveLoader}
          />
        </div>
      </PopupButtons>
    </>
  )
}

DialogTemplateSecondStep.propTypes = {
  actions: array,
  templateData: object,
  onBack: func.isRequired,
  onFinished: func.isRequired,
  onCancel: func.isRequired,
  isEdit: bool
}

export default DialogTemplateSecondStep
