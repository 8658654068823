import React, { useEffect, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { Meteor } from '../../../../../../meteorAdapter';
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { ROUTE, routes } from '../../../../../configs/routes'
import { processError } from '../../../../../services/helpers'

export const NetworkConnection = () => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const [connStats, setConnStats] = useState({})

  useEffect(() => {
    main.showGlobalLoader(true)
    Meteor.invoke('diagnostics.getNetworkConnectionStats')
      .then((res) => {
        setConnStats(res)
      })
      .catch((err) => {
        processError(err, main)
      })
      .finally(() => {
        main.showGlobalLoader(false)
      })
  }, [])

  const toFixedNumber = (v, digits = 2, base = 10) => {
    const pow = Math.pow(base, digits)
    return Math.round(v * pow) / pow
  }

  const formatValue = (value = '-') => {
    return typeof value === 'number' ? toFixedNumber(value) : value
  }

  return (
    <div className={css('main')}>
      <div className={css('container')}>
        <div className={css('nav-bar')}>
          <NavLink className={css('link')} to={routes[ROUTE.SETTINGS]}>
            {t('dlg:dialogsSettings')}
          </NavLink>
          <div>></div>
          <div className={css('active')}>{t('dlg:networkConnection')}</div>
        </div>
        <div className={css('header')}>{t('dlg:connection')}</div>
        <div className={css('sub-header')}>{t('dlg:averagePingTime')}</div>
        <div className={css('info')}>
          <div className={css(['item', '--header'])}>
            <div />
            <div>{t('dlg:5min')}</div>
            <div>{t('dlg:10min')}</div>
            <div>{t('dlg:60min')}</div>
          </div>
          <div className={css('item')}>
            <div>{t('dlg:user')}</div>
            <div>{formatValue(connStats.userStats?.prev5mins?.[0]?.avgPingDelayMs)}</div>
            <div>{formatValue(connStats.userStats?.prev10mins?.[0]?.avgPingDelayMs)}</div>
            <div>{formatValue(connStats.userStats?.prev60mins?.[0]?.avgPingDelayMs)}</div>
          </div>
          <div className={css('item')}>
            <div>{t('dlg:host')}</div>
            <div>{formatValue(connStats.hostStats?.prev5mins?.[0]?.avgPingDelayMs)}</div>
            <div>{formatValue(connStats.hostStats?.prev10mins?.[0]?.avgPingDelayMs)}</div>
            <div>{formatValue(connStats.hostStats?.prev60mins?.[0]?.avgPingDelayMs)}</div>
          </div>
          <div className={css('item')}>
            <div>{t('dlg:company')}</div>
            <div>{formatValue(connStats.groupStats?.prev5mins?.[0]?.avgPingDelayMs)}</div>
            <div>{formatValue(connStats.groupStats?.prev10mins?.[0]?.avgPingDelayMs)}</div>
            <div>{formatValue(connStats.groupStats?.prev60mins?.[0]?.avgPingDelayMs)}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
