import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { node, string } from 'prop-types'

export const NoItems = ({ Icon, title, description }) => {
  const css = useStyles(styles)

  return (
    <div className={css('no-items-container')}>
      <div>
        <div className={css('no-items-icon')}>
          <Icon width={80} height={80} />
        </div>
        <div className={css('no-items-texts')}>
          <div className={css('no-items-title')}>{title}</div>
          <div className={css('no-items-description')}>{description}</div>
        </div>
      </div>
    </div>
  )
}

NoItems.props = {
  Icon: node,
  title: string,
  description: string
}
