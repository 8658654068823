import { Meteor } from '../../meteorAdapter';

import Groups from '../../collections/client/groups'
import { USER_STATUSES } from '../../constants'

export default class AgentHelpers {
  static canAcceptHotChats = (personalDialogsCount, checkMode = 'auto') => {
    const res = {
      auto: false,
      manual: false
    }

    const currentUser = Meteor.user({
      fields: {
        groupsIds: 1,
        'profile.isBlocked': 1,
        doNotDisturb: 1,
        status: 1,
        chatDistributionLimit: 1,
        __department: 1
      }
    })
    if (currentUser == null) {
      return res
    }

    const {
      groupsIds = [],
      profile = {},
      status,
      __department = {},
      doNotDisturb,
      chatDistributionLimit
    } = currentUser

    if (profile.isBlocked === true || doNotDisturb === true || status === USER_STATUSES.OFFLINE) {
      return res
    }

    const currentGroupId = groupsIds[0]
    const currentGroup = Groups.findOne(currentGroupId, {
      fields: {
        chatsDistribution: 1
      }
    })
    if (currentGroup == null) {
      return res
    }

    const { mode = 'MANUAL', conditions = {} } = currentGroup.chatsDistribution || {}

    if (mode === 'MANUAL') {
      return res
    }

    const forbiddenUserStatuses = new Set((conditions.user_has_statuses && conditions.user_statuses) || [])
    if (forbiddenUserStatuses.has(status)) {
      return res
    }

    res.manual = true
    if (checkMode === 'manual') {
      return res
    }

    if (conditions.user_has_limit_active_chats && personalDialogsCount >= chatDistributionLimit) {
      return res
    }

    const { hasLimitPersonalChats = false, limitPersonalChats = 0 } = __department

    if (hasLimitPersonalChats && personalDialogsCount >= limitPersonalChats) {
      return res
    }

    res.auto = true
    res.chatsNeededQuantity = chatDistributionLimit
      ? chatDistributionLimit - personalDialogsCount
      : limitPersonalChats - personalDialogsCount
    return res
  }
}
