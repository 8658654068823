import { memo } from 'react'
import { array, func, oneOfType, string } from 'prop-types'
import dotProp from 'dot-prop-immutable'
import { useSelector } from 'react-redux'

const Selectorable = (props) => {
  const value = useSelector((state) => dotProp.get(state, props.selector))
  return props.children(value)
}

Selectorable.propTypes = {
  selector: oneOfType([string, array]).isRequired,
  children: func.isRequired
}

export default memo(Selectorable)
