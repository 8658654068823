import React from 'react'

export const AvatarSvg = (props) => {
  return (
    <svg width={44} height={44} viewBox='0 0 44 44' fill='none' {...props}>
      <mask
        id='mask0_28126_25663'
        style={{
          maskType: 'alpha'
        }}
        maskUnits='userSpaceOnUse'
        x='2'
        y='2'
        width='40'
        height='40'
      >
        <circle cx='22' cy='22' r='20' fill='#CBCFD2' />
      </mask>
      <g mask='url(#mask0_28126_25663)'>
        <circle cx='22' cy='22' r='20' fill={props.bg} />
        <circle cx='27.3334' cy='38' r='20' fill={props.sh} />
        <circle cx='22.0001' cy='20.6667' r='6.66671' fill='white' />
        <circle cx='22.0001' cy='42.0003' r='12.0001' fill='white' />
      </g>
      <rect x='1.25' y='1.25' width='41.5' height='41.5' rx='20.75' stroke='white' strokeWidth='1.5' />
    </svg>
  )
}
