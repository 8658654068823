import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { Input } from '@flomni/components/dist/components/input'
import { func, object } from 'prop-types'
import { ValueSelector } from '../shared/value-selector'
import classnames from 'classnames'

export const ChatIdFilterItem = ({ item, onChangeItemProperty }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()

  const OPERATOR = {
    IS: 'is',
    IN: 'in'
  }

  const [selectedSelector, setSelectedSelector] = useState(item.chatIdEqual ? OPERATOR.IS : OPERATOR.IN)
  const [selectedId, setSelectedId] = useState(item.chatId)

  const onSelectSelector = (selector) => {
    setSelectedSelector(selector)
    onChangeItemProperty('chatIdEqual', selector === OPERATOR.IS)
  }

  const onSelectId = (value) => {
    setSelectedId(value)
    onChangeItemProperty('chatId', value)
  }

  return (
    <div className={css(classnames('container', selectedSelector === OPERATOR.IN ? '--extended' : null))}>
      <div>
        <ValueSelector items={Object.values(OPERATOR)} item={selectedSelector} onChange={onSelectSelector} />
      </div>
      <Input
        shaded
        autoFocus
        variation='secondary'
        placeholder={selectedSelector === OPERATOR.IS ? 'ID' : t('dlg:idsSeparatedWithComma')}
        value={selectedId || ''}
        onChange={(e) => onSelectId(e.target.value)}
      />
    </div>
  )
}

ChatIdFilterItem.propTypes = {
  onChangeItemProperty: func.isRequired,
  item: object.isRequired
}
