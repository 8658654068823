import { Meteor, Mongo, ReactiveDict } from '../meteorAdapter';

export const PersonalDialogs = new Mongo.Collection('PersonalDialogs', { defineMutationMethods: false })

export const AgentStatuses = new Mongo.Collection(null)
export const ChatMessages = new Mongo.Collection(null)
export const DialogReminders = new Mongo.Collection(null)
export const GroupChannels = new Mongo.Collection(null)
export const GroupDepartments = new Mongo.Collection(null)
export const NotificationEvents = new Mongo.Collection(null)
export const Staff = new Mongo.Collection(null)
export const Tags = new Mongo.Collection(null)

export const ClientsActivityStore = new ReactiveDict()
export const VisibleMessagesCount = new ReactiveDict()

Meteor.connection.registerStore('__receivers', {
  update (msg) {
    if (msg.msg === 'changed') {
      const { fields = {} } = msg
      Object.entries(fields).forEach(([
        receiver,
        lastActivityAt
      ]) => {
        ClientsActivityStore.set(receiver, lastActivityAt)
      })
    }
  }
})

Meteor.connection.registerStore('__visible_messages_count', {
  update (msg) {
    if (msg.msg === 'added') {
      const { id: dialogId, fields = {} } = msg
      VisibleMessagesCount.set(dialogId, fields.count)
    }
  }
})

Meteor.connection.registerStore('__messages', {
  update (msg) {
    const { msg: op, fields = {} } = msg
    if (op === 'changed') {
      const { isNew, message = {} } = fields
      const { _id: messageId, ...data } = message
      ChatMessages.upsert(messageId, data)

      if (
        isNew &&
        message.isInvisible === false
      ) {
        const count = VisibleMessagesCount.get(msg.id) ?? 0
        VisibleMessagesCount.set(msg.id, count + 1)
      }
    } else if (op === 'added') {
      const { messages = [] } = fields
      messages.forEach(message => {
        const { _id: messageId, ...data } = message
        ChatMessages.upsert(messageId, data)
      })
    }
  }
})

Meteor.connection.registerStore('__groupMembers', {
  update (msg) {
    const collection = Staff
    const { msg: op, fields = {} } = msg

    if (op === 'changed') {
      if (fields.clearSubscription) {
        collection.find({ __sid: msg.id }).forEach(({ _id }) => {
          collection.remove(_id)
        })
      } else {
        delete fields.item._id
        collection.upsert(msg.id, fields.item)
      }
    } else if (op === 'added') {
      const { items = [] } = fields
      items.forEach(({ _id: userId, ...user }) => {
        collection.upsert(userId, user)
      })
    } else if (op === 'removed') {
      collection.remove(msg.id)
    }
  }
})

Meteor.connection.registerStore('__notificationEvents', {
  update (msg) {
    const collection = NotificationEvents
    const { msg: op, fields = {} } = msg

    if (op === 'changed') {
      collection.upsert(msg.id, fields)
    }
  }
})

Meteor.connection.registerStore('__groupChannels', {
  update (msg) {
    const collection = GroupChannels
    const { msg: op, fields = {} } = msg

    if (op === 'changed') {
      if (fields.clearSubscription) {
        collection.find({ __sid: msg.id }).forEach(({ _id }) => {
          collection.remove(_id)
        })
      } else {
        collection.upsert(msg.id, fields.item)
      }
    } else if (op === 'added') {
      const { items = [] } = fields
      items.forEach(({ _id: channelId, ...channel }) => {
        collection.upsert(channelId, channel)
      })
    } else if (op === 'removed') {
      collection.remove(msg.id)
    }
  }
})

Meteor.connection.registerStore('__groupDepartments', {
  update (msg) {
    const collection = GroupDepartments
    const { msg: op, fields = {} } = msg

    if (op === 'changed') {
      if (fields.clearSubscription) {
        collection.find({ __sid: msg.id }).forEach(({ _id }) => {
          collection.remove(_id)
        })
      } else {
        collection.upsert(msg.id, fields.item)
      }
    } else if (op === 'added') {
      const { items = [] } = fields
      items.forEach(({ _id: departmentId, ...department }) => {
        collection.upsert(departmentId, department)
      })
    } else if (op === 'removed') {
      collection.remove(msg.id)
    }
  }
})

Meteor.connection.registerStore('__groupAgentStatuses', {
  update (msg) {
    const collection = AgentStatuses
    const { msg: op, fields = {} } = msg

    if (op === 'changed') {
      if (fields.clearSubscription) {
        collection.find({ __sid: msg.id }).forEach(({ _id }) => {
          collection.remove(_id)
        })
      } else {
        collection.upsert(msg.id, fields.item)
      }
    } else if (op === 'added') {
      const { items = [] } = fields
      items.forEach(({ _id: statusId, ...status }) => {
        collection.upsert(statusId, status)
      })
    } else if (op === 'removed') {
      collection.remove(msg.id)
    }
  }
})

Meteor.connection.registerStore('__groupTags', {
  update (msg) {
    const collection = Tags
    const { msg: op, fields = {} } = msg

    if (op === 'changed') {
      if (fields.clearSubscription) {
        collection.find({ __sid: msg.id }).forEach(({ _id }) => {
          collection.remove(_id)
        })
      } else {
        collection.upsert(msg.id, fields.item)
      }
    } else if (op === 'added') {
      const { items = [] } = fields
      items.forEach(({ _id: tagId, ...tag }) => {
        collection.upsert(tagId, tag)
      })
    } else if (op === 'removed') {
      collection.remove(msg.id)
    }
  }
})

Meteor.connection.registerStore('__dialogReminders', {
  update (msg) {
    const collection = DialogReminders
    const { msg: op, fields = {} } = msg

    if (op === 'changed') {
      if (fields.clearSubscription) {
        collection.find({ __sid: msg.id }).forEach(({ _id }) => {
          collection.remove(_id)
        })
      } else {
        collection.upsert(msg.id, fields.item)
      }
    } else if (op === 'added') {
      const { items = [] } = fields
      items.forEach(({ _id: reminderId, ...reminder }) => {
        collection.upsert(reminderId, reminder)
      })
    } else if (op === 'removed') {
      collection.remove(msg.id)
    }
  }
})
