import React, { useContext, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { Input } from '@flomni/components/dist/components/input'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { func, object } from 'prop-types'
import { Meteor } from '../../../../../../../../../../../meteorAdapter';
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { ButtonWithLoader } from '../../../../../../../../../shared/button-with-loader'
import { store } from '../../../../../../../../../../state/dialogs'
import { processError } from '../../../../../../../../../../services/helpers'

export const EditComment = ({ comment, onEdit, onCancel }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const [showSaveLoader, setShowSaveLoader] = useState(false)
  const { main } = useDispatch()
  const { selectedDialogId } = useContext(store)

  const { register, formState, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      text: comment.text
    }
  })

  const onEditClickHandler = () => {
    const { text } = getValues()

    setShowSaveLoader(true)
    setTimeout(() => {
      Meteor.invoke('dialogs.updateComment', {
        dialogId: selectedDialogId,
        commentId: comment.id,
        text: text.trim()
      })
        .then(() => {
          setShowSaveLoader(false)
          onEdit()
        })
        .catch((err) => {
          setShowSaveLoader(false)
          processError(err, main)
        })
    }, 800)
  }

  const onCancelClickHandler = () => {
    onCancel()
  }

  return (
    <div className={css('container')}>
      <Input
        variation='secondary'
        autoFocus
        textArea
        textAreaHeight={4.5}
        placeholder={t('dlg:typeNote')}
        name='text'
        ref={register({
          validate: (value) => value.trim().length > 0
        })}
      />
      <div className={css('tips')} style={{ display: 'none' }}>
        <span>@{t('dlg:mention').toLowerCase()}</span>
        <span className={css('tip-bold')}>**{t('dlg:bold').toLowerCase()}**</span>
        <span className={css('tip-italic')}>_{t('dlg:italic').toLowerCase()}_</span>
      </div>
      <div className={css('buttons')}>
        <SecondaryButton view='light' onClick={onCancelClickHandler}>
          <SecondaryButtonText>{t('dlg:cancel')}</SecondaryButtonText>
        </SecondaryButton>
        <ButtonWithLoader
          text={t('dlg:editNote')}
          onClick={onEditClickHandler}
          disabled={!formState.isValid || !formState.isDirty}
          spinnerSize={10}
          spinnerStrokeWidth={14}
          isLoading={showSaveLoader}
        />
      </div>
    </div>
  )
}

EditComment.propTypes = {
  comment: object,
  onEdit: func.isRequired,
  onCancel: func.isRequired
}
