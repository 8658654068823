import { createSelector } from 'reselect'
import { USER_ROLES } from '../../../constants'

const getMainState = ({ main }) => main

const getUserRoles = createSelector(
  getMainState,
  (mainState) => {
    return mainState?.currentUserRoles
  }
)

const getIsUserOperator = createSelector(
  getUserRoles,
  (roles) => {
    if (!roles || !roles.length) {
      return true
    }

    return roles.includes(USER_ROLES.OPERATOR)
  }
)

const mainSelectors = {
  getUserRoles,
  getMainState,
  getIsUserOperator
}

export {
  mainSelectors
}
