import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import { Spinner } from '@flomni/components/dist/components/spinner'
import React, { useEffect, useState } from 'react'
import { Meteor } from '../../../../../../meteorAdapter';
import { useDispatch } from 'react-redux'
import { processError } from '../../../../../services/helpers'
import { parseRawStats, PERIODS } from '../shared/utils'
import { TeamDashboardChart } from './chart'
import { PeriodSelect } from '../../../shared/period-select'

export const TeamDashboard = () => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()

  const [selectedPeriodGeneral, setSelectedPeriodGeneral] = useState(PERIODS.TODAY)
  const [showLoaderGeneral, setShowLoaderGeneral] = useState(false)
  const [statsGeneral, setStatsGeneral] = useState({})

  useEffect(() => {
    setShowLoaderGeneral(true)
    Meteor.invoke('groups.getGroupActivityStats', {
      period: selectedPeriodGeneral,
      type: 'group'
    })
      .then(([rawStats]) => {
        setStatsGeneral(parseRawStats(rawStats.result?.eventTypes))
      })
      .catch((err) => {
        processError(err, main)
      })
      .finally(() => {
        setShowLoaderGeneral(false)
      })
  }, [selectedPeriodGeneral])

  return (
    <div className={css(['container', '--border-bottom'])}>
      <div className={css('header')}>
        <div>{t('dlg:generalAnalytics')}</div>
        <div className={css('filters')}>
          <PeriodSelect selectedPeriod={selectedPeriodGeneral} setSelectedPeriod={setSelectedPeriodGeneral} />
        </div>
      </div>
      <div className={css('content')}>
        <TeamDashboardChart period={selectedPeriodGeneral} />
        {showLoaderGeneral && (
          <div className={css('spinner-container')}>
            <div className={css('spinner')}>
              <Spinner strokeWidth={5} />
            </div>
          </div>
        )}
        {!showLoaderGeneral && (
          <div>
            <div className={css('line')}>
              <div className={css(['item', '--border-right'])}>
                <div className={css('title')}>{t('dlg:processingEfficiency')}</div>
                <div className={css('value')}>
                  <span>
                    {statsGeneral.avgChatsPerHour
                      ? Math.round(statsGeneral.avgChatsPerHour * 100) / 100
                      : '-'}
                  </span>
                  {!!statsGeneral.avgChatsPerHour && (
                    <span className={css('value-m')}>{t('dlg:perHour')}</span>
                  )}
                </div>
                <div className={css('description')}>{t('dlg:averageProcessedChatsPerHour')}</div>
              </div>
              <div className={css('item')}>
                <div className={css('title')}>{t('dlg:rate')}</div>
                <div className={css('value')}>
                  {statsGeneral.avgRate ? Math.round(statsGeneral.avgRate * 100) / 100 : '-'}
                </div>
                <div className={css('description')}>{t('dlg:averageRateFromCustomers')}</div>
              </div>
            </div>
            <div className={css(['line', '--border-top'])}>
              <div className={css(['item', '--border-right'])}>
                <div className={css('title')}>{t('dlg:waitingTime')}</div>
                <div className={css('value')}>
                  <span>
                    {statsGeneral.avgQueueTimeSecs
                      ? Math.round(statsGeneral.avgQueueTimeSecs * 100) / 100
                      : '-'}
                  </span>
                  {!!statsGeneral.avgQueueTimeSecs && (
                    <span className={css('value-m')}>{t('dlg:seconds')}</span>
                  )}
                </div>
                <div className={css('description')}>{t('dlg:averageWaitingTimeInQueue')}</div>
              </div>
              <div className={css('item')}>
                <div className={css('title')}>{t('dlg:pendingChats')}</div>
                <div className={css('value')}>
                  {statsGeneral.newDialogs ? Math.round(statsGeneral.newDialogs * 100) / 100 : '-'}
                </div>
                <div className={css('description')}>{t('dlg:averagePendingChatsInQueue')}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
