import React, { useContext, useEffect, useMemo, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { Meteor } from '../../../../../../../../meteorAdapter';
import { useDispatch, useSelector } from 'react-redux'
import { SortPopup } from '../../../../../shared/sort-popup'
import { FiltersPopup } from '../../../../../shared/filters-popup'
import { store } from '../../../../../../../state/dialogs'
import { processError } from '../../../../../../../services/helpers'
import { bool } from 'prop-types'

export const SortFilterOptions = ({ showAgentsFilter }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const currentUserGroupId = useSelector(({ main }) => main.currentUserGroupId)
  const { sortOrder, filterUsers, sortFieldName, filterTags, filterStatuses, dispatch } = useContext(store)
  const [tags, setTags] = useState([])
  const [users, setUsers] = useState([])

  const STATUSES = ['new', 'assigned', 'reminded', 'reopened']

  const sortItems = useMemo(() => {
    return {
      checkedId: sortFieldName,
      defaultCheckedId: 'createdAt',
      items: [
        {
          title: t('dlg:createdDate'),
          id: 'createdAt',
          order: sortFieldName === 'createdAt' ? sortOrder : -1,
          defaultOrder: -1,
          ascLabel: t('dlg:newestFirst'),
          descLabel: t('dlg:oldestFirst')
        },
        {
          title: t('dlg:lastAction'),
          id: 'lastMessageAt',
          order: sortFieldName === 'lastMessageAt' ? sortOrder : -1,
          defaultOrder: -1,
          ascLabel: t('dlg:newestFirst'),
          descLabel: t('dlg:oldestFirst')
        }
      ]
    }
  }, [sortFieldName, sortOrder])

  const filterItems = useMemo(() => {
    const items = [
      {
        name: t('dlg:status'),
        id: 'status',
        isChecked: filterStatuses !== null,
        defaultValue: false,
        items: STATUSES.map((status) => {
          return {
            name: t(`dlg:${status}`),
            id: status,
            isChecked: filterStatuses ? filterStatuses.includes(status) : false,
            defaultValue: false
          }
        })
      },
      {
        name: t('dlg:tags'),
        id: 'tag',
        isChecked: filterTags !== null,
        defaultValue: false,
        items: tags.map((tag) => {
          return {
            name: tag.name,
            id: tag._id,
            isChecked: filterTags ? filterTags.includes(tag._id) : false,
            defaultValue: false
          }
        })
      }
    ]

    if (showAgentsFilter) {
      items.push({
        name: t('dlg:agents'),
        id: 'agents',
        isChecked: filterUsers !== null,
        defaultValue: false,
        isSelect: true,
        selectPlaceholder: t('selectAgents'),
        items: users.map((user) => {
          return {
            name: user.name,
            id: user.userId,
            isChecked: filterUsers ? filterUsers.includes(user.userId) : false,
            defaultValue: false
          }
        })
      })
    }
    return items
  }, [filterStatuses, filterTags, showAgentsFilter, filterUsers, users])

  useEffect(() => {
    if (!currentUserGroupId) {
      return
    }
    Meteor.invoke('users.getUsers', {})
      .then(({ list = [] }) => {
        const users = list.filter((user) => !user.isRemoved)
        setUsers(users)
      })
      .catch((err) => {
        processError(err, main)
      })
  }, [currentUserGroupId])

  useEffect(() => {
    if (!currentUserGroupId) {
      return
    }
    Meteor.invoke('groups.getGroupTags', { groupId: currentUserGroupId })
      .then(({ items }) => {
        setTags((items || []).filter((tag) => tag.kind !== 'session'))
      })
      .catch((err) => {
        processError(err, main)
      })
  }, [currentUserGroupId])

  const onSetSort = (config) => {
    const item = config.items.find((item) => item.id === config.checkedId)
    dispatch({ type: 'setSortFieldName', sortFieldName: config.checkedId })
    dispatch({ type: 'setSortOrder', sortOrder: item.order })
  }

  const onSetFilter = (items) => {
    let selectedStatuses = null
    const statusFilter = items.find((item) => item.id === 'status')
    if (statusFilter && statusFilter.isChecked) {
      selectedStatuses = []
      statusFilter.items.forEach((group) => {
        if (group.isChecked) {
          selectedStatuses.push(group.id)
        }
      })
    }
    let selectedTags = null
    const tagFilter = items.find((item) => item.id === 'tag')
    if (tagFilter && tagFilter.isChecked) {
      selectedTags = []
      tagFilter.items.forEach((group) => {
        if (group.isChecked) {
          selectedTags.push(group.id)
        }
      })
    }
    let selectedUsers = null
    const agentsFilter = items.find((item) => item.id === 'agents')
    if (agentsFilter && agentsFilter.isChecked) {
      selectedUsers = []
      agentsFilter.items.forEach((group) => {
        if (group.isChecked) {
          selectedUsers.push(group.id)
        }
      })
    }

    dispatch({ type: 'setFilterTags', filterTags: selectedTags })
    dispatch({ type: 'setFilterStatuses', filterStatuses: selectedStatuses })
    dispatch({ type: 'setFilterUsers', filterUsers: selectedUsers })
  }

  return (
    <div className={css('buttons')}>
      <SortPopup config={sortItems} onDone={onSetSort} onReset={onSetSort} />
      <FiltersPopup items={filterItems} onDone={onSetFilter} onReset={onSetFilter} />
    </div>
  )
}

SortFilterOptions.propTypes = {
  showAgentsFilter: bool
}
