import React, { useCallback, useState } from 'react'
import { any, func, bool } from 'prop-types'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonIcon } from '@flomni/components/dist/components/secondary-button/icon'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { Dropdown } from '@flomni/components/dist/components/dropdown'
import { SecondaryDropdownItem } from '@flomni/components/dist/components/secondary-dropdown-item/item'
import { SecondaryDropdownItemText } from '@flomni/components/dist/components/secondary-dropdown-item/text'
import { useTranslation } from 'react-i18next'
import { SvgIconCalendar } from '@flomni/components/dist/components/svg/feathers/SvgIconCalendar'
import { PERIODS } from '../../pages/dashboard/shared/utils'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'

export const PeriodSelect = ({ selectedPeriod, setSelectedPeriod, showAll }) => {
  const { t } = useTranslation()
  const css = useStyles(styles)

  const [showPeriodTeam, setShowPeriodTeam] = useState(false)

  const onOverlayClickHandler = useCallback(() => {
    setShowPeriodTeam(false)
  }, [])

  return (
    <div>
      <SecondaryButton view='light' onClick={() => setShowPeriodTeam(true)}>
        <SecondaryButtonIcon>
          <SvgIconCalendar />
        </SecondaryButtonIcon>
        <SecondaryButtonText>{!selectedPeriod ? t(`dlg:allEvents`) : t(`dlg:periodStats.${selectedPeriod}`)}</SecondaryButtonText>
      </SecondaryButton>
      <Dropdown
        active={showPeriodTeam}
        variation='secondary'
        position='right'
        onOverlayClick={onOverlayClickHandler}
      >
        {showAll && <SecondaryDropdownItem
          active={!selectedPeriod}
          onClick={() => {
            setSelectedPeriod('')
            setShowPeriodTeam(false)
          }}
        >
          <SecondaryDropdownItemText>
            <span className={css('all')}>{t(`dlg:allEvents`)}</span>
          </SecondaryDropdownItemText>
        </SecondaryDropdownItem>}
        {Object.values(PERIODS).map((period) => (
          <SecondaryDropdownItem
            key={period}
            active={selectedPeriod === period}
            onClick={() => {
              setSelectedPeriod(period)
              setShowPeriodTeam(false)
            }}
          >
            <SecondaryDropdownItemText>{t(`dlg:periodStats.${period}`)}</SecondaryDropdownItemText>
          </SecondaryDropdownItem>
        ))}
      </Dropdown>
    </div>
  )
}

PeriodSelect.propTypes = {
  selectedPeriod: any,
  setSelectedPeriod: func.isRequired,
  showAll: bool
}
