import React, { useContext, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { Trans, useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { func, object } from 'prop-types'
import { DateUtils } from '@flomni/modules/dist/services/date'
import { Meteor } from '../../../../../../../../../../meteorAdapter';
import { useDispatch, useSelector } from 'react-redux'
import { store } from '../../../../../../../../../state/dialogs'
import { ButtonWithLoader } from '../../../../../../../../shared/button-with-loader'
import { processError } from '../../../../../../../../../services/helpers'

const RemoveReminderForm = ({ reminder, onCancel }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const currentUserGroupId = useSelector(({ main }) => main.currentUserGroupId)
  const { selectedDialogId } = useContext(store)
  const [showLoader, setShowLoader] = useState(false)

  const onHandlerRemove = async () => {
    setShowLoader(true)
    await new Promise((resolve) => setTimeout(resolve, 800))
    try {
      await Meteor.invoke('reminders.removeReminder', {
        groupId: currentUserGroupId,
        dialogId: selectedDialogId,
        reminderId: reminder._id
      })
    } catch (err) {
      processError(err, main)
    }
  }

  const handlerClickOnCancel = () => {
    onCancel()
  }

  return (
    <div>
      <div className={css('group')}>
        <div className={css('title')}>{t('dlg:removeReminder')}</div>
        <div className={css('name')}>
          <Trans
            i18nKey='dlg:removeReminderText'
            values={{ date: DateUtils.format(reminder.intendedAt, DateUtils.DATE_TIME_FORMAT) }}
            components={[0, <span className={css('text-bold')}>1</span>, 2]}
          />
        </div>
      </div>
      <div className={css(['group', '--buttons'])}>
        <SecondaryButton view='light' onClick={handlerClickOnCancel}>
          <SecondaryButtonText>{t('dlg:cancel')}</SecondaryButtonText>
        </SecondaryButton>
        <ButtonWithLoader
          view='warning'
          text={t('dlg:remove')}
          onClick={onHandlerRemove}
          spinnerSize={10}
          spinnerStrokeWidth={14}
          isLoading={showLoader}
        />
      </div>
    </div>
  )
}

RemoveReminderForm.propTypes = {
  reminder: object.isRequired,
  onCancel: func.isRequired
}

export default RemoveReminderForm
