import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SettingsAccount } from './SettingsList/SettingsAccount'
import { SettingsTeamSecurity } from './SettingsList/SettingsTeamSecurity'
import { SettingsTools } from './SettingsList/SettingsTools'
import { SettingsDiagnostic } from './SettingsList/SettingsDiagnostic'
import { SettingsPolicies } from './SettingsList/SettingsPolicies'

export const Settings = () => {
  const css = useStyles(styles)
  const { t } = useTranslation()

  return (
    <div className={css('container')}>
      <div className={css('header')}>{t('dlg:dialogsSettings')}</div>
      <div className={css('line')}>
        <SettingsAccount />
        <SettingsTeamSecurity />
        <SettingsTools />
      </div>
      <div className={css(['line', '--border-top'])}>
        <SettingsDiagnostic />
        <SettingsPolicies />
      </div>
    </div>
  )
}
