import React, { useCallback, useRef, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { Input } from '@flomni/components/dist/components/input'
import { Select } from '@flomni/components/dist/components/select'
import { NavLink } from 'react-router-dom'
import { ROUTE, routes } from '../../../../../configs/routes'
import { useForm } from 'react-hook-form'
import { Meteor, useTracker } from '../../../../../../meteorAdapter';
import { useDispatch } from 'react-redux'
import { object } from 'prop-types'
import { ControlContainer } from '@flomni/components/dist/components/control-container'
import timezones from '../../../../../services/timezones'
import { Avatar } from '../../../shared/avatar'
import { base64MimeType, processError, toBase64, urlToFile } from '../../../../../services/helpers'
import ChangePassword from './change-password'
import { ButtonWithLoader } from '../../../../shared/button-with-loader'
import { Tag } from '@flomni/components/dist/components/tag'
import { CopyField } from '../../../shared/copy-field'
import UploaderHelpers from '../../../../../helpers/uploaderHelpers'
import { USER_ROLES } from '../../../../../../constants'

const ProfilePage = ({ user }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const [showChangePassword, setShowChangePassword] = useState(false)
  const [showSaveLoader, setShowSaveLoader] = useState(false)
  const [footerStatus, setFooterStatus] = useState('')
  const avatarInputFile = useRef(null)
  const isOperator = (user.roles || []).includes(USER_ROLES.OPERATOR)
  const isSupervisor = (user.roles || []).includes(USER_ROLES.SUPERVISOR)

  const { register, watch, setValue, errors, reset, formState, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: user.profile.name,
      position: user.profile.position,
      email: user.emails[0].address,
      tz: user.profile.tz,
      avatarUrl: user.profile.avatarUrl
    }
  })
  const tz = watch('tz')
  const avatarUrl = watch('avatarUrl')

  const tzOptions = timezones
    .reduce((list, timezone) => list.concat(timezone.children), [])
    .map(({ id, text }) => ({
      value: id,
      label: text
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1))
  const selectedTzOption = tzOptions.find((timezoneOption) => timezoneOption.value === tz)

  const onSave = useCallback(() => {
    const { tz, name, position, avatarUrl } = getValues()
    setShowSaveLoader(true)

    setTimeout(() => {
      const tasks = []
      if (avatarUrl !== user.profile.avatarUrl) {
        if (!avatarUrl) {
          tasks.push(Meteor.invoke('users.updateUserAvatar', { url: '', userId: user._id }))
        } else {
          const uploadAvatarTask = urlToFile(avatarUrl, 'avatar', base64MimeType(avatarUrl)).then((file) => {
            return UploaderHelpers.uploadAvatar(user._id, file)
          })
          tasks.push(uploadAvatarTask)
        }
      }
      if (tz !== user.profile.tz || position !== user.profile.position || name !== user.profile.name) {
        tasks.push(
          Meteor.invoke('users.updateProfile', {
            tz,
            position: position || null,
            name
          })
        )
      }
      Promise.all(tasks)
        .then(() => {
          reset({
            tz,
            name,
            position,
            avatarUrl
          })
          setFooterStatus(t('dlg:yourChangesSaved'))
        })
        .catch((err) => {
          processError(err, main)
        })
        .finally(() => {
          setShowSaveLoader(false)
        })
    }, 800)
  }, [user])

  const onCancel = useCallback(() => {
    reset({
      name: user.profile.name,
      position: user.profile.position,
      email: user.emails[0].address,
      tz: user.profile.tz,
      avatarUrl: user.profile.avatarUrl
    })
    setFooterStatus(t('dlg:yourChangesDiscarded'))
  }, [user])

  const deleteAvatar = () => {
    setValue('avatarUrl', '', { shouldDirty: true })
  }

  const selectAvatar = () => {
    avatarInputFile.current.click()
  }

  const handleFileChange = useCallback(
    (event) => {
      if (event.target.files.length) {
        toBase64(event.target.files[0]).then((url) => {
          setValue('avatarUrl', url, { shouldDirty: true })
        })
      }
    },
    [user]
  )

  return (
    <div className={css('main')}>
      <div className={css('container')}>
        <div className={css('nav-bar')}>
          <NavLink className={css('link')} to={routes[ROUTE.SETTINGS]}>
            {t('dlg:dialogsSettings')}
          </NavLink>
          <div>></div>
          <div className={css('active')}>{t('dlg:profileData')}</div>
        </div>
        <input hidden name='tz' ref={register()} />
        <input hidden name='avatarUrl' ref={register()} />
        <div className={css('form')}>
          <div className={css('header')}>
            <div>{t('dlg:yourData')}</div>
            <CopyField value={user._id} />
          </div>
          <div className={css('body')}>
            <div className={css('body-line')}>
              <div className={css('title')}>{t('dlg:avatar')}</div>
              <div className={css('avatar-block-line')}>
                <Avatar url={avatarUrl} />
                {avatarUrl && (
                  <div className={css('avatar-buttons')}>
                    <SecondaryButton onClick={selectAvatar}>
                      <SecondaryButtonText>{t('dlg:change')}</SecondaryButtonText>
                    </SecondaryButton>
                    <SecondaryButton view='light' onClick={deleteAvatar}>
                      <SecondaryButtonText>{t('dlg:delete')}</SecondaryButtonText>
                    </SecondaryButton>
                  </div>
                )}
                {!avatarUrl && (
                  <SecondaryButton onClick={selectAvatar}>
                    <SecondaryButtonText>{t('dlg:upload')}</SecondaryButtonText>
                  </SecondaryButton>
                )}
                <input
                  type='file'
                  name='file'
                  accept='image/*'
                  ref={avatarInputFile}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
              </div>
            </div>
            <div className={css('line-description')}>{t('dlg:avatarsHelpRecognizeYou')}</div>
            <div className={css('body-line')}>
              <div className={css('title')}>{t('dlg:fullName')}</div>
              <ControlContainer variation='secondary' warning={errors.name ? errors.name.message : null}>
                <Input
                  variation='secondary'
                  name='name'
                  placeholder={t('dlg:fullName')}
                  ref={register({ required: t('dlg:fullNameRequired') })}
                />
              </ControlContainer>
            </div>
            <div className={css('body-line')}>
              <div className={css('title-group')}>
                <div className={css('title')}>{t('dlg:position')}</div>
                <Tag color='gray' variation='secondary' view='filled'>
                  {t('dlg:optional')}
                </Tag>
              </div>
              <Input variation='secondary' name='position' placeholder={t('dlg:position')} ref={register()} readOnly={!isSupervisor} />
            </div>
            <div className={css('body-line')}>
              <div className={css('title')}>{t('dlg:timeZone')}</div>
              <Select
                variation='secondary'
                options={tzOptions}
                value={selectedTzOption}
                onChange={(option) =>
                  setValue('tz', option.value, { shouldDirty: true, shouldValidate: true })
                }
              />
            </div>
            <div className={css('body-line')}>
              <div className={css('title')}>{t('dlg:email')}</div>
              <ControlContainer variation='secondary' warning={errors.email ? errors.email.message : null}>
                <Input
                  variation='secondary'
                  name='email'
                  disabled={isOperator}
                  placeholder={t('dlg:email')}
                  ref={register({ required: t('dlg:emailRequired') })}
                />
              </ControlContainer>
            </div>
            <div className={css('line-description')}>{t('dlg:usesEmailAsLoginMessage')}</div>
            <div className={css('body-line')}>
              <div className={css('title')}>{t('dlg:password')}</div>
              <div>
                <SecondaryButton onClick={() => setShowChangePassword(true)}>
                  <SecondaryButtonText>{t('dlg:changePassword')}</SecondaryButtonText>
                </SecondaryButton>
              </div>
            </div>
          </div>
          <div className={css('footer')}>
            <div className={css('footer-status')}>{footerStatus}</div>
            <div className={css('footer-buttons')}>
              <SecondaryButton view='light' onClick={onCancel} disabled={!formState.isDirty}>
                <SecondaryButtonText>{t('dlg:cancel')}</SecondaryButtonText>
              </SecondaryButton>
              <ButtonWithLoader
                text={t('dlg:save')}
                onClick={onSave}
                disabled={!formState.isValid || !formState.isDirty}
                spinnerSize={10}
                spinnerStrokeWidth={14}
                isLoading={showSaveLoader}
              />
            </div>
          </div>
        </div>
      </div>
      {showChangePassword && (
        <ChangePassword
          onCancel={() => setShowChangePassword(false)}
          onAdded={() => setShowChangePassword(false)}
        />
      )}
    </div>
  )
}

ProfilePage.propTypes = {
  user: object
}

export const Profile = () => {
  const user = useTracker(() => Meteor.user(), [])
  return user ? <ProfilePage user={user} /> : null
}
