import React, { useEffect, useState } from 'react'
import moment from 'moment'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { object } from 'prop-types'
import { Tag } from '@flomni/components/dist/components/tag'
import { SvgIconStopWatch } from '@flomni/components/dist/components/svg/feathers/SvgIconStopWatch'
import { useTranslation } from 'react-i18next'
import FlipNumbers from 'react-flip-numbers'

export const InactiveTimer = ({ chat }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const [value, setValue] = useState(null)
  const [isAttention, setIsAttention] = useState(false)
  const MAX_MINUTES_TO_ATTENTION = 5

  const formatNumber = (value) => {
    return value < 10 ? `0${value}` : value
  }

  const updateValue = () => {
    if (chat.lastInboundMessageAt) {
      const lastInboundMessageAt = moment(chat.lastInboundMessageAt)

      if (chat.lastStaffMessageAt) {
        const lastStaffMessageAt = moment(chat.lastStaffMessageAt)
        if (lastStaffMessageAt.isAfter(lastInboundMessageAt)) {
          setValue(null)
          return
        }
      }

      const duration = moment.duration(moment().diff(lastInboundMessageAt))
      const minutes = duration.asMinutes()

      setValue(
        minutes < 60
          ? `${formatNumber(duration.minutes())}:${formatNumber(duration.seconds())}`
          : t('dlg:oneHourMore')
      )
      setIsAttention(minutes >= MAX_MINUTES_TO_ATTENTION)
      return
    }
    setValue(null)
  }

  useEffect(() => {
    updateValue()

    const timer = setInterval(updateValue, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [chat])

  return value ? (
    <Tag
      classes={{ inner: css('tag') }}
      color={isAttention ? 'red' : 'gray'}
      variation='secondary'
      view='filled'
    >
      <SvgIconStopWatch />
      <div className={css(['number', isAttention ? '--red' : '--gray'])}>
        <FlipNumbers height={11} width={7} play numbers={value} perspective={400} />
      </div>
    </Tag>
  ) : null
}

InactiveTimer.props = {
  chat: object
}
