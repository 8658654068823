import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { array, bool, func, object, string } from 'prop-types'
import { SvgIconSend } from '@flomni/components/dist/components/svg/feathers/SvgIconSend'
import { SvgIconMenuDots } from '@flomni/components/dist/components/svg/feathers/SvgIconMenuDots'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonIcon } from '@flomni/components/dist/components/secondary-button/icon'
import { ButtonWithLoader } from '../../../../../../../../shared/button-with-loader'
import { useSend } from '../../send-hook'
import { SvgIconAttach } from '@flomni/components/dist/components/svg/feathers/SvgIconAttach'
import { SvgIconBookmark } from '@flomni/components/dist/components/svg/feathers/SvgIconBookmark'
import { SvgIconButtonsStroke } from '@flomni/components/dist/components/svg/feathers/SvgIconButtonsStroke'
import { SvgIconLightiningStroke } from '@flomni/components/dist/components/svg/feathers/SvgIconLightiningStroke'
import { SvgIconSmile } from '@flomni/components/dist/components/svg/feathers/SvgIconSmile'
import { SvgIconClose } from '@flomni/components/dist/components/svg/feathers/SvgIconClose'
import classnames from 'classnames'
import { MobileAttachmentsMenu } from './attachments-menu'
import { AttachmentsList } from '../../reply/attachments-list'
import { ButtonsList } from '../../reply/buttons-list'
import { ActionsList } from '../../reply/actions-list'
import { TemplatesList } from '../../reply/templates-list'
import { Picker } from 'emoji-mart'
import { SuggestionsList } from '../../reply/suggestions-list'

export const MobileReply = ({
  mbrain,
  actions,
  templates,
  updateTemplates,
  passiveMembers,
  drafts,
  chatCaption,
  clientProfile,
  receiver,
  allowSuggestions
}) => {
  const css = useStyles(styles)
  const { t } = useTranslation()

  const {
    message,
    showSendLoader,
    showButtons,
    showActions,
    showTemplates,
    showEmojiPicker,
    attachments,
    buttons,
    setShowEmojiPicker,
    setShowActions,
    showSuggestions,
    setShowSuggestions,
    editingMessage,
    handlerOnAddAttachments,
    handlerOnDeleteAttachment,
    handlerOnChangeFileAttachment,
    handlerOnChangeContactAttachment,
    handlerOnChangeLocationAttachment,
    handlerOnChangeAction,
    handlerOnChangeButton,
    onSend,
    onUpdate,
    handlePaste,
    onChangeText,
    closeTemplates,
    closeButtons,
    openEmojiPicker,
    openTemplates,
    openActions,
    openButtons,
    getButtons,
    onButtonSelected,
    onActionSelected,
    getTemplates,
    onTemplateSelected,
    handleTemplates,
    onSelectEmoji,
    onCancelEditing
  } = useSend(mbrain, actions, templates, updateTemplates, passiveMembers, chatCaption, clientProfile, receiver, drafts)
  const [showAttachmentsList, setShowAttachmentsList] = useState(false)

  const onCloseMenu = () => {
    setShowAttachmentsList(false)
    setShowEmojiPicker(false)
    setShowActions(false)
    closeTemplates()
    closeButtons()
  }

  const onOpenAttachments = () => {
    setShowAttachmentsList(true)
    setShowEmojiPicker(false)
    setShowActions(false)
    closeTemplates()
    closeButtons()
  }

  const showMenu = showAttachmentsList || showButtons || showActions || showTemplates || showEmojiPicker

  return (
    <>
      <div className={css(['input-line', editingMessage ? '--editing' : null])}>
        <div className={css('input-left')}>
          {!showMenu && (
            <SecondaryButton view='light' onClick={onOpenAttachments}>
              <SecondaryButtonIcon>
                <SvgIconMenuDots />
              </SecondaryButtonIcon>
            </SecondaryButton>
          )}
          {showMenu && (
            <SecondaryButton view='light' onClick={() => onCloseMenu(false)}>
              <SecondaryButtonIcon>
                <SvgIconClose />
              </SecondaryButtonIcon>
            </SecondaryButton>
          )}
          <textarea
            autoFocus
            className={css('input')}
            placeholder={t('dlg:typeMessage')}
            onChange={onChangeText}
            onKeyUp={handleTemplates}
            onPaste={handlePaste}
            value={message}
          />
        </div>
        {!editingMessage && (
          <ButtonWithLoader
            Icon={<SvgIconSend />}
            onClick={onSend}
            disabled={message.length === 0}
            spinnerSize={10}
            spinnerStrokeWidth={14}
            isLoading={showSendLoader}
          />
        )}
      </div>
      {editingMessage && (
        <div className={css('editing-buttons')}>
          <SecondaryButton view='light' onClick={onCancelEditing}>
            <SecondaryButtonIcon>
              <SvgIconClose />
            </SecondaryButtonIcon>
          </SecondaryButton>
          <ButtonWithLoader
            Icon={<SvgIconSend />}
            onClick={onUpdate}
            disabled={message.length === 0}
            spinnerSize={10}
            spinnerStrokeWidth={14}
            isLoading={showSendLoader}
          />
        </div>
      )}
      <AttachmentsList
        items={attachments}
        onDelete={handlerOnDeleteAttachment}
        onChangeFile={handlerOnChangeFileAttachment}
        onChangeContact={handlerOnChangeContactAttachment}
        onChangeLocation={handlerOnChangeLocationAttachment}
        onChangeAction={handlerOnChangeAction}
        onChangeButton={handlerOnChangeButton}
      />
      {showSuggestions && allowSuggestions && (
        <SuggestionsList
          mbrain={mbrain}
          templates={templates}
          onTemplateSelected={onTemplateSelected}
          onClose={() => setShowSuggestions(false)}
        />
      )}
      {showMenu && (
        <>
          {showAttachmentsList && <MobileAttachmentsMenu onAdd={handlerOnAddAttachments} />}
          {showButtons && (
            <ButtonsList
              buttons={getButtons()}
              actions={actions}
              allButtons={buttons}
              onButtonSelected={onButtonSelected}
            />
          )}
          {showActions && <ActionsList actions={actions} onActionSelected={onActionSelected} />}
          {showTemplates && (
            <TemplatesList
              actions={actions}
              templates={getTemplates()}
              allTemplates={templates}
              onTemplateSelected={onTemplateSelected}
              onAddedTemplate={updateTemplates}
            />
          )}
          {showEmojiPicker && (
            <div className={css('emoji-picker')}>
              <Picker
                color='#586ADA'
                showPreview={false}
                showSkinTones={false}
                perLine={4}
                set='apple'
                emoji=''
                title=''
                onSelect={onSelectEmoji}
                style={{ width: 'auto', border: 'none' }}
              />
            </div>
          )}
          <div className={css('menu')}>
            <div
              className={css(classnames('menu-btn', showAttachmentsList ? '--opened' : null))}
              onClick={onOpenAttachments}
            >
              <SvgIconAttach width={20} height={20} />
            </div>
            <div
              className={css(classnames('menu-btn', showButtons ? '--opened' : null))}
              onClick={() => {
                setShowAttachmentsList(false)
                openButtons()
              }}
            >
              <SvgIconButtonsStroke width={20} height={20} />
            </div>
            <div
              className={css(classnames('menu-btn', showActions ? '--opened' : null))}
              onClick={() => {
                setShowAttachmentsList(false)
                openActions()
              }}
            >
              <SvgIconLightiningStroke width={20} height={20} />
            </div>
            <div
              className={css(classnames('menu-btn', showEmojiPicker ? '--opened' : null))}
              onClick={() => {
                setShowAttachmentsList(false)
                openEmojiPicker()
              }}
            >
              <SvgIconSmile width={20} height={20} />
            </div>
            <div
              className={css(classnames('menu-btn', showTemplates ? '--opened' : null))}
              onClick={() => {
                setShowAttachmentsList(false)
                openTemplates()
              }}
            >
              <SvgIconBookmark width={20} height={20} />
            </div>
          </div>
        </>
      )}
    </>
  )
}

MobileReply.propTypes = {
  actions: array,
  templates: array,
  passiveMembers: array,
  drafts: object,
  updateTemplates: func.isRequired,
  chatCaption: string,
  clientProfile: object,
  receiver: string,
  mbrain: object,
  allowSuggestions: bool
}
