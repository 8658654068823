import React, { useContext, useEffect, useState } from 'react'
import { array, bool, func } from 'prop-types'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import { CenterPanel } from '../../conversations/Conversations/center-panel'
import { RightPanel } from '../../conversations/Conversations/right-panel'
import styles from './index.module.scss'
import { SearchResultsPanel } from './results-panel'
import { store } from '../../../../../state/dialogs'
import { useBanClient } from '../../../shared/useBanClient'
import { Meteor, useTracker, Match } from '../../../../../../meteorAdapter';
import { useMetaData } from '../../../shared/useMetaData'
import { useSelector } from 'react-redux'
import { ROUTE, routes } from '../../../../../configs/routes'
import { useHistory } from 'react-router-dom'

export const SearchResults = ({ results, resultsCounter, showLoader, onLoadMore, onReset, onChanged }) => {
  const css = useStyles(styles)
  const { selectedDialogId, dispatch } = useContext(store)
  const [receiver, setReceiver] = useState(null)
  const { banResult, clientId, updateBanResult } = useBanClient()
  const { crmFields, relatedChats, recipient, metaDataLoading, setCrmFields, setRecipient } = useMetaData()
  const [isAllowedLoadMore, setIsAllowedLoadMore] = useState(true)
  const isDetailsPanelCollapsed = useSelector(({ main }) => main.isDetailsPanelCollapsed)
  const history = useHistory()

  useTracker(() => {
    if (Match.isDocumentId(selectedDialogId)) {
      Meteor.subscribe('fetchOneDialogData', selectedDialogId)
    }
  }, [selectedDialogId])

  useEffect(() => {
    const dialog =
      results.find((dialog) => dialog._id === selectedDialogId) ?? (results.length ? results[0] : null)
    setReceiver(dialog?.receiver)
    dispatch({ type: 'setSelectedDialog', selectedDialog: dialog })
  }, [results])

  const onResetResults = () => {
    dispatch({ type: 'setSelectedDialog', selectedDialog: null })
    onReset()
  }

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop - 10 <= e.target.clientHeight
    if (!bottom) {
      setIsAllowedLoadMore(true)
    }
    if (bottom && !showLoader && isAllowedLoadMore) {
      setIsAllowedLoadMore(false)
      onLoadMore()
    }
  }

  const onAssignedCurrentUser = () => {
    history.push({
      pathname: routes[ROUTE.CONVERSATIONS]
    })
  }

  return (
    <div className={css(['container', isDetailsPanelCollapsed ? '--collapsed' : null])}>
      <div className={css('scroll-area')} onScroll={handleScroll}>
        <SearchResultsPanel loading={showLoader} chats={results} chatsCounter={resultsCounter} onReset={onResetResults} />
      </div>
      {selectedDialogId && (
        <>
          <CenterPanel
            banResult={banResult}
            updateBanResult={updateBanResult}
            clientId={clientId}
            onAssignedCurrentUser={onAssignedCurrentUser}
            onChanged={() => {
              onChanged(receiver)
            }}
            crmFields={crmFields}
          />
          <RightPanel
            banResult={banResult}
            updateBanResult={updateBanResult}
            crmFields={crmFields}
            relatedChats={relatedChats}
            recipient={recipient}
            metaDataLoading={metaDataLoading}
            setCrmFields={setCrmFields}
            setRecipient={setRecipient}
          />
        </>
      )}
    </div>
  )
}

SearchResults.propTypes = {
  results: array,
  showLoader: bool,
  onLoadMore: func.isRequired,
  onReset: func.isRequired,
  onChanged: func.isRequired
}
