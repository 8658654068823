import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { func, object } from 'prop-types'
import { ValueSelector } from '../shared/value-selector'
import { STATUS } from '../../constants'

export const ChatStatusFilterItem = ({ item, onChangeItemProperty }) => {
  const { t } = useTranslation()

  const [selectedStatus, setSelectedStatus] = useState(item.status)

  const onSelectStatus = (status) => {
    setSelectedStatus(status)
    onChangeItemProperty('status', status)
  }

  return (
    <ValueSelector
      items={Object.values(STATUS)}
      item={selectedStatus}
      onChange={onSelectStatus}
      placeholder={t('dlg:selectStatus')}
    />
  )
}

ChatStatusFilterItem.propTypes = {
  onChangeItemProperty: func.isRequired,
  item: object.isRequired
}
