/* @prettier */
import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import Bowser from 'bowser'
import copy from 'copy-to-clipboard'
import { Meteor, getMeteorWebsocketEndpoint } from '../../../../../../meteorAdapter'
import { NavLink } from 'react-router-dom'
import { ROUTE, routes } from '../../../../../configs/routes'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { PlateButtonText } from '@flomni/components/dist/components/plate-button/text'
import { SvgBtCopy } from '@flomni/components/dist/components/svg/icons/SvgBtCopy'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { useVersionManifest } from '../../../../shared/hooks/useVersionManifest'

export const ApplicationInfo = () => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()

  const browser = Bowser.getParser(window.navigator.userAgent)

  const currentUser = Meteor.user({
    fields: {
      emails: 1,
      'profile.name': 1,
      roles: 1
    }
  })

  const { manifest } = useVersionManifest()

  const info = {
    userName: currentUser?.profile?.name ?? 'n/a',
    userEmail: currentUser?.emails?.[0]?.address ?? 'n/a',
    userRole: currentUser?.roles?.[0] ?? 'n/a',
    clientCommitHash: manifest.commitHash ?? 'n/a',
    meteorWebsocketEndpoint: getMeteorWebsocketEndpoint(),
    UA: browser._ua,
    browser: JSON.stringify(browser.parsedResult)
  }

  if (manifest.environmentName) {
    info.clientEnvironment = manifest.environmentName
  }
  if (manifest.buildAt) {
    info.clientBuildAt = moment(manifest.buildAt).format('HH:mm:ss DD-MM-YYYY')
  }

  if (window.navigator.connection) {
    const { effectiveType, rtt, downlink } = window.navigator.connection
    info.networkConnection = `${effectiveType} ↓${downlink}Mbit/s ↔${rtt}ms`
  }

  const onCopy = () => {
    const text = Object.keys(info)
      .map((key) => `${t(`dlg:${key}`)}: ${info[key]}`)
      .join('\n')

    if (copy(text)) {
      main.showSuccessSystemNotification(t('dlg:copied'))
      return
    }

    main.showWarningSystemNotification(t('dlg:cantCopy'))
  }

  return (
    <div className={css('main')}>
      <div className={css('container')}>
        <div className={css('nav-bar')}>
          <NavLink className={css('link')} to={routes[ROUTE.SETTINGS]}>
            {t('dlg:dialogsSettings')}
          </NavLink>
          <div>{'>'}</div>
          <div className={css('active')}>{t('dlg:applicationInformation')}</div>
        </div>
        <div className={css('header')}>{t('dlg:details')}</div>
        <div className={css('info')}>
          <div className={css('copy-info')}>
            <PlateButton onClick={onCopy}>
              <PlateButtonIcon>
                <SvgBtCopy />
              </PlateButtonIcon>
              <PlateButtonText>{t('dlg:copy')}</PlateButtonText>
            </PlateButton>
          </div>
          {Object.keys(info).map((key) => (
            <div key={key} className={css('item')}>
              <div className={css('title')}>{t(`dlg:${key}`)}</div>
              <div className={css('value')}>{info[key]}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
