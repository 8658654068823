import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { func, object } from 'prop-types'
import { SvgIconButtonsFilled } from '@flomni/components/dist/components/svg/feathers/SvgIconButtonsFilled'
import { useTranslation } from 'react-i18next'

export const AttachmentButton = ({ button, onChange }) => {
  const { t } = useTranslation()
  const css = useStyles(styles)

  return (
    <>
      <div className={css('container')} onClick={() => onChange(button)}>
        <div className={css('header')}>
          <SvgIconButtonsFilled />
          <div className={css('name')}>{button.name}</div>
        </div>
        <div className={css('text')}>{button.value}</div>
        <div className={css('change')}>{t('dlg:clickToChangeButton')}</div>
      </div>
    </>
  )
}

AttachmentButton.propTypes = {
  button: object.isRequired,
  onChange: func.isRequired
}
