import React, { useState } from 'react'
import { Popup } from '@flomni/components/dist/components/popup/popup'
import { func } from 'prop-types'
import DialogAddChatFirstStep from './first-step'
import DialogAddChatSecondStep from './second-step'

const DialogAddChat = ({ onCancel, initPhone = '', prefferedMessenger }) => {
  const [stepNumber, setStepNumber] = useState(1)
  const [phone, setPhone] = useState(initPhone)
  const [receiverIds, setReceiverIds] = useState(null)
  const [templates, setTemplates] = useState(null)

  const handlerClickNextStep = (phone, { receiverIds, templates }) => {
    setPhone(phone)
    setReceiverIds(receiverIds)
    setTemplates(templates)
    setStepNumber(2)
  }

  const handlerClickBackStep = () => {
    setStepNumber(1)
  }

  const handlerClickOnCancel = () => {
    onCancel()
  }

  return (
    <Popup open variation='secondary'>
      {stepNumber === 1 && (
        <DialogAddChatFirstStep
          initPhone={phone}
          onNext={handlerClickNextStep}
          onCancel={handlerClickOnCancel}
          searchNow={!!initPhone}
        />
      )}
      {stepNumber === 2 && (
        <DialogAddChatSecondStep
          onCancel={handlerClickOnCancel}
          onBack={handlerClickBackStep}
          phone={phone}
          receiverIds={receiverIds}
          templates={templates}
          prefferedMessenger={prefferedMessenger}
        />
      )}
    </Popup>
  )
}

DialogAddChat.propTypes = {
  onCancel: func.isRequired
}

export default DialogAddChat
