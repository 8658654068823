import { useRef, useState } from 'react'
import { Random } from '../../../../../../../../meteorAdapter';

export const useInsertAttachment = (onAdd) => {
  const [showMenu, setShowMenu] = useState(false)
  const [showAddContact, setShowAddContact] = useState(false)
  const [showAddLocation, setShowAddLocation] = useState(false)
  const inputEl = useRef(null)

  const handleInsertImageOrVideo = (event) => {
    event.preventDefault()
    inputEl.current.accept = 'video/*,image/*'
    inputEl.current.click()
    setShowMenu(false)
  }

  const handleInsertLocation = () => {
    setShowMenu(false)
    setShowAddLocation(true)
  }

  const handleInsertFile = (event) => {
    event.preventDefault()
    inputEl.current.accept = ''
    inputEl.current.click()
    setShowMenu(false)
  }

  const handleInsertContact = () => {
    setShowMenu(false)
    setShowAddContact(true)
  }

  const onFileChange = (event) => {
    if (event.target.files.length) {
      const attachments = []
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files.item(i)
        attachments.push({
          _id: Random.id(),
          type: 'file',
          file
        })
      }
      onAdd(attachments)
    }
    inputEl.current.value = ''
  }

  const onInsertContact = (name, phone) => {
    setShowAddContact(false)
    onAdd([
      {
        _id: Random.id(),
        type: 'contact',
        contact: {
          name,
          phone
        }
      }
    ])
  }

  const onInsertLocation = (name, lat, lng) => {
    setShowAddLocation(false)
    onAdd([
      {
        _id: Random.id(),
        type: 'location',
        location: {
          name,
          lat,
          lng
        }
      }
    ])
  }

  return {
    inputEl,
    showMenu,
    showAddLocation,
    setShowAddLocation,
    showAddContact,
    setShowAddContact,
    setShowMenu,
    onInsertContact,
    onInsertLocation,
    onFileChange,
    handleInsertImageOrVideo,
    handleInsertLocation,
    handleInsertFile,
    handleInsertContact
  }
}
