import React, { useEffect, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { array, func } from 'prop-types'
import { SvgIconPlus } from '@flomni/components/dist/components/svg/feathers/SvgIconPlus'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonText } from '@flomni/components/dist/components/plate-button/text'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { SvgIconButtonsFilled } from '@flomni/components/dist/components/svg/feathers/SvgIconButtonsFilled'
import { Random } from '../../../../../../../../meteorAdapter';
import ButtonForm from '../button-form'
import ButtonItem from '../button-item'

const ButtonsList = ({ actions, onButtonsUpdate, onShowAddButton, buttons }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const [showAddButton, setShowAddButton] = useState(false)

  useEffect(() => {
    onShowAddButton(showAddButton)
  }, [showAddButton])

  const onAddButton = (button) => {
    setShowAddButton(false)
    onButtonsUpdate([
      ...buttons,
      {
        ...button,
        type: 'button',
        id: Random.id()
      }
    ])
  }

  const onRemoveButton = (id) => {
    onButtonsUpdate(buttons.filter((button) => button.id !== id))
  }

  const onEditButton = (id, fields) => {
    onButtonsUpdate(
      buttons.map((button) => {
        if (button.id === id) {
          return {
            ...button,
            ...fields
          }
        }
        return button
      })
    )
  }

  return (
    <>
      <div className={css('header')}>
        <div className={css('header-title')}>{t('dlg:buttons')}</div>
        {!showAddButton && (
          <PlateButton
            view='usual'
            onClick={() => {
              setShowAddButton(true)
            }}
          >
            <PlateButtonIcon>
              <SvgIconPlus />
            </PlateButtonIcon>
            <PlateButtonText>{t('dlg:addButton')}</PlateButtonText>
          </PlateButton>
        )}
      </div>
      {!showAddButton && buttons.length === 0 && (
        <div className={css('no-items-header')}>
          <div className={css('no-items-icon')}>
            <SvgIconButtonsFilled width={20} height={20} />
          </div>
          <div>
            <div className={css('no-items-title')}>{t('dlg:youDontHaveButtons')}</div>
            <div className={css('no-items-description')}>{t('dlg:createSetupFirstClickAddButton')}</div>
          </div>
        </div>
      )}
      {showAddButton && (
        <div className={css('add-button-form')}>
          <ButtonForm
            actions={actions}
            onSave={onAddButton}
            onCancel={() => {
              setShowAddButton(false)
            }}
          />
        </div>
      )}
      {buttons.length > 0 && (
        <div className={css('list')}>
          {buttons.map((button) => (
            <ButtonItem
              key={button.id}
              button={button}
              actions={actions}
              onEdit={(fields) => onEditButton(button.id, fields)}
              onRemove={() => {
                onRemoveButton(button.id)
              }}
            />
          ))}
        </div>
      )}
    </>
  )
}

ButtonsList.propTypes = {
  actions: array,
  buttons: array,
  onButtonsUpdate: func.isRequired,
  onShowAddButton: func.isRequired
}

export default ButtonsList
