import { Meteor, useTracker } from '../../../../meteorAdapter';
import Dialogs from '../../../../collections/client/dialogs'
import { useContext, useEffect, useState } from 'react'
import { store } from '../../../state/dialogs'
import { useSelector } from 'react-redux'

export const useBanClient = () => {
  const { selectedDialogId } = useContext(store)
  const groupId = useSelector(({ main }) => main.currentUserGroupId)
  const [banResult, setBanResult] = useState(null)

  const { receiver } = useTracker(() => {
    if (!selectedDialogId) {
      return {}
    }

    return (
      Dialogs.findOne(selectedDialogId, {
        fields: {
          receiver: 1
        }
      }) || {}
    )
  }, [selectedDialogId])

  const updateBanResult = () => {
    Meteor.invoke('clients.isBannedClient', {
      userHash: receiver,
      groupId
    }).then((res) => {
      setBanResult(res ? res[1] : null)
    })
  }

  useEffect(() => {
    if (receiver) {
      updateBanResult()
    } else {
      setBanResult(null)
    }
  }, [receiver])

  return { banResult, receiver, updateBanResult }
}
