import React, { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { object } from 'prop-types'
import { Match } from '../../../../../../../../../meteorAdapter';
import { Trans, useTranslation } from 'react-i18next'
import { Avatar } from '../../../../../../shared/avatar'
import { Staff } from '../../../../../../../../customStores'
import { Tooltip } from '@flomni/components/dist/components/tooltip'
import { DateUtils } from '@flomni/modules/dist/services/date'
import classnames from 'classnames'
import { isMobileMode } from '../../../../../../../../services/helpers'
import DisputeScoreDialog from '../dispute-score-dialog'
import { PERMISSIONS_PAGE } from '../../../../../../../../services/constants'
import { USER_ROLES } from '../../../../../../../../../constants'

export const EventMessage = ({ message }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const [showExcludeScoreDialog, setShowExcludeScoreDialog] = useState(false)
  const [currentUserRoles, currentUserPermissions] = useSelector(
    ({ main }) => [main.currentUserRoles, main.currentUserPermissions]
  )
  const isSupervisor = currentUserRoles.includes(USER_ROLES.SUPERVISOR)

  const getStaffProfileById = (userId, name) => {
    if (Match.isDocumentId(userId)) {
      const userProfile = Staff.findOne(userId, {
        fields: {
          profile: 1
        }
      })
      if (userProfile?.profile) {
        return userProfile.profile
      }
    }
    return {
      name,
      avatarUrl: '/images/bot.svg'
    }
  }

  const placeholders = message.event?.placeholders
  const mainUser = getStaffProfileById(
    placeholders?.mainUserId ?? message.mainUserId,
    message.mainUserNameDefault
  )
  const secondUser = getStaffProfileById(
    placeholders?.secondUserId ?? message.secondUserId,
    message.secondUserNameDefault
  )

  const Text = ({ text, cls }) => {
    return <span className={cls}>{text}</span>
  }

  const Tag = ({ text }) => {
    return (
      <div className={css('tag-container')}>
        <div className={css('tag')}>{text}</div>
      </div>
    )
  }

  const UserName = ({ name, avatarUrl }) => {
    return (
      <div className={css('username-container')}>
        <div className={css('username')}>
          <Avatar url={avatarUrl} cls={css('avatar-small')} />
          <Text cls={css('text-bold')} text={name} />
        </div>
      </div>
    )
  }

  const handlerClickScore = useCallback(() => {
    setShowExcludeScoreDialog(true)
  })

  const hideExcludeScoreDialog = useCallback(() => {
    setShowExcludeScoreDialog(false)
  })

  const allowDisputeRates = (currentUserPermissions.includes(PERMISSIONS_PAGE.ALLOW_DISPUTE_RATES) || isSupervisor)

  return (
    <div className={css(classnames('container', isMobileMode ? '--mobile' : null))}>
      <div>
        <Tooltip
          variation='secondary'
          content={DateUtils.format(message.time, DateUtils.DATE_TIME_FULL_FORMAT)}
          dropdownProps={{ noScrollControl: true }}
        >
          <div className={css('message')}>
            {message.event ? (
              ['agentScore', 'botScore', 'overallScore'].includes(message.event.token)
                ? (
                  <div
                    className={allowDisputeRates ? css('score') : ''}
                    onClick={allowDisputeRates ? handlerClickScore : null}
                  >
                    <Trans
                      i18nKey={`dlg:token.${message.event.token}${message.event.disputed ? 'Disputed' : ''}`}
                      components={{
                        score: <Text cls={css('text-bold')} text={placeholders?.score} />,
                        status: <Text text={
                          t(`dlg:${message.event.disputeResolution || 'disputeProcessing'}`)
                        } />
                      }}
                    />
                  </div>
                )
                : <Trans
                  i18nKey={`dlg:token.${message.event.token}`}
                  components={{
                    mainUserName: <UserName name={mainUser.name} avatarUrl={mainUser.avatarUrl} />,
                    secondUserName: <UserName name={secondUser.name} avatarUrl={secondUser.avatarUrl} />,
                    comment: <Text cls={css('text-italic')} text={placeholders?.comment} />,
                    typingText: <Text cls={css('text-italic')} text={placeholders?.text} />,
                    oldComment: <Text cls={css('text-italic')} text={placeholders?.oldComment} />,
                    newComment: <Text cls={css('text-italic')} text={placeholders?.newComment} />,
                    reminder: <Text cls={css('text-italic')} text={placeholders?.reminder} />,
                    tag: <Tag text={placeholders?.tag} />,
                    clientName: <Text cls={css('text-bold')} text={placeholders?.clientName} />,
                    strategy: <Text cls={css('text-bold')} text={placeholders?.strategy} />,
                    channelName: (
                      <Text cls={css('text-bold')} text={t(`dlg:channelType.${placeholders?.channelName}`)} />
                    ),
                    action: <Text cls={css('text-bold')} text={placeholders?.action} />,
                    subjects: <Text cls={css('text-bold')} text={placeholders?.subjects} />,
                    red: <span className={css('red')} />,
                    blue: <span className={css('blue')} />,
                    italic: <span className={css('text-italic')} />,
                    clientLanguage: <Text cls={css('text-bold')} text={placeholders?.clientLanguage ? t(`dlg:languages.${placeholders?.clientLanguage}`) : ''} />,
                    departmentName: (
                      <Text
                        cls={css('text-italic')}
                        text={placeholders?.departments ? placeholders?.departments[0] : ''}
                      />
                    )
                  }}
                />
            ) : (
              <div>{message.action || ''}</div>
            )}
          </div>
        </Tooltip>
      </div>
      {showExcludeScoreDialog && (
        <DisputeScoreDialog
          onCancel={hideExcludeScoreDialog}
          onComplete={hideExcludeScoreDialog}
          message={message}
        />)
      }
    </div>
  )
}

EventMessage.propTypes = {
  message: object
}
