import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { func, object } from 'prop-types'
import { Popup } from '@flomni/components/dist/components/popup/popup'
import { SvgIconClose } from '@flomni/components/dist/components/svg/feathers/SvgIconClose'
import { DateUtils } from '@flomni/modules/dist/services/date'

export const ImagePreview = ({ message, attachment, onClose }) => {
  const css = useStyles(styles)

  return (
    <Popup open variation='secondary' classes={{ content: css('container') }}>
      <div className={css('header')}>
        <div>
          <div className={css('name')}>{attachment.name || '-'}</div>
          <div className={css('date')}>{DateUtils.format(message.time, DateUtils.DATE_TIME_FORMAT)}</div>
        </div>
        <div className={css('cross')} onClick={onClose}>
          <SvgIconClose />
        </div>
      </div>
      <div className={css('content')}>
        <img alt='image' src={attachment.url} />
      </div>
    </Popup>
  )
}

ImagePreview.propTypes = {
  attachment: object.isRequired,
  message: object.isRequired,
  onClose: func.isRequired
}
