import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { Dropdown } from '@flomni/components/dist/components/dropdown'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonText } from '@flomni/components/dist/components/plate-button/text'
import { array, bool, func, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { SecondaryDropdownItem } from '@flomni/components/dist/components/secondary-dropdown-item/item'
import { SecondaryDropdownItemText } from '@flomni/components/dist/components/secondary-dropdown-item/text'
import { Input } from '@flomni/components/dist/components/input'

export const ValueSelector = ({ items, item, placeholder, onChange, translate, isSearchable }) => {
  const { t } = useTranslation()
  const css = useStyles(styles)
  const [showSelector, setShowSelector] = useState(false)
  const [selectedItem, setSelectedItem] = useState(item)
  const [search, setSearch] = useState('')

  const onOverlaySelectorHandler = useCallback(() => {
    setShowSelector(false)
  }, [])

  const onChangeItem = (value) => {
    setSelectedItem(value)
    setShowSelector(false)
  }

  useEffect(() => {
    if (selectedItem !== undefined) {
      onChange(selectedItem)
    }
  }, [selectedItem])

  const selectedItemByValue = items.find((i) => (i?.value ? i.value === selectedItem : i === selectedItem))
  const selectedItemName = selectedItemByValue?.label || selectedItemByValue || null

  const filteredItems = useMemo(() => {
    if (!isSearchable) {
      return items
    }
    const searchLowerCase = search.toLowerCase().trim()
    return items.filter((item) => (item?.label || item).toLowerCase().indexOf(searchLowerCase) > -1)
  }, [items, search, isSearchable])

  return (
    <div>
      <PlateButton view={selectedItemName ? 'usual' : 'bright'} onClick={() => setShowSelector(true)}>
        <PlateButtonText>
          {selectedItemName ? (translate ? t(`dlg:${selectedItemName}`) : selectedItemName) : placeholder}
        </PlateButtonText>
      </PlateButton>
      <Dropdown
        active={showSelector}
        variation='secondary'
        classes={{ root: css('selector') }}
        offsetTarget='iconButton'
        onOverlayClick={onOverlaySelectorHandler}
      >
        {isSearchable && (
          <Input
            variation='secondary'
            autoFocus
            classes={{ input: css('search-input') }}
            placeholder={t('dlg:search')}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        )}
        {filteredItems.length === 0 && <div className={css('no-items')}>{t('dlg:noItems')}</div>}
        {filteredItems.map((item, index) => {
          const name = item?.label || item
          const key = item?.value || index
          const value = item?.value || name
          return (
            <SecondaryDropdownItem
              key={key}
              view='usual'
              thin
              active={value === selectedItem}
              onClick={() => onChangeItem(value)}
            >
              <SecondaryDropdownItemText>{translate ? t(`dlg:${name}`) : name}</SecondaryDropdownItemText>
            </SecondaryDropdownItem>
          )
        })}
      </Dropdown>
    </div>
  )
}

ValueSelector.defaultProps = {
  translate: true,
  isSearchable: false
}

ValueSelector.propTypes = {
  items: array.isRequired,
  item: string,
  placeholder: string,
  onChange: func.isRequired,
  translate: bool,
  isSearchable: bool
}
