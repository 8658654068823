import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { array, bool, object } from 'prop-types'
import { SvgIconUser } from '@flomni/components/dist/components/svg/feathers/SvgIconUser'
import { SvgIconSheet } from '@flomni/components/dist/components/svg/feathers/SvgIconSheet'
import { SvgIconLocation } from '@flomni/components/dist/components/svg/feathers/SvgIconLocation'
import classnames from 'classnames'
import { formatFileSize } from '../../../../../../../../services/helpers'
import { useTranslation } from 'react-i18next'
import URI from 'urijs'
import { ImagePreview } from './image-preview'
import { LocationPreview } from './location-preview'
import ReactPlayer from 'react-player'

export const AttachmentsMessage = ({ message, attachments, isInbound }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const GAPI_MAPS = 'https://maps.googleapis.com/maps/api/staticmap'
  const GAPI_MAPS_KEY = 'AIzaSyDMkMsvhGictObaZxP_oeMTpVrVsjKmEO0'
  const [imageAttachmentPreview, setImageAttachmentPreview] = useState(null)
  const [locationAttachmentPreview, setLocationAttachmentPreview] = useState(null)

  const getMapUrl = (attachment) => {
    const lat = parseFloat(attachment.lat) || 0.0
    const lng = parseFloat(attachment.lng) || 0.0

    return URI(GAPI_MAPS)
      .search({
        center: `${lat}, ${lng}`,
        size: '320x228',
        mapType: 'roadmap',
        markers: `color: red| label: 1| ${lat}, ${lng}`,
        zoom: '14',
        key: GAPI_MAPS_KEY
      })
      .toString()
  }

  const download = (attachment) => {
    const a = document.createElement('a')
    a.href = attachment.url
    a.setAttribute('download', attachment.name)
    a.setAttribute('target', '_blank')
    a.click()
  }

  return attachments.map((attachment, index) => {
    // TODO Remove after storage lifecycle expiration
    if (attachment.url?.match(/^https:\/\/flomni-storage-dev.storage.yandexcloud.net\//))
      attachment.url = attachment.url.split('?AWSAccessKeyId')[0]
    return (
      <div
        key={message._id + index}
        className={css(
          classnames(
            'attachment-container',
            isInbound ? '--inbound' : null,
            message.isRemoved ? '--removed' : null
          )
        )}
      >
        <div className={css(classnames('attachment-body', attachment.type === 'image' ? '--image' : null))}>
          {attachment.type === 'contact' && (
            <div className={css('attachment-item')}>
              <div className={css('attachment-icon')}>
                <SvgIconUser />
              </div>
              <div className={css('attachment-item-body')}>
                <div>{attachment.name}</div>
                <div className={css('attachment-value')}>{attachment.value}</div>
              </div>
            </div>
          )}
          {attachment.type === 'file' && (
            <div className={css(['attachment-item', '--file'])} onClick={() => download(attachment)}>
              <div className={css('attachment-icon')}>
                <SvgIconSheet />
              </div>
              <div className={css('attachment-item-body')}>
                <div>{attachment.name}</div>
                <div className={css('attachment-value')}>{formatFileSize(attachment.size)}</div>
              </div>
            </div>
          )}
          {attachment.type === 'location' && (
            <div className={css('location-block')} onClick={() => setLocationAttachmentPreview(attachment)}>
              <div className={css('attachment-item')}>
                <div className={css('attachment-icon')}>
                  <SvgIconLocation />
                </div>
                <div className={css('attachment-item-body')}>
                  <div>{attachment.name}</div>
                  <div className={css('attachment-value')}>
                    {t('dlg:latLngCoordinates', {
                      lat: attachment.lat.toString(),
                      lng: attachment.lng.toString()
                    })}
                  </div>
                </div>
              </div>
              <img role='presentation' className={css('map')} src={getMapUrl(attachment)} />
            </div>
          )}
          {attachment.type === 'image' && (
            <div className={css('image-block')} onClick={() => setImageAttachmentPreview(attachment)}>
              <div className={css('image-blur')} style={{ backgroundImage: `url(${attachment.url})` }} />
              <div className={css('image-container')}>
                <img src={attachment.url} />
              </div>
              <img className={css('image-hidden')} src={attachment.url} />
            </div>
          )}
          {(attachment.type === 'audio' || attachment.type === 'video') && (
            <div className={css('react-player')}>
              <ReactPlayer
                controls
                url={attachment.url}
                width='290px'
                height={attachment.type === 'audio' ? '32px' : '360px'}
              />
            </div>
          )}
        </div>
        {imageAttachmentPreview && (
          <ImagePreview
            message={message}
            attachment={imageAttachmentPreview}
            onClose={() => setImageAttachmentPreview(null)}
          />
        )}
        {locationAttachmentPreview && (
          <LocationPreview
            attachment={locationAttachmentPreview}
            onClose={() => setLocationAttachmentPreview(null)}
          />
        )}
      </div>
    )
  })
}

AttachmentsMessage.propTypes = {
  message: object.isRequired,
  attachments: array.isRequired,
  isInbound: bool
}
