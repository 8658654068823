import React, { useEffect, useState } from 'react'
import moment from 'moment'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { object } from 'prop-types'
import { Tag } from '@flomni/components/dist/components/tag'
import { SvgIconStopWatch } from '@flomni/components/dist/components/svg/feathers/SvgIconStopWatch'
import { useTranslation } from 'react-i18next'

export const TotalTime = ({ createdAt, lastMessageAt }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const [value, setValue] = useState(null)

  const formatNumber = (value) => {
    return value < 10 ? `0${value}` : value
  }

  useEffect(() => {
    if (createdAt && lastMessageAt) {
      const duration = moment.duration(moment(lastMessageAt).diff(moment(createdAt)))
      const hours = duration.asHours()

      let value = t('dlg:oneDayMore')
      if (hours < 24) {
        value = `${formatNumber(duration.hours())}:${formatNumber(duration.minutes())}:${formatNumber(
          duration.seconds()
        )}`
      }
      setValue(value)
    }
  }, [createdAt, lastMessageAt])

  return value ? (
    <Tag classes={{ inner: css('tag') }} color={'gray'} variation='secondary' view='stroked'>
      <SvgIconStopWatch />
      {value}
    </Tag>
  ) : null
}

TotalTime.props = {
  createdAt: object,
  lastMessageAt: object
}
