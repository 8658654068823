/* @prettier */
// @ts-check
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SvgIconInfo } from '@flomni/components/dist/components/svg/feathers/SvgIconInfo'
import moment from 'moment'
import classnames from 'classnames'
import { isMobileMode } from '../../../services/helpers'
import { useVersionManifest } from '../../shared/hooks/useVersionManifest'

export const UpdateAlert = () => {
  const css = useStyles(styles)
  const { t } = useTranslation()

  const SECONDS_TO_FORCE_UPDATE = 5 * 60
  const [timeToReset, setTimeToReset] = useState(SECONDS_TO_FORCE_UPDATE)
  const { hasNewVersion } = useVersionManifest()

  const updateTimeToReset = (value) => {
    const time = value - 1
    setTimeToReset(time)
    if (time <= 0) {
      reload()
    } else {
      setTimeout(() => updateTimeToReset(time), 1000)
    }
  }

  useEffect(() => {
    if (hasNewVersion) {
      setTimeout(() => updateTimeToReset(SECONDS_TO_FORCE_UPDATE), 1000)
    }
  }, [hasNewVersion])

  const reload = () => {
    window.location.reload()
  }

  if (!hasNewVersion) {
    return null
  }

  return (
    <div className={css(classnames('container', isMobileMode ? '--mobile' : null))}>
      <div className={css('icon')}>
        <SvgIconInfo />
      </div>
      <div className={css('text')}>
        {t('dlg:newVersionIsAvailableDescription', { time: moment.utc(timeToReset * 1000).format('mm:ss') })}
      </div>
      <div className={css('button')} onClick={reload}>
        {t('dlg:update')}
      </div>
    </div>
  )
}
