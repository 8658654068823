export default [
  {
    text: 'America',
    children: [
      {
        id: 'America/Adak',
        text: 'Adak'
      },
      {
        id: 'America/Anchorage',
        text: 'Anchorage'
      },
      {
        id: 'America/Anguilla',
        text: 'Anguilla'
      },
      {
        id: 'America/Antigua',
        text: 'Antigua'
      },
      {
        id: 'America/Araguaina',
        text: 'Araguaina'
      },
      {
        id: 'America/Argentina/Buenos_Aires',
        text: 'Argentina - Buenos Aires'
      },
      {
        id: 'America/Argentina/Catamarca',
        text: 'Argentina - Catamarca'
      },
      {
        id: 'America/Argentina/ComodRivadavia',
        text: 'Argentina - ComodRivadavia'
      },
      {
        id: 'America/Argentina/Cordoba',
        text: 'Argentina - Cordoba'
      },
      {
        id: 'America/Argentina/Jujuy',
        text: 'Argentina - Jujuy'
      },
      {
        id: 'America/Argentina/La_Rioja',
        text: 'Argentina - La Rioja'
      },
      {
        id: 'America/Argentina/Mendoza',
        text: 'Argentina - Mendoza'
      },
      {
        id: 'America/Argentina/Rio_Gallegos',
        text: 'Argentina - Rio Gallegos'
      },
      {
        id: 'America/Argentina/Salta',
        text: 'Argentina - Salta'
      },
      {
        id: 'America/Argentina/San_Juan',
        text: 'Argentina - San Juan'
      },
      {
        id: 'America/Argentina/San_Luis',
        text: 'Argentina - San Luis'
      },
      {
        id: 'America/Argentina/Tucuman',
        text: 'Argentina - Tucuman'
      },
      {
        id: 'America/Argentina/Ushuaia',
        text: 'Argentina - Ushuaia'
      },
      {
        id: 'America/Aruba',
        text: 'Aruba'
      },
      {
        id: 'America/Asuncion',
        text: 'Asuncion'
      },
      {
        id: 'America/Atikokan',
        text: 'Atikokan'
      },
      {
        id: 'America/Atka',
        text: 'Atka'
      },
      {
        id: 'America/Bahia',
        text: 'Bahia'
      },
      {
        id: 'America/Barbados',
        text: 'Barbados'
      },
      {
        id: 'America/Belem',
        text: 'Belem'
      },
      {
        id: 'America/Belize',
        text: 'Belize'
      },
      {
        id: 'America/Blanc-Sablon',
        text: 'Blanc-Sablon'
      },
      {
        id: 'America/Boa_Vista',
        text: 'Boa Vista'
      },
      {
        id: 'America/Bogota',
        text: 'Bogota'
      },
      {
        id: 'America/Boise',
        text: 'Boise'
      },
      {
        id: 'America/Buenos_Aires',
        text: 'Buenos Aires'
      },
      {
        id: 'America/Cambridge_Bay',
        text: 'Cambridge Bay'
      },
      {
        id: 'America/Campo_Grande',
        text: 'Campo Grande'
      },
      {
        id: 'America/Cancun',
        text: 'Cancun'
      },
      {
        id: 'America/Caracas',
        text: 'Caracas'
      },
      {
        id: 'America/Catamarca',
        text: 'Catamarca'
      },
      {
        id: 'America/Cayenne',
        text: 'Cayenne'
      },
      {
        id: 'America/Cayman',
        text: 'Cayman'
      },
      {
        id: 'America/Chicago',
        text: 'Chicago'
      },
      {
        id: 'America/Chihuahua',
        text: 'Chihuahua'
      },
      {
        id: 'America/Coral_Harbour',
        text: 'Coral Harbour'
      },
      {
        id: 'America/Cordoba',
        text: 'Cordoba'
      },
      {
        id: 'America/Costa_Rica',
        text: 'Costa Rica'
      },
      {
        id: 'America/Cuiaba',
        text: 'Cuiaba'
      },
      {
        id: 'America/Curacao',
        text: 'Curacao'
      },
      {
        id: 'America/Danmarkshavn',
        text: 'Danmarkshavn'
      },
      {
        id: 'America/Dawson',
        text: 'Dawson'
      },
      {
        id: 'America/Dawson_Creek',
        text: 'Dawson Creek'
      },
      {
        id: 'America/Denver',
        text: 'Denver'
      },
      {
        id: 'America/Detroit',
        text: 'Detroit'
      },
      {
        id: 'America/Dominica',
        text: 'Dominica'
      },
      {
        id: 'America/Edmonton',
        text: 'Edmonton'
      },
      {
        id: 'America/Eirunepe',
        text: 'Eirunepe'
      },
      {
        id: 'America/El_Salvador',
        text: 'El Salvador'
      },
      {
        id: 'America/Ensenada',
        text: 'Ensenada'
      },
      {
        id: 'America/Fortaleza',
        text: 'Fortaleza'
      },
      {
        id: 'America/Fort_Wayne',
        text: 'Fort Wayne'
      },
      {
        id: 'America/Glace_Bay',
        text: 'Glace Bay'
      },
      {
        id: 'America/Godthab',
        text: 'Godthab'
      },
      {
        id: 'America/Goose_Bay',
        text: 'Goose Bay'
      },
      {
        id: 'America/Grand_Turk',
        text: 'Grand Turk'
      },
      {
        id: 'America/Grenada',
        text: 'Grenada'
      },
      {
        id: 'America/Guadeloupe',
        text: 'Guadeloupe'
      },
      {
        id: 'America/Guatemala',
        text: 'Guatemala'
      },
      {
        id: 'America/Guayaquil',
        text: 'Guayaquil'
      },
      {
        id: 'America/Guyana',
        text: 'Guyana'
      },
      {
        id: 'America/Halifax',
        text: 'Halifax'
      },
      {
        id: 'America/Havana',
        text: 'Havana'
      },
      {
        id: 'America/Hermosillo',
        text: 'Hermosillo'
      },
      {
        id: 'America/Indiana/Indianapolis',
        text: 'Indiana - Indianapolis'
      },
      {
        id: 'America/Indiana/Knox',
        text: 'Indiana - Knox'
      },
      {
        id: 'America/Indiana/Marengo',
        text: 'Indiana - Marengo'
      },
      {
        id: 'America/Indiana/Petersburg',
        text: 'Indiana - Petersburg'
      },
      {
        id: 'America/Indiana/Tell_City',
        text: 'Indiana - Tell City'
      },
      {
        id: 'America/Indiana/Vevay',
        text: 'Indiana - Vevay'
      },
      {
        id: 'America/Indiana/Vincennes',
        text: 'Indiana - Vincennes'
      },
      {
        id: 'America/Indiana/Winamac',
        text: 'Indiana - Winamac'
      },
      {
        id: 'America/Indianapolis',
        text: 'Indianapolis'
      },
      {
        id: 'America/Inuvik',
        text: 'Inuvik'
      },
      {
        id: 'America/Iqaluit',
        text: 'Iqaluit'
      },
      {
        id: 'America/Jamaica',
        text: 'Jamaica'
      },
      {
        id: 'America/Jujuy',
        text: 'Jujuy'
      },
      {
        id: 'America/Juneau',
        text: 'Juneau'
      },
      {
        id: 'America/Kentucky/Louisville',
        text: 'Kentucky - Louisville'
      },
      {
        id: 'America/Kentucky/Monticello',
        text: 'Kentucky - Monticello'
      },
      {
        id: 'America/Knox_IN',
        text: 'Knox IN'
      },
      {
        id: 'America/La_Paz',
        text: 'La Paz'
      },
      {
        id: 'America/Lima',
        text: 'Lima'
      },
      {
        id: 'America/Los_Angeles',
        text: 'Los Angeles'
      },
      {
        id: 'America/Louisville',
        text: 'Louisville'
      },
      {
        id: 'America/Maceio',
        text: 'Maceio'
      },
      {
        id: 'America/Managua',
        text: 'Managua'
      },
      {
        id: 'America/Manaus',
        text: 'Manaus'
      },
      {
        id: 'America/Marigot',
        text: 'Marigot'
      },
      {
        id: 'America/Martinique',
        text: 'Martinique'
      },
      {
        id: 'America/Matamoros',
        text: 'Matamoros'
      },
      {
        id: 'America/Mazatlan',
        text: 'Mazatlan'
      },
      {
        id: 'America/Mendoza',
        text: 'Mendoza'
      },
      {
        id: 'America/Menominee',
        text: 'Menominee'
      },
      {
        id: 'America/Merida',
        text: 'Merida'
      },
      {
        id: 'America/Mexico_City',
        text: 'Mexico City'
      },
      {
        id: 'America/Miquelon',
        text: 'Miquelon'
      },
      {
        id: 'America/Moncton',
        text: 'Moncton'
      },
      {
        id: 'America/Monterrey',
        text: 'Monterrey'
      },
      {
        id: 'America/Montevideo',
        text: 'Montevideo'
      },
      {
        id: 'America/Montreal',
        text: 'Montreal'
      },
      {
        id: 'America/Montserrat',
        text: 'Montserrat'
      },
      {
        id: 'America/Nassau',
        text: 'Nassau'
      },
      {
        id: 'America/New_York',
        text: 'New York'
      },
      {
        id: 'America/Nipigon',
        text: 'Nipigon'
      },
      {
        id: 'America/Nome',
        text: 'Nome'
      },
      {
        id: 'America/Noronha',
        text: 'Noronha'
      },
      {
        id: 'America/North_Dakota/Center',
        text: 'North Dakota - Center'
      },
      {
        id: 'America/North_Dakota/New_Salem',
        text: 'North Dakota - New Salem'
      },
      {
        id: 'America/Ojinaga',
        text: 'Ojinaga'
      },
      {
        id: 'America/Panama',
        text: 'Panama'
      },
      {
        id: 'America/Pangnirtung',
        text: 'Pangnirtung'
      },
      {
        id: 'America/Paramaribo',
        text: 'Paramaribo'
      },
      {
        id: 'America/Phoenix',
        text: 'Phoenix'
      },
      {
        id: 'America/Port-au-Prince',
        text: 'Port-au-Prince'
      },
      {
        id: 'America/Porto_Acre',
        text: 'Porto Acre'
      },
      {
        id: 'America/Port_of_Spain',
        text: 'Port of Spain'
      },
      {
        id: 'America/Porto_Velho',
        text: 'Porto Velho'
      },
      {
        id: 'America/Puerto_Rico',
        text: 'Puerto Rico'
      },
      {
        id: 'America/Rainy_River',
        text: 'Rainy River'
      },
      {
        id: 'America/Rankin_Inlet',
        text: 'Rankin Inlet'
      },
      {
        id: 'America/Recife',
        text: 'Recife'
      },
      {
        id: 'America/Regina',
        text: 'Regina'
      },
      {
        id: 'America/Resolute',
        text: 'Resolute'
      },
      {
        id: 'America/Rio_Branco',
        text: 'Rio Branco'
      },
      {
        id: 'America/Rosario',
        text: 'Rosario'
      },
      {
        id: 'America/Santa_Isabel',
        text: 'Santa Isabel'
      },
      {
        id: 'America/Santarem',
        text: 'Santarem'
      },
      {
        id: 'America/Santiago',
        text: 'Santiago'
      },
      {
        id: 'America/Santo_Domingo',
        text: 'Santo Domingo'
      },
      {
        id: 'America/Sao_Paulo',
        text: 'Sao Paulo'
      },
      {
        id: 'America/Scoresbysund',
        text: 'Scoresbysund'
      },
      {
        id: 'America/Shiprock',
        text: 'Shiprock'
      },
      {
        id: 'America/St_Barthelemy',
        text: 'St Barthelemy'
      },
      {
        id: 'America/St_Johns',
        text: 'St Johns'
      },
      {
        id: 'America/St_Kitts',
        text: 'St Kitts'
      },
      {
        id: 'America/St_Lucia',
        text: 'St Lucia'
      },
      {
        id: 'America/St_Thomas',
        text: 'St Thomas'
      },
      {
        id: 'America/St_Vincent',
        text: 'St Vincent'
      },
      {
        id: 'America/Swift_Current',
        text: 'Swift Current'
      },
      {
        id: 'America/Tegucigalpa',
        text: 'Tegucigalpa'
      },
      {
        id: 'America/Thule',
        text: 'Thule'
      },
      {
        id: 'America/Thunder_Bay',
        text: 'Thunder Bay'
      },
      {
        id: 'America/Tijuana',
        text: 'Tijuana'
      },
      {
        id: 'America/Toronto',
        text: 'Toronto'
      },
      {
        id: 'America/Tortola',
        text: 'Tortola'
      },
      {
        id: 'America/Vancouver',
        text: 'Vancouver'
      },
      {
        id: 'America/Virgin',
        text: 'Virgin'
      },
      {
        id: 'America/Whitehorse',
        text: 'Whitehorse'
      },
      {
        id: 'America/Winnipeg',
        text: 'Winnipeg'
      },
      {
        id: 'America/Yakutat',
        text: 'Yakutat'
      },
      {
        id: 'America/Yellowknife',
        text: 'Yellowknife'
      }
    ]
  },
  {
    text: 'Europe',
    children: [
      {
        id: 'Europe/Amsterdam',
        text: 'Amsterdam'
      },
      {
        id: 'Europe/Andorra',
        text: 'Andorra'
      },
      {
        id: 'Europe/Athens',
        text: 'Athens'
      },
      {
        id: 'Europe/Belfast',
        text: 'Belfast'
      },
      {
        id: 'Europe/Belgrade',
        text: 'Belgrade'
      },
      {
        id: 'Europe/Berlin',
        text: 'Berlin'
      },
      {
        id: 'Europe/Bratislava',
        text: 'Bratislava'
      },
      {
        id: 'Europe/Brussels',
        text: 'Brussels'
      },
      {
        id: 'Europe/Bucharest',
        text: 'Bucharest'
      },
      {
        id: 'Europe/Budapest',
        text: 'Budapest'
      },
      {
        id: 'Europe/Chisinau',
        text: 'Chisinau'
      },
      {
        id: 'Europe/Copenhagen',
        text: 'Copenhagen'
      },
      {
        id: 'Europe/Dublin',
        text: 'Dublin'
      },
      {
        id: 'Europe/Gibraltar',
        text: 'Gibraltar'
      },
      {
        id: 'Europe/Guernsey',
        text: 'Guernsey'
      },
      {
        id: 'Europe/Helsinki',
        text: 'Helsinki'
      },
      {
        id: 'Europe/Isle_of_Man',
        text: 'Isle of Man'
      },
      {
        id: 'Europe/Istanbul',
        text: 'Istanbul'
      },
      {
        id: 'Europe/Jersey',
        text: 'Jersey'
      },
      {
        id: 'Europe/Kaliningrad',
        text: 'Kaliningrad'
      },
      {
        id: 'Europe/Kiev',
        text: 'Kiev'
      },
      {
        id: 'Europe/Lisbon',
        text: 'Lisbon'
      },
      {
        id: 'Europe/Ljubljana',
        text: 'Ljubljana'
      },
      {
        id: 'Europe/London',
        text: 'London'
      },
      {
        id: 'Europe/Luxembourg',
        text: 'Luxembourg'
      },
      {
        id: 'Europe/Madrid',
        text: 'Madrid'
      },
      {
        id: 'Europe/Malta',
        text: 'Malta'
      },
      {
        id: 'Europe/Mariehamn',
        text: 'Mariehamn'
      },
      {
        id: 'Europe/Minsk',
        text: 'Minsk'
      },
      {
        id: 'Europe/Monaco',
        text: 'Monaco'
      },
      {
        id: 'Europe/Moscow',
        text: 'Moscow'
      },
      {
        id: 'Europe/Nicosia',
        text: 'Nicosia'
      },
      {
        id: 'Europe/Oslo',
        text: 'Oslo'
      },
      {
        id: 'Europe/Paris',
        text: 'Paris'
      },
      {
        id: 'Europe/Podgorica',
        text: 'Podgorica'
      },
      {
        id: 'Europe/Prague',
        text: 'Prague'
      },
      {
        id: 'Europe/Riga',
        text: 'Riga'
      },
      {
        id: 'Europe/Rome',
        text: 'Rome'
      },
      {
        id: 'Europe/Samara',
        text: 'Samara'
      },
      {
        id: 'Europe/San_Marino',
        text: 'San Marino'
      },
      {
        id: 'Europe/Sarajevo',
        text: 'Sarajevo'
      },
      {
        id: 'Europe/Simferopol',
        text: 'Simferopol'
      },
      {
        id: 'Europe/Skopje',
        text: 'Skopje'
      },
      {
        id: 'Europe/Sofia',
        text: 'Sofia'
      },
      {
        id: 'Europe/Stockholm',
        text: 'Stockholm'
      },
      {
        id: 'Europe/Tallinn',
        text: 'Tallinn'
      },
      {
        id: 'Europe/Tirane',
        text: 'Tirane'
      },
      {
        id: 'Europe/Tiraspol',
        text: 'Tiraspol'
      },
      {
        id: 'Europe/Uzhgorod',
        text: 'Uzhgorod'
      },
      {
        id: 'Europe/Vaduz',
        text: 'Vaduz'
      },
      {
        id: 'Europe/Vatican',
        text: 'Vatican'
      },
      {
        id: 'Europe/Vienna',
        text: 'Vienna'
      },
      {
        id: 'Europe/Vilnius',
        text: 'Vilnius'
      },
      {
        id: 'Europe/Volgograd',
        text: 'Volgograd'
      },
      {
        id: 'Europe/Warsaw',
        text: 'Warsaw'
      },
      {
        id: 'Europe/Zagreb',
        text: 'Zagreb'
      },
      {
        id: 'Europe/Zaporozhye',
        text: 'Zaporozhye'
      },
      {
        id: 'Europe/Zurich',
        text: 'Zurich'
      }
    ]
  },
  {
    text: 'Asia',
    children: [
      {
        id: 'Asia/Aden',
        text: 'Aden'
      },
      {
        id: 'Asia/Almaty',
        text: 'Almaty'
      },
      {
        id: 'Asia/Amman',
        text: 'Amman'
      },
      {
        id: 'Asia/Anadyr',
        text: 'Anadyr'
      },
      {
        id: 'Asia/Aqtau',
        text: 'Aqtau'
      },
      {
        id: 'Asia/Aqtobe',
        text: 'Aqtobe'
      },
      {
        id: 'Asia/Ashgabat',
        text: 'Ashgabat'
      },
      {
        id: 'Asia/Ashkhabad',
        text: 'Ashkhabad'
      },
      {
        id: 'Asia/Baghdad',
        text: 'Baghdad'
      },
      {
        id: 'Asia/Bahrain',
        text: 'Bahrain'
      },
      {
        id: 'Asia/Baku',
        text: 'Baku'
      },
      {
        id: 'Asia/Bangkok',
        text: 'Bangkok'
      },
      {
        id: 'Asia/Beirut',
        text: 'Beirut'
      },
      {
        id: 'Asia/Bishkek',
        text: 'Bishkek'
      },
      {
        id: 'Asia/Brunei',
        text: 'Brunei'
      },
      {
        id: 'Asia/Calcutta',
        text: 'Calcutta'
      },
      {
        id: 'Asia/Choibalsan',
        text: 'Choibalsan'
      },
      {
        id: 'Asia/Chongqing',
        text: 'Chongqing'
      },
      {
        id: 'Asia/Chungking',
        text: 'Chungking'
      },
      {
        id: 'Asia/Colombo',
        text: 'Colombo'
      },
      {
        id: 'Asia/Dacca',
        text: 'Dacca'
      },
      {
        id: 'Asia/Damascus',
        text: 'Damascus'
      },
      {
        id: 'Asia/Dhaka',
        text: 'Dhaka'
      },
      {
        id: 'Asia/Dili',
        text: 'Dili'
      },
      {
        id: 'Asia/Dubai',
        text: 'Dubai'
      },
      {
        id: 'Asia/Dushanbe',
        text: 'Dushanbe'
      },
      {
        id: 'Asia/Gaza',
        text: 'Gaza'
      },
      {
        id: 'Asia/Harbin',
        text: 'Harbin'
      },
      {
        id: 'Asia/Ho_Chi_Minh',
        text: 'Ho Chi Minh'
      },
      {
        id: 'Asia/Hong_Kong',
        text: 'Hong Kong'
      },
      {
        id: 'Asia/Hovd',
        text: 'Hovd'
      },
      {
        id: 'Asia/Irkutsk',
        text: 'Irkutsk'
      },
      {
        id: 'Asia/Istanbul',
        text: 'Istanbul'
      },
      {
        id: 'Asia/Jakarta',
        text: 'Jakarta'
      },
      {
        id: 'Asia/Jayapura',
        text: 'Jayapura'
      },
      {
        id: 'Asia/Jerusalem',
        text: 'Jerusalem'
      },
      {
        id: 'Asia/Kabul',
        text: 'Kabul'
      },
      {
        id: 'Asia/Kamchatka',
        text: 'Kamchatka'
      },
      {
        id: 'Asia/Karachi',
        text: 'Karachi'
      },
      {
        id: 'Asia/Kashgar',
        text: 'Kashgar'
      },
      {
        id: 'Asia/Kathmandu',
        text: 'Kathmandu'
      },
      {
        id: 'Asia/Katmandu',
        text: 'Katmandu'
      },
      {
        id: 'Asia/Kolkata',
        text: 'Kolkata'
      },
      {
        id: 'Asia/Krasnoyarsk',
        text: 'Krasnoyarsk'
      },
      {
        id: 'Asia/Kuala_Lumpur',
        text: 'Kuala Lumpur'
      },
      {
        id: 'Asia/Kuching',
        text: 'Kuching'
      },
      {
        id: 'Asia/Kuwait',
        text: 'Kuwait'
      },
      {
        id: 'Asia/Macao',
        text: 'Macao'
      },
      {
        id: 'Asia/Macau',
        text: 'Macau'
      },
      {
        id: 'Asia/Magadan',
        text: 'Magadan'
      },
      {
        id: 'Asia/Makassar',
        text: 'Makassar'
      },
      {
        id: 'Asia/Manila',
        text: 'Manila'
      },
      {
        id: 'Asia/Muscat',
        text: 'Muscat'
      },
      {
        id: 'Asia/Nicosia',
        text: 'Nicosia'
      },
      {
        id: 'Asia/Novokuznetsk',
        text: 'Novokuznetsk'
      },
      {
        id: 'Asia/Novosibirsk',
        text: 'Novosibirsk'
      },
      {
        id: 'Asia/Omsk',
        text: 'Omsk'
      },
      {
        id: 'Asia/Oral',
        text: 'Oral'
      },
      {
        id: 'Asia/Phnom_Penh',
        text: 'Phnom Penh'
      },
      {
        id: 'Asia/Pontianak',
        text: 'Pontianak'
      },
      {
        id: 'Asia/Pyongyang',
        text: 'Pyongyang'
      },
      {
        id: 'Asia/Qatar',
        text: 'Qatar'
      },
      {
        id: 'Asia/Qyzylorda',
        text: 'Qyzylorda'
      },
      {
        id: 'Asia/Rangoon',
        text: 'Rangoon'
      },
      {
        id: 'Asia/Riyadh',
        text: 'Riyadh'
      },
      {
        id: 'Asia/Saigon',
        text: 'Saigon'
      },
      {
        id: 'Asia/Sakhalin',
        text: 'Sakhalin'
      },
      {
        id: 'Asia/Samarkand',
        text: 'Samarkand'
      },
      {
        id: 'Asia/Seoul',
        text: 'Seoul'
      },
      {
        id: 'Asia/Shanghai',
        text: 'Shanghai'
      },
      {
        id: 'Asia/Singapore',
        text: 'Singapore'
      },
      {
        id: 'Asia/Taipei',
        text: 'Taipei'
      },
      {
        id: 'Asia/Tashkent',
        text: 'Tashkent'
      },
      {
        id: 'Asia/Tbilisi',
        text: 'Tbilisi'
      },
      {
        id: 'Asia/Tehran',
        text: 'Tehran'
      },
      {
        id: 'Asia/Tel_Aviv',
        text: 'Tel Aviv'
      },
      {
        id: 'Asia/Thimbu',
        text: 'Thimbu'
      },
      {
        id: 'Asia/Thimphu',
        text: 'Thimphu'
      },
      {
        id: 'Asia/Tokyo',
        text: 'Tokyo'
      },
      {
        id: 'Asia/Ujung_Pandang',
        text: 'Ujung Pandang'
      },
      {
        id: 'Asia/Ulaanbaatar',
        text: 'Ulaanbaatar'
      },
      {
        id: 'Asia/Ulan_Bator',
        text: 'Ulan Bator'
      },
      {
        id: 'Asia/Urumqi',
        text: 'Urumqi'
      },
      {
        id: 'Asia/Vientiane',
        text: 'Vientiane'
      },
      {
        id: 'Asia/Vladivostok',
        text: 'Vladivostok'
      },
      {
        id: 'Asia/Yakutsk',
        text: 'Yakutsk'
      },
      {
        id: 'Asia/Yekaterinburg',
        text: 'Yekaterinburg'
      },
      {
        id: 'Asia/Yerevan',
        text: 'Yerevan'
      }
    ]
  },
  {
    text: 'Africa',
    children: [
      {
        id: 'Africa/Abidjan',
        text: 'Abidjan'
      },
      {
        id: 'Africa/Accra',
        text: 'Accra'
      },
      {
        id: 'Africa/Addis_Ababa',
        text: 'Addis Ababa'
      },
      {
        id: 'Africa/Algiers',
        text: 'Algiers'
      },
      {
        id: 'Africa/Asmara',
        text: 'Asmara'
      },
      {
        id: 'Africa/Asmera',
        text: 'Asmera'
      },
      {
        id: 'Africa/Bamako',
        text: 'Bamako'
      },
      {
        id: 'Africa/Bangui',
        text: 'Bangui'
      },
      {
        id: 'Africa/Banjul',
        text: 'Banjul'
      },
      {
        id: 'Africa/Bissau',
        text: 'Bissau'
      },
      {
        id: 'Africa/Blantyre',
        text: 'Blantyre'
      },
      {
        id: 'Africa/Brazzaville',
        text: 'Brazzaville'
      },
      {
        id: 'Africa/Bujumbura',
        text: 'Bujumbura'
      },
      {
        id: 'Africa/Cairo',
        text: 'Cairo'
      },
      {
        id: 'Africa/Casablanca',
        text: 'Casablanca'
      },
      {
        id: 'Africa/Ceuta',
        text: 'Ceuta'
      },
      {
        id: 'Africa/Conakry',
        text: 'Conakry'
      },
      {
        id: 'Africa/Dakar',
        text: 'Dakar'
      },
      {
        id: 'Africa/Dar_es_Salaam',
        text: 'Dar es Salaam'
      },
      {
        id: 'Africa/Djibouti',
        text: 'Djibouti'
      },
      {
        id: 'Africa/Douala',
        text: 'Douala'
      },
      {
        id: 'Africa/El_Aaiun',
        text: 'El Aaiun'
      },
      {
        id: 'Africa/Freetown',
        text: 'Freetown'
      },
      {
        id: 'Africa/Gaborone',
        text: 'Gaborone'
      },
      {
        id: 'Africa/Harare',
        text: 'Harare'
      },
      {
        id: 'Africa/Johannesburg',
        text: 'Johannesburg'
      },
      {
        id: 'Africa/Kampala',
        text: 'Kampala'
      },
      {
        id: 'Africa/Khartoum',
        text: 'Khartoum'
      },
      {
        id: 'Africa/Kigali',
        text: 'Kigali'
      },
      {
        id: 'Africa/Kinshasa',
        text: 'Kinshasa'
      },
      {
        id: 'Africa/Lagos',
        text: 'Lagos'
      },
      {
        id: 'Africa/Libreville',
        text: 'Libreville'
      },
      {
        id: 'Africa/Lome',
        text: 'Lome'
      },
      {
        id: 'Africa/Luanda',
        text: 'Luanda'
      },
      {
        id: 'Africa/Lubumbashi',
        text: 'Lubumbashi'
      },
      {
        id: 'Africa/Lusaka',
        text: 'Lusaka'
      },
      {
        id: 'Africa/Malabo',
        text: 'Malabo'
      },
      {
        id: 'Africa/Maputo',
        text: 'Maputo'
      },
      {
        id: 'Africa/Maseru',
        text: 'Maseru'
      },
      {
        id: 'Africa/Mbabane',
        text: 'Mbabane'
      },
      {
        id: 'Africa/Mogadishu',
        text: 'Mogadishu'
      },
      {
        id: 'Africa/Monrovia',
        text: 'Monrovia'
      },
      {
        id: 'Africa/Nairobi',
        text: 'Nairobi'
      },
      {
        id: 'Africa/Ndjamena',
        text: 'Ndjamena'
      },
      {
        id: 'Africa/Niamey',
        text: 'Niamey'
      },
      {
        id: 'Africa/Nouakchott',
        text: 'Nouakchott'
      },
      {
        id: 'Africa/Ouagadougou',
        text: 'Ouagadougou'
      },
      {
        id: 'Africa/Porto-Novo',
        text: 'Porto-Novo'
      },
      {
        id: 'Africa/Sao_Tome',
        text: 'Sao Tome'
      },
      {
        id: 'Africa/Timbuktu',
        text: 'Timbuktu'
      },
      {
        id: 'Africa/Tripoli',
        text: 'Tripoli'
      },
      {
        id: 'Africa/Tunis',
        text: 'Tunis'
      },
      {
        id: 'Africa/Windhoek',
        text: 'Windhoek'
      }
    ]
  },
  {
    text: 'Australia',
    children: [
      {
        id: 'Australia/ACT',
        text: 'ACT'
      },
      {
        id: 'Australia/Adelaide',
        text: 'Adelaide'
      },
      {
        id: 'Australia/Brisbane',
        text: 'Brisbane'
      },
      {
        id: 'Australia/Broken_Hill',
        text: 'Broken Hill'
      },
      {
        id: 'Australia/Canberra',
        text: 'Canberra'
      },
      {
        id: 'Australia/Currie',
        text: 'Currie'
      },
      {
        id: 'Australia/Darwin',
        text: 'Darwin'
      },
      {
        id: 'Australia/Eucla',
        text: 'Eucla'
      },
      {
        id: 'Australia/Hobart',
        text: 'Hobart'
      },
      {
        id: 'Australia/LHI',
        text: 'LHI'
      },
      {
        id: 'Australia/Lindeman',
        text: 'Lindeman'
      },
      {
        id: 'Australia/Lord_Howe',
        text: 'Lord Howe'
      },
      {
        id: 'Australia/Melbourne',
        text: 'Melbourne'
      },
      {
        id: 'Australia/North',
        text: 'North'
      },
      {
        id: 'Australia/NSW',
        text: 'NSW'
      },
      {
        id: 'Australia/Perth',
        text: 'Perth'
      },
      {
        id: 'Australia/Queensland',
        text: 'Queensland'
      },
      {
        id: 'Australia/South',
        text: 'South'
      },
      {
        id: 'Australia/Sydney',
        text: 'Sydney'
      },
      {
        id: 'Australia/Tasmania',
        text: 'Tasmania'
      },
      {
        id: 'Australia/Victoria',
        text: 'Victoria'
      },
      {
        id: 'Australia/West',
        text: 'West'
      },
      {
        id: 'Australia/Yancowinna',
        text: 'Yancowinna'
      }
    ]
  },
  {
    text: 'Indian',
    children: [
      {
        id: 'Indian/Antananarivo',
        text: 'Antananarivo'
      },
      {
        id: 'Indian/Chagos',
        text: 'Chagos'
      },
      {
        id: 'Indian/Christmas',
        text: 'Christmas'
      },
      {
        id: 'Indian/Cocos',
        text: 'Cocos'
      },
      {
        id: 'Indian/Comoro',
        text: 'Comoro'
      },
      {
        id: 'Indian/Kerguelen',
        text: 'Kerguelen'
      },
      {
        id: 'Indian/Mahe',
        text: 'Mahe'
      },
      {
        id: 'Indian/Maldives',
        text: 'Maldives'
      },
      {
        id: 'Indian/Mauritius',
        text: 'Mauritius'
      },
      {
        id: 'Indian/Mayotte',
        text: 'Mayotte'
      },
      {
        id: 'Indian/Reunion',
        text: 'Reunion'
      }
    ]
  },
  {
    text: 'Atlantic',
    children: [
      {
        id: 'Atlantic/Azores',
        text: 'Azores'
      },
      {
        id: 'Atlantic/Bermuda',
        text: 'Bermuda'
      },
      {
        id: 'Atlantic/Canary',
        text: 'Canary'
      },
      {
        id: 'Atlantic/Cape_Verde',
        text: 'Cape Verde'
      },
      {
        id: 'Atlantic/Faeroe',
        text: 'Faeroe'
      },
      {
        id: 'Atlantic/Faroe',
        text: 'Faroe'
      },
      {
        id: 'Atlantic/Jan_Mayen',
        text: 'Jan Mayen'
      },
      {
        id: 'Atlantic/Madeira',
        text: 'Madeira'
      },
      {
        id: 'Atlantic/Reykjavik',
        text: 'Reykjavik'
      },
      {
        id: 'Atlantic/South_Georgia',
        text: 'South Georgia'
      },
      {
        id: 'Atlantic/Stanley',
        text: 'Stanley'
      },
      {
        id: 'Atlantic/St_Helena',
        text: 'St Helena'
      }
    ]
  },
  {
    text: 'Pacific',
    children: [
      {
        id: 'Pacific/Apia',
        text: 'Apia'
      },
      {
        id: 'Pacific/Auckland',
        text: 'Auckland'
      },
      {
        id: 'Pacific/Chatham',
        text: 'Chatham'
      },
      {
        id: 'Pacific/Easter',
        text: 'Easter'
      },
      {
        id: 'Pacific/Efate',
        text: 'Efate'
      },
      {
        id: 'Pacific/Enderbury',
        text: 'Enderbury'
      },
      {
        id: 'Pacific/Fakaofo',
        text: 'Fakaofo'
      },
      {
        id: 'Pacific/Fiji',
        text: 'Fiji'
      },
      {
        id: 'Pacific/Funafuti',
        text: 'Funafuti'
      },
      {
        id: 'Pacific/Galapagos',
        text: 'Galapagos'
      },
      {
        id: 'Pacific/Gambier',
        text: 'Gambier'
      },
      {
        id: 'Pacific/Guadalcanal',
        text: 'Guadalcanal'
      },
      {
        id: 'Pacific/Guam',
        text: 'Guam'
      },
      {
        id: 'Pacific/Honolulu',
        text: 'Honolulu'
      },
      {
        id: 'Pacific/Johnston',
        text: 'Johnston'
      },
      {
        id: 'Pacific/Kiritimati',
        text: 'Kiritimati'
      },
      {
        id: 'Pacific/Kosrae',
        text: 'Kosrae'
      },
      {
        id: 'Pacific/Kwajalein',
        text: 'Kwajalein'
      },
      {
        id: 'Pacific/Majuro',
        text: 'Majuro'
      },
      {
        id: 'Pacific/Marquesas',
        text: 'Marquesas'
      },
      {
        id: 'Pacific/Midway',
        text: 'Midway'
      },
      {
        id: 'Pacific/Nauru',
        text: 'Nauru'
      },
      {
        id: 'Pacific/Niue',
        text: 'Niue'
      },
      {
        id: 'Pacific/Norfolk',
        text: 'Norfolk'
      },
      {
        id: 'Pacific/Noumea',
        text: 'Noumea'
      },
      {
        id: 'Pacific/Pago_Pago',
        text: 'Pago Pago'
      },
      {
        id: 'Pacific/Palau',
        text: 'Palau'
      },
      {
        id: 'Pacific/Pitcairn',
        text: 'Pitcairn'
      },
      {
        id: 'Pacific/Ponape',
        text: 'Ponape'
      },
      {
        id: 'Pacific/Port_Moresby',
        text: 'Port Moresby'
      },
      {
        id: 'Pacific/Rarotonga',
        text: 'Rarotonga'
      },
      {
        id: 'Pacific/Saipan',
        text: 'Saipan'
      },
      {
        id: 'Pacific/Samoa',
        text: 'Samoa'
      },
      {
        id: 'Pacific/Tahiti',
        text: 'Tahiti'
      },
      {
        id: 'Pacific/Tarawa',
        text: 'Tarawa'
      },
      {
        id: 'Pacific/Tongatapu',
        text: 'Tongatapu'
      },
      {
        id: 'Pacific/Truk',
        text: 'Truk'
      },
      {
        id: 'Pacific/Wake',
        text: 'Wake'
      },
      {
        id: 'Pacific/Wallis',
        text: 'Wallis'
      },
      {
        id: 'Pacific/Yap',
        text: 'Yap'
      }
    ]
  },
  {
    text: 'Antarctica',
    children: [
      {
        id: 'Antarctica/Casey',
        text: 'Casey'
      },
      {
        id: 'Antarctica/Davis',
        text: 'Davis'
      },
      {
        id: 'Antarctica/DumontDUrville',
        text: 'DumontDUrville'
      },
      {
        id: 'Antarctica/Macquarie',
        text: 'Macquarie'
      },
      {
        id: 'Antarctica/Mawson',
        text: 'Mawson'
      },
      {
        id: 'Antarctica/McMurdo',
        text: 'McMurdo'
      },
      {
        id: 'Antarctica/Palmer',
        text: 'Palmer'
      },
      {
        id: 'Antarctica/Rothera',
        text: 'Rothera'
      },
      {
        id: 'Antarctica/South_Pole',
        text: 'South Pole'
      },
      {
        id: 'Antarctica/Syowa',
        text: 'Syowa'
      },
      {
        id: 'Antarctica/Vostok',
        text: 'Vostok'
      }
    ]
  },
  {
    text: 'Arctic',
    children: [
      {
        id: 'Arctic/Longyearbyen',
        text: 'Longyearbyen'
      }
    ]
  },
  {
    text: 'UTC',
    children: [
      {
        id: 'UTC',
        text: 'UTC'
      }
    ]
  }
]
