import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import { Chart } from '@flomni/components/dist/components/chart'
import React, { useEffect, useMemo, useState } from 'react'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonText } from '@flomni/components/dist/components/plate-button/text'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { SvgIconExportArrow } from '@flomni/components/dist/components/svg/feathers/SvgIconExportArrow'
import moment from 'moment'
import { processError } from '../../../../../../services/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from '@flomni/components/dist/components/spinner'
import {
  chartOptions,
  getEmptyDatesData,
  getForecastPeriodsArr,
  getPeriodDates,
  loadDates,
  PERIODS
} from '../../shared/utils'
import { ROUTE, routes } from '../../../../../../configs/routes'
import { useHistory } from 'react-router-dom'
import { DateUtils } from '@flomni/modules/dist/services/date'

export const WorkforceDashboardChart = ({ period, onMetricsCalculated, showLoader, setShowLoader }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const history = useHistory()

  const [datesCurrent, setDatesCurrent] = useState([])
  const [historyData, setHistoryData] = useState([])
  const [dtPeriodCurrent, setDtPeriodCurrent] = useState(null)

  const currentUserWorkforceSettings = useSelector(({ main }) => main.currentUserWorkforceSettings)

  useEffect(() => {
    setShowLoader(true)
    const [startCurrent, endCurrent] = getPeriodDates(period)
    const forecastPeriodsArr = getForecastPeriodsArr()
    setDtPeriodCurrent([startCurrent, endCurrent])

    Promise.all([loadDates('dialog.chat.conversation,dialogs.agent.status', startCurrent, endCurrent), ...(forecastPeriodsArr.map(([startPrevious, endPrevious]) => loadDates('dialog.chat.conversation,dialogs.agent.status', startPrevious, endPrevious)))])
      .then(([datesCurrent, ...forecastData]) => {
        setDatesCurrent(datesCurrent)
        setHistoryData(forecastData.flat())
      })
      .catch((err) => {
        processError(err, main)
      })
      .finally(() => {
        setShowLoader(false)
      })
  }, [period])

  const getChartData = (dates, dtPeriod) => {
    let data = dates
      .filter((item) => item.eventType === 'dialog.chat.conversation')
      .map((item) => {
        const totalNumberOfSessions = item.metrics.find(({ name }) => name === 'totalNumberOfSessions')
        return {
          dtPeriod: item.dtPeriod,
          count: totalNumberOfSessions?.value ?? 0
        }
      })
    data = data.concat(getEmptyDatesData(data, dtPeriod))

    const datasets = data.map((item) => {
      const dt = new Date(item.dtPeriod)
      return {
        hours: dtPeriod ? moment(dt).diff(dtPeriod[0], 'hours') : 0,
        x: moment(dt).unix(),
        y: item.count,
        tooltip: `${item.count}`,
        dt
      }
    })
    datasets.sort((a, b) => (a.x - b.x > 0 ? 1 : -1))
    return datasets
  }

  const currentDataset = useMemo(() => {
    if (currentUserWorkforceSettings.chatsNumberPerHour) {
      const data = getChartData(datesCurrent, dtPeriodCurrent)
      return data.map((item) => {
        const y = Math.round((item.y / currentUserWorkforceSettings.chatsNumberPerHour) * 100) / 100
        return {
          ...item,
          y,
          tooltip: `${y}`
        }
      })
    }
    return []
  }, [datesCurrent, dtPeriodCurrent, currentUserWorkforceSettings.chatsNumberPerHour])

  const actualDataset = useMemo(() => {
    let data = datesCurrent
      .filter((item) => item.eventType === 'dialogs.agent.status')
      .map((item) => {
        const online = item.metrics.find(({ name }) => name === 'online')
        const onlineDND = item.metrics.find(({ name }) => name === 'onlineDND')
        const count = Math.round((online?.value || 0 + onlineDND?.value || 0) / 36000) / 100
        return {
          dtPeriod: item.dtPeriod,
          count
        }
      })
    data = data.concat(getEmptyDatesData(data, dtPeriodCurrent))

    const datasets = data.map((item) => {
      const dt = new Date(item.dtPeriod)
      return {
        x: moment(dt).unix(),
        y: item.count,
        tooltip: `${item.count}`,
        dt
      }
    })
    datasets.sort((a, b) => (a.x - b.x > 0 ? 1 : -1))
    return datasets
  }, [datesCurrent, dtPeriodCurrent])

  const forecastDataset = useMemo(() => {
    const data = []
    if (period === PERIODS.YESTERDAY) return data

    const sumPreviousData = historyData
      .filter((item) => item.eventType === 'dialog.chat.conversation')
      .reduce((objectsByKeyValue, obj) => {
        const value = moment(obj.dt).hour()
        if (!objectsByKeyValue[value]) objectsByKeyValue[value] = 0
        objectsByKeyValue[value] += obj.count
        return objectsByKeyValue
      }, {})

    for (const [key, value] of Object.entries(sumPreviousData)) {
      const agentSeatsCalculated = value / currentUserWorkforceSettings.chatsNumberPerHour / 4
      const hourPoint = moment().hour(key).startOf('hour')
      data.push({
        x: hourPoint.unix(),
        y: agentSeatsCalculated,
        dt: hourPoint.toDate(),
        tooltip: agentSeatsCalculated
      })
    }

    return data
  }, [historyData, dtPeriodCurrent, currentUserWorkforceSettings.chatsNumberPerHour])

  useEffect(() => {
    const counts = [...actualDataset, ...forecastDataset].map((item) => item.y)
    const avg = counts.length
      ? Math.round((counts.reduce((acc, value) => acc + value, 0) / counts.length) * 100) / 100
      : 0
    const max = counts.length ? Math.max(...counts) : 0

    const hasForecast = forecastDataset.some((item) => item.y)
    const hasFact = actualDataset.some((item) => item.y)
    const hasPlan = currentDataset.some((item) => item.y)

    onMetricsCalculated(Math.ceil(avg), Math.ceil(max), hasForecast || hasFact || hasPlan)
  }, [forecastDataset, actualDataset])

  const onEditCoefficients = () => {
    history.push(routes[ROUTE.WORKFORCE_MANAGEMENT])
  }

  if (showLoader) {
    return (
      <div className={css('spinner-container')}>
        <div className={css('spinner')}>
          <Spinner strokeWidth={5} />
        </div>
      </div>
    )
  }

  const options = {
    ...chartOptions,
    plugins: {
      ...chartOptions.plugins,
      legend: {
        ...chartOptions.plugins.legend,
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true
        }
      },
    },
    scales: {
      ...chartOptions.scales,
      x: {
        ticks: {
          stepSize: 60 * 60 * (period === PERIODS.LAST_7_DAYS ? 24 : 1),
          maxRotation: 90,
          minRotation: 90,
          callback: (label, index, labels) => {
            if (labels.length) {
              return moment
                .unix(label)
                .format(period === PERIODS.LAST_7_DAYS ? DateUtils.DATE_FORMAT : DateUtils.TIME_FORMAT)
            }
            return label
          }
        }
      }
    }
  }

  const datasets = [
    {
      label: t('dlg:plan'),
      data: currentDataset,
      fill: false,
      showLine: true,
      lineTension: 0,
      backgroundColor: 'rgb(100, 190, 132)',
      borderColor: 'rgb(100, 190, 132)'
    },
    {
      label: t('dlg:fact'),
      data: actualDataset,
      fill: false,
      showLine: true,
      lineTension: 0,
      backgroundColor: 'grey',
      borderColor: 'grey'
    }
  ]

  if (period !== PERIODS.YESTERDAY) {
    datasets.push({
      label: t('dlg:forecast'),
      data: forecastDataset,
      fill: false,
      showLine: true,
      lineTension: 0,
      backgroundColor: '#586ADA',
      borderColor: '#586ADA'
    })
  }

  return (
    <div className={css('chart-container')}>
      <div className={css('chart-header-wf')}>
        <PlateButton view='usual' onClick={onEditCoefficients}>
          <PlateButtonIcon>
            <SvgIconExportArrow />
          </PlateButtonIcon>
          <PlateButtonText>{t('dlg:editForecastCoefficients')}</PlateButtonText>
        </PlateButton>
      </div>
      <div>
        <Chart
          type='scatter'
          options={options}
          data={{
            datasets
          }}
        />
      </div>
    </div>
  )
}
