/** @prettier */
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Popup } from '@flomni/components/dist/components/popup/popup'
import { PopupTitle } from '@flomni/components/dist/components/popup/title'
import { PopupContent } from '@flomni/components/dist/components/popup/content'
import { PopupButtons } from '@flomni/components/dist/components/popup/buttons'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { Meteor, useTracker } from '../../../../meteorAdapter'

export const ConnectionLost = () => {
  const css = useStyles(styles)
  const { t } = useTranslation()

  const DELAY_BEFORE_SHOWING = 2 * 1000 // 2 secs

  const [retrySeconds, setRetrySeconds] = useState(0)
  const [isVisible, setIsVisible] = useState(false)
  const [retryInterval, setRetryInterval] = useState(null)
  const [visibleTimeout, setVisibleTimeout] = useState(null)

  const { status } = useTracker(() => Meteor.status(), [])

  const unscheduleTimeouts = () => {
    if (retryInterval !== null) {
      clearInterval(retryInterval)
      setRetryInterval(null)
    }
    if (visibleTimeout !== null) {
      clearTimeout(visibleTimeout)
      setVisibleTimeout(null)
    }
  }

  const startRetryInterval = () => {
    if (retryInterval !== null) {
      clearInterval(retryInterval)
    }

    const interval = setInterval(() => {
      setRetrySeconds((retrySeconds) => (retrySeconds === 0 ? 3 : retrySeconds - 1))
    }, 1000)
    setRetryInterval(interval)
  }

  useEffect(() => {
    if (status !== 'connected') {
      const timeout = setTimeout(() => {
        setIsVisible(true)
      }, DELAY_BEFORE_SHOWING)
      setVisibleTimeout(timeout)
    } else if (visibleTimeout !== null) {
      unscheduleTimeouts()
      setIsVisible(false)
    }

    if (status === 'waiting') {
      startRetryInterval()
    }

    return unscheduleTimeouts
  }, [status, isVisible])

  const simulateReconnect = () => {
    setRetrySeconds(3)
    startRetryInterval()
  }

  if (status === 'connected' || isVisible === false) {
    return null
  }

  return (
    <Popup open variation="secondary">
      <PopupTitle title={t('dlg:systemNotification')} />
      <PopupContent>
        <div className={css('block')}>
          <div className={css('title')}>{t('dlg:serverConnectionWasLost')}</div>
          <div className={css('description')}>{t(`dlg:reconnectStatus.${status}`, { retrySeconds })}</div>
          <svg width="85" height="58" viewBox="0 0 85 58" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.0625 29H28.6213" stroke="#697386" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M56.3789 29H83.9377" stroke="#697386" strokeLinecap="round" strokeLinejoin="round" />
            <path
              d="M49.2913 22.2001L35.709 35.7997"
              stroke="#697386"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M49.2913 35.7997L35.709 22.2001"
              stroke="#697386"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.4248 40.6202C19.6379 45.4138 23.1751 49.4728 27.6186 52.3177C32.0621 55.1625 37.2261 56.6743 42.5002 56.6743C47.7744 56.6743 52.9384 55.1625 57.3819 52.3177C61.8253 49.4728 65.3626 45.4138 67.5757 40.6202"
              stroke="#697386"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.4248 17.3797C19.6379 12.5861 23.1751 8.52711 27.6186 5.68227C32.0621 2.83743 37.2261 1.32568 42.5002 1.32568C47.7744 1.32568 52.9384 2.83743 57.3819 5.68227C61.8253 8.52711 65.3626 12.5861 67.5757 17.3797"
              stroke="#697386"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </PopupContent>
      <PopupButtons>
        <SecondaryButton onClick={simulateReconnect}>
          <SecondaryButtonText>{t('dlg:tryToReconnectNow')}</SecondaryButtonText>
        </SecondaryButton>
      </PopupButtons>
    </Popup>
  )
}
