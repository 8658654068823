import { NavLink } from 'react-router-dom'
import { ROUTE, routes } from '../../../../../../configs/routes'
import React from 'react'
import styles from '../../index.module.scss'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { mainSelectors } from '../../../../../../state/main/selectors'
import useStyles from '@flomni/modules/dist/helpers/useStyles'

const SettingsTeamSecurity = () => {
  const css = useStyles(styles)

  const { t } = useTranslation()

  const isCurrentUserOperator = useSelector(mainSelectors.getIsUserOperator)

  return (
    <div className={css(['items', '--border-right'])}>
      <div className={css('item-header')}>{t('dlg:teamAndSecurity')}</div>
      <div className={css('links')}>
        {!isCurrentUserOperator && (
          <>
            <div>
              <NavLink className={css('link')} to={routes[ROUTE.TEAM]}>
                {t('dlg:agentsTeam')}
              </NavLink>
            </div>
            <div>
              <NavLink className={css('link')} to={routes[ROUTE.GROUP]}>
                {t('dlg:agentGroups')}
              </NavLink>
            </div>
          </>
        )}
        <div className={css(['link', '--disabled'])}>{t('dlg:securityHistory')}</div>
      </div>
    </div>
  )
}

export {
  SettingsTeamSecurity
}
