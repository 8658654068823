import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonIcon } from '@flomni/components/dist/components/secondary-button/icon'
import { SvgIconFilter } from '@flomni/components/dist/components/svg/feathers/SvgIconFilter'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { Dropdown } from '@flomni/components/dist/components/dropdown'
import { SecondaryDropdownItem } from '@flomni/components/dist/components/secondary-dropdown-item/item'
import { SecondaryDropdownItemText } from '@flomni/components/dist/components/secondary-dropdown-item/text'
import { Spinner } from '@flomni/components/dist/components/spinner'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { Meteor, useTracker } from '../../../../../../meteorAdapter';
import { USER_ROLES } from '../../../../../../constants'
import { useDispatch, useSelector } from 'react-redux'
import { processError } from '../../../../../services/helpers'
import { parseRawStats, PERIODS } from '../shared/utils'
import { PersonalDashboardChart } from './chart'
import { UserSelect } from '../../../shared/user-select'
import { PeriodSelect } from '../../../shared/period-select'

const FILTER_TYPE = {
  SINGLE: 'single',
  GROUP: 'group'
}

export const PersonalDashboard = ({ inWorkDialogsCount }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()

  const [showLoaderTeam, setShowLoaderTeam] = useState(true)
  const [statsTeam, setStatsTeam] = useState({})
  const [selectedPeriodTeam, setSelectedPeriodTeam] = useState(PERIODS.TODAY)
  const [showFilter, setShowFilter] = useState(false)
  const [selectedType, setSelectedType] = useState(FILTER_TYPE.SINGLE)
  const [selectedUser, setSelectedUser] = useState(null)

  const currentUserRoles = useSelector(({ main }) => main.currentUserRoles)

  const isOperator = currentUserRoles.includes(USER_ROLES.OPERATOR)
  const currentUserId = Meteor.userId()

  useEffect(() => {
    isOperator && setSelectedUser({ userId: currentUserId })
  }, [isOperator, currentUserId])

  useEffect(() => {
    if (!selectedUser) {
      return
    }

    setShowLoaderTeam(true)
    Meteor.invoke('groups.getGroupActivityStats', {
      userId: selectedUser.userId,
      period: selectedPeriodTeam,
      type: 'agent'
    })
      .then(([rawStats]) => {
        setStatsTeam(parseRawStats(rawStats.result?.eventTypes))
      })
      .catch((err) => {
        processError(err, main)
      })
      .finally(() => {
        setShowLoaderTeam(false)
      })
  }, [selectedUser, selectedPeriodTeam])

  useEffect(() => {
    if (selectedUser) {
      Meteor.subscribe('fetchChatMemberCounters', selectedUser.userId)
    }
  }, [selectedUser])

  const onOverlayFilterClickHandler = useCallback(() => {
    setShowFilter(false)
  }, [])

  const userDialogsCount = useTracker(() => {
    if (!selectedUser) {
      return 0
    }
    const user = Meteor.users.findOne(selectedUser.userId, {
      fields: {
        numHotChats: 1
      }
    })
    return (user?.numHotChats ?? 0) + (currentUserId === selectedUser.userId ? inWorkDialogsCount : 0)
  }, [selectedUser, inWorkDialogsCount])

  return (
    <div className={css(['container', '--border-bottom'])}>
      <div className={css('header')}>
        <div>{t('dlg:teamAnalytics')}</div>
        <div className={css('filters')}>
          {!isOperator && (
            <>
              <div>
                <SecondaryButton view='light' onClick={() => setShowFilter(true)}>
                  <SecondaryButtonIcon>
                    <SvgIconFilter />
                  </SecondaryButtonIcon>
                  <SecondaryButtonText>{t(`dlg:filterStats.${selectedType}`)}</SecondaryButtonText>
                </SecondaryButton>
                <Dropdown
                  active={showFilter}
                  variation='secondary'
                  position='right'
                  onOverlayClick={onOverlayFilterClickHandler}
                >
                  {Object.values(FILTER_TYPE).map((filter) => (
                    <SecondaryDropdownItem
                      key={filter}
                      disabled={filter === FILTER_TYPE.GROUP}
                      active={selectedType === filter}
                      onClick={() => {
                        setSelectedType(filter)
                        setShowFilter(false)
                      }}
                    >
                      <SecondaryDropdownItemText>{t(`dlg:filterStats.${filter}`)}</SecondaryDropdownItemText>
                    </SecondaryDropdownItem>
                  ))}
                </Dropdown>
              </div>
              <div>{t('dlg:byDashboardFilter')}</div>
              <UserSelect
                setShowLoader={setShowLoaderTeam}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
              />
              <div>{t('dlg:forDashboardFilter')}</div>
            </>
          )}
          <PeriodSelect selectedPeriod={selectedPeriodTeam} setSelectedPeriod={setSelectedPeriodTeam} />
        </div>
      </div>
      <div className={css('content')}>
        <PersonalDashboardChart userId={selectedUser?.userId} period={selectedPeriodTeam} />
        {showLoaderTeam && (
          <div className={css('spinner-container')}>
            <div className={css('spinner')}>
              <Spinner strokeWidth={5} />
            </div>
          </div>
        )}
        {!showLoaderTeam && (
          <div>
            <div className={css('line')}>
              <div className={css(['item', '--border-right', '--link'])}>
                <div className={css('title')}>{t('dlg:onlineChats')}</div>
                <div className={css('value')}>{userDialogsCount}</div>
              </div>
              <div className={css('item')}>
                <div className={css('title')}>{t('dlg:processedChats')}</div>
                <div className={css('value')}>{statsTeam.totalNumberOfSessions || '-'}</div>
              </div>
            </div>
            <div className={css(['line', '--border-top'])}>
              <div className={css(['item', '--border-right'])}>
                <div className={css('title')}>{t('dlg:readyHours')}</div>
                <div className={css('value')}>
                  {statsTeam.onlineMs == null ? '-' : moment.utc(statsTeam.onlineMs).format('HH:mm')}
                </div>
                <div className={css('description')}>{t('dlg:readyHoursDescription')}</div>
              </div>
              <div className={css('item')}>
                <div className={css('title')}>{t('dlg:workedHours')}</div>
                <div className={css('value')}>
                  {statsTeam.totalMs == null ? '-' : moment.utc(statsTeam.totalMs).format('HH:mm')}
                </div>
                <div className={css('description')}>{t('dlg:workedHoursDescription')}</div>
              </div>
            </div>
            <div className={css(['line', '--border-top'])}>
              <div className={css(['item', '--border-right'])}>
                <div className={css('title')}>{t('dlg:processingEfficiency')}</div>
                <div className={css('value')}>
                  <span>
                    {statsTeam.avgChatsPerHour ? Math.round(statsTeam.avgChatsPerHour * 100) / 100 : '-'}
                  </span>
                  {!!statsTeam.avgChatsPerHour && <span className={css('value-m')}>{t('dlg:perHour')}</span>}
                </div>
                <div className={css('description')}>{t('dlg:averageProcessedChatsPerHour')}</div>
              </div>
              <div className={css('item')}>
                <div className={css('title')}>{t('dlg:rate')}</div>
                <div className={css('value')}>
                  {statsTeam.avgRate ? Math.round(statsTeam.avgRate * 100) / 100 : '-'}
                </div>
                <div className={css('description')}>{t('dlg:averageRateFromCustomers')}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
