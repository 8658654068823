import React, { useEffect, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonText } from '@flomni/components/dist/components/plate-button/text'
import { useTranslation } from 'react-i18next'
import { Tag } from '@flomni/components/dist/components/tag'
import { SvgIconSearch } from '@flomni/components/dist/components/svg/feathers/SvgIconSearch'
import { array, func, object } from 'prop-types'
import { Select } from '@flomni/components/dist/components/select'
import { Meteor } from '../../../../../../../../../../meteorAdapter';
import { isMobileMode, processError } from '../../../../../../../../../services/helpers'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'

export const SuggestionsList = ({ mbrain, onTemplateSelected, templates, onClose }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const [contexts, setContexts] = useState([])
  const [megabrain, setMegabrain] = useState(null)
  const selectedContextId = useSelector(({ main }) => main.selectedContextId)

  useEffect(() => {
    setMegabrain(mbrain)
    if (mbrain?.topics[0]?.topicId) {
      Meteor.invoke('mbrain.TextLabeling.contexts.get', {}).then((res) => {
        setContexts(res?.status === 'ok' && res?.msg ? res.msg : [])
      })
    }
  }, [mbrain])

  const onSelectContext = (id) => {
    if (id === selectedContextId) {
      return
    }

    main.setSelectedContextId(id)

    Meteor.invoke('mbrain.TextClassifier.predictions.post', {
      text: megabrain.text,
      contextId: id
    })
      .then((res) => {
        if (res && res.status !== 'error' && res.labels?.length) {
          const mbrain = {
            topics: res.labels
              .sort((a, b) => b.probability - a.probability)
              .map(({ id, text, probability }) => {
                return {
                  topicId: id,
                  topicName: text,
                  matchedProbability: probability
                }
              }),
            entities: [],
            emotions: [],
            text: megabrain.text
          }
          setMegabrain(mbrain)
        }
      })
      .catch((err) => {
        processError(err, main)
      })
  }

  const filteredTemplates = templates.filter((template) => {
    const topicId = mbrain.selectTopic ? mbrain.selectTopic.topicId : megabrain?.topics[0].topicId
    if (Array.isArray(template.suggestionsTopic)) {
      return template.suggestionsTopic.includes(topicId)
    } else {
      return template.suggestionsTopic === topicId
    }
  })

  const contextOptions = contexts.map((context) => {
    return {
      label: context.name,
      value: context.id
    }
  })
  const selectedContextOption = contextOptions.find((option) => option.value === selectedContextId) || null

  return (
    <div className={css(classnames('container', isMobileMode ? '--mobile' : null))}>
      <div className={css('header')}>
        <div>
          <div className={css('title')}>{t('dlg:templateSuggestions')}</div>
          <div className={css('description')}>
            {t('dlg:forTopicName', { name: mbrain.selectTopic ? mbrain.selectTopic.topicName : (megabrain?.topics[0]?.topicName ?? '-') })}
          </div>
        </div>
        <div className={css('header-right')}>
          <Select
            variation='secondary'
            placeholder={t('dlg:selectContext')}
            options={contextOptions}
            value={selectedContextOption}
            onChange={(option) => {
              onSelectContext(option.value)
            }}
          />
          <PlateButton view='usual' onClick={onClose}>
            <PlateButtonText>{t('dlg:close')}</PlateButtonText>
          </PlateButton>
        </div>
      </div>
      {filteredTemplates.length > 0 && (
        <div className={css('list')}>
          {filteredTemplates.map((item) => (
            <div key={item.id} className={css('item')} onClick={() => onTemplateSelected(item)}>
              <div className={css('item-command')}>/{item.title || item.name}</div>
              <div>
                <div className={css('item-text')}>{item.text}</div>
                {item.attachments && (
                  <div className={css('item-buttons')}>
                    {item.attachments.map((attachment) => {
                      return attachment.type === 'button' ? (
                        <Tag
                          key={attachment.id}
                          variation='secondary'
                          view='stroked'
                          color='gray'
                          classes={{ root: css('item-button') }}
                        >
                          {attachment.name}
                        </Tag>
                      ) : null
                    })}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      {filteredTemplates.length === 0 && (
        <div className={css('no-results')}>
          <SvgIconSearch />
          <div className={css('no-results-text')}>{t('dlg:noSuggestions')}...</div>
        </div>
      )}
    </div>
  )
}

SuggestionsList.propTypes = {
  mbrain: object,
  templates: array,
  onTemplateSelected: func.isRequired,
  onClose: func.isRequired
}
