import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { useInsertAttachment } from '../../../insert-attachment-hook'
import { func } from 'prop-types'
import MobileLocationDialog from './location-dialog'
import MobileContactDialog from './contact-dialog'

export const MobileAttachmentsMenu = ({ onAdd }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const {
    inputEl,
    showAddLocation,
    setShowAddLocation,
    showAddContact,
    setShowAddContact,
    onInsertContact,
    onInsertLocation,
    onFileChange,
    handleInsertImageOrVideo,
    handleInsertLocation,
    handleInsertFile,
    handleInsertContact
  } = useInsertAttachment(onAdd)

  return (
    <div className={css('items')}>
      <input ref={inputEl} type='file' multiple className={css('input')} onChange={onFileChange} />
      <div className={css('item')} onClick={handleInsertImageOrVideo}>
        {t('dlg:imageOrVideo')}
      </div>
      <div className={css('item')} onClick={handleInsertLocation}>
        {t('dlg:location')}
      </div>
      <div className={css('item')} onClick={handleInsertFile}>
        {t('dlg:file')}
      </div>
      <div className={css('item')} onClick={handleInsertContact}>
        {t('dlg:contact')}
      </div>
      {showAddLocation && (
        <MobileLocationDialog
          onAdded={onInsertLocation}
          onCancel={() => {
            setShowAddLocation(false)
          }}
        />
      )}
      {showAddContact && (
        <MobileContactDialog
          onAdded={onInsertContact}
          onCancel={() => {
            setShowAddContact(false)
          }}
        />
      )}
    </div>
  )
}

MobileAttachmentsMenu.propTypes = {
  onAdd: func.isRequired
}
