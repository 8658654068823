import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { Trans, useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { func, object } from 'prop-types'

const RemoveButtonForm = ({ button, onRemove, onCancel }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()

  const onHandlerRemove = () => {
    onRemove()
  }

  const handlerClickOnCancel = () => {
    onCancel()
  }

  return (
    <div>
      <div className={css('group')}>
        <div className={css('title')}>{t('dlg:removeButton')}</div>
        <div className={css('name')}>
          <Trans
            i18nKey='dlg:buttonWillBeRemovedFromTemplate'
            values={{ name: button.name.toUpperCase() }}
            components={[0, <span className={css('text-bold')}>1</span>, 2]}
          />
        </div>
      </div>
      <div className={css(['group', '--buttons'])}>
        <SecondaryButton view='light' onClick={handlerClickOnCancel}>
          <SecondaryButtonText>{t('dlg:cancel')}</SecondaryButtonText>
        </SecondaryButton>
        <SecondaryButton view='warning' onClick={onHandlerRemove}>
          <SecondaryButtonText>{t('dlg:remove')}</SecondaryButtonText>
        </SecondaryButton>
      </div>
    </div>
  )
}

RemoveButtonForm.propTypes = {
  button: object.isRequired,
  onRemove: func.isRequired,
  onCancel: func.isRequired
}

export default RemoveButtonForm
