import React, { useEffect, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { ROUTE, routes } from '../../../../../configs/routes'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { SecondaryButtonIcon } from '@flomni/components/dist/components/secondary-button/icon'
import { Input } from '@flomni/components/dist/components/input'
import { SvgIconPlus } from '@flomni/components/dist/components/svg/feathers/SvgIconPlus'
import { SvgIconSearch } from '@flomni/components/dist/components/svg/feathers/SvgIconSearch'
import { Meteor, useTracker } from '../../../../../../meteorAdapter';
import { FiltersPopup } from '../../../shared/filters-popup'
import { SortPopup } from '../../../shared/sort-popup'
import { Spinner } from '@flomni/components/dist/components/spinner'
import { SvgIconButtonsFilled } from '@flomni/components/dist/components/svg/feathers/SvgIconButtonsFilled'
import DeleteButton from './delete-button'
import { SvgIconEdit } from '@flomni/components/dist/components/svg/feathers/SvgIconEdit'
import { SvgIconDelete } from '@flomni/components/dist/components/svg/feathers/SvgIconDelete'
import { BUTTON_KIND } from '../../../../../services/constants'
import ButtonForm from './button-form'
import { useDispatch, useSelector } from 'react-redux'
import { processError } from '../../../../../services/helpers'

export const MessageButtons = () => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const currentUserGroupId = useSelector(({ main }) => main.currentUserGroupId)
  const [filteredButtons, setFilteredButtons] = useState([])
  const [search, setSearch] = useState('')
  const [filterGroups, setFilterGroups] = useState(null)
  const [sortFields, setSortFields] = useState(null)
  const [filterItems, setFilterItems] = useState([])
  const [actions, setActions] = useState([])
  const [showAdd, setShowAdd] = useState(false)
  const [editButton, setEditButton] = useState(null)
  const [deleteButton, setDeleteButton] = useState(null)
  const [sortItems] = useState({
    checkedId: null,
    items: []
  })
  const [loadingActions, setLoadingActions] = useState(true)

  const [buttons, loadingButtons] = useTracker(() => {
    const currentUserId = Meteor.userId()
    const user = Meteor.users.findOne(currentUserId, {
      fields: {
        buttons: 1
      }
    })
    return [user?.buttons || [], !user]
  }, [])

  useEffect(() => {
    if (currentUserGroupId) {
      Meteor.invoke('groups.getGroupBotActions', {
        groupId: currentUserGroupId
      })
        .then((actions) => {
          setActions(actions.items)
        })
        .catch((err) => {
          processError(err, main)
        })
        .finally(() => {
          setLoadingActions(false)
        })
    }
  }, [currentUserGroupId])

  useEffect(() => {
    setSortFields(sortItems)
    setFilterItems([])
  }, [])

  useEffect(() => {
    applyFilters(buttons, filterGroups, sortFields)
  }, [buttons])

  useEffect(() => {
    applyFilters(buttons, filterGroups, sortFields)
  }, [search])

  const applyFilters = (list, filterGroupsIds, sortItems) => {
    let filteredList = [...list]
    if (search) {
      const lowerSearch = search.toLowerCase()
      filteredList = filteredList.filter(
        (item) =>
          item.name.toLowerCase().indexOf(lowerSearch) !== -1 ||
          (item.description || '').toLowerCase().indexOf(lowerSearch) !== -1
      )
    }
    setFilteredButtons(filteredList)
  }

  const onSetFilter = () => {
    setFilterGroups([])
    applyFilters(buttons, [], sortFields)
  }

  const onSetSort = (items) => {
    setSortFields(items)
    applyFilters(buttons, filterGroups, items)
  }

  const showLoader = loadingButtons || loadingActions

  return (
    <div className={css('main')}>
      <div className={css('container')}>
        <div className={css('nav-bar')}>
          <NavLink className={css('link')} to={routes[ROUTE.SETTINGS]}>
            {t('dlg:dialogsSettings')}
          </NavLink>
          <div>></div>
          <div className={css('active')}>{t('dlg:messageButtons')}</div>
        </div>
        <div className={css('header')}>
          <div className={css('title')}>{t('dlg:buttons')}</div>
          {!showLoader && buttons.length > 0 && (
            <SecondaryButton onClick={() => setShowAdd(true)} classes={{ inner: css('btn-icon-with-text') }}>
              <SecondaryButtonIcon>
                <SvgIconPlus />
              </SecondaryButtonIcon>
              <SecondaryButtonText>{t('dlg:newButton')}</SecondaryButtonText>
            </SecondaryButton>
          )}
        </div>
        {showLoader && (
          <div className={css('spinner')}>
            <Spinner strokeWidth={5} />
          </div>
        )}
        {!showLoader && buttons.length === 0 && (
          <div className={css('no-items')}>
            <div className={css('no-items-icon')}>
              <SvgIconButtonsFilled width={36} height={36} />
            </div>
            <div className={css('no-items-title')}>{t('dlg:youDontHaveButtons')}</div>
            <div className={css('no-items-description')}>{t('dlg:createFirstButtonSeeingHere')}</div>
            <SecondaryButton classes={{ root: css('no-items-button') }} onClick={() => setShowAdd(true)}>
              <SecondaryButtonIcon>
                <SvgIconPlus />
              </SecondaryButtonIcon>
              <SecondaryButtonText>{t('dlg:newButton')}</SecondaryButtonText>
            </SecondaryButton>
          </div>
        )}
        {!showLoader && buttons.length > 0 && (
          <div className={css('info')}>
            <div className={css('toolbox')}>
              <Input
                icons={[<SvgIconSearch />]}
                variation='secondary'
                shaded
                placeholder={t('dlg:searchByNameOrDescription')}
                onChange={(e) => setSearch(e.target.value)}
              />
              <div style={{ display: 'none' }}>
                <SortPopup disabled config={sortItems} onDone={onSetSort} onReset={onSetSort} />
                <FiltersPopup disabled items={filterItems} onDone={onSetFilter} onReset={onSetFilter} />
              </div>
            </div>
            <div className={css('table-header')}>
              <div>{t('dlg:buttonName')}</div>
              <div>{t('dlg:buttonType')}</div>
              <div>{t('dlg:description')}</div>
            </div>
            <div>
              {filteredButtons.map((item) => (
                <div key={item.id} className={css('item')}>
                  <div className={css('item-name')}>{item.name}</div>
                  <div className={css('item-name')}>
                    {item.kind === BUTTON_KIND.CUSTOM ? t('dlg:chatbotTransition') : t('dlg:url')}
                  </div>
                  <div className={css('item-name')}>
                    {item.description ?? (
                      <span className={css('no-description')}>{t('dlg:hereIsNoDescription')}</span>
                    )}
                  </div>
                  <div className={css('item-buttons')}>
                    <SecondaryButton view='light' onClick={() => setEditButton(item)}>
                      <SecondaryButtonIcon>
                        <SvgIconEdit />
                      </SecondaryButtonIcon>
                      <SecondaryButtonText>{t('dlg:edit')}</SecondaryButtonText>
                    </SecondaryButton>
                    <SecondaryButton view='light' onClick={() => setDeleteButton(item)}>
                      <SecondaryButtonIcon>
                        <SvgIconDelete />
                      </SecondaryButtonIcon>
                      <SecondaryButtonText>{t('dlg:remove')}</SecondaryButtonText>
                    </SecondaryButton>
                  </div>
                </div>
              ))}
            </div>
            <div className={css('table-footer')}>
              <div className={css('count')}>{t('dlg:countButtons', { count: filteredButtons.length })}</div>
            </div>
          </div>
        )}
      </div>
      {deleteButton && (
        <DeleteButton
          onCancel={() => setDeleteButton(null)}
          onDeleted={() => {
            setDeleteButton(null)
          }}
          button={deleteButton}
        />
      )}
      {editButton && (
        <ButtonForm
          onCancel={() => setEditButton(null)}
          onSaved={() => {
            setEditButton(null)
          }}
          actions={actions}
          button={editButton}
        />
      )}
      {showAdd && (
        <ButtonForm
          actions={actions}
          onCancel={() => setShowAdd(false)}
          onSaved={() => {
            setShowAdd(false)
          }}
        />
      )}
    </div>
  )
}
