import React, { useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { Avatar } from '../../shared/avatar'
import classnames from 'classnames'
import { SvgIconMenuBurger } from '@flomni/components/dist/components/svg/feathers/SvgIconMenuBurger'
import { useTranslation } from 'react-i18next'
import { bool, func, object, string } from 'prop-types'
import { StatusesPage } from './statuses-page'

export const HeaderMobile = ({ profile, status, doNotDisturb, getProfileName, agentStatus }) => {
  const { t } = useTranslation()
  const css = useStyles(styles)
  const [showMenu, setShowMenu] = useState(false)

  return (
    <div className={css('header')}>
      <div className={css('menu')}>
        <div className={css('menu-icon')} style={{ display: 'none' }}>
          <SvgIconMenuBurger />
        </div>
      </div>
      <div className={css('page-name')}>{t('dlg:myChats')}</div>
      <div className={css('user-block')} onClick={() => setShowMenu(true)}>
        {profile?.avatarUrl ? (
          <Avatar url={profile?.avatarUrl} />
        ) : (
          <div className={css('user-logo')}>{getProfileName()}</div>
        )}
        <div className={css(classnames('user-status', status, doNotDisturb ? '--not-disturb' : null))} />
      </div>
      {showMenu && (
        <StatusesPage profile={profile} agentStatus={agentStatus} onClose={() => setShowMenu(false)} />
      )}
    </div>
  )
}

HeaderMobile.propTypes = {
  profile: object,
  status: string,
  doNotDisturb: bool,
  getProfileName: func.isRequired,
  agentStatus: string
}
