import React, { useContext, useEffect, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { Header } from './header'
import { Messages } from './messages'
import { ChatInput } from './chat-input'
import { store } from '../../../../../../state/dialogs'
import { Meteor, useTracker } from '../../../../../../../meteorAdapter';
import { useDispatch, useSelector } from 'react-redux'
import { isMobileMode, processError } from '../../../../../../services/helpers'
import { array, func, object, string } from 'prop-types'
import Dialogs from '../../../../../../../collections/client/dialogs'
import { BanInfo } from './ban-info'
import classnames from 'classnames'

export const CenterPanel = ({
  onChanged,
  onAssignedCurrentUser,
  banResult,
  updateBanResult,
  clientId,
  crmFields,
  recipient
}) => {
  const css = useStyles(styles)
  const { main } = useDispatch()
  const currentUserGroupId = useSelector(({ main }) => main.currentUserGroupId)
  const { selectedDialogId } = useContext(store)
  const [templates, setTemplates] = useState([])
  const [actions, setActions] = useState([])
  const [mbrain, setMbrain] = useState(null)
  const [isShow, setIsShow] = useState(false)

  useEffect(() => {
    updateTemplates()
  }, [])

  useEffect(() => {
    if (!currentUserGroupId) {
      return
    }
    Meteor.invoke('groups.getGroupBotActions', {
      groupId: currentUserGroupId
    })
      .then((actions) => {
        setActions(actions.items)
      })
      .catch((err) => {
        processError(err, main)
      })
  }, [currentUserGroupId])

  const {
    subjects,
    stuffUsers,
    receiver,
    state,
    stuffInfo,
    stuffCounts,
    preparedAt,
    lastMessageAt,
    lastInboundMessageAt,
    chatbotEnabled,
    drafts,
    createdAt,
    chatCaption,
    language,
    clientProfile,
    lastDeliveredAt,
    lastSeenAt,
    passiveMembers,
    allowSuggestions
  } = useTracker(() => {
    return (
      Dialogs.findOne(selectedDialogId, {
        fields: {
          subjects: 1,
          stuffUsers: 1,
          receiver: 1,
          state: 1,
          stuffInfo: 1,
          stuffCounts: 1,
          preparedAt: 1,
          lastMessageAt: 1,
          lastInboundMessageAt: 1,
          chatbotEnabled: 1,
          drafts: 1,
          createdAt: 1,
          chatCaption: 1,
          language: 1,
          clientProfile: 1,
          lastDeliveredAt: 1,
          lastSeenAt: 1,
          passiveMembers: 1,
          allowSuggestions: 1
        }
      }) ?? {}
    )
  }, [selectedDialogId])

  const user = useTracker(() => Meteor.user(), [])
  const allowSuggestionsForProfile = user?.profile?.allowSuggestions === true
  const { unreadMessagesCount = 1, unreadRemindersCount = 0 } = (stuffCounts && stuffCounts[user?._id]) || {}
  const unreadMessages = unreadRemindersCount + unreadMessagesCount

  const updateTemplates = () => {
    Meteor.invoke('templates.getTemplates', {
      groupId: currentUserGroupId,
      type: ['group', 'user']
    })
      .then((templates) => {
        setTemplates(templates)
      })
      .catch((err) => {
        processError(err, main)
      })
  }

  const handleMarkVisited = () => {
    if (unreadMessages < 1) return

    Meteor.invoke('markChatVisited', selectedDialogId).catch((err) => {
      processError(err, main)
    })
  }

  useEffect(() => {
    setIsShow(true)
  }, [])

  return (
    <div
      className={css(classnames('container', isMobileMode ? '--mobile' : null, isShow || isMobileMode ? '--show' : null))}
      onMouseEnter={handleMarkVisited}
    >
      {!isMobileMode && (
        <Header
          subjects={subjects}
          stuffUsers={stuffUsers || []}
          receiver={receiver}
          state={state}
          stuffInfo={stuffInfo || {}}
          preparedAt={preparedAt}
          lastMessageAt={lastMessageAt}
          createdAt={createdAt}
          chatCaption={chatCaption}
          clientProfile={clientProfile || {}}
          onChanged={onChanged}
          allowSuggestionsForProfile={allowSuggestionsForProfile}
          allowSuggestions={allowSuggestions ?? true}
          crmFields={crmFields}
          language={language}
        />
      )}
      <Messages
        updateTemplates={updateTemplates}
        actions={actions}
        chatCaption={chatCaption}
        clientProfile={clientProfile || {}}
        receiver={receiver}
        lastDeliveredAt={lastDeliveredAt}
        lastSeenAt={lastSeenAt}
        lastInboundMessageAt={lastInboundMessageAt || null}
        chatbotEnabled={chatbotEnabled}
        stuffUsers={stuffUsers || []}
        onUpdateMbrain={setMbrain}
        allowSuggestions={(allowSuggestions ?? true) && allowSuggestionsForProfile}
        language={language}
      />
      <BanInfo
        banResult={banResult}
        updateBanResult={updateBanResult}
        groupId={currentUserGroupId}
        receiver={receiver}
      />
      <ChatInput
        state={state}
        templates={templates}
        updateTemplates={updateTemplates}
        actions={actions}
        chatCaption={chatCaption}
        clientProfile={clientProfile || {}}
        receiver={receiver}
        onAssignedCurrentUser={onAssignedCurrentUser}
        stuffUsers={stuffUsers || []}
        passiveMembers={passiveMembers || []}
        drafts={drafts || {}}
        mbrain={mbrain}
        chatbotEnabled={chatbotEnabled}
        allowSuggestions={(allowSuggestions ?? true) && allowSuggestionsForProfile}
        recipient={recipient}
        language={language}
      />
    </div>
  )
}

CenterPanel.propTypes = {
  onChanged: func.isRequired,
  onAssignedCurrentUser: func.isRequired,
  banResult: object,
  updateBanResult: func.isRequired,
  clientId: string,
  crmFields: array
}
