import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { SvgIconButtonsStroke } from '@flomni/components/dist/components/svg/feathers/SvgIconButtonsStroke'
import { SvgIconLightiningStroke } from '@flomni/components/dist/components/svg/feathers/SvgIconLightiningStroke'
import { SvgIconSmile } from '@flomni/components/dist/components/svg/feathers/SvgIconSmile'
import { SvgIconBookmark } from '@flomni/components/dist/components/svg/feathers/SvgIconBookmark'
import { SvgIconClose } from '@flomni/components/dist/components/svg/feathers/SvgIconClose'
import { SvgIconLanguage } from '@flomni/components/dist/components/svg/feathers/SvgIconLanguage'
import { Tooltip } from '@flomni/components/dist/components/tooltip'
import { ButtonWithLoader } from '../../../../../../../shared/button-with-loader'
import { InsertAttachment } from './insert-attachment'
import { AttachmentsList } from './attachments-list'
import { Picker } from 'emoji-mart'
import { TemplatesList } from './templates-list'
import { ActionsList } from './actions-list'
import { ButtonsList } from './buttons-list'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { array, bool, func, object, string } from 'prop-types'
import { SuggestionsList } from './suggestions-list'
import { useSend } from '../send-hook'
import TextareaAutosize from 'react-autosize-textarea'

export const Reply = ({
  mbrain,
  actions,
  templates,
  updateTemplates,
  passiveMembers,
  drafts,
  chatCaption,
  clientProfile,
  receiver,
  allowSuggestions,
  language
}) => {
  const css = useStyles(styles)

  const {
    message,
    showSendLoader,
    attachments,
    showEmojiPicker,
    showTemplates,
    showSuggestions,
    showActions,
    setShowSuggestions,
    showButtons,
    buttons,
    setShowActions,
    setShowEmojiPicker,
    editingMessage,
    getTemplates,
    onTemplateSelected,
    onButtonSelected,
    handlerOnAddAttachments,
    closeButtons,
    openButtons,
    openActions,
    closeTemplates,
    openTemplates,
    onUpdate,
    onSend,
    handlePaste,
    onChangeText,
    handlerOnDeleteAttachment,
    handlerOnChangeFileAttachment,
    handlerOnChangeContactAttachment,
    handlerOnChangeLocationAttachment,
    handlerOnChangeAction,
    handlerOnChangeButton,
    onSelectEmoji,
    onActionSelected,
    getButtons,
    handleEnter,
    handleTemplates,
    openEmojiPicker,
    onCancelEditing,
    onShowTranslate,
    onShowOriginal,
    originalText,
    onSendTranslate,
    showTranslate,
    toggleTranslate,
    translationSettings
  } = useSend(mbrain, actions, templates, updateTemplates, passiveMembers, chatCaption, clientProfile, receiver, drafts, language)
  const { t } = useTranslation()

  const maxRows = Math.floor(window.innerHeight / 40)
  const translate = language && translationSettings?.defaultLanguage !== language

  return (
    <div>
      <AttachmentsList
        items={attachments}
        onDelete={handlerOnDeleteAttachment}
        onChangeFile={handlerOnChangeFileAttachment}
        onChangeContact={handlerOnChangeContactAttachment}
        onChangeLocation={handlerOnChangeLocationAttachment}
        onChangeAction={handlerOnChangeAction}
        onChangeButton={handlerOnChangeButton}
      />
      {showEmojiPicker && (
        <div className={css('emoji-picker')}>
          <Picker
            color='#586ADA'
            showPreview={false}
            showSkinTones={false}
            perLine={4}
            set='apple'
            emoji=''
            title=''
            onSelect={onSelectEmoji}
            style={{ width: 'auto', border: 'none' }}
          />
        </div>
      )}
      {showTemplates && (
        <TemplatesList
          actions={actions}
          templates={getTemplates()}
          allTemplates={templates}
          onTemplateSelected={onTemplateSelected}
          onAddedTemplate={updateTemplates}
        />
      )}
      {showSuggestions && allowSuggestions && (
        <SuggestionsList
          mbrain={mbrain}
          templates={templates}
          onTemplateSelected={onTemplateSelected}
          onClose={() => setShowSuggestions(false)}
        />
      )}
      {showActions && <ActionsList actions={actions} onActionSelected={onActionSelected} />}
      {showButtons && (
        <ButtonsList
          buttons={getButtons()}
          actions={actions}
          allButtons={buttons}
          onButtonSelected={onButtonSelected}
        />
      )}
      <TextareaAutosize
        autoFocus
        className={css('input')}
        rows={2}
        maxRows={maxRows}
        placeholder={t('dlg:typeAddSavedReplyOrMessage')}
        onChange={onChangeText}
        onKeyPress={handleEnter}
        onKeyUp={handleTemplates}
        onPaste={handlePaste}
        value={message}
        spellCheck
      />
      <div className={css('toolbox')}>
        <div className={css('buttons')}>
          <InsertAttachment onAdd={handlerOnAddAttachments} />
          <div>
            <Tooltip variation='secondary' content={t('dlg:addButton')} dropdownProps={{ direction: 'up' }}>
              <div>
                <PlateButton
                  view={showButtons ? 'bright' : 'usual'}
                  onClick={() => (showButtons ? closeButtons() : openButtons())}
                >
                  <PlateButtonIcon>
                    {!showButtons && <SvgIconButtonsStroke />}
                    {showButtons && <SvgIconClose />}
                  </PlateButtonIcon>
                </PlateButton>
              </div>
            </Tooltip>
          </div>
          <div>
            <Tooltip
              variation='secondary'
              content={t('dlg:chooseChatbotAction')}
              dropdownProps={{ direction: 'up' }}
            >
              <div>
                <PlateButton
                  view={showActions ? 'bright' : 'usual'}
                  onClick={() => (showActions ? setShowActions(false) : openActions())}
                >
                  <PlateButtonIcon>
                    {!showActions && <SvgIconLightiningStroke />}
                    {showActions && <SvgIconClose />}
                  </PlateButtonIcon>
                </PlateButton>
              </div>
            </Tooltip>
          </div>
          <div>
            <Tooltip variation='secondary' content={t('dlg:insertEmoji')} dropdownProps={{ direction: 'up' }}>
              <div>
                <PlateButton
                  view={showEmojiPicker ? 'bright' : 'usual'}
                  onClick={() => (showEmojiPicker ? setShowEmojiPicker(false) : openEmojiPicker())}
                >
                  <PlateButtonIcon>
                    {!showEmojiPicker && <SvgIconSmile />}
                    {showEmojiPicker && <SvgIconClose />}
                  </PlateButtonIcon>
                </PlateButton>
              </div>
            </Tooltip>
          </div>
          <div>
            <Tooltip variation='secondary' content={t('dlg:useTemplate')} dropdownProps={{ direction: 'up' }}>
              <div>
                <PlateButton
                  view={showTemplates ? 'bright' : 'usual'}
                  onClick={() => (showTemplates ? closeTemplates() : openTemplates())}
                >
                  <PlateButtonIcon>
                    {!showTemplates && <SvgIconBookmark />}
                    {showTemplates && <SvgIconClose />}
                  </PlateButtonIcon>
                </PlateButton>
              </div>
            </Tooltip>
          </div>
          {translate && <div>
            <Tooltip variation='secondary' content={t('dlg:translation')} dropdownProps={{ direction: 'up' }}>
              <div>
                <PlateButton
                  view={showTranslate ? 'bright' : 'usual'}
                  onClick={() => toggleTranslate()}
                >
                  <PlateButtonIcon>
                    <SvgIconLanguage />
                  </PlateButtonIcon>
                </PlateButton>
              </div>
            </Tooltip>
          </div>}
        </div>
        {editingMessage && (
          <div className={css('editing-buttons')}>
            <SecondaryButton view='light' onClick={onCancelEditing}>
              <SecondaryButtonText>{t('dlg:cancel')}</SecondaryButtonText>
            </SecondaryButton>
            <ButtonWithLoader
              text={t('dlg:update')}
              onClick={onUpdate}
              disabled={message.length === 0}
              spinnerSize={10}
              spinnerStrokeWidth={14}
              isLoading={showSendLoader}
            />
          </div>
        )}
        <div className={css('translate-buttons')}>
          {!editingMessage && !!originalText && showTranslate && (
            <ButtonWithLoader
              text={t('dlg:showOriginal')}
              onClick={onShowOriginal}
              view='light'
            />
          )}
          {!editingMessage && (!translate || !!originalText || !showTranslate) && (
            <ButtonWithLoader
              text={t('dlg:send')}
              onClick={onSend}
              disabled={message.length === 0}
              spinnerSize={10}
              spinnerStrokeWidth={14}
              isLoading={showSendLoader}
            />
          )}
        </div>
        {!editingMessage && translate && !originalText && showTranslate && (
          <div className={css('translate-buttons')}>
            <ButtonWithLoader
              view='light'
              text={t('dlg:showTranslation')}
              onClick={onShowTranslate}
              disabled={message.length === 0}
              spinnerSize={10}
              spinnerStrokeWidth={14}
              isLoading={showSendLoader}
            />
            <ButtonWithLoader
              text={t('dlg:translateSend')}
              onClick={onSendTranslate}
              disabled={message.length === 0}
              spinnerSize={10}
              spinnerStrokeWidth={14}
              isLoading={showSendLoader}
            />
          </div>
        )}
      </div>
    </div>
  )
}

Reply.propTypes = {
  actions: array,
  templates: array,
  passiveMembers: array,
  drafts: object,
  updateTemplates: func.isRequired,
  chatCaption: string,
  clientProfile: object,
  receiver: string,
  mbrain: object,
  allowSuggestions: bool
}
