import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { Input } from '@flomni/components/dist/components/input'
import { ControlContainer } from '@flomni/components/dist/components/control-container'
import { Popup } from '@flomni/components/dist/components/popup/popup'
import { PopupButtons } from '@flomni/components/dist/components/popup/buttons'
import { PopupContent } from '@flomni/components/dist/components/popup/content'
import { PopupTitle } from '@flomni/components/dist/components/popup/title'
import { Tip } from '@flomni/components/dist/components/tip'

import { func } from 'prop-types'
import { useForm } from 'react-hook-form'
import { Meteor } from '../../../../../../../meteorAdapter';
import { useDispatch } from 'react-redux'
import { processError } from '../../../../../../services/helpers'

const ChangePassword = ({ onAdded, onCancel }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()

  const { register, formState, errors, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: ''
    }
  })

  const handlerClickChangePassword = async (event) => {
    event.preventDefault()
    main.showGlobalLoader(true)
    const values = getValues()
    Meteor.invoke('users.updateUserPassword', { password: values.newPassword })
      .then(() => {
        main.showGlobalLoader(false)
        onAdded()
      })
      .catch((err) => {
        main.showGlobalLoader(false)
        processError(err, main)
      })
  }

  const handlerClickOnCancel = () => {
    onCancel()
  }

  return (
    <Popup open variation='secondary'>
      <PopupTitle title={t('dlg:passwordChanging')} />
      <form>
        <PopupContent classes={{ root: css('content') }}>
          <div className={css('line')}>
            <div className={css('title')}>{t('dlg:oldPassword')}</div>
            <ControlContainer
              variation='secondary'
              warning={errors.oldPassword ? errors.oldPassword.message : null}
            >
              <Input
                variation='secondary'
                type='password'
                classes={{ root: css('field') }}
                placeholder={t('dlg:oldPassword')}
                warning={!!errors.oldPassword}
                name='oldPassword'
                ref={register({ required: t('dlg:oldPasswordRequired') })}
              />
            </ControlContainer>
          </div>
          <div className={css('line')}>
            <div className={css('title')}>{t('dlg:newPassword')}</div>
            <ControlContainer
              variation='secondary'
              warning={errors.newPassword ? errors.newPassword.message : null}
            >
              <Input
                variation='secondary'
                type='password'
                classes={{ root: css('field') }}
                placeholder={t('dlg:newPassword')}
                warning={!!errors.newPassword}
                name='newPassword'
                ref={register({ required: t('dlg:newPasswordRequired') })}
              />
            </ControlContainer>
          </div>
          <div className={css('line')}>
            <div className={css('title')}>{t('dlg:reEnterNewPassword')}</div>
            <ControlContainer
              variation='secondary'
              warning={errors.newPasswordConfirm ? errors.newPasswordConfirm.message : null}
            >
              <Input
                variation='secondary'
                type='password'
                classes={{ root: css('field') }}
                placeholder={t('dlg:reEnterNewPassword')}
                warning={!!errors.newPasswordConfirm}
                name='newPasswordConfirm'
                ref={register({
                  required: t('dlg:reEnterNewPasswordRequired'),
                  validate: (value) => {
                    const values = getValues()
                    return values.newPassword === value ? null : t('dlg:confirmPasswordDoesntMatch')
                  }
                })}
              />
            </ControlContainer>
          </div>
          <Tip variation='secondary' view='warning' classes={{ root: css('tip') }}>
            {t('dlg:changePasswordTip')}
          </Tip>
        </PopupContent>
        <PopupButtons>
          <SecondaryButton view='light' onClick={handlerClickOnCancel}>
            <SecondaryButtonText>{t('dlg:cancel')}</SecondaryButtonText>
          </SecondaryButton>
          <SecondaryButton
            view='warning'
            onClick={handlerClickChangePassword}
            disabled={!formState.isValid || !formState.isDirty}
          >
            <SecondaryButtonText>{t('dlg:changePassword')}</SecondaryButtonText>
          </SecondaryButton>
        </PopupButtons>
      </form>
    </Popup>
  )
}

ChangePassword.propTypes = {
  onAdded: func.isRequired,
  onCancel: func.isRequired
}

export default ChangePassword
