import { Random } from '../../meteorAdapter';
import moment from 'moment'

import DialogHelpers from '../helpers/dialogHelpers'
import UploaderHelpers from './uploaderHelpers.js'

export default class ChatConsoleHelpers {
  static isShortcut (text = '') {
    return text[0] === '/' && text.includes(' ') === false
  }

  static isBotAction (text = '') {
    return /^#((?!#).)*$/g.test(text)
  }

  static getClipboardItems (e) {
    const { items = [] } = e.clipboardData ?? e.originalEvent.clipboardData
    return [...items]
  }

  static getClipboardImageItems (e) {
    const items = this.getClipboardItems(e)
    return items.filter(({ kind, type }) => kind === 'file' && type.startsWith('image/'))
  }

  static pasteImages (dialogId, userId, items) {
    return items.map(item => {
      const file = item.getAsFile()
      if (!file) return

      const { size } = file
      const name = moment().format('DD_MM_YYYY_HH_mm') + '.png'
      return UploaderHelpers.uploadImage(userId, dialogId, file).then(res => {
        return { type: 'image', name, size, url: res.url, fileId: res.fileId }
      })
    }).filter(Boolean)
  }

  static populatePlaceholders (text, vars) {
    return DialogHelpers.populateTemplatePlaceholders(text, vars)
  }

  static appendAttachment (attachments, attachment, vars) {
    if (attachment.type === 'template') {
      const original = attachment.text ?? attachment.title ?? attachment.name ?? ''
      const text = this.populatePlaceholders(original, vars)

      const attachments = (
        attachment.attachments ?? []
      ).map(attachment => {
        if (attachment.type === 'button') {
          attachment.name = this.populatePlaceholders(attachment.name, vars)
        }
        return attachment
      })
      return { text, attachments }
    } else if (attachment.type === 'button') {
      attachment.pattern = attachment.name
      attachment.name = this.populatePlaceholders(attachment.name, vars)
    }

    attachment.id = attachment.id ?? Random.id()
    const updatedAttachments = [
      ...attachments.filter(({ id }) => id !== attachment.id),
      attachment
    ]
    return { attachments: updatedAttachments }
  }

  static removeAttachment (attachments, attachmentId) {
    return [
      attachments.filter(({ id }) => id !== attachmentId),
      attachments.find(({ id }) => id === attachmentId)
    ]
  }
}
