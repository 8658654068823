/* eslint-disable default-case */
import moment from 'moment'
import { DateUtils } from '@flomni/modules/dist/services/date'
import { Meteor } from '../../../../../../meteorAdapter';

export const PERIODS = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  LAST_7_DAYS: 'last_7_days'
}

export const HISTORY_PERIODS = {
  SAME_WEEKDAY: 'same_weekday'
}

export const parseRawStats = (rawStats = []) => {
  const res = {}
  rawStats.forEach((event) => {
    const { eventType, metrics = [] } = event

    if (eventType === 'dialog.chat.conversation') {
      const totalNumberOfSessions = metrics.find(({ name }) => name === 'totalNumberOfSessions')?.value
      if (typeof totalNumberOfSessions === 'number') {
        res.totalNumberOfSessions = (res.totalNumberOfSessions ?? 0) + totalNumberOfSessions
      }

      res.avgQueueTime = metrics.find(({ name }) => name === 'avgQueueTime')?.value
    } else if (eventType === 'dialogs.agent.status') {
      const onlineMs = metrics.find(({ name }) => name === 'online')?.value
      res.totalMs = metrics.reduce((sum, a) => sum + a.value, 0)
      if (typeof onlineMs === 'number') {
        res.onlineMs = (res.onlineMs ?? 0) + onlineMs
      }
    } else if (eventType === 'core.rate') {
      res.avgRate = metrics.find(({ name }) => name === 'rateScore')?.value
      res.clientsCount = metrics.find(({ name }) => name === 'rateCount')?.value
    } else if (eventType === 'dialogs.chat.status') {
      const newDialogs = metrics.find(({ name }) => name === 'newDialogs')?.value
      if (typeof newDialogs === 'number') {
        res.newDialogs = (res.newDialogs ?? 0) + newDialogs
      }
    }
  })

  if (typeof res.onlineMs === 'number') {
    res.onlineHrs = res.onlineMs / 3600000

    if (res.onlineHrs > 0 && typeof res.totalNumberOfSessions === 'number') {
      res.avgChatsPerHour = res.totalNumberOfSessions / res.onlineHrs
    }
  }

  if (typeof res.avgQueueTime === 'number') {
    res.avgQueueTimeSecs = res.avgQueueTime / 1000
  }

  return res
}

export const chartOptions = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          return context.dataset.data[context.dataIndex]?.tooltip ?? context.formattedValue
        },
        title: (items) => {
          const tooltip = items[0]
          const dt = tooltip.dataset.data[tooltip.dataIndex]?.dt
          if (dt) {
            return moment(dt).format(DateUtils.DATE_TIME_FORMAT)
          }
          return tooltip.label
        }
      }
    }
  },
  scales: {
    x: {
      ticks: {
        autoSkipPadding: 15,
        maxRotation: 90,
        minRotation: 90
      }
    },
    y: {
      display: false
    }
  }
}

export const getForecastPeriodsArr = (period = HISTORY_PERIODS.SAME_WEEKDAY) => {
  const periodsArr = []
  for (let i = 0; i < 4; i++) {
    let startDate, endDate
    switch (period) {
      case HISTORY_PERIODS.SAME_WEEKDAY:
        startDate = moment().subtract(7 * (i + 1), 'days').startOf('hour')
        endDate = moment().subtract(7 * (i + 1), 'days').endOf('day')
        break
    }
    periodsArr.push([startDate.toISOString(), endDate.toISOString()])
  }
  return periodsArr
}

export const getLast4PeriodsDates = (period) => {
  let startDate = moment().subtract(4, 'days').startOf('day')
  let endDate = moment().startOf('day')
  switch (period) {
    case PERIODS.YESTERDAY:
      startDate = moment().subtract(5, 'days').startOf('day')
      endDate = moment().subtract(1, 'days').endOf('day')
      break
    case PERIODS.LAST_7_DAYS:
      startDate = moment().subtract(28, 'days').startOf('day')
      break
  }
  return [startDate.toISOString(), endDate.toISOString()]
}

export const getPreviousPeriodDates = (period) => {
  let startDate = moment().subtract(1, 'day').startOf('day')
  let endDate = moment().subtract(1, 'day').endOf('day')
  switch (period) {
    case PERIODS.YESTERDAY:
      startDate = moment().subtract(2, 'days').startOf('day')
      endDate = moment().subtract(2, 'days').endOf('day')
      break
    case PERIODS.LAST_7_DAYS:
      startDate = moment().subtract(14, 'days').startOf('day')
      endDate = moment().subtract(7, 'days').endOf('day')
      break
  }
  return [startDate.toISOString(), endDate.toISOString()]
}

export const getPeriodDates = (period) => {
  let startDate = moment().startOf('day')
  let endDate = moment().endOf('hour')
  switch (period) {
    case PERIODS.YESTERDAY:
      startDate = moment().subtract(1, 'days').startOf('day')
      endDate = moment().subtract(1, 'days').endOf('day')
      break
    case PERIODS.LAST_7_DAYS:
      startDate = moment().subtract(7, 'days').startOf('day')
      break
  }
  return [startDate.toISOString(), endDate.toISOString()]
}

export const loadDates = (eventType, startDate, endDate, type = 'group', reportType = 'company', userId) => {
  const params = {
    period: 'custom',
    startDate,
    endDate,
    groupBy: 'hour',
    type,
    userId,
    reportType,
    eventType
  }
  return Meteor.invoke('groups.getGroupActivityStats', params).then((results) => {
    return results[0]?.result?.agents[0]?.dates || []
  })
}

export const getChartLegend = (dtPeriod) => {
  if (dtPeriod) {
    const start = moment(dtPeriod[0])
    const hoursCount = getHoursCountDiff(dtPeriod)
    return [...Array(hoursCount).keys()].map((hour) =>
      start.clone().add(hour, 'hours').format(DateUtils.TIME_FORMAT)
    )
  }
  return []
}

export const getHoursCountDiff = (dtPeriod) => {
  return moment(dtPeriod[1]).diff(moment(dtPeriod[0]), 'hours') + 1
}

export const getEmptyDatesData = (data, dtPeriod) => {
  const emptyDates = []
  if (dtPeriod) {
    const hoursMap = {}
    data.forEach((item) => {
      hoursMap[item.dtPeriod] = item
    })
    const hoursCount = getHoursCountDiff(dtPeriod)
    for (let i = 0; i < hoursCount; i++) {
      const dt = moment(dtPeriod[0]).add(i, 'hours').toISOString()
      if (!hoursMap[dt]) {
        emptyDates.push({
          count: 0,
          tooltip: '0',
          dtPeriod: dt
        })
      }
    }
  }
  return emptyDates
}
