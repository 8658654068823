/** @prettier */
// @ts-check
import { useState, useEffect, useRef } from 'react'

/**
 * @typedef {Object} VersionManifest
 * @property {string} versionId
 * @property {string} commitHash
 * @property {string} commitRef
 * @property {string} environmentName
 * @property {string} buildAt
 */

const updateInterval = 1000 * 30 // every 30 seconds

/**
 * @param {string} cacheSeed
 * @returns {Promise<VersionManifest>}
 */
export function getVersionManifest(cacheSeed = `${Date.now()}`) {
  return fetch(`/version-manifest.json?v=${cacheSeed}`, {
    cache: 'no-cache',
    keepalive: true
  }).then((response) => response.json())
}

export function useVersionManifest() {
  const [manifest, setManifest] = useState(/** @type {Partial<VersionManifest>} */ ({}))
  const timerRef = useRef(/** @type {NodeJS.Timeout | null} */ (null))
  const [lastAttemptError, setLastAttemptError] = useState(/** @type {Error | null} */ (null))
  const [currentVersionId, setCurrentVersionId] = useState('')
  const [hasNewVersion, setHasNewVersion] = useState(false)

  async function updateManifest() {
    try {
      const manifest = await getVersionManifest()
      setManifest((oldManifest) => ({ ...oldManifest, ...manifest }))

      setLastAttemptError(null)
      timerRef.current = setTimeout(updateManifest, updateInterval)
    } catch (err) {
      setLastAttemptError(err)
      timerRef.current = setTimeout(updateManifest, updateInterval / 4)
    }
  }

  useEffect(() => {
    if (!manifest.versionId) {
      return
    }

    if (!currentVersionId) {
      setCurrentVersionId(manifest.versionId)
    } else if (currentVersionId !== manifest.versionId) {
      setHasNewVersion(true)
    }
  }, [manifest, currentVersionId])

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      return
    }

    updateManifest()

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  return { manifest, lastAttemptError, currentVersionId, hasNewVersion }
}
