import React, { useEffect, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { Popup } from '@flomni/components/dist/components/popup/popup'
import { PopupButtons } from '@flomni/components/dist/components/popup/buttons'
import { PopupContent } from '@flomni/components/dist/components/popup/content'
import { PopupTitle } from '@flomni/components/dist/components/popup/title'

import { func, object } from 'prop-types'
import { useForm } from 'react-hook-form'
import { Tip } from '@flomni/components/dist/components/tip'
import { ButtonWithLoader } from '../../../../../../../shared/button-with-loader'
import { Meteor } from '../../../../../../../../../meteorAdapter';
import { useDispatch } from 'react-redux'
import { processError } from '../../../../../../../../services/helpers'
import { Input } from '@flomni/components/dist/components/input'
import { ControlContainer } from '@flomni/components/dist/components/control-container'

const DisputeScoreDialog = ({ onComplete, onCancel, message }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const [showLoader, setShowLoader] = useState(false)

  const { register, unregister, formState, getValues, setValue, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      disputeReason: ''
    }
  })

  useEffect(() => {
    register('disputeReason')

    return () => {
      unregister('disputeReason')
    }
  }, [])

  const disputeReason = watch('disputeReason')

  const handlerClickSave = async (event) => {
    event.preventDefault()
    setShowLoader(true)
    await new Promise((resolve) => setTimeout(resolve, 800))
    try {
      const values = getValues()
      await Meteor.invoke('messages.disputeMessage', {
        messageId: message._id,
        disputeReason: values.disputeReason
      })
      setShowLoader(false)
      onComplete()
    } catch (err) {
      setShowLoader(false)
      processError(err, main)
    }
  }

  const handlerClickOnCancel = (event) => {
    event.preventDefault()
    onCancel()
  }

  return (
    <Popup open variation='secondary'>
      <PopupTitle
        title={
          message.event.disputed
            ? message.event.disputeResolution ? t('dlg:validationResults') : t('dlg:ratingIsPending')
            : t('dlg:excludingScore')}
      />
      <form className={css('form')}>
        <PopupContent classes={{ root: css('content') }}>
          <Tip
            variation='secondary'
            view={message.event.disputeResolution
              ? (message.event.disputeResolution === 'disputeRejected' ? 'usual' : 'warning')
              : 'info'
            }
            classes={{ root: css('tip') }}
          >
            {!!message.event.disputeResolution && <span className={css('title')}>
              {t(`dlg:${message.event.disputeResolution}TipTitle`)}
            </span>}
            <div>
              {message.event.disputed
                ? (
                  message.event.disputeResolution
                    ? t(`dlg:${message.event.disputeResolution}TipText`)
                    : t('dlg:ratingIsPendingTip')
                )
                : t('dlg:excludingScoreTip')
              }
            </div>
          </Tip>
          {!message.event.disputed && <div className={css('line')}>
            <div className={css('title')}>{t('dlg:reason')}</div>
            <ControlContainer variation='secondary'>
              <Input
                variation='secondary'
                classes={{ root: css('field') }}
                placeholder={t('dlg:whyAssessmentWrong')}
                value={disputeReason}
                onChange={(e) =>
                  setValue('disputeReason', e.target.value, { shouldDirty: true, shouldValidate: true })
                }
              />
            </ControlContainer>
          </div>}
        </PopupContent>
        <PopupButtons>
          <SecondaryButton view='light' onClick={handlerClickOnCancel}>
            <SecondaryButtonText>{t('dlg:close')}</SecondaryButtonText>
          </SecondaryButton>
          {!message.event.disputed && <ButtonWithLoader
            view='usual'
            text={t('dlg:submitForReview')}
            disabled={!formState.isValid}
            onClick={handlerClickSave}
            spinnerSize={10}
            spinnerStrokeWidth={14}
            isLoading={showLoader}
          />}
        </PopupButtons>
      </form>
    </Popup>
  )
}

DisputeScoreDialog.propTypes = {
  onComplete: func.isRequired,
  onCancel: func.isRequired,
  message: object.isRequired
}

export default DisputeScoreDialog
