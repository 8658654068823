/** @prettier */
import * as MeteorRN from '@meteorrn/core'
import localforage from 'localforage'
import { getMeteorWebsocketEndpoint, getBackendBaseUrl } from './startup/meteorUrl'

export const Meteor = MeteorRN.default
export const useTracker = MeteorRN.useTracker
export const Random = Meteor.Random
export const Mongo = MeteorRN.Mongo
export const Data = Meteor.getData()
export const DDP = Data.ddp
export const ReactiveDict = MeteorRN.ReactiveDict

export { getBackendBaseUrl, getMeteorWebsocketEndpoint }

const settings = (() => {
  const {
    REACT_APP_FLOMNI_AGENT_HEARTBEAT_INTERVAL: heartbeatInterval,
    REACT_APP_FLOMNI_FORCED_DISTRIBUTION_INTERVAL: forcedDistributionInterval,
    REACT_APP_FLOMNI_DISABLE_FORCED_DISTRIBUTION: disableForcedDistribution,
    REACT_APP_FLOMNI_DEFAULT_LOCALE: defaultLocale
  } = process.env

  return {
    FLOMNI_AGENT_HEARTBEAT_INTERVAL: parseInt(heartbeatInterval, 10) || 20, // seconds,
    FLOMNI_FORCED_DISTRIBUTION_INTERVAL: parseInt(forcedDistributionInterval, 10) || 1, // seconds
    FLOMNI_DISABLE_FORCED_DISTRIBUTION: disableForcedDistribution === 'true',
    FLOMNI_DEFAULT_LOCALE: defaultLocale || 'en-US'
  }
})()

Object.assign(Meteor, {
  setTimeout: window.setTimeout.bind(window),
  clearTimeout: window.clearTimeout.bind(window),
  setInterval: window.setInterval.bind(window),
  clearInterval: window.clearInterval.bind(window),
  _localStorage: window.localStorage,
  absoluteUrl(path) {
    if (path && !path.startsWith('/')) {
      path = `/${path}`
    }

    return window.location.origin + path
  },
  settings: {
    public: settings
  },
  connection: {
    registerStore(collectionId, callbacks) {
      Meteor.getData().waitDdpReady(() => {
        for (let [event, callback] of Object.entries(callbacks)) {
          if (event !== 'update') {
            throw new Error('Only update callback is supported for now')
          }

          for (const event of ['added', 'changed', 'removed']) {
            Meteor.getData().ddp.eventInterface.on(event, (packet) => {
              if (packet?.collection !== collectionId) {
                return
              }

              callback(packet)
            })
          }
        }
      })
    }
  }
})

const AsyncStorage = localforage.createInstance({
  name: 'meteor-async-storage'
})

export function connect() {
  Meteor.connect(getMeteorWebsocketEndpoint(), {
    AsyncStorage,
    autoReconnect: true,
    reconnectInterval: 2500,
    suppressUrlErrors: true
  })
}

// RegExp from [https://www.npmjs.com/package/simpl-schema#regex]
const documentIdPattern = /^[23456789ABCDEFGHJKLMNPQRSTWXYZabcdefghijkmnopqrstuvwxyz]{17}$/
const mongoIdPattern = /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i

export const Match = {
  isDocumentId(id) {
    return typeof id === 'string' && documentIdPattern.test(id)
  },
  isMongoId(id) {
    return typeof id === 'string' && mongoIdPattern.test(id)
  },
  Where: (condition) => {
    return (x) => {
      return condition(x)
    }
  },
  // very naive implementation
  check: (x, condition) => {
    if (!condition(x)) {
      throw new Error('Match failed')
    }
  },
  isNonEmptyString: (x) => {
    return typeof x === 'string' && x.length > 0
  },
  isDate: (x) => {
    return x instanceof Date && !isNaN(+x)
  }
}
