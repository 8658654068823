import React, { useCallback, useEffect, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { Dropdown } from '@flomni/components/dist/components/dropdown'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { CheckboxOption } from '@flomni/components/dist/components/checkbox-option'
import { array, bool, func } from 'prop-types'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { PlateButtonText } from '@flomni/components/dist/components/plate-button/text'
import { PlateButtonCounter } from '@flomni/components/dist/components/plate-button/counter'
import { SvgIconFilter } from '@flomni/components/dist/components/svg/feathers/SvgIconFilter'
import { MultiSearchSelect } from '@flomni/components/dist/components/select/multi-search'

const FilterSelect = ({ items, placeholder, onChange }) => {
  const options = items.map((item) => {
    return {
      label: item.name,
      value: item.id
    }
  })
  const checkedIds = items.filter((item) => item.isChecked).map((item) => item.id)
  const selectedOptions = options.filter((option) => checkedIds.includes(option.value))

  return (
    <MultiSearchSelect
      menuPortalTarget={document.body}
      options={options}
      value={selectedOptions}
      placeholder={placeholder}
      onChange={(options) => onChange(options ? options.map((option) => option.value) : [])}
    />
  )
}

export const FiltersPopup = ({ items, onDone, onReset, disabled }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const [opened, setOpened] = useState(false)
  const [filterItems, setFilterItems] = useState([])

  useEffect(() => {
    const newItems = [...items].map((item) => {
      const findFilter = filterItems.find((filter) => filter.id === item.id)
      if (findFilter) {
        if (findFilter.isChecked) {
          item.isChecked = findFilter.isChecked
        }
        item.items?.map((subItem) => {
          const findSubFilter = findFilter.items?.find((subFilter) => subFilter.id === subItem.id)
          if (findSubFilter && findSubFilter.isChecked) {
            subItem.isChecked = findSubFilter.isChecked
          }
          return subItem
        })
      }
      return item
    })
    setFilterItems(newItems)
  }, [items])

  const onDoneClickHandler = async () => {
    setOpened(false)
    onDone(filterItems)
  }

  const onResetClickHandler = () => {
    setOpened(false)
    const resetItems = filterItems.map((item) => {
      const newItem = {
        ...item,
        isChecked: item.defaultValue
      }
      if (item.items) {
        newItem.items = item.items.map((subItem) => {
          return {
            ...subItem,
            isChecked: subItem.defaultValue
          }
        })
      }
      return newItem
    })
    setFilterItems(resetItems)
    onReset(resetItems)
  }

  const changeItem = (itemId, subItemId, checked) => {
    setFilterItems(
      filterItems.map((item) => {
        const newItem = {
          ...item,
          isChecked: item.id === itemId && !subItemId ? checked : item.isChecked
        }
        if (item.items) {
          newItem.items = item.items.map((subItem) => {
            return {
              ...subItem,
              isChecked: item.id === itemId && subItem.id === subItemId ? checked : subItem.isChecked
            }
          })
        }
        return newItem
      })
    )
  }

  const changeItems = (itemId, ids) => {
    setFilterItems(
      filterItems.map((item) => {
        const newItem = {
          ...item
        }
        if (item.items) {
          newItem.items = item.items.map((subItem) => {
            return {
              ...subItem,
              isChecked: item.id === itemId ? ids.includes(subItem.id) : subItem.isChecked
            }
          })
        }
        return newItem
      })
    )
  }

  const onOverlayClick = useCallback((e) => {
    if (e.target.id.indexOf('react-select') === 0) {
      return
    }
    setOpened(false)
  }, [])

  const checkedFilterItemsCount = items.filter((item) => item.isChecked).length

  return (
    <div>
      <PlateButton onClick={() => setOpened(true)} disabled={disabled}>
        <PlateButtonIcon>
          <SvgIconFilter />
        </PlateButtonIcon>
        <PlateButtonText>{t('dlg:filter')}</PlateButtonText>
        {checkedFilterItemsCount > 0 && <PlateButtonCounter>{checkedFilterItemsCount}</PlateButtonCounter>}
      </PlateButton>
      <Dropdown
        classes={{ root: css('dropdown'), content: css('content') }}
        variation='secondary'
        active={opened}
        onOverlayClick={onOverlayClick}
      >
        <div className={css('header')}>
          <div className={css('header-item')}>
            <SecondaryButton
              view='light'
              onClick={onResetClickHandler}
              classes={{ root: css('header-button') }}
            >
              <SecondaryButtonText>{t('dlg:reset')}</SecondaryButtonText>
            </SecondaryButton>
          </div>
          <div className={css(['header-item', 'header-title'])}>{t('dlg:filters')}</div>
          <div className={css(['header-item', '--right'])}>
            <SecondaryButton onClick={onDoneClickHandler} classes={{ root: css('header-button') }}>
              <SecondaryButtonText>{t('dlg:done')}</SecondaryButtonText>
            </SecondaryButton>
          </div>
        </div>
        <div className={css('items')}>
          {filterItems
            .filter((item) => item.items && item.items.length > 0)
            .map((item) => (
              <div key={item.id}>
                <div className={css('chk')}>
                  <CheckboxOption
                    variation='secondary'
                    classes={{ label: css('chk-label') }}
                    checked={item.isChecked}
                    onChange={(e) => changeItem(item.id, null, e.target.checked)}
                  >
                    {item.name}
                  </CheckboxOption>
                </div>
                {item.isChecked && (
                  <div className={css('chk-sub-item')}>
                    {item.isSelect && (
                      <FilterSelect
                        items={item.items}
                        placeholder={item.selectPlaceholder || '1'}
                        onChange={(ids) => changeItems(item.id, ids)}
                      />
                    )}
                    {!item.isSelect &&
                      item.items.map((subItem) => (
                        <div key={subItem.id}>
                          <CheckboxOption
                            variation='secondary'
                            size='small'
                            onChange={(e) => changeItem(item.id, subItem.id, e.target.checked)}
                            checked={subItem.isChecked}
                          >
                            {subItem.name}
                          </CheckboxOption>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            ))}
        </div>
      </Dropdown>
    </div>
  )
}

FiltersPopup.propTypes = {
  items: array.isRequired,
  onDone: func,
  onReset: func,
  disabled: bool
}
