import debounce from 'lodash.debounce'
import Favico from 'favico.js'

import pink from '@material-ui/core/colors/pink'
import deepPurple from '@material-ui/core/colors/deepPurple'

const favicon = new Favico({
  animation: 'fade'
})

const setBadge = debounce((...args) => {
  favicon.badge(...args)
}, 350)

const CONTENT_COLORS = {
  primary: pink[500],
  secondary: deepPurple[500]
}

export default class FaviconHelpers {
  static update (content = '', badgeColor = 'primary') {
    const bgColor = badgeColor in CONTENT_COLORS
      ? CONTENT_COLORS[badgeColor]
      : badgeColor

    setBadge(content, { bgColor })
  }

  static updateCounter (val = '', badgeColor = 'primary') {
    const content = typeof val === 'number'
      ? (val > 99 ? '99+' : val)
      : val

    FaviconHelpers.update(content, badgeColor)
  }
}
