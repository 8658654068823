export const STRATEGIES = {
  FEWEST_ACTIVE_CHATS: 'strategies.FEWEST_ACTIVE_CHATS',
  FEWEST_ALL_CHATS: 'strategies.FEWEST_ALL_CHATS',
  EARLIEST_CHAT_ASSIGNED: 'strategies.EARLIEST_CHAT_ASSIGNED'
}

export const USER_STATUSES = {
  ONLINE: 'online',
  STANDBY: 'standby',
  OFFLINE: 'offline'
}

export const USER_ROLES = {
  OPERATOR: 'operator',
  SUPERVISOR: 'supervisor',
  ADMIN: 'admin'
}
