import React, { useEffect, useState } from 'react'
import { SearchForm } from './search'
import { SearchResults } from './results'
import { DialogsProvider } from '../../../../state/dialogs'
import { Meteor, Random } from '../../../../../meteorAdapter';
import { Spinner } from '@flomni/components/dist/components/spinner'
import styles from './index.module.scss'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import { AGENT_TYPE, DATE_SELECTOR, FILTER_PROPERTY, PERIOD, STATUS, LOCAL_STORAGE } from './search/constants'
import { processError } from '../../../../services/helpers'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import { DateUtils } from '@flomni/modules/dist/services/date'

export const Search = () => {
  const css = useStyles(styles)
  const { main } = useDispatch()
  const location = useLocation()
  const [filters, setFilters] = useState([])
  const [results, setResults] = useState([])
  const [resultsCounter, setResultsCounter] = useState([])
  const [showResults, setShowResults] = useState(false)
  const [showSearchLoader, setShowSearchLoader] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [browseTimeout, setBrowseTimeout] = useState(null)
  const RECEIVER_KEY = 'receiver'
  const FILTER_KEY = 'filter'
  const timeoutProperties = [FILTER_PROPERTY.CHAT_ID, FILTER_PROPERTY.CHAT_MESSAGES]
  const INITIAL_LIMIT = 21
  const [limit, setLimit] = useState(INITIAL_LIMIT)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (params.has(FILTER_KEY)) {
      setFilters(JSON.parse(decodeURIComponent(params.get(FILTER_KEY)) || '[]'))
      return
    }
    if (params.has(RECEIVER_KEY)) {
      setShowLoader(true)
      const receivers = params.get(RECEIVER_KEY).split(',')
      Promise.all(receivers.map((receiver) =>
        Meteor.invoke('getDialogByReceiver', { receiver })
      ))
        .then((dialogs) => {
          dialogs = dialogs.filter(Boolean)
          if (dialogs.length) {
            setResults(dialogs)
            setResultsCounter(dialogs.length)
            setShowResults(true)
          }
          setFilters([
            {
              id: Random.id(),
              property: FILTER_PROPERTY.CHAT_ID,
              chatIdEqual: true,
              chatId: params.get(RECEIVER_KEY)
            }
          ])
        })
        .catch((err) => {
          processError(err, main)
        })
        .finally(() => {
          setShowLoader(false)
        })
    } else {
      const filters = location.state?.filters || [
        {
          id: Random.id(),
          property: FILTER_PROPERTY.DATE,
          selector: DATE_SELECTOR.IS_TODAY,
          tz: 'UTC',
          period: PERIOD.DAYS
        }
      ]
      setFilters(filters)
      if (location.state?.filters) {
        window.history.replaceState({}, document.title)
      }
    }
  }, [])

  useEffect(() => {
    if (filters.length && filters.every(isFilterValid) && !showResults) {
      const timeout = filters.some((filter) => timeoutProperties.includes(filter.property)) ? 3000 : 0
      if (browseTimeout) {
        clearTimeout(browseTimeout)
        setBrowseTimeout(null)
      }
      setBrowseTimeout(
        setTimeout(() => {
          browseResults(true)
          setBrowseTimeout(null)
        }, timeout)
      )
    }

    return () => {
      if (browseTimeout) {
        clearTimeout(browseTimeout)
        setBrowseTimeout(null)
      }
    }
  }, [filters])

  useEffect(() => {
    if (filters.length && filters.every(isFilterValid)) {
      browseResults()
    }
  }, [limit])

  const isFilterValid = (item) => {
    if (item.property === FILTER_PROPERTY.DATE) {
      return (
        item.selector === DATE_SELECTOR.IS_TODAY ||
        (item.selector === DATE_SELECTOR.IS_IN_THE_LAST && !!item.periodValue) ||
        ((item.selector === DATE_SELECTOR.IS_EQUAL_TO ||
          item.selector === DATE_SELECTOR.IS_AFTER ||
          item.selector === DATE_SELECTOR.IS_BEFORE) &&
          !!item.from) ||
        (item.selector === DATE_SELECTOR.IS_BETWEEN && !!item.from && !!item.to)
      )
    }
    if (item.property === FILTER_PROPERTY.AGENT) {
      return item.agentType === AGENT_TYPE.CHATBOT || (item.agentType === AGENT_TYPE.HUMAN && !!item.agentId)
    }
    if (item.property === FILTER_PROPERTY.TAG) {
      return !!item.tagId
    }
    if (item.property === FILTER_PROPERTY.TOPIC) {
      return !!item.topicId
    }
    if (item.property === FILTER_PROPERTY.CHAT_DURATION) {
      return !!item.durationValue
    }
    if (item.property === FILTER_PROPERTY.CHAT_RATE) {
      return !!item.rate
    }
    if (item.property === FILTER_PROPERTY.CHAT_STATUS) {
      return !!item.status
    }
    if (item.property === FILTER_PROPERTY.CHAT_ID) {
      return !!item.chatId
    }
    if (item.property === FILTER_PROPERTY.CHAT_MESSAGES) {
      return !!item.message
    }
    if (item.property === FILTER_PROPERTY.CHANNEL) {
      return !!item.channelId
    }
    return false
  }

  const getOldFilter = (filter) => {
    if (filter.property === FILTER_PROPERTY.DATE) {
      const keepOffset = filter.tz !== 'UTC'
      if (filter.selector === DATE_SELECTOR.IS_TODAY) {
        return {
          dateFrom: moment().startOf('day').toISOString(keepOffset),
          dateTo: moment().endOf('day').toISOString(keepOffset)
        }
      }
      if (filter.selector === DATE_SELECTOR.IS_BEFORE) {
        return {
          dateTo: moment(filter.from, DateUtils.DATE_TIME_FORMAT).startOf('day').toISOString(keepOffset)
        }
      }
      if (filter.selector === DATE_SELECTOR.IS_AFTER) {
        return {
          dateFrom: moment(filter.from, DateUtils.DATE_TIME_FORMAT).endOf('day').toISOString(keepOffset)
        }
      }
      if (filter.selector === DATE_SELECTOR.IS_BETWEEN) {
        return {
          dateFrom: moment(filter.from, DateUtils.DATE_TIME_FORMAT).toISOString(keepOffset),
          dateTo: moment(filter.to, DateUtils.DATE_TIME_FORMAT).toISOString(keepOffset)
        }
      }
      if (filter.selector === DATE_SELECTOR.IS_EQUAL_TO) {
        return {
          dateFrom: moment(filter.from, DateUtils.DATE_TIME_FORMAT).startOf('day').toISOString(keepOffset),
          dateTo: moment(filter.from, DateUtils.DATE_TIME_FORMAT).endOf('day').toISOString(keepOffset)
        }
      }
      if (filter.selector === DATE_SELECTOR.IS_IN_THE_LAST) {
        return {
          dateFrom: moment().subtract(filter.periodValue, filter.period).toISOString(keepOffset)
        }
      }
    }
    if (filter.property === FILTER_PROPERTY.TAG) {
      return {
        tags: [filter.tagId]
      }
    }
    if (filter.property === FILTER_PROPERTY.CHANNEL) {
      return {
        channels: [filter.channelId]
      }
    }
    if (filter.property === FILTER_PROPERTY.AGENT && filter.agentType === AGENT_TYPE.HUMAN) {
      return {
        users: [filter.agentId]
      }
    }
    if (filter.property === FILTER_PROPERTY.CHAT_RATE) {
      return {
        score: filter.rate
      }
    }
    if (filter.property === FILTER_PROPERTY.TOPIC) {
      return {
        subjects: filter.topicId
      }
    }
    if (filter.property === FILTER_PROPERTY.CHAT_STATUS) {
      return {
        closed: filter.status === STATUS.CLOSED
      }
    }
    return {}
  }

  const browseResults = async (needTimeout) => {
    setShowSearchLoader(true)
    if (needTimeout) {
      await new Promise((resolve) => setTimeout(resolve, 800))
    }

    const requestFilters = {}
    filters.forEach((item) => {
      Object.assign(requestFilters, getOldFilter(item))
    })
    const requestsByReceiver = []
    const requestsByMessage = []
    filters.forEach((item) => {
      if (item.property === FILTER_PROPERTY.CHAT_ID && !!item.chatId) {
        if (item.chatIdEqual) {
          item.chatId.split(',').forEach((id) => requestsByReceiver.push(id))
        } else {
          const ids = item.chatId.split(',')
          ids.forEach((id) => {
            const trimId = id.trim()
            if (trimId) {
              requestsByReceiver.push(trimId)
            }
          })
        }
      }
      if (item.property === FILTER_PROPERTY.CHAT_MESSAGES && !!item.message) {
        requestsByMessage.push(item.message)
      }
    })

    try {
      const tasks = []
      let resultsCount = 0
      const byFilters = Meteor.invoke('getDialogs', {
        types: [],
        sortOrder: -1,
        filters: requestFilters,
        limit,
        search: requestsByMessage.length ? requestsByMessage.join(' ') : null
      }).then((res) => {
        resultsCount += res.count
        return res ? res.dialogs : []
      })
      requestsByReceiver.forEach((receiver) => {
        tasks.push(Meteor.invoke('getDialogByReceiver', { receiver }).then((dialog) => {
          if (dialog) resultsCount++
          return [dialog]
        }))
      })
      tasks.push(byFilters)
      const results = await Promise.all(tasks)
      setResults(results.flat().filter((res) => !!res))
      setResultsCounter(resultsCount)
    } catch (err) {
      processError(err, main)
    } finally {
      setShowSearchLoader(false)
    }
  }

  const onDialogChanged = (receiver) => {
    main.showGlobalLoader(true)
    Meteor.invoke('getDialogByReceiver', { receiver })
      .then((dialog) => {
        setResults(results.map((result) => (result._id === dialog._id ? dialog : result)))
        setResultsCounter(1)
      })
      .catch((err) => {
        processError(err, main)
      })
      .finally(() => {
        main.showGlobalLoader(false)
      })
  }

  const saveLocalSearch = () => {
    const recentSearches = JSON.parse(Meteor._localStorage.getItem(LOCAL_STORAGE.SEARCHES) || '[]')
    Meteor._localStorage.setItem(LOCAL_STORAGE.SEARCHES, JSON.stringify([filters, ...recentSearches].slice(0, 9)))
  }

  if (showLoader) {
    return (
      <div className={css('loader')}>
        <div className={css('spinner')}>
          <Spinner strokeWidth={5} />
        </div>
      </div>
    )
  }

  return showResults ? (
    <DialogsProvider isSearchMode>
      <SearchResults
        showLoader={showSearchLoader}
        results={results}
        resultsCounter={resultsCounter}
        onLoadMore={() => setLimit(limit + 20)}
        onReset={() => {
          setShowResults(false)
          setLimit(INITIAL_LIMIT)
        }}
        onChanged={onDialogChanged}
      />
    </DialogsProvider>
  ) : (
    <SearchForm
      showLoader={showSearchLoader}
      results={results}
      resultsCounter={resultsCounter}
      filters={filters}
      setFilters={setFilters}
      setShowResults={setShowResults}
      onResult={() => {
        setShowResults(true)
        saveLocalSearch()
      }}
    />
  )
}
