import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { object } from 'prop-types'
import { DateUtils } from '@flomni/modules/dist/services/date'

export const LastMessageDate = ({ chat }) => {
  const { t } = useTranslation()
  const [value, setValue] = useState('')

  const updateValue = () => {
    if (!chat.lastMessageAt) {
      setValue(null)
      return
    }
    const diffSeconds = moment().diff(moment(chat.lastMessageAt), 'seconds')
    // one minute
    if (diffSeconds < 60) {
      setValue(t('dlg:lessThanMinuteAgo'))
      return
    }
    // one week
    if (diffSeconds > 604800) {
      setValue(moment(chat.lastMessageAt).format('ll'))
      return
    }
    // one hour
    if (diffSeconds < 3600) {
      setValue(t('dlg:minutesAgo', { count: Math.round(diffSeconds / 60) }))
      return
    }
    setValue(moment(chat.lastMessageAt).format(`ddd, ${DateUtils.TIME_FORMAT}`))
  }

  useEffect(() => {
    updateValue()

    const timer = setInterval(updateValue, 60000)
    return () => {
      clearInterval(timer)
    }
  }, [chat])

  return value
}

LastMessageDate.props = {
  chat: object
}
