import React, { useEffect, useMemo, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { string } from 'prop-types'
import classnames from 'classnames'
import { AvatarSvg } from './AvatarSvg'

export const Avatar = ({ url, cls, colorName }) => {
  const css = useStyles(styles)
  const [imgUrl, setImgUrl] = useState(url)
  const computedStyle = window.getComputedStyle(document.documentElement)

  const greyColor = {
    bg: computedStyle.getPropertyValue('--color-gray-40'),
    sh: computedStyle.getPropertyValue('--color-gray-50')
  }
  const colors = [
    {
      bg: computedStyle.getPropertyValue('--color-red-90'),
      sh: computedStyle.getPropertyValue('--color-red-d')
    },
    {
      bg: computedStyle.getPropertyValue('--color-red-70'),
      sh: computedStyle.getPropertyValue('--color-red-80')
    },
    {
      bg: computedStyle.getPropertyValue('--color-orange-80'),
      sh: computedStyle.getPropertyValue('--color-orange-90')
    },
    {
      bg: computedStyle.getPropertyValue('--color-orange-65'),
      sh: computedStyle.getPropertyValue('--color-orange-70')
    },
    {
      bg: computedStyle.getPropertyValue('--color-yellow-90'),
      sh: computedStyle.getPropertyValue('--color-yellow-d')
    },
    {
      bg: computedStyle.getPropertyValue('--color-yellow-70'),
      sh: computedStyle.getPropertyValue('--color-yellow-80')
    },
    {
      bg: computedStyle.getPropertyValue('--color-green-80'),
      sh: computedStyle.getPropertyValue('--color-green-90')
    },
    {
      bg: computedStyle.getPropertyValue('--color-green-70'),
      sh: computedStyle.getPropertyValue('--color-green-80')
    },
    {
      bg: computedStyle.getPropertyValue('--color-blue-80'),
      sh: computedStyle.getPropertyValue('--color-blue-90')
    },
    {
      bg: computedStyle.getPropertyValue('--color-blue-70'),
      sh: computedStyle.getPropertyValue('--color-blue-80')
    },
    {
      bg: computedStyle.getPropertyValue('--color-purple-70'),
      sh: computedStyle.getPropertyValue('--color-purple-80')
    },
    {
      bg: computedStyle.getPropertyValue('--color-purple-90'),
      sh: computedStyle.getPropertyValue('--color-purple-d')
    }
  ]

  useEffect(() => {
    setImgUrl(url)
  }, [url])

  const onError = () => {
    setImgUrl(null)
  }

  const hashCode = (str) => {
    return str.split('').reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0)
      return a & a
    }, 0)
  }

  const color = useMemo(() => {
    if (!colorName) {
      return greyColor
    }
    return colors[Math.abs(hashCode(colorName)) % colors.length]
  }, [colorName])

  return (
    <div className={classnames(css('logo'), cls)}>
      {!imgUrl && <AvatarSvg bg={color.bg} sh={color.sh} />}
      {imgUrl && <img alt='' src={imgUrl} onError={onError} />}
    </div>
  )
}

Avatar.propTypes = {
  url: string,
  cls: string,
  colorName: string
}
