import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { func, object } from 'prop-types'
import { ValueSelector } from '../shared/value-selector'
import { conversationChannelsMapping } from '../../../../../../../configs/conversationChannelsMapping';

export const ChannelFilterItem = ({ item, onChangeItemProperty }) => {
  const { t } = useTranslation()

  const [selectedChannelId, setSelectedChannelId] = useState(item.channelId)
  const [channels, setChannels] = useState([])

  useEffect(() => {
    const defaultChannels = conversationChannelsMapping
    const channels = Object.keys(defaultChannels).map((key) => {
      return { label: defaultChannels[key], value: key }
    })
    channels.sort((a, b) => (a.label > b.label && 1) || -1)
    setChannels(channels)
  }, [])

  const onSelectChannelId = (channelId) => {
    setSelectedChannelId(channelId)
    onChangeItemProperty('channelId', channelId)
  }

  return (
    <ValueSelector
      items={channels}
      item={selectedChannelId}
      onChange={onSelectChannelId}
      placeholder={t('dlg:selectChannel')}
      translate={false}
    />
  )
}

ChannelFilterItem.propTypes = {
  onChangeItemProperty: func.isRequired,
  item: object.isRequired
}
