import React, { useCallback } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { SvgIconAttach } from '@flomni/components/dist/components/svg/feathers/SvgIconAttach'
import { Tooltip } from '@flomni/components/dist/components/tooltip'
import { Dropdown } from '@flomni/components/dist/components/dropdown'
import { SecondaryDropdownItem } from '@flomni/components/dist/components/secondary-dropdown-item/item'
import { SecondaryDropdownItemText } from '@flomni/components/dist/components/secondary-dropdown-item/text'
import { func } from 'prop-types'
import AddContact from './add-contact'
import AddLocation from './add-location'
import { useInsertAttachment } from '../../insert-attachment-hook'

export const InsertAttachment = ({ onAdd }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const {
    inputEl,
    showMenu,
    showAddLocation,
    setShowMenu,
    showAddContact,
    setShowAddContact,
    setShowAddLocation,
    onInsertLocation,
    onInsertContact,
    onFileChange,
    handleInsertImageOrVideo,
    handleInsertLocation,
    handleInsertFile,
    handleInsertContact
  } = useInsertAttachment(onAdd)

  const onOverlayClickHandler = useCallback(() => {
    setShowMenu(false)
  }, [])

  return (
    <div>
      <input ref={inputEl} type='file' multiple className={css('input')} onChange={onFileChange} />

      <Dropdown
        active={showMenu}
        variation='secondary'
        direction='up'
        offsetTarget='iconButton'
        onOverlayClick={onOverlayClickHandler}
      >
        <SecondaryDropdownItem view='bright' onClick={handleInsertImageOrVideo}>
          <SecondaryDropdownItemText>{t('dlg:imageOrVideo')}</SecondaryDropdownItemText>
        </SecondaryDropdownItem>
        <SecondaryDropdownItem view='bright' onClick={handleInsertLocation}>
          <SecondaryDropdownItemText>{t('dlg:location')}</SecondaryDropdownItemText>
        </SecondaryDropdownItem>
        <SecondaryDropdownItem view='bright' onClick={handleInsertFile}>
          <SecondaryDropdownItemText>{t('dlg:file')}</SecondaryDropdownItemText>
        </SecondaryDropdownItem>
        <SecondaryDropdownItem view='bright' onClick={handleInsertContact}>
          <SecondaryDropdownItemText>{t('dlg:contact')}</SecondaryDropdownItemText>
        </SecondaryDropdownItem>
      </Dropdown>
      <div>
        <Tooltip
          variation='secondary'
          content={t('dlg:insertAttachment')}
          dropdownProps={{ direction: 'up' }}
          forcedActive={showMenu ? false : null}
        >
          <div>
            <PlateButton onClick={() => setShowMenu(true)}>
              <PlateButtonIcon>
                <SvgIconAttach />
              </PlateButtonIcon>
            </PlateButton>
          </div>
        </Tooltip>
      </div>
      {showAddContact && (
        <AddContact
          onAdded={onInsertContact}
          onCancel={() => {
            setShowAddContact(false)
          }}
        />
      )}
      {showAddLocation && (
        <AddLocation
          onAdded={onInsertLocation}
          onCancel={() => {
            setShowAddLocation(false)
          }}
        />
      )}
    </div>
  )
}

InsertAttachment.propTypes = {
  onAdd: func.isRequired
}
