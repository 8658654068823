import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { array, func } from 'prop-types'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { SvgIconClose } from '@flomni/components/dist/components/svg/feathers/SvgIconClose'
import { AttachmentFile } from './attachment-file'
import { AttachmentImage } from './attachment-image'
import classnames from 'classnames'
import { AttachmentContact } from './attachment-contact'
import { AttachmentLocation } from './attachment-location'
import { AttachmentAction } from './attachment-action'
import { AttachmentButton } from './attachment-button'
import { isMobileMode } from '../../../../../../../../../services/helpers'

export const AttachmentsList = ({
  items,
  onDelete,
  onChangeFile,
  onChangeContact,
  onChangeLocation,
  onChangeAction,
  onChangeButton
}) => {
  const css = useStyles(styles)

  const isFileTypeImageOrVideo = (type) => {
    return /^image\/|video\//g.test(type)
  }

  return items && items.length > 0 ? (
    <div className={css(classnames('list', isMobileMode ? '--mobile' : null))}>
      {items.map((item) => {
        const isFileImage = item.type === 'file' && isFileTypeImageOrVideo(item.file.type)
        return (
          <div key={item._id} className={css(classnames('item', isFileImage ? '--image' : null))}>
            <PlateButton
              classes={{ root: css('close-btn') }}
              view='dark'
              hoverType='dark'
              onClick={() => onDelete(item._id)}
            >
              <PlateButtonIcon>
                <SvgIconClose />
              </PlateButtonIcon>
            </PlateButton>
            {item.type === 'file' && !isFileTypeImageOrVideo(item.file.type) && (
              <AttachmentFile file={item.file} onChange={(file) => onChangeFile(item._id, file)} />
            )}
            {isFileImage && <AttachmentImage file={item.file} />}
            {item.type === 'contact' && (
              <AttachmentContact
                contact={item.contact}
                onChange={(contact) => onChangeContact(item._id, contact)}
              />
            )}
            {item.type === 'location' && (
              <AttachmentLocation
                location={item.location}
                onChange={(location) => onChangeLocation(item._id, location)}
              />
            )}
            {item.type === 'action' && <AttachmentAction action={item.action} onChange={onChangeAction} />}
            {item.type === 'button' && <AttachmentButton button={item.button} onChange={onChangeButton} />}
          </div>
        )
      })}
    </div>
  ) : null
}

AttachmentsList.propTypes = {
  items: array,
  onDelete: func.isRequired,
  onChangeFile: func.isRequired,
  onChangeContact: func.isRequired,
  onChangeLocation: func.isRequired,
  onChangeAction: func.isRequired,
  onChangeButton: func.isRequired
}
