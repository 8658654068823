import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SvgIconLightiningFilled } from '@flomni/components/dist/components/svg/feathers/SvgIconLightiningFilled'
import { array, func } from 'prop-types'
import classnames from 'classnames'
import { isMobileMode } from '../../../../../../../../../services/helpers'

export const ActionsList = ({ onActionSelected, actions }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()

  return (
    <div className={css(classnames('container', isMobileMode ? '--mobile' : null))}>
      <div className={css('header')}>
        <div className={css('title')}>{t('dlg:chatbotActions')}</div>
      </div>
      {actions.length === 0 && (
        <div className={css('no-items')}>
          <div className={css('no-items-header')}>
            {!isMobileMode && (
              <div className={css('no-items-icon')}>
                <SvgIconLightiningFilled width={16} height={20} />
              </div>
            )}
            <div className={css('no-items-title')}>{t('dlg:youDontHaveChatbotActions')}</div>
          </div>
        </div>
      )}
      {actions.length > 0 && (
        <div className={css('list')}>
          {actions.map((item) => (
            <div key={item.actionId} className={css('item')} onClick={() => onActionSelected(item)}>
              <div className={css('item-text')}>{item.name}</div>
              <div className={css('item-description')}>
                {item.description ?? <span className={css('no-description')}>{t('dlg:noDescription')}</span>}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

ActionsList.propTypes = {
  actions: array,
  onActionSelected: func.isRequired
}
