import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { any, func, bool } from 'prop-types'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonIcon } from '@flomni/components/dist/components/secondary-button/icon'
import { SvgIconUser } from '@flomni/components/dist/components/svg/feathers/SvgIconUser'
import { SvgIconGroup } from '@flomni/components/dist/components/svg/feathers/SvgIconGroup'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { Dropdown } from '@flomni/components/dist/components/dropdown'
import { Input } from '@flomni/components/dist/components/input'
import { SecondaryDropdownItem } from '@flomni/components/dist/components/secondary-dropdown-item/item'
import { SecondaryDropdownItemText } from '@flomni/components/dist/components/secondary-dropdown-item/text'
import { useTranslation } from 'react-i18next'
import { Meteor } from '../../../../../meteorAdapter';
import { processError } from '../../../../services/helpers'
import { useDispatch } from 'react-redux'

export const UserSelect = ({ setShowLoader, selectedUser, setSelectedUser, showAll }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()

  const [users, setUsers] = useState([])
  const [showUsers, setShowUsers] = useState(false)
  const [searchUser, setSearchUser] = useState('')

  useEffect(() => {
    setShowLoader(true)
    Meteor.invoke('users.getUsers', {})
      .then(({ list = [] }) => {
        const users = list.filter((user) => !user.isRemoved)
        users.sort((a, b) => (a.name > b.name ? 1 : -1))
        setUsers(users)

        const currentUserId = Meteor.userId()
        const currentUser = users.find((user) => user.userId === currentUserId)
        if (currentUser) {
          setSelectedUser(currentUser)
        }
      })
      .catch((err) => {
        processError(err, main)
      })
      .finally(() => {
        setShowLoader(false)
      })
  }, [])

  const onOverlayClickHandler = useCallback(() => {
    setShowUsers(false)
  }, [])

  const filteredUsers = useMemo(() => {
    const searchLowerCase = searchUser.toLowerCase().trim()
    return users.filter((user) => user.name.toLowerCase().indexOf(searchLowerCase) > -1)
  }, [users, searchUser])

  return (
    <div>
      <SecondaryButton view='light' disabled={users.length === 0} onClick={() => setShowUsers(true)}>
        <SecondaryButtonIcon>
          <SvgIconUser />
        </SecondaryButtonIcon>
        {(selectedUser || showAll) && <SecondaryButtonText>{!selectedUser ? t('dlg:allAgents') : selectedUser.name}</SecondaryButtonText>}
      </SecondaryButton>
      <Dropdown
        active={showUsers}
        variation='secondary'
        position='right'
        onOverlayClick={onOverlayClickHandler}
      >
        <Input
          variation='secondary'
          autoFocus
          classes={{ input: css('search-input') }}
          placeholder={t('dlg:search')}
          value={searchUser}
          onChange={(e) => setSearchUser(e.target.value)}
        />
        {showAll && <SecondaryDropdownItem
          active={!selectedUser}
          onClick={() => {
            setSelectedUser(undefined)
            setShowUsers(false)
          }}
        >
          <SecondaryButtonIcon>
            <SvgIconGroup />
          </SecondaryButtonIcon>
          <SecondaryDropdownItemText>
            <span className={css('all')}>{t('dlg:allAgents')}</span>
          </SecondaryDropdownItemText>
        </SecondaryDropdownItem>}
        {filteredUsers.map((user) => (
          <SecondaryDropdownItem
            key={user.userId}
            active={selectedUser?.userId === user.userId}
            onClick={() => {
              setSelectedUser(user)
              setShowUsers(false)
            }}
          >
            <SecondaryDropdownItemText>{user.name}</SecondaryDropdownItemText>
          </SecondaryDropdownItem>
        ))}
      </Dropdown>
    </div>
  )
}

UserSelect.propTypes = {
  setShowLoader: func,
  selectedUser: any,
  setSelectedUser: func.isRequired,
  showAll: bool
}

UserSelect.defaultProps = {
  setShowLoader: () => {}
}
