import React, { useContext, useEffect, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { Tabs } from '@flomni/components/dist/components/tabs/tabs'
import { TabsHeaderDropdown } from '@flomni/components/dist/components/tabs/tabs-header-dropdown'
import { TabsBody } from '@flomni/components/dist/components/tabs/tabs-body'
import { Tab } from '@flomni/components/dist/components/tabs/tab'
import { Panel } from '@flomni/components/dist/components/tabs/panel'
import { Meteor, useTracker } from '../../../../../../meteorAdapter';
import { store } from '../../../../../state/dialogs'
import Dialogs from '../../../../../../collections/client/dialogs'
import { ProgressList } from '../../conversations/Conversations/left-panel/progress-list'
import { SortFilterOptions } from '../../conversations/Conversations/left-panel/sort-filters-options'
import { QueueList } from '../../conversations/Conversations/left-panel/queue-list'
import { number } from 'prop-types'
import { Tag } from '@flomni/components/dist/components/tag'
import classnames from 'classnames'
import { NoItems } from '../../conversations/Conversations/left-panel/no-items'
import { SvgIconQueue } from '@flomni/components/dist/components/svg/feathers/SvgIconQueue'
import { useSelector } from 'react-redux'
import { CompletedList } from './completed-list'

export const LeftPanel = ({ newDialogsCount, teamDialogsCount }) => {
  const DIALOG_TYPES = {
    BOT: 'BOT',
    ARCHIVE: 'ARCHIVE',
    NEW: 'NEW',
    TEAM: 'TEAM'
  }
  const DIALOG_STATES = {
    BOT_DRIVEN: 'BOT_DRIVEN',
    IN_WORK: 'IN_WORK',
    NEW: 'NEW',
    ARCHIVED: 'ARCHIVED'
  }
  const COUNT_IN_BATCH = 20
  const TAB_TO_TYPE = {
    0: DIALOG_TYPES.BOT,
    1: DIALOG_TYPES.NEW,
    2: DIALOG_TYPES.TEAM,
    3: DIALOG_TYPES.ARCHIVE
  }
  const TAB_TO_STATE = {
    0: DIALOG_STATES.BOT_DRIVEN,
    1: DIALOG_STATES.NEW,
    2: DIALOG_STATES.IN_WORK,
    3: DIALOG_STATES.ARCHIVED
  }

  const css = useStyles(styles)
  const { t } = useTranslation()
  const {
    sortOrder,
    sortFieldName,
    filterTags,
    filterStatuses,
    filterUsers,
    selectedDialogId,
    selectedDialogsTabIndex,
    dispatch
  } = useContext(store)
  const [currentUserDepartmentId, currentUserGroupId, currentUserRoles] = useSelector(({ main }) => [
    main.currentUserDepartmentId,
    main.currentUserGroupId,
    main.currentUserRoles
  ])
  const [dialogType, setDialogType] = useState(DIALOG_TYPES.BOT)
  const [limit, setLimit] = useState(COUNT_IN_BATCH)
  const [isAllowedLoadMore, setIsAllowedLoadMore] = useState(true)

  const [listDialogsLoading, listDialogs] = useTracker(() => {
    const status = {}
    if (filterStatuses && filterStatuses.length) {
      filterStatuses.forEach((filterStatus) => {
        status[filterStatus] = true
      })
    }
    const handle = Meteor.subscribe('fetchGroupDialogs', {
      user: {
        groupId: currentUserGroupId,
        departmentId: currentUserDepartmentId,
        roles: currentUserRoles
      },
      types: [dialogType],
      limit,
      sortOrder,
      sortField: sortFieldName,
      filters: {
        tags: filterTags ?? [],
        status,
        agents: filterUsers ?? []
      }
    })
    const loading = !handle.ready()

    const chats = Dialogs.find(
      {
        state: TAB_TO_STATE[selectedDialogsTabIndex]
      },
      {
        sort: {
          lastMessageAt: sortOrder
        }
      }
    ).fetch()

    return [loading, chats]
  }, [
    sortOrder,
    sortFieldName,
    filterTags,
    filterStatuses,
    filterUsers,
    dialogType,
    limit,
    currentUserGroupId,
    currentUserDepartmentId,
    currentUserRoles
  ])

  useTracker(() => {
    const receivers = listDialogs.map(({ receiver }) => receiver)
    Meteor.subscribe('fetchClientsActivity', receivers)
  }, [listDialogs])

  const setTabIndex = (index) => {
    dispatch({ type: 'setSelectedDialogsTabIndex', selectedDialogsTabIndex: index })
  }

  useEffect(() => {
    const type = TAB_TO_TYPE[selectedDialogsTabIndex]
    if (dialogType !== type) {
      setDialogType(type)
      window.setTimeout(() => {
        dispatch({ type: 'setSelectedDialog', selectedDialog: null })
      }, 100)
    }
  }, [selectedDialogsTabIndex])

  useEffect(() => {
    if (!selectedDialogId) {
      dispatch({ type: 'setSelectedDialog', selectedDialog: listDialogs.length ? listDialogs[0] : null })
    }
  }, [listDialogs, selectedDialogId])

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop - 10 <= e.target.clientHeight
    if (!bottom) {
      setIsAllowedLoadMore(true)
    }
    if (bottom && !listDialogsLoading && isAllowedLoadMore) {
      setIsAllowedLoadMore(false)
      setLimit(limit + COUNT_IN_BATCH)
    }
  }

  const noItemsToSelect = !selectedDialogId && !listDialogsLoading && listDialogs.length === 0

  return (
    <div className={css(classnames('panel', noItemsToSelect ? '--wide' : null))}>
      <div className={css('container')}>
        <div className={css('header')}>
          <div className={css('title')}>{t('dlg:allChats')}</div>
          <SortFilterOptions showAgentsFilter />
        </div>
        <Tabs state={[selectedDialogsTabIndex, setTabIndex]} view='secondary' classes={{ root: css('tabs') }}>
          <TabsHeaderDropdown classes={{ root: css('tabs-header') }}>
            <Tab>{t('dlg:chatbot')}</Tab>
            <Tab
              label={
                newDialogsCount > 0 ? (
                  <Tag color='green' variation='secondary' view='filled'>
                    {newDialogsCount}
                  </Tag>
                ) : null
              }
            >
              {t('dlg:queue')}
            </Tab>
            <Tab
              label={
                teamDialogsCount > 0 ? (
                  <Tag color='green' variation='secondary' view='filled'>
                    {teamDialogsCount}
                  </Tag>
                ) : null
              }
            >
              {t('dlg:agents')}
            </Tab>
            <Tab>{t('dlg:completed')}</Tab>
          </TabsHeaderDropdown>
          <TabsBody classes={{ root: css('tabs-body') }}>
            <Panel>
              <div className={css('scroll-area')} onScroll={handleScroll}>
                <ProgressList chats={listDialogs} queueCount={0} loading={listDialogsLoading} />
              </div>
            </Panel>
            <Panel>
              <div className={css('scroll-area')} onScroll={handleScroll}>
                <QueueList chats={listDialogs} loading={listDialogsLoading} />
              </div>
            </Panel>
            <Panel>
              <div className={css('scroll-area')} onScroll={handleScroll}>
                <ProgressList chats={listDialogs} queueCount={0} loading={listDialogsLoading} />
              </div>
            </Panel>
            <Panel>
              <div className={css('scroll-area')} onScroll={handleScroll}>
                <CompletedList chats={listDialogs} loading={listDialogsLoading} />
              </div>
            </Panel>
          </TabsBody>
        </Tabs>
      </div>
      {noItemsToSelect && selectedDialogsTabIndex === 1 && (
        <NoItems
          Icon={SvgIconQueue}
          title={t('dlg:noChatsInQueue')}
          description={t('dlg:noChatsInQueueDescription')}
        />
      )}
    </div>
  )
}

LeftPanel.propTypes = {
  newDialogsCount: number,
  teamDialogsCount: number
}
