import React, { useCallback, useEffect, useState, useMemo } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { Meteor, useTracker, Random } from '../../../../../../meteorAdapter';
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { PlateButtonText } from '@flomni/components/dist/components/plate-button/text'
import { SvgIconPlus } from '@flomni/components/dist/components/svg/feathers/SvgIconPlus'
import { SvgIconStarStroke } from '@flomni/components/dist/components/svg/feathers/SvgIconStarStroke'
import { SvgIconClockStroke } from '@flomni/components/dist/components/svg/feathers/SvgIconClockStroke'
import { FilterItem } from './filter'
import { array, func } from 'prop-types'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { Spinner } from '@flomni/components/dist/components/spinner'
import { Item } from './item'
import { processError } from '../../../../../services/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { Tags } from '../../../../../customStores'
import { LOCAL_STORAGE } from './constants'

export const SearchForm = ({ showLoader, filters, setFilters, results, resultsCounter, onResult }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const user = useTracker(() => Meteor.user(), [])
  const currentUserGroupId = useSelector(({ main }) => main.currentUserGroupId)
  const [recentSearches, setRecentSearches] = useState(
    JSON.parse(Meteor._localStorage.getItem(LOCAL_STORAGE.SEARCHES) || '[]')
  )
  const [reference, setReference] = useState({})

  const onAddFilterHandler = () => {
    setFilters([
      ...filters,
      {
        id: Random.id(),
        property: null
      }
    ])
  }

  const onDeleteFilterHandler = (item) => {
    setFilters(filters.filter((filter) => filter.id !== item.id))
  }

  const onChangeFilterHandler = (item) => {
    setFilters(filters.map((filter) => (filter.id === item.id ? item : filter)))
  }

  useEffect(() => {
    async function fetchAPI () {
      if (currentUserGroupId) {
        try {
          const members = await Meteor.invoke('groups.getGroupMembers', {
            groupId: currentUserGroupId,
            fields: ['profile.name'],
            excludeBlocked: true
          })
          const tags = await Meteor.invoke('groups.getGroupTags', { groupId: currentUserGroupId })
          const topics = Tags.find(
            {
              sourceId: currentUserGroupId,
              sourceType: 'group'
            },
            {
              fields: {
                name: 1,
                kind: 1
              },
              sort: {
                name: 1
              }
            }
          )
            .map(({ _id, name, kind }) => ({
              _id,
              name,
              kind
            }))
            .filter(({ kind }) => kind === 'session')

          setReference({ ...reference, members: members || [], tags: tags.items || [], topics: topics })
        } catch (err) {
          processError(err, main)
        }
      }
    }
    fetchAPI()
  }, [currentUserGroupId])

  const deleteRecentSearch = useCallback((indexSearch) => {
    const searches = recentSearches.filter((search, index) => index !== indexSearch)
    setRecentSearches(searches)
    Meteor._localStorage.setItem(LOCAL_STORAGE.SEARCHES, JSON.stringify(searches))
  }, [recentSearches])

  const deleteFavoriteSearch = useCallback((searchFavouriteId) => {
    Meteor.call('users.delSearchFavourite', { searchFavouriteId })
  }, [])

  const addFavoriteSearch = useCallback((filter) => {
    Meteor.call('users.addSearchFavourite', {
      searchFavouriteFilter: { filter }
    })
  }, [])

  const searchFavourites = useMemo(() => {
    return user?.searchFavourites?.filter((item) => item.searchFavouriteId && item.filter?.filter) || []
  }, [user?.searchFavourites])

  return (
    <div className={css('container')}>
      <div className={css('main')}>
        <div className={css('header')}>
          <div className={css('header-title')}>{t('dlg:searchWhatever')}</div>
          <div className={css('header-description')}>{t('dlg:searchByNameContentId')}</div>
        </div>
        <div className={css('body')}>
          <div className={css('filters')}>
            <div className={css('filters-title')}>{t('dlg:filters').toUpperCase()}</div>
            {filters.map((filter) => (
              <FilterItem
                key={filter.id}
                item={filter}
                onChange={onChangeFilterHandler}
                onDelete={() => onDeleteFilterHandler(filter)}
              />
            ))}
            <div className={css('add-filter-btn')}>
              <PlateButton
                noBg={filters.length > 0}
                view={filters.length === 0 ? 'bright' : 'usual'}
                onClick={onAddFilterHandler}
                disabled={filters.some((filter) => !filter.property)}
              >
                <PlateButtonIcon>
                  <SvgIconPlus />
                </PlateButtonIcon>
                <PlateButtonText>{t('dlg:addFilter')}</PlateButtonText>
              </PlateButton>
            </div>
            {filters.length > 0 && (
              <div className={css('results-btn')}>
                <SecondaryButton
                  disabled={results === null || results.length === 0 || showLoader}
                  onClick={() => {
                    onResult(results)
                  }}
                >
                  <SecondaryButtonText>{t('dlg:browseResults')}</SecondaryButtonText>
                </SecondaryButton>
                {showLoader && (
                  <div className={css('loader')}>
                    <div className={css('spinner')}>
                      <Spinner strokeWidth={5} />
                    </div>
                  </div>
                )}
                {!showLoader && results !== null && (
                  <div className={css('count-chats')}>
                    {t('dlg:foundCountChats', { count: resultsCounter })}
                  </div>
                )}
              </div>
            )}
          </div>
          {!!searchFavourites?.length && <div className={css('favorites')}>
            <div className={css('favorites-title')}>
              <SvgIconStarStroke />
              <div>{t('dlg:favoriteRequests').toUpperCase()}</div>
            </div>
            <div className={css`searches`}>
              {searchFavourites.map((search, index) => (
                <Item
                  id={search.searchFavouriteId}
                  key={index}
                  index={index}
                  search={search.filter.filter}
                  setFilters={setFilters}
                  onDelete={deleteFavoriteSearch}
                  reference={reference}
                />
              ))}
            </div>
          </div>}
          {!!recentSearches?.length && <div className={css('recent')}>
            <div className={css('recent-title')}>
              <SvgIconClockStroke />
              <div>{t('dlg:recentSearches').toUpperCase()}</div>
            </div>
            <div className={css`searches`}>
              {recentSearches.map((search, index) => (
                <Item
                  key={index}
                  index={index}
                  search={search}
                  setFilters={setFilters}
                  onDelete={deleteRecentSearch}
                  reference={reference}
                  addFavoriteSearch={addFavoriteSearch}
                />
              ))}
            </div>
          </div>}
        </div>
      </div>
    </div>
  )
}

SearchForm.propTypes = {
  filters: array,
  setFilters: func.isRequired
}
