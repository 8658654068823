import React, { useCallback, useEffect, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from '@flomni/components/dist/components/secondary-button/button'
import { SecondaryButtonText } from '@flomni/components/dist/components/secondary-button/text'
import { RadioOption } from '@flomni/components/dist/components/radio-option'
import { Tag } from '@flomni/components/dist/components/tag'
import { Tip } from '@flomni/components/dist/components/tip'
import { NavLink } from 'react-router-dom'
import { ROUTE, routes } from '../../../../../configs/routes'
import { useForm } from 'react-hook-form'
import { Meteor, useTracker } from '../../../../../../meteorAdapter';
import { useDispatch } from 'react-redux'
import { object } from 'prop-types'
import { ButtonWithLoader } from '../../../../shared/button-with-loader'
import { Spinner } from '@flomni/components/dist/components/spinner'
import classnames from 'classnames'
import { processError } from '../../../../../services/helpers'

const NotificationsPage = ({ user }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const [showLinkerLoader, setShowLinkerLoader] = useState(true)
  const [showSaveLoader, setShowSaveLoader] = useState(false)
  const [showRequestNotificationLoader, setShowRequestNotificationLoader] = useState(false)
  const [footerStatus, setFooterStatus] = useState('')
  const [notificationPermission, setNotificationPermission] = useState('default')
  const { register, watch, setValue, reset, formState, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      soundType: user.profile.sounds
    }
  })
  const soundType = watch('soundType')
  const browserSupportNotifications = 'Notification' in window
  const chatContainerClass = css('chat-container')

  useEffect(() => {
    if (browserSupportNotifications) {
      setNotificationPermission(window.Notification.permission)
    }
  }, [browserSupportNotifications])

  useEffect(() => {
    window.flomni = {
      forceUserHash: `dialogsUser-${Meteor.userId()}`,
      clientKey: '5ac66c7fcaf3190008653a3c',
      customizing: {
        'modules.linker.standalone.widget.startText': null,
        'modules.linker.standalone.layout': 'left'
      },
      onLoad: () => {
        setShowLinkerLoader(false)
      },
      onLinked: (profile) => {
        Meteor.invoke('users.updateProfile', {
          flomniRecipient: profile.generatedUserHashId
        })
          .then(() => {
            setFooterStatus(t('dlg:yourChangesSaved'))
          })
          .catch((err) => {
            processError(err, main)
          })
      }
    }
    const script = document.createElement('script')
    script.src = 'https://i.v2.flomni.com/linker.standalone.js'
    script.setAttribute('data-selector', `.${chatContainerClass}`)
    script.async = true
    document.body.appendChild(script)

    return () => {
      if (window.$flomni && window.$flomni.chat) {
        window.$flomni.chat.destroy()
        window.$flomni.chat = null
      }
      document.body.removeChild(script)
    }
  }, [])

  const onSave = () => {
    const { soundType } = getValues()
    setShowSaveLoader(true)

    setTimeout(() => {
      Meteor.invoke('users.updateProfile', {
        sounds: soundType
      })
        .then(() => {
          reset({
            soundType
          })
          setFooterStatus(t('dlg:yourChangesSaved'))
        })
        .catch((err) => {
          processError(err, main)
        })
        .finally(() => {
          setShowSaveLoader(false)
        })
    }, 800)
  }

  const onCancel = useCallback(() => {
    reset({
      soundType: user.profile.sounds
    })
    setFooterStatus(t('dlg:yourChangesDiscarded'))
  }, [user])

  const requestNotificationPermission = () => {
    setShowRequestNotificationLoader(true)
    window.Notification.requestPermission().then((result) => {
      setShowRequestNotificationLoader(false)
      setNotificationPermission(result)
    })
  }

  return (
    <div className={css('main')}>
      <div className={css('container')}>
        <div className={css('nav-bar')}>
          <NavLink className={css('link')} to={routes[ROUTE.SETTINGS]}>
            {t('dlg:dialogsSettings')}
          </NavLink>
          <div>></div>
          <div className={css('active')}>{t('dlg:notifications')}</div>
        </div>
        <input hidden name='soundType' ref={register()} />
        <div className={css('form')}>
          <div className={css('header')}>
            <div>{t('dlg:browserSoundsNotifications')}</div>
          </div>
          <div className={css('body')}>
            <div className={css('body-line')}>
              <div className={css('title')}>{t('dlg:notifyMeAbout')}</div>
              <div className={css('options')}>
                <div className={css('option')}>
                  <RadioOption
                    variation='secondary'
                    onChange={(e) => {
                      setValue('soundType', e.target.value, { shouldDirty: true })
                    }}
                    value='ALL'
                    checked={soundType === 'ALL'}
                    size='small'
                  >
                    {t('dlg:allNewMessages')}
                  </RadioOption>
                </div>
                <div className={css('option')}>
                  <RadioOption
                    variation='secondary'
                    onChange={(e) => {
                      setValue('soundType', e.target.value, { shouldDirty: true })
                    }}
                    value='PERSONAL'
                    checked={soundType === 'PERSONAL'}
                    size='small'
                  >
                    {t('dlg:privateMessages')}
                  </RadioOption>
                </div>
                <div className={css('option')}>
                  <RadioOption
                    variation='secondary'
                    onChange={(e) => {
                      setValue('soundType', e.target.value, { shouldDirty: true })
                    }}
                    value='DISABLED'
                    checked={soundType === 'DISABLED'}
                    size='small'
                  >
                    {t('dlg:nothing')}
                  </RadioOption>
                </div>
              </div>
            </div>
            {browserSupportNotifications && (
              <>
                <div className={css('body-line')}>
                  <div className={css('title')}>{t('dlg:desktopNotifications')}</div>
                  <div>
                    {notificationPermission === 'denied' && (
                      <div className={css('denied')}>
                        <Tag color='red' variation='secondary'>{t('dlg:denied')}</Tag>
                        <div className={css('hint')}>{t('dlg:changeNotificationPreferences')}:</div>
                      </div>
                    )}
                    {notificationPermission === 'default' && (
                      <ButtonWithLoader
                        text={t('dlg:enable')}
                        onClick={requestNotificationPermission}
                        spinnerSize={10}
                        spinnerStrokeWidth={14}
                        isLoading={showRequestNotificationLoader}
                      />
                    )}
                    {notificationPermission === 'granted' && (
                      <Tag color='green' variation='secondary'>{t('dlg:enabled')}</Tag>
                    )}
                  </div>
                </div>
                {notificationPermission === 'denied' && (
                  <Tip variation='secondary' view='warning'>
                    <p className={css('rubric')} dangerouslySetInnerHTML={{
                      __html: t('dlg:changeNotificationPreferencesSteps.chrome')
                    }} />
                    <p className={css('rubric')} dangerouslySetInnerHTML={{
                      __html: t('dlg:changeNotificationPreferencesSteps.firefox')
                    }} />
                    <p className={css('rubric')} dangerouslySetInnerHTML={{
                      __html: t('dlg:changeNotificationPreferencesSteps.safari')
                    }} />
                    <p className={css('rubric')} dangerouslySetInnerHTML={{
                      __html: t('dlg:changeNotificationPreferencesSteps.edge')
                    }} />
                  </Tip>
                )}
              </>
            )}
          </div>
          <div className={css('footer')}>
            <div className={css('footer-status')}>{footerStatus}</div>
            <div className={css('footer-buttons')}>
              <SecondaryButton view='light' onClick={onCancel} disabled={!formState.isDirty}>
                <SecondaryButtonText>{t('dlg:cancel')}</SecondaryButtonText>
              </SecondaryButton>
              <ButtonWithLoader
                text={t('dlg:save')}
                onClick={onSave}
                disabled={!formState.isValid || !formState.isDirty}
                spinnerSize={10}
                spinnerStrokeWidth={14}
                isLoading={showSaveLoader}
              />
            </div>
          </div>
        </div>
        <div className={css('form')}>
          <div className={css('header')}>
            <div>{t('dlg:pushAlertIntoMessenger')}</div>
          </div>
          <div className={css('body')}>
            <div className={css('body-line-messenger')}>
              <div className={css(classnames('title', !showLinkerLoader ? '--linker-title' : null))}>
                {t('dlg:connectAnyMessenger')}
              </div>
              <div>
                {showLinkerLoader && (
                  <div className={css('spinner')}>
                    <Spinner strokeWidth={5} />
                  </div>
                )}
                <div className={chatContainerClass} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

NotificationsPage.propTypes = {
  user: object
}

export const Notifications = () => {
  const user = useTracker(() => Meteor.user(), [])
  return user ? <NotificationsPage user={user} /> : null
}
