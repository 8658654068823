import React from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { Meteor, useTracker } from '../../../../../meteorAdapter';
import { RadioOption } from '@flomni/components/dist/components/radio-option'
import { useDispatch, useSelector } from 'react-redux'
import { SecondaryDropdownItem } from '@flomni/components/dist/components/secondary-dropdown-item/item'
import { SecondaryDropdownItemText } from '@flomni/components/dist/components/secondary-dropdown-item/text'
import { SecondaryDropdownItemIcon } from '@flomni/components/dist/components/secondary-dropdown-item/icon'
import { SvgIconArrowRight } from '@flomni/components/dist/components/svg/feathers/SvgIconArrowRight'
import { func, string, object } from 'prop-types'
import { processError } from '../../../../services/helpers'
import { AgentStatuses } from '../../../../customStores'
import { useEmptyQueue } from '../../shared/useEmptyQueue'

export const HeaderMenu = ({ agentStatus, onStatusChange, emptyQueue, setEmptyQueue }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const currentUserGroupId = useSelector(({ main }) => main.currentUserGroupId)

  const agentStatuses = useTracker(() => {
    return AgentStatuses.find(
      {
        groupId: currentUserGroupId,
        isActive: true,
        isHidden: false
      },
      {
        fields: {
          status: 1
        }
      }
    ).fetch()
  }, [currentUserGroupId])

  const handlerChangeStatus = (agentStatus) => {
    main.showGlobalLoader(true)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { emptyQueueTimeMs, emptyQueueNoActiveChatsMs, emptyQueueSlotsAvailableMs} = useEmptyQueue(emptyQueue, setEmptyQueue)
    Meteor.invoke('users.changeAgentStatus', {
      status: agentStatus,
      emptyQueueTimeMs,
      emptyQueueNoActiveChatsMs,
      emptyQueueSlotsAvailableMs
    })
      .catch((err) => {
        processError(err, main)
      })
      .finally(() => {
        main.showGlobalLoader(false)
      })
    onStatusChange()
  }

  return (
    <>
      <SecondaryDropdownItem view='header'>
        <SecondaryDropdownItemText>{t('dlg:statuses')}</SecondaryDropdownItemText>
      </SecondaryDropdownItem>
      {agentStatuses.map((status) => (
        <div key={status._id} className={css('statuses-menu-item')}>
          <RadioOption
            variation='secondary'
            value={status._id}
            checked={agentStatus === status.status}
            onChange={() => handlerChangeStatus(status.status)}
          >
            {t(`dlg:agentStatuses.${status.status}`)}
          </RadioOption>
        </div>
      ))}
      <div className={css('statuses-menu-separator')} />
      <SecondaryDropdownItem view='header'>
        <SecondaryDropdownItemText>{t('dlg:actions')}</SecondaryDropdownItemText>
      </SecondaryDropdownItem>
      <SecondaryDropdownItem
        view='bright'
        onClick={() => {
          onStatusChange()
          Meteor.logout(() => {
            main.logout()
          })
        }}
      >
        <SecondaryDropdownItemText>{t('dlg:logOut')}</SecondaryDropdownItemText>
        <SecondaryDropdownItemIcon>
          <SvgIconArrowRight />
        </SecondaryDropdownItemIcon>
      </SecondaryDropdownItem>
    </>
  )
}

HeaderMenu.propTypes = {
  agentStatus: string,
  onStatusChange: func.isRequired,
  emptyQueue: object,
  setEmptyQueue: func
}
