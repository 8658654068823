import { NavLink } from 'react-router-dom'
import { ROUTE, routes } from '../../../../../../configs/routes'
import React from 'react'
import styles from '../../index.module.scss'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import { useSelector } from 'react-redux'
import { mainSelectors } from '../../../../../../state/main/selectors'
import { useTranslation } from 'react-i18next'

const SettingsTools = () => {
  const css = useStyles(styles)

  const { t } = useTranslation()

  const isCurrentUserOperator = useSelector(mainSelectors.getIsUserOperator)

  return (
    <div className={css('items')}>
      <div className={css('item-header')}>{t('dlg:tools')}</div>
      <div className={css('links')}>
        <div>
          <NavLink className={css('link')} to={routes[ROUTE.MESSAGE_TEMPLATES]}>
            {t('dlg:messageTemplates')}
          </NavLink>
        </div>
        <div>
          <NavLink className={css('link')} to={routes[ROUTE.MESSAGE_BUTTONS]}>
            {t('dlg:messageButtons')}
          </NavLink>
        </div>
        {!isCurrentUserOperator && (
          <div>
            <NavLink className={css('link')} to={routes[ROUTE.WORKFORCE_MANAGEMENT]}>
              {t('dlg:workforceManagement')}
            </NavLink>
          </div>
        )}
      </div>
    </div>
  )
}

export {
  SettingsTools
}
