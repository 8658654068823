import React, { useCallback, useState, useEffect } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { Dropdown } from '@flomni/components/dist/components/dropdown'
import { NavLink } from 'react-router-dom'
import { ROUTE, routes } from '../../../../configs/routes'
import { Avatar } from '../../shared/avatar'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { SvgIconArrowDownChevron } from '@flomni/components/dist/components/svg/feathers/SvgIconArrowDownChevron'
import { PlateButtonText } from '@flomni/components/dist/components/plate-button/text'
import { Tag } from '@flomni/components/dist/components/tag'
import { bool, func, number, object, string } from 'prop-types'
import classnames from 'classnames'
import { HeaderMenu } from '../menu'

export const HeaderDesktop = ({
  dialogsCount,
  hasSearch,
  hasAllChats,
  agentStatus,
  profile,
  status,
  doNotDisturb,
  getProfileName,
  emptyQueue,
  setEmptyQueue
}) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const [isStatusesOpened, setStatusesOpened] = useState(false)
  const [isShow, setIsShow] = useState(false)

  const overlayClickHandler = useCallback(() => {
    setStatusesOpened(false)
  }, [])

  useEffect(() => {
    setIsShow(true)
  }, [])

  return (
    <div className={css(classnames('header'))}>
      <div className={css(classnames('logo-block', isShow ? '--show' : null))} />
      <div className={css(classnames('menu', isShow ? '--show' : null))}>
        <NavLink className={css('menu-item')} activeClassName={css('--active')} to={routes[ROUTE.DASHBOARD]}>
          <div className={css('menu-icon')}>
            <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                className={css('menu-icon-light')}
                d='M6.66663 0.499512H1.66663C1.02332 0.499512 0.5 1.02283 0.5 1.66614V4.66614C0.5 5.30957 1.02332 5.83289 1.66663 5.83289H6.66663C7.31006 5.83289 7.83337 5.30957 7.83337 4.66614V1.66614C7.83337 1.02283 7.31006 0.499512 6.66663 0.499512Z'
                fill='#A3ACB9'
              />
              <path
                className={css('menu-icon-dark')}
                d='M6.66663 7.16602H1.66663C1.02332 7.16602 0.5 7.68933 0.5 8.33276V15.3328C0.5 15.9761 1.02332 16.4994 1.66663 16.4994H6.66663C7.31006 16.4994 7.83337 15.9761 7.83337 15.3328V8.33276C7.83337 7.68933 7.31006 7.16602 6.66663 7.16602Z'
                fill='#4F566B'
              />
              <path
                className={css('menu-icon-dark')}
                d='M15.3334 11.166H10.3334C9.68994 11.166 9.16663 11.6893 9.16663 12.3328V15.3328C9.16663 15.9761 9.68994 16.4994 10.3334 16.4994H15.3334C15.9767 16.4994 16.5 15.9761 16.5 15.3328V12.3328C16.5 11.6893 15.9767 11.166 15.3334 11.166Z'
                fill='#4F566B'
              />
              <path
                className={css('menu-icon-light')}
                d='M15.3334 0.499512H10.3334C9.68994 0.499512 9.16663 1.02283 9.16663 1.66614V8.66614C9.16663 9.30957 9.68994 9.83289 10.3334 9.83289H15.3334C15.9767 9.83289 16.5 9.30957 16.5 8.66614V1.66614C16.5 1.02283 15.9767 0.499512 15.3334 0.499512V0.499512Z'
                fill='#A3ACB9'
              />
            </svg>
          </div>
          <div className={css('menu-title')}>{t('dlg:dashboard')}</div>
        </NavLink>
        {hasSearch && (
          <NavLink className={css('menu-item')} activeClassName={css('--active')} to={routes[ROUTE.SEARCH]}>
            <div className={css('menu-icon')}>
              <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  className={css('menu-icon-dark')}
                  d='M16.1288 13.4832L12.7344 10.6034C12.6537 10.536 12.5547 10.4996 12.4543 10.4996C12.3139 10.4996 12.1803 10.5703 12.0968 10.6888C11.7105 11.2366 11.2369 11.7106 10.689 12.0972C10.587 12.1692 10.5194 12.2793 10.5037 12.3992C10.4879 12.5193 10.523 12.638 10.6026 12.7334L13.4842 16.1308C13.6795 16.3651 13.963 16.5003 14.2609 16.5003C14.5287 16.5003 14.7837 16.3928 14.979 16.1975L16.1971 14.9794C16.4047 14.7717 16.5119 14.5008 16.499 14.2164C16.4859 13.9317 16.3545 13.6714 16.1288 13.4832Z'
                  fill='#4F566B'
                />
                <path
                  className={css('menu-icon-light')}
                  d='M6.41716 12.2511C7.98647 12.2511 9.46202 11.6399 10.5718 10.5301C11.6817 9.4202 12.2929 7.94477 12.2929 6.37537C12.2929 3.13552 9.65706 0.499634 6.41716 0.499634C3.17723 0.499634 0.541504 3.13552 0.541504 6.37537C0.541504 9.61522 3.17731 12.2511 6.41716 12.2511ZM2.44561 6.37537C2.44561 4.18543 4.22722 2.40382 6.41716 2.40382C8.60712 2.40382 10.3887 4.18543 10.3887 6.37537C10.3887 7.43447 9.97519 8.43133 9.22417 9.18227C8.47312 9.93324 7.47626 10.3468 6.41716 10.3468C4.22722 10.3469 2.44561 8.56528 2.44561 6.37537Z'
                  fill='#A3ACB9'
                />
              </svg>
            </div>
            <div className={css('menu-title')}>{t('dlg:search')}</div>
          </NavLink>
        )}
        <div className={css('menu-separator')} />
        <NavLink
          className={css('menu-item')}
          activeClassName={css('--active')}
          to={routes[ROUTE.CONVERSATIONS]}
        >
          <div className={css('menu-icon')}>
            <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                className={css('menu-icon-dark')}
                fillRule='evenodd'
                clipRule='evenodd'
                d='M15.7115 7.22712C15.6447 7.42327 15.5666 7.61627 15.4768 7.80567L15.9909 10.3764C16.0487 10.6656 15.8274 10.937 15.5312 10.937C15.5256 10.937 15.5221 10.9373 15.5186 10.9375C15.4813 10.9395 15.4433 10.9287 12.8379 10.4136C12.1077 10.76 11.3246 10.9358 10.5077 10.9369C9.99013 10.9362 9.4892 10.8632 9.01446 10.7275C9.6334 9.89516 9.99999 8.86458 9.99999 7.75C9.99999 5.24461 8.14771 3.16368 5.74066 2.80473C6.71652 1.06229 8.61809 -0.0932614 10.7703 0.00592205L10.7705 0.0064847C14.4036 0.182262 16.8646 3.7923 15.6944 7.22712H15.7115Z'
                fill='#4F566B'
              />
              <path
                className={css('menu-icon-light')}
                d='M5.17028 11.488C7.23472 11.488 8.91428 9.80845 8.91428 7.74401C8.91428 5.67956 7.23472 4 5.17028 4C3.10583 4 1.42627 5.67956 1.42627 7.74401C1.42627 9.80845 3.10583 11.488 5.17028 11.488Z'
                fill='#A3ACB9'
              />
              <path
                className={css('menu-icon-light')}
                d='M8.66952 11.0464C7.79124 11.9765 6.54748 12.558 5.1703 12.558C3.79311 12.558 2.54935 11.9765 1.67108 11.0464C0.664472 11.7182 0 12.8642 0 14.1626V16.4803H10.3406V14.1626C10.3406 12.8642 9.67612 11.7182 8.66952 11.0464Z'
                fill='#A3ACB9'
              />
            </svg>
            {dialogsCount > 0 && (
              <Tag color='green' variation='secondary' view='filled' classes={{ root: css('counter') }}>
                {dialogsCount}
              </Tag>
            )}
          </div>
          <div className={css('menu-title')}>{t('dlg:myChats')}</div>
        </NavLink>
        {hasAllChats && (
          <NavLink className={css('menu-item')} activeClassName={css('--active')} to={routes[ROUTE.CHATBOT]}>
            <div className={css('menu-icon')}>
              <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  className={css('menu-icon-light')}
                  d='M10.9123 4.06325C10.009 2.21889 8.11953 0.999512 6.00011 0.999512C2.96746 0.999512 0.500241 3.45273 0.500241 6.46813C0.500241 7.28761 0.676174 8.07306 1.02342 8.80539L0.509334 11.3761C0.443898 11.7033 0.732579 11.9926 1.05988 11.9278L3.66229 11.4133C4.01269 11.5795 4.37518 11.7059 4.74764 11.793C3.91275 7.83872 6.91005 4.12694 10.9123 4.06325Z'
                  fill='#A3ACB9'
                />
                <path
                  className={css('menu-icon-dark')}
                  d='M15.9768 12.8052C16.0666 12.6158 16.1447 12.4228 16.2115 12.2266H16.1944C17.3646 8.79181 14.9036 5.18177 11.2705 5.006C11.2705 5.00581 11.2704 5.00562 11.2704 5.00543C8.14431 4.86137 5.54712 7.36431 5.54712 10.4679C5.54712 13.4808 7.99584 15.9321 11.0077 15.9365C11.8247 15.9354 12.6077 15.7595 13.3379 15.4131C16.1881 15.9766 15.9658 15.9365 16.0312 15.9365C16.3275 15.9365 16.5487 15.6651 16.4909 15.3759L15.9768 12.8052Z'
                  fill='#4F566B'
                />
              </svg>
            </div>
            <div className={css('menu-title')}>{t('dlg:allChats')}</div>
          </NavLink>
        )}
        <NavLink className={css(['menu-item'])} activeClassName={css('--active')} to={routes[ROUTE.EVENTS]}>
          <div className={css('menu-icon')}>
            <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                className={css('menu-icon-dark')}
                d='M10.2647 12.0613C9.92695 12.307 9.60479 12.3236 9.41229 12.3022C8.92229 12.2474 8.48266 11.878 8.23632 11.3141C8.07101 10.9357 7.98307 10.4275 8.08229 9.89111C7.30363 10.3079 6.30457 11.2543 6.30457 13.3669C6.30457 14.5774 7.28941 15.5622 8.49994 15.5622C9.71048 15.5622 10.6953 14.5774 10.6953 13.3669C10.6954 12.8939 10.5418 12.4364 10.2647 12.0613Z'
                fill='#4F566B'
              />
              <path
                className={css('menu-icon-light')}
                d='M15.0343 9.5854L14.9636 8.32459L14.194 9.32568C14.1327 9.40537 14.0022 9.54634 13.8356 9.67484C13.9015 9.45678 13.9602 9.20806 13.9991 8.93375C14.1335 7.98375 14.0498 6.52569 12.8879 4.97435L12.4004 4.32341L12.0809 5.07128C11.7124 5.93397 11.1162 6.36675 10.5621 6.17388C10.1355 6.02544 9.78878 5.53722 9.61087 4.83432C9.44494 4.17872 9.3485 3.02504 9.83165 1.25195L10.0366 0.499512L9.27581 0.670886C6.70957 1.24901 4.98029 3.07141 4.27488 5.94109C3.97835 7.14741 3.92251 8.29025 3.93082 9.06321C3.32445 8.573 3.02167 8.14287 3.01376 8.1315L2.43617 7.28294L2.17307 8.2765C2.02848 8.82259 1.9552 9.38753 1.9552 9.95562C1.9552 13.5375 4.84757 16.456 8.41931 16.4995C6.72854 16.4565 5.36635 15.0677 5.36635 13.3668C5.36635 11.174 6.30835 10.0058 7.0986 9.41187C7.96088 8.76381 8.83047 8.64865 8.86706 8.64406L9.95237 8.50806L9.30287 9.38818C8.89359 9.94277 8.92784 10.5526 9.09634 10.9384C9.21825 11.2174 9.39531 11.356 9.51665 11.3696C9.66969 11.387 9.82362 11.2114 9.90168 11.1054L10.2082 10.6889L10.593 11.0344C11.2544 11.6282 11.6338 12.4784 11.6338 13.3668C11.6338 15.0677 10.2716 16.4565 8.58081 16.4995C12.1526 16.456 15.0449 13.5375 15.0449 9.95562C15.045 9.83581 15.0414 9.71121 15.0343 9.5854Z'
                fill='#A3ACB9'
              />
            </svg>
          </div>
          <div className={css('menu-title')}>{t('dlg:events')}</div>
        </NavLink>
        <div className={css('menu-separator')} />
        <NavLink className={css(['menu-item'])} activeClassName={css('--active')} to={routes[ROUTE.SETTINGS]}>
          <div className={css('menu-icon')}>
            <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                className={css('menu-icon-dark')}
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3.625 9.24951C1.62297 9.24951 0 10.8725 0 12.8745C0 14.8765 1.62297 16.4995 3.625 16.4995H12.375C14.377 16.4995 16 14.8765 16 12.8745C16 10.8725 14.377 9.24951 12.375 9.24951H3.625ZM12.375 15.2495C13.6867 15.2495 14.75 14.1862 14.75 12.8745C14.75 11.5628 13.6867 10.4995 12.375 10.4995C11.0633 10.4995 10 11.5628 10 12.8745C10 14.1862 11.0633 15.2495 12.375 15.2495Z'
                fill='#4F566B'
              />
              <path
                className={css('menu-icon-light')}
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3.625 0.499512C1.62297 0.499512 0 2.12248 0 4.12451C0 6.12654 1.62297 7.74951 3.625 7.74951H12.375C14.377 7.74951 16 6.12654 16 4.12451C16 2.12248 14.377 0.499512 12.375 0.499512H3.625ZM3.625 6.49951C4.93668 6.49951 6 5.43619 6 4.12451C6 2.81284 4.93668 1.74951 3.625 1.74951C2.31332 1.74951 1.25 2.81284 1.25 4.12451C1.25 5.43619 2.31332 6.49951 3.625 6.49951Z'
                fill='#A3ACB9'
              />
            </svg>
          </div>
          <div className={css('menu-title')}>{t('dlg:settings')}</div>
        </NavLink>
      </div>
      <div className={css('right-menu')}>
        <div className={css('user-block')}>
          {profile?.avatarUrl ? (
            <Avatar url={profile?.avatarUrl} />
          ) : (
            <div className={css('user-logo')}>{getProfileName()}</div>
          )}
          <div className={css(classnames('user-status', status))}>
            {doNotDisturb && <div className={css('not-disturb')} />}
          </div>
        </div>
        {!!agentStatus && (
          <div>
            <PlateButton
              classes={{ root: css('status-btn') }}
              noBg
              view='usual'
              onClick={() => setStatusesOpened(true)}
            >
              <PlateButtonText>{t(`dlg:agentStatuses.${agentStatus}`)}</PlateButtonText>
              <PlateButtonIcon>
                <SvgIconArrowDownChevron />
              </PlateButtonIcon>
            </PlateButton>
            <Dropdown
              variation='secondary'
              active={isStatusesOpened}
              position='right'
              classes={{ root: css('menu-dropdown') }}
              onOverlayClick={overlayClickHandler}
            >
              <HeaderMenu
                agentStatus={agentStatus}
                onStatusChange={() => setStatusesOpened(false)}
                emptyQueue={emptyQueue}
                setEmptyQueue={setEmptyQueue}
              />
            </Dropdown>
          </div>
        )}
      </div>
    </div>
  )
}

HeaderDesktop.propTypes = {
  dialogsCount: number,
  hasSearch: bool,
  hasAllChats: bool,
  profile: object,
  agentStatus: string,
  status: string,
  doNotDisturb: bool,
  getProfileName: func.isRequired,
  emptyQueue: object,
  setEmptyQueue: func
}
