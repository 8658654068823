import React, { useEffect, useState } from 'react'
import useStyles from '@flomni/modules/dist/helpers/useStyles'
import styles from './index.module.scss'
import { Trans, useTranslation } from 'react-i18next'
import { Tag } from '@flomni/components/dist/components/tag'
import { SvgIconShow } from '@flomni/components/dist/components/svg/feathers/SvgIconShow'
import { Meteor, useTracker } from '../../../../../../meteorAdapter';
import { FiltersPopup } from '../../../shared/filters-popup'
import { SortPopup } from '../../../shared/sort-popup'
import { Spinner } from '@flomni/components/dist/components/spinner'
import { DateUtils } from '@flomni/modules/dist/services/date'
import { PlateButton } from '@flomni/components/dist/components/plate-button/button'
import { PlateButtonIcon } from '@flomni/components/dist/components/plate-button/icon'
import { SvgIconArrowLeft } from '@flomni/components/dist/components/svg/feathers/SvgIconArrowLeft'
import { SvgIconArrowRight } from '@flomni/components/dist/components/svg/feathers/SvgIconArrowRight'
import { Select } from '@flomni/components/dist/components/select'
import { SvgIconReminder } from '@flomni/components/dist/components/svg/feathers/SvgIconReminder'
import { ROUTE, routes } from '../../../../../configs/routes'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { processError } from '../../../../../services/helpers'
import { UserAvatarStatus } from '../../../shared/user-avatar-status'
import { Staff } from '../../../../../customStores'

export const RemindersTab = ({ userId, startDate, endDate }) => {
  const css = useStyles(styles)
  const { t } = useTranslation()
  const { main } = useDispatch()
  const currentUserGroupId = useSelector(({ main }) => main.currentUserGroupId)
  const history = useHistory()
  const [events, setEvents] = useState([])
  const [filteredEvents, setFilteredEvents] = useState([])
  const [filterGroups, setFilterGroups] = useState(null)
  const [sortFields, setSortFields] = useState(null)
  const [filterItems] = useState([])
  const [showLoader, setShowLoader] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalEvents, setTotalEvents] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [countOnPage, setCountOnPage] = useState(10)

  const [sortItems] = useState({
    checkedId: null,
    items: []
  })

  const updateEvents = () => {
    setShowLoader(true)
    Meteor.invoke('agentNotifications.fetchNotifications', {
      kind: 'reminder',
      offset: currentPage * countOnPage,
      limit: countOnPage,
      userId,
      startDate,
      endDate
    })
      .then((response) => {
        setEvents(response.notifications)
        const total = response.total || 0
        const totalPages = Math.ceil(total / countOnPage)

        setTotalEvents(total || 0)
        setTotalPages(totalPages < 1 ? 1 : totalPages)
        applyFilters(response.notifications, filterGroups, sortFields)
      })
      .catch((err) => {
        processError(err, main)
      })
      .finally(() => {
        setShowLoader(false)
      })
  }

  useEffect(() => {
    updateEvents()
    setSortFields(sortItems)
  }, [])

  useEffect(() => {
    updateEvents()
  }, [currentPage, countOnPage, userId, startDate, endDate])

  const applyFilters = (list, filterGroupsIds, sortItems) => {
    const filteredList = [...list]
    setFilteredEvents(filteredList)
  }

  const onSetFilter = (items) => {
    const selectedGroupsIds = []
    items.forEach((item) => {
      if (item.isChecked) {
        item.items.forEach((group) => {
          if (group.isChecked) {
            selectedGroupsIds.push(group.id)
          }
        })
      }
    })
    setFilterGroups(selectedGroupsIds)
    applyFilters(events, selectedGroupsIds, sortFields)
  }

  const onSetSort = (items) => {
    setSortFields(items)
    applyFilters(events, filterGroups, items)
  }

  const getReceiver = (item) => {
    return item.templateData?.receiver
  }

  const users = useTracker(() => {
    return Staff.find(
      {
        groupsIds: currentUserGroupId
      },
      {
        fields: {
          status: 1,
          profile: 1,
          doNotDisturb: 1
        }
      }
    ).map(({ _id, profile = {}, status }) => ({
      id: _id,
      status,
      profile
    }))
  }, [])

  const getUser = (item) => {
    const user = users.find((user) => user.id === item.templateData.mainUserId)
    return {
      id: item.templateData.mainUserId,
      status: user?.status,
      doNotDisturb: user?.doNotDisturb,
      profile: {
        avatarUrl: user?.profile?.avatarUrl,
        name: user?.profile?.name || item.templateData.mainUserName
      }
    }
  }

  const getChatName = (item) => {
    return item.templateData?.chatName || getReceiver(item)
  }

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1)
  }

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  const openChat = (item) => {
    const receiver = getReceiver(item)
    if (receiver) {
      history.push({
        pathname: routes[ROUTE.SEARCH],
        search: `?receiver=${receiver}`
      })
    }
  }

  return (
    <>
      {events.length === 0 && (
        <>
          {showLoader && (
            <div className={css('spinner')}>
              <Spinner strokeWidth={5} />
            </div>
          )}
          {!showLoader && (
            <div className={css('no-items')}>
              <div className={css('no-items-icon')}>
                <SvgIconReminder width={44} height={44} />
              </div>
              <div className={css('no-items-title')}>{t('dlg:youDontHaveReminders')}</div>
              <div className={css('no-items-description')}>{t('dlg:createFirstReminderSeeingHere')}</div>
            </div>
          )}
        </>
      )}
      {events.length > 0 && (
        <>
          <div className={css('toolbox')} style={{ display: 'none' }}>
            <SortPopup disabled config={sortItems} onDone={onSetSort} onReset={onSetSort} />
            <FiltersPopup disabled items={filterItems} onDone={onSetFilter} onReset={onSetFilter} />
          </div>
          <div className={css('table-header')}>
            <div>{t('dlg:state')}</div>
            <div>{t('dlg:dueDate')}</div>
            <div>{t('dlg:remindMessage')}</div>
            <div>{t('dlg:operator')}</div>
            <div>{t('dlg:chatWith')}</div>
          </div>
          {showLoader && (
            <div className={css('spinner')}>
              <Spinner strokeWidth={5} />
            </div>
          )}
          {!showLoader && (
            <>
              <div>
                {filteredEvents.map((item) => {
                  const isTriggered = item.scheduledAt
                    ? item.scheduledAt.getTime() < new Date().getTime()
                    : true

                  return (
                    <div key={item._id} className={css('item')}>
                      <div>
                        <Tag variation='secondary' color={isTriggered ? 'gray' : 'blue'}>
                          {isTriggered ? t('dlg:triggered') : t('dlg:upcoming')}
                        </Tag>
                      </div>
                      <div className={css('item-due-date')}>
                        {DateUtils.format(item.scheduledAt, DateUtils.DATE_TIME_FORMAT)}
                      </div>
                      <div className={css('item-message')}>{item.templateData.reminderText}</div>
                      <div>
                        <UserAvatarStatus item={getUser(item)} />
                      </div>
                      <div>
                        <div className={css('item-receiver')} onClick={() => openChat(item)}>
                          <div className={css('item-receiver-name')}>{getChatName(item)}</div>
                          <div className={css('item-receiver-eye')}>
                            <SvgIconShow />
                          </div>
                        </div>
                      </div>
                      <div className={css('item-date')}>
                        {DateUtils.format(item.createdAt, DateUtils.DATE_TIME_FORMAT)}
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className={css('table-footer')}>
                <div className={css('count-events')}>
                  <Trans
                    i18nKey='dlg:countEvents'
                    values={{
                      from: currentPage * countOnPage + 1,
                      to:
                        (currentPage + 1) * countOnPage > totalEvents
                          ? totalEvents
                          : (currentPage + 1) * countOnPage,
                      total: totalEvents
                    }}
                    components={[
                      <span className={css('text-semibold')}>0</span>,
                      1,
                      <span className={css('text-semibold')}>2</span>,
                      3,
                      <span className={css('text-semibold')}>4</span>,
                      5
                    ]}
                  />
                </div>
                <div className={css('right-block')}>
                  <div className={css('count-pages')}>
                    <span>{t('dlg:display')}</span>
                    <Select
                      variation='secondary'
                      options={[10, 20, 50].map((value) => {
                        return {
                          label: value,
                          value
                        }
                      })}
                      value={{
                        label: countOnPage,
                        value: countOnPage
                      }}
                      onChange={(option) => {
                        setCountOnPage(option.value)
                        setCurrentPage(0)
                      }}
                    />
                  </div>
                  <div className={css('pager')}>
                    <PlateButton noBg view='bright' onClick={handlePrevPage} disabled={currentPage === 0}>
                      <PlateButtonIcon>
                        <SvgIconArrowLeft />
                      </PlateButtonIcon>
                    </PlateButton>
                    <div className={css('pages')}>
                      <span className={css('current-page')}>{currentPage + 1}</span>
                      <span>/</span>
                      <span>{totalPages}</span>
                    </div>
                    <PlateButton
                      noBg
                      view='bright'
                      onClick={handleNextPage}
                      disabled={currentPage + 1 === totalPages}
                    >
                      <PlateButtonIcon>
                        <SvgIconArrowRight />
                      </PlateButtonIcon>
                    </PlateButton>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}
